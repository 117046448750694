/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useRef, useEffect, useCallback, useMemo, useState} from 'react';

import {AgGridReact} from 'ag-grid-react';

import {agHelpers, shotTableAgGridColumns} from '../../agHelpers';
import {findElementById} from '../../../helpers';
import {defaultColDef} from '../../../consts';

const ShotTable = props => {
  const {
    data,
    handleChange: setCurrentAd,
    setup,
    projectId,
    sizes,
    setFillAllModal,
    assets,
    rowIndex,
  } = props;

  const gridRef = useRef();
  const {setColumnWidth: agSetColumnWidth, handleFilterChange} = useMemo(() => agHelpers(gridRef), [
    gridRef,
  ]);

  const columnDefs = useMemo(
    () =>
      shotTableAgGridColumns(
        setup,
        sizes,
        data._id + 1,
        projectId,
        assets,
        setCurrentAd,
        setFillAllModal
      ),
    [setup, sizes, data._id, projectId, assets, setCurrentAd, setFillAllModal]
  );

  useEffect(() => {
    handleFilterChange();
  }, [gridRef, sizes, handleFilterChange]);

  const setColumnWidth = useCallback(() => {
    agSetColumnWidth();
    handleFilterChange();
  }, [agSetColumnWidth, handleFilterChange]);

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const processCellForClipboard = useCallback(params => {
    return params.value?.name ?? params.value;
  }, []);

  const doesExternalFilterPass = useCallback(
    node => {
      const size = findElementById(sizes, node.id);

      return size?.enabled;
    },
    [sizes]
  );

  return (
    <div style={{marginLeft: '30px', marginRight: '30px', borderLeft: '2px solid blue'}}>
      <div className="ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          rowData={data.shotSizes}
          rowHeight={64}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          detailRowAutoHeight
          refreshStrategy="rows"
          suppressRowClickSelection
          getRowId={params => params.data._id}
          suppressRowTransform
          isExternalFilterPresent={() => true}
          doesExternalFilterPass={doesExternalFilterPass}
          suppressContextMenu
          processCellForClipboard={processCellForClipboard}
          immutableData
          domLayout="autoHeight"
          onGridReady={onGridReady}
          stopEditingWhenCellsLoseFocus
        />
      </div>
    </div>
  );
};

export default ShotTable;
