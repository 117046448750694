/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import {Spinner, Toast, Tabs, Tab} from 'react-bootstrap';
import Draggable from 'react-draggable';
import {NimblePicker} from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';

import api from 'api';
import TooltipHelper from 'helpers/tooltip';
import {filterPreviews, getCommentTree, getFilteredAds, getUniqueDimensions} from './helpers';
import SharedFilters from './components/SharedFilters';
import ShareNavbar from './components/ShareNavbar';
import SharedEmailForm from './components/SharedEmailForm';
import SharedComment from './components/SharedComment';
import SharedPagination from './components/SharedPagination';
import SharedAd from './components/SharedAd';
import {initalShareFilter} from './consts';

const maxLimit = 250;

const SharedPage = () => {
  const {id: previewId} = useParams();
  // get current page string and convert it to number
  const savedCurrentPage = localStorage.getItem('currentPage') * 1;
  const resultsNum = localStorage.getItem('resultsNum') * 1;
  const maxCommentChar = 512;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [guest, setGuest] = useState(null);
  const [apiData, setApiData] = useState({});
  const [allApiData, setAllApiData] = useState({});
  const [filters, setFilters] = useState(initalShareFilter);
  const [adId, setAdId] = useState(null);
  const [adIndex, setAdIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(savedCurrentPage || 1);
  const [resultsPerPage, setResultsPerPage] = useState(resultsNum || 1);
  const [totalPages, setTotalPages] = useState(1);
  const commentRef = useRef();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName, setPageName] = useState('Loading shared page...');
  const [characterCount, setCharacterCount] = useState(0);
  const [activeTab, setActiveTab] = useState('active');
  const [activeCommentsNumber, setActiveCommentsNumber] = useState(0);
  const [resolvedCommentsNumber, setResolvedCommentsNumber] = useState(0);

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCloseModal = () => {
    setAdId(null);
    setCharacterCount(0);
    setIsModalVisible(false);
  };

  const isFilterApplyed = useMemo(() => {
    return !_.isEqual(filters, initalShareFilter);
  }, [filters]);

  const filteredData = useMemo(() => {
    if (!apiData.ads) {
      return [];
    }

    // filter all ads data
    if (isFilterApplyed) {
      return getFilteredAds(allApiData.ads, filters);
    }

    // filter pagination ads data
    return getFilteredAds(apiData.ads, filters);
  }, [allApiData.ads, apiData.ads, filters, isFilterApplyed]);

  const pageNumber = useMemo(() => {
    // get total page number for pagination
    return Math.ceil(totalPages / resultsPerPage);
  }, [resultsPerPage, totalPages]);

  const handleAddComment = async (comment, index) => {
    try {
      // post comment
      await api.publicSharedPage.addComment(previewId, adId, comment);

      // get result for ads at current page
      if (!isFilterApplyed) {
        const result = await axios.get(
          `https://${
            process.env.REACT_APP_API_DOMAIN
          }/api/dco/preview/${previewId}?page=${currentPage - 1}&limit=${resultsPerPage}`
        );

        // setApiData(result.data);
        setApiData(old => ({
          ...old,
          ads: old.ads.map((ad, i) => {
            if (i === index) {
              return {...ad, comments: result.data.ads[index].comments};
            }
            return ad;
          }),
        }));
      }

      // get all ads back
      const result = await axios.get(
        `https://${process.env.REACT_APP_API_DOMAIN}/api/dco/preview/${previewId}`
      );
      setAllApiData(result.data);
    } catch (err) {
      console.log('add comment error', err);
    }
  };

  const tabTitle = (title, count, key) => {
    const active = activeTab !== key;
    return (
      <div className="d-flex w-100 align-items-center justify-content-between">
        <span>{title}</span>
        <sup
          className={`${active ? 'gray-600-text' : 'primary-text'}  ml-5`}
          style={{
            borderRadius: '50%',
            display: 'inline-block',
            textAlign: 'center',
            width: '20px',
            lineHeight: '20px',
            backgroundColor: 'white',
            verticalAlign: 'middle',
            position: 'inherit',
          }}
        >
          {count}
        </sup>
      </div>
    );
  };

  const renderCustomBody = index => {
    return guest ? (
      <Tabs activeKey={activeTab} className="comments-tabs " onSelect={t => setActiveTab(t)}>
        <Tab eventKey="active" title={tabTitle('Active', activeCommentsNumber, 'active')}>
          <div className="shared-comments">
            <form
              className="d-flex align-items-end mb-2"
              style={{columnGap: '1rem'}}
              onSubmit={e => {
                e.preventDefault();
                handleAddComment(
                  {
                    name: guest.name,
                    email: guest.email,
                    text: commentRef.current.value,
                    userId: guest.id,
                    parentId: null,
                  },
                  index
                );
                commentRef.current.value = '';
              }}
            >
              <textarea
                className="shared-comment form-control"
                rows={8}
                ref={commentRef}
                placeholder="Enter comment"
                maxLength={maxCommentChar}
                onChange={e => setCharacterCount(e.target.value.length)}
              />
              <button type="submit" className="btn btn-primary">
                Send
              </button>
              <p
                className={`m-0 text-nowrap ${
                  characterCount === maxCommentChar ? 'text-danger' : ''
                }`}
                style={{fontSize: '0.75rem', width: 70, color: '#a7a7a7'}}
              >
                {characterCount} / {maxCommentChar}
              </p>
            </form>
            <div>
              {filteredData[index] &&
                getCommentTree(filteredData[index].comments)
                  ?.filter(comm => !comm.resolved)
                  ?.filter(comment => !comment.parentId)
                  .map(com => {
                    return (
                      <SharedComment
                        key={com.commentId}
                        comment={com}
                        onAddComment={handleAddComment}
                        guest={guest}
                        adIndex={index}
                      />
                    );
                  })}
            </div>
          </div>
        </Tab>
        <Tab eventKey="resolved" title={tabTitle('Resolved', resolvedCommentsNumber, 'resolved')}>
          <div className="shared-comments">
            <div>
              {filteredData[index] &&
                getCommentTree(filteredData[index].comments)
                  ?.filter(comm => comm.resolved)
                  ?.filter(comment => !comment.parentId)
                  .map(com => {
                    return (
                      <SharedComment
                        key={com.commentId}
                        comment={com}
                        onAddComment={handleAddComment}
                        guest={guest}
                        adIndex={index}
                      />
                    );
                  })}
            </div>
          </div>
        </Tab>
      </Tabs>
    ) : (
      <div>
        <p>
          As a guest you are able to leave comments and reply on existing comment, we just need your
          email and name
        </p>
        <SharedEmailForm />
        <p className="mt-4">
          Or sign in with your{' '}
          <Link to="/login">
            <b>
              <span className="mx-1">Grid8</span>
            </b>
          </Link>{' '}
          account
        </p>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const result = await axios.get(
          `https://${
            process.env.REACT_APP_API_DOMAIN
          }/api/dco/preview/${previewId}?page=${currentPage - 1}&limit=${resultsPerPage}`
        );
        const newPreviews = filterPreviews(result.data);
        setApiData(newPreviews);
        setPageName(result.data.title);
        setError(false);
      } catch (err) {
        console.log('shared error', err);
        setError(true);
      }
      setIsLoading(false);
    })();
  }, [currentPage, previewId, resultsPerPage]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const result = await axios.get(
          `https://${process.env.REACT_APP_API_DOMAIN}/api/dco/preview/${previewId}?page=0&limit=${maxLimit}`
        );
        const pageNums = Math.ceil(result.data.ads.length);
        const newPreviews = filterPreviews(result.data);
        setAllApiData(newPreviews);
        setTotalPages(pageNums);
      } catch (err) {
        console.log('shared error', err);
        setError(true);
      }
      setIsLoading(false);
    })();
  }, [previewId]);

  useEffect(() => {
    const guestUser = localStorage.getItem('guestUser');
    if (guestUser) {
      setGuest(JSON.parse(guestUser));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem('resultsNum', resultsPerPage);
  }, [resultsPerPage]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.keyCode === 27) {
        console.log('esc pressed');
        // 27 is the code for the escape key
        setIsModalVisible(false);
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="shared-page-wrapper">
      <ShareNavbar guest={guest} onSignIn={handleOpenModal} />
      {error ? (
        <h1 className="text-center mt-5">Feed Error</h1>
      ) : (
        <main className="p-5">
          <div className="mb-5" style={{width: 'fit-content'}}>
            <TooltipHelper
              placement="right"
              content={
                <div style={{textAlign: 'start'}}>
                  <p className="m-0">
                    <strong>Brand</strong>: {apiData?.brand}
                  </p>
                  <p className="m-0">
                    <strong>Team</strong>: {apiData?.team}
                  </p>
                  <p className="m-0">
                    <strong>Project</strong>: {apiData?.project}
                  </p>
                  <p className="m-0">
                    <strong>Shared by</strong>: {apiData?.sharedBy}
                  </p>
                  <p className="m-0">
                    <strong>DCO setup</strong>: {apiData?.dcoSetupName}
                  </p>
                </div>
              }
            >
              <h2 className="m-0 px-2">{pageName || 'Shared Page'}</h2>
            </TooltipHelper>
          </div>
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner>
              </div>
            ) : (
              <>
                <SharedFilters
                  filters={filters}
                  setFilters={setFilters}
                  sizes={getUniqueDimensions(apiData.ads)}
                  isFilterApplyed={isFilterApplyed}
                />
                {filteredData && !isFilterApplyed && (
                  <SharedPagination
                    setResultsPerPage={setResultsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    resultsPerPage={resultsPerPage}
                    totalPages={pageNumber}
                    maxLimit={maxLimit}
                  />
                )}
                <div className="d-flex flex-column pt-5" style={{rowGap: '4rem'}}>
                  {filteredData?.length
                    ? filteredData.map((ad, index) => {
                        return (
                          <SharedAd
                            key={ad.adId}
                            ad={ad}
                            setAdId={setAdId}
                            handleOpenModal={handleOpenModal}
                            setAdIndex={setAdIndex}
                            index={index}
                            handleCloseModal={handleCloseModal}
                            setIsModalVisible={setIsModalVisible}
                            isModalVisible={isModalVisible}
                            setActiveCommentsNumber={setActiveCommentsNumber}
                            setResolvedCommentsNumber={setResolvedCommentsNumber}
                          />
                        );
                      })
                    : null}
                </div>
                {isModalVisible && (
                  <Draggable cancel=".shared-comment">
                    <Toast
                      style={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: 700,
                        width: 700,
                        maxHeight: '50vh',
                        overflowY: 'auto',
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                      }}
                    >
                      <div
                        className="d-flex justify-content-between align-items-center pt-3 pb-3 px-4"
                        style={{
                          borderBottom: '1px solid lightgray',
                          cursor: 'grab',
                          backgroundColor: '#f9f9f9',
                          position: 'sticky',
                          top: 0,
                        }}
                      >
                        <h3 className="m-0">
                          {guest ? `${filteredData[adIndex].name}` : 'Sign in as guest'}
                        </h3>
                        <p
                          className="m-0 cursor-pointer p-2"
                          onClick={() => {
                            setIsModalVisible(false);
                            setCharacterCount(0);
                          }}
                        >
                          x
                        </p>
                      </div>
                      <Toast.Body>{renderCustomBody(adIndex)}</Toast.Body>
                    </Toast>
                  </Draggable>
                )}
              </>
            )}
          </div>
        </main>
      )}
    </div>
  );
};

export default connect(store => {
  console.log('store: ', store);
})(SharedPage);
