import React, {useState, useEffect, useMemo} from 'react';
import Loadable from 'react-loadable';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import FilePreviw from '../../helpers/filePreview';
import {ErrorMessages} from '../../helpers/error';
import Spinner from '../project/spinner';
import {getFile, captureLiveVideo} from './slice';
import {ImageCropModal} from '../../components/ImageCrop';
import {getUsersBasic} from '../user/slice';

const Comments = Loadable({loader: () => import('../comment'), loading: () => '...'});

const FileView = ({
  dispatch,
  users,
  file,
  match,
  history,
  id,
  smartCropperBrandId,
  location,
  onCloseToo,
}) => {
  const [fileVersion, setFileVersion] = useState(-1);
  const [captureInProgress, setCaptureInProgress] = useState(false);
  const [videoPreview, setVideoPreview] = useState(false);
  const [deletedAsset, setDeletedAsset] = useState(false);

  const hide = () => {
    document.body.classList.remove('side-modal-body');
    history.replace({pathname: match.url.replace(`/file/${match.params.fileId}`, '')});
    if (onCloseToo) {
      onCloseToo();
    }
  };

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(location.search);
      const params = urlParams.get('trash') === 'true' ? {trash: true} : {};
      if (params.trash) {
        setDeletedAsset(true);
      }
      const {payload, error} = await dispatch(
        getFile({
          key: `file/${id}`,
          id,
          params,
        })
      );
      if (!error) setFileVersion(payload.versions.length - 1);
    })();
  }, [id, location.search]);

  useEffect(() => {
    (async () => dispatch(getUsersBasic()))();
  }, [dispatch]);

  const urlToPromise = url => {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) reject(err);
        else resolve(data);
      });
    });
  };

  const downloadFile = () => {
    console.log('downloading file version', fileVersion);
    const fileToDownload = file?.asyncState?.[`file/${id}`]?.item;
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${fileToDownload.displayName}.zip`;

    const urls = [];
    console.log(fileToDownload);
    if (['FILE', 'FONT'].includes(fileToDownload.kind)) {
      urls.push({
        fileName: `${fileToDownload.path.replace('/', '')}/${fileToDownload.displayName}`.replace(
          /^\//,
          ''
        ),
        url: `${process.env.REACT_APP_S3_URL}/${
          fileToDownload.versions[fileVersion].name
        }?x=${Math.random()}`,
      });
    } else if (fileToDownload.kind === 'CREATIVE') {
      console.log('file to download', fileToDownload);
      fileToDownload.versions[fileVersion].files.forEach(f =>
        urls.push({
          fileName: `${fileToDownload.path.replace('/', '')}/${fileToDownload.displayName}/${
            fileToDownload.creativeTitle
          }${f}`.replace(/^\//, ''),
          url: `${process.env.REACT_APP_S3_URL}/${
            fileToDownload.versions[fileVersion].dir
          }${encodeURIComponent(f)}?x=${Math.random()}`,
        })
      );
    }

    urls.forEach(({url, fileName}) => {
      zip.file(fileName, urlToPromise(url), {binary: true});
      count += 1;
      if (count === urls.length) {
        zip.generateAsync({type: 'blob'}).then(content => {
          FileSaver(content, zipFilename);
        });
      }
    });
  };

  const createdBy = useMemo(() => {
    console.log('fileVersion', fileVersion);
    if (fileVersion === -1) {
      return '';
    }
    const userId = file?.asyncState?.[`file/${id}`]?.item?.versions?.[fileVersion]?.createdBy;
    console.log('userId:', userId);
    if (!userId) {
      return '';
    }

    const user = users.items.find(u => u._id === userId);
    console.log('user:', user, users);
    if (!user) {
      return '';
    }

    return `Uploaded by ${user.name} ${user.lastName}`;
  }, [fileVersion, file.asyncState, id, users.items]);

  // fileVersion !== -1 &&
  //   console.log({
  //     users,
  //     fileMain: file,
  //     file: file.asyncState[`file/${id}`]?.item?.versions[fileVersion],
  //   });

  console.log('filev', fileVersion);

  return (
    <Modal
      show
      animation={false}
      onHide={hide}
      backdrop={false}
      className={`side-modal ${
        file?.asyncState[`file/${id}`]?.item?.kind === 'CREATIVE' ? 'creative-dialog' : ''
      }`}
      dialogClassName={
        fileVersion !== -1 &&
        file.asyncState[`file/${id}`]?.item?.versions &&
        file.asyncState[`file/${id}`]?.item?.versions[fileVersion]?.embed
          ? 'embededLinkSize'
          : null
      }
      onShow={() => document.body.classList.add('side-modal-body')}
    >
      <Modal.Header>
        <button onClick={hide}>x</button>
      </Modal.Header>
      <Modal.Body className="mb-3">
        {file.asyncState[`file/${id}`] && file.asyncState[`file/${id}`].fetching && 'loading...'}
        {file.asyncState[`file/${id}`] && !file.asyncState[`file/${id}`].error && (
          <ErrorMessages error={(file.asyncState[`file/${id}`] || {}).error} />
        )}
        {file.asyncState[`file/${id}`] && file.asyncState[`file/${id}`].item._id && (
          <>
            <div className="d-flex justify-content-between">
              <h4>{file.asyncState[`file/${id}`].item.displayName} </h4>
              <div>
                {file.asyncState[`file/${id}`].item.kind !== 'CREATIVE' && (
                  <select
                    className="form-control custom-select custom-select-sm border-0 mb-1"
                    value={fileVersion}
                    onChange={e => setFileVersion(e.target.value)}
                  >
                    {file.asyncState[`file/${id}`].item.versions.map((v, k) => (
                      <option value={k} key={k}>
                        [
                        {moment(file.asyncState[`file/${id}`].item.versions[k].createdAt).format(
                          'YY/MM/DD HH:mm'
                        )}
                        ] version {k + 1}{' '}
                        {k === file.asyncState[`file/${id}`].item.versions.length - 1 && '(latest)'}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            {(file?.asyncState?.[`file/${id}`]?.item?.mime?.includes('image') ||
              file?.asyncState?.[`file/${id}`]?.item?.mime?.includes('video')) && (
              <div className="d-flex">
                {file?.asyncState?.[`file/${id}`]?.item?.mime?.includes('image') &&
                  smartCropperBrandId && (
                    <ImageCropModal
                      match={match}
                      asset={file.asyncState[`file/${id}`].item}
                      smartCropperBrandId={smartCropperBrandId}
                      version={fileVersion}
                      dispatch={dispatch}
                      hide={hide}
                    />
                  )}
              </div>
            )}

            {fileVersion !== -1 && (
              <FilePreviw
                key={id}
                asset={file.asyncState[`file/${id}`].item}
                version={fileVersion}
                setVersion={setFileVersion}
              />
            )}
            {file.asyncState[`file/${id}`].item.versions[fileVersion]?.url &&
              file.asyncState[`file/${id}`].item.versions[fileVersion]?.url.indexOf('m3u8') !=
                -1 && (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  {!captureInProgress && (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <button
                        type="button"
                        style={{border: 'none', backgroundColor: 'transparent', outline: 'none'}}
                        onClick={e => {
                          console.log(file.asyncState[`file/${id}`].item);
                          setCaptureInProgress(true);
                          captureLiveVideo({
                            projectId: file.asyncState[`file/${id}`].item.parents.projectId,
                            outputName: file.asyncState[`file/${id}`].item.displayName,
                            streamUrl:
                              file.asyncState[`file/${id}`].item.versions[fileVersion]?.url,
                            path:
                              file.asyncState[`file/${id}`].item.path +
                              (file.asyncState[`file/${id}`].item.path.length > 1 ? '/' : ''),
                          })
                            .then(r => {
                              console.log(r);
                              let newVid = r.data.newFiles.find(
                                o =>
                                  o.displayName ==
                                  file.asyncState[`file/${id}`].item.displayName + '.mp4'
                              );
                              if (newVid) {
                                console.log(newVid);
                                setVideoPreview(newVid.versions[newVid.versions.length - 1]);
                              } else
                                console.log(
                                  "can't find file with name",
                                  file.asyncState[`file/${id}`].item.displayName + '.mp4',
                                  newVid
                                );
                              setCaptureInProgress(false);
                            })
                            .catch(console.error);
                        }}
                      >
                        <img style={{marginBottom: 40}} src="/icons/record-livestream.svg" />
                      </button>
                      {videoPreview && (
                        <div>
                          <video
                            width={350}
                            height={200}
                            controls
                            key={`${process.env.REACT_APP_S3_URL}/${videoPreview.name}`}
                            className="dynamicVideoBugFix"
                          >
                            <source
                              src={`${process.env.REACT_APP_S3_URL}/${videoPreview.name}`}
                              type={'video/mp4'}
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </div>
                  )}
                  {captureInProgress && (
                    <div>
                      <Spinner />
                      capturing live video...
                    </div>
                  )}
                </div>
              )}
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {(file?.asyncState[`file/${id}`]?.item?.kind !== 'CREATIVE' ||
                (file?.asyncState[`file/${id}`]?.item?.kind === 'CREATIVE' &&
                  file?.asyncState[`file/${id}`]?.item?.variants.length === 1)) && (
                <span
                  className="downloadtxtbtn"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={downloadFile}
                >
                  Download
                </span>
              )}
              <span
                className="dco-edit-label"
                style={{
                  textTransform: 'none',
                }}
              >
                {createdBy}
              </span>
            </div>
            {!deletedAsset && (
              <div className="comment-textarea mt-2">
                <Comments
                  key={match.params.fileId}
                  type="file"
                  id={match.params.fileId}
                  parents={file.asyncState[`file/${id}`].item.parents}
                />
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default connect(store => ({
  file: store.file,
  users: store.user,
}))(withRouter(FileView));
