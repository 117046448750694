import React, {useEffect, useState} from 'react';

const DraggableList = ({items}) => {
  const [list, setList] = useState(items);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
    // e.dataTransfer.setData('draggedItemIndex', index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();

    if (draggedIndex === index) return; // Skip if hovering over the dragged item

    const newList = [...list];
    const draggedItem = newList[draggedIndex];
    newList.splice(draggedIndex, 1); // Remove dragged item
    newList.splice(index, 0, draggedItem); // Insert it in the new position

    setDraggedIndex(index);
    setList(newList);
    setFrames(newList);
  };

  const handleDrop = e => {
    setDraggedIndex(null);
  };

  useEffect(() => {
    setList([...items]);
  }, [items]);

  return (
    <div>
      {list.map((item, index) => (
        <div
          key={index}
          draggable
          onDragStart={e => handleDragStart(e, index)}
          onDragOver={e => handleDragOver(e, index)}
          onDrop={handleDrop}
          style={{
            padding: '8px',
            margin: '4px',
            backgroundColor: 'white',
            cursor: 'move',
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default DraggableList;
