/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, {useState} from 'react';
import {Accordion} from 'react-bootstrap';
import Select from 'react-select';
import {initalPrivateShareFilter, initalShareFilter} from '../consts';

const customSelectStyles = {
  control: provided => {
    return {
      ...provided,
      border: 'none',
      borderRadius: '0',
      borderBottom: '1px solid #081ee0',
      width: 150,
    };
  },
};

const SharedFilters = ({
  filters,
  setFilters,
  sizes = [],
  isFilterApplyed,
  privateFilter = false,
  disableAll = false,
}) => {
  const [show, setShow] = useState(false);

  const toggleSize = size => {
    const index = filters.sizes.findIndex(s => s === size);
    if (index >= 0) {
      // Size exists in the array, so remove it
      const newSizes = [...filters.sizes];
      newSizes.splice(index, 1);
      setFilters(old => ({...old, sizes: newSizes}));
    } else {
      // Size doesn't exist in the array, so add it
      setFilters(old => ({...old, sizes: [...old.sizes, size]}));
    }
  };

  return (
    <Accordion
      activeKey={show}
      className="border-0 dco-acc gray-200-background p-2"
      defaultActiveKey={false}
      alwaysOpen
    >
      <Accordion.Item className="" eventKey>
        <Accordion.Header
          className="dco-filters-header m-0"
          onClick={() => {
            setShow(old => !old);
          }}
        >
          <span
            className="icon icon-arrow-left dco-filters-header-icon x10 primary-background mr-2"
            style={{transform: show ? 'rotate(-270deg)' : 'rotate(270deg)'}}
          />
          <span>Filters</span>
          {isFilterApplyed && (
            <button
              type="button"
              className="ml-2 btn"
              style={{fontSize: 10, color: 'blue'}}
              onClick={e => {
                e.stopPropagation();
                setFilters(privateFilter ? initalPrivateShareFilter : initalShareFilter);
              }}
            >
              Clear filters
            </button>
          )}
        </Accordion.Header>
        <Accordion.Body
          className="d-flex flex-wrap p-2 pt-4 align-items-baseline"
          style={{columnGap: '2rem', rowGap: '2rem', width: 'calc(100% - 150px)'}}
        >
          {/* Search */}
          <div className="p-2" style={{background: 'white', width: 200, maxHeight: 135}}>
            <h5>Search</h5>
            <input
              placeholder="Name"
              className="form-control form-control-sm dco-edit-input"
              style={{height: 38}}
              onChange={e => setFilters(old => ({...old, name: e.target.value}))}
              value={filters.name}
              disabled={disableAll}
            />
          </div>
          {/* Status */}
          <div className="p-2" style={{background: 'white', maxHeight: 135}}>
            <h5>Review State</h5>
            <Select
              styles={customSelectStyles}
              isDisabled={disableAll}
              isSearchable={false}
              cacheOptions={false}
              placeholder="Select filter"
              value={{value: filters.status, label: filters.status}}
              options={[
                {value: 'Draft', label: 'Draft'},
                {value: 'In review', label: 'In review'},
                {value: 'Approved', label: 'Approved'},
                {value: 'On Hold', label: 'On Hold'},
              ]}
              onChange={e => {
                if (!e) {
                  setFilters(old => ({...old, status: ''}));
                  return;
                }
                setFilters(old => ({...old, status: e.value}));
              }}
            />
          </div>
          {/* Publish state */}
          <div className="p-2" style={{background: 'white', maxHeight: 135}}>
            <h5>Publish State</h5>
            <Select
              styles={customSelectStyles}
              isDisabled={disableAll}
              isSearchable={false}
              cacheOptions={false}
              placeholder="Select filter "
              value={{value: filters.publishedStatus, label: filters.publishedStatus}}
              options={['Published', 'Unpublished', 'Changed', 'Never Published'].map(s => ({
                label: s,
                value: s,
              }))}
              onChange={e => {
                if (!e) {
                  setFilters(old => ({...old, publishedStatus: ''}));
                  return;
                }
                setFilters(old => ({...old, publishedStatus: e.value}));
              }}
            />
          </div>
          {/* Sizes */}
          <div className="p-2" style={{background: 'white', maxWidth: 750}}>
            <h5>Sizes</h5>
            <div className="d-flex flex-wrap">
              {sizes.map((size, i) => {
                const isClicked = filters.sizes.some(item => item === size);

                return (
                  <button
                    key={i}
                    onClick={() => toggleSize(size)}
                    disabled={disableAll}
                    type="button"
                    className="shared-filter-icon-wrapper"
                    style={{
                      opacity: isClicked ? '0.6' : '1',
                    }} /* icon-invisible icon icon-view */
                  >
                    <p className="m-0 mr-2">{size}</p>
                    <div className={`icon ${isClicked ? 'icon-invisible' : 'icon-view'}`} />
                  </button>
                );
              })}
            </div>
          </div>
          {/* Comments */}
          {'hasComments' in filters && (
            <div className="p-2" style={{background: 'white', width: 200, maxHeight: 135}}>
              <h5>Has comments</h5>
              <Select
                /*defaultValue={[{value: "creativeForm.indexFile", label: creativeForm.indexFile}]}*/
                styles={customSelectStyles}
                isDisabled={disableAll}
                isClearable
                options={['Yes'].map(f => ({value: f, label: f}))}
                onChange={e => {
                  setFilters(old => ({...old, hasComments: e !== null && e.value === 'Yes'}));
                }}
              />
            </div>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SharedFilters;
