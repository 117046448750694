import React from 'react';
import Select from 'react-select/async';
import {unwrapResult} from '@reduxjs/toolkit';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {ErrorMessages} from './../../helpers/error';
import {createTeam} from './slice';
import {getBrands} from './../brand/slice';

const customStyles = {
  control: () => ({
    backgroundColor: '#fdfffe',
    backgroundClip: 'padding-box',
    border: '1px solid #eaeff1',
    borderRadius: '0.3125rem',
    cursor: 'text',
    display: 'flex',
  }),
  dropdownIndicator: () => ({
    padding: '4px',
  }),
};

const TeamForm = ({dispatch, team, auth, hide, id}) => {
  const loadBrands = async q => {
    const res = await dispatch(getBrands({q, limit: 20}));
    const brands = unwrapResult(res);
    return brands.items
      .filter(i => (auth.user.role === 'admin' ? true : !!auth.user.access.brand[i._id]))
      .map(i => ({value: i._id, label: i.name}));
  };

  const submit = async (form, formik) => {
    const final = Object.assign({}, form);
    final.brandId = (final.brandId || {}).value;
    const res = await dispatch(createTeam({id, form: final}));
    if (createTeam.rejected.match(res)) {
      formik.setErrors(res.payload);
      formik.setSubmitting(false);
    } else {
      hide();
    }
  };

  return (
    <Modal show onHide={hide}>
      <Modal.Header style={{justifyContent: 'space-between'}}>
        <Modal.Title>{id ? 'Edit team' : 'Create new team'}</Modal.Title>
        <button
          onClick={hide}
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{name: '', brandId: null}}
          onSubmit={submit}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize
        >
          {({isSubmitting, setFieldValue, values}) => (
            <Form className={isSubmitting || team.fetching ? 'loading' : ''}>
              <div className="form-group">
                <Select
                  defaultOptions
                  styles={customStyles}
                  name="brandId"
                  placeholder="Select a brand"
                  value={values.brandId}
                  onChange={selected => setFieldValue('brandId', selected)}
                  loadOptions={loadBrands}
                />
                <ErrorMessage
                  name="brandId"
                  render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                />
              </div>
              <div className="form-group">
                <Field type="text" name="name" placeholder="Name" className="form-control" />
                <ErrorMessage
                  name="name"
                  render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                />
              </div>

              <div className="form-group text-right">
                <button className="btn btn-secondary mr-3" type="button" onClick={hide}>
                  Cancel
                </button>
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              </div>
              <ErrorMessages error={team.error} />
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default connect(store => ({
  team: store.team,
  brand: store.brand,
  auth: store.auth,
}))(TeamForm);
