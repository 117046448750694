import React, {useRef, useState} from 'react';
import axios from 'axios';

import {validateEmail} from '../helpers';

const SharedEmailForm = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const nameRef = useRef('');
  const emailRef = useRef('');
  const loginEmailRef = useRef('');

  const handleLogin = async (email, e) => {
    if (!email) {
      return;
    }
    try {
      e.preventDefault();
      const result = await axios.get(
        `https://${process.env.REACT_APP_API_DOMAIN}/api/dco/preview/userEmail/${email}`
      );

      if (!result.data) {
        // no grid8 user
        // show guest user form
        setShowCreateForm(true);
        return;
      }

      const guestUser = {
        email,
        id: result.data.id,
        name: result.data.name,
        isGrid8user: result.data.grid8user || false,
      };

      window.localStorage.setItem('guestUser', JSON.stringify(guestUser));
      window.location.reload();
    } catch (error) {
      console.log('get email error', error);
    }
  };

  const onSubmit = async (name, email) => {
    if (!name || !email) {
      return;
    }

    const isValid = validateEmail(email);
    if (!isValid) {
      return;
    }

    try {
      const result = await axios.post(
        `https://${process.env.REACT_APP_API_DOMAIN}/api/dco/preview/createPublicUser`,
        {
          name,
          email,
        }
      );

      const guestUser = {
        email,
        id: result.data.id,
        token: result.data.token,
        name,
      };

      window.localStorage.setItem('guestUser', JSON.stringify(guestUser));
      window.location.reload();
    } catch (error) {
      console.log('create user error: ', error);
    }
  };

  return showCreateForm ? (
    <form
      className="d-flex my-2"
      style={{columnGap: 16}}
      onSubmit={e => {
        e.preventDefault();
        onSubmit(nameRef.current.value, emailRef.current.value);
      }}
    >
      <input className="form-control" ref={emailRef} type="email" placeholder="Email" required />
      <input className="form-control" ref={nameRef} placeholder="Enter full name" required />
      <button className="btn btn-primary" type="submit">
        Login
      </button>
    </form>
  ) : (
    <form className="d-flex" onSubmit={e => handleLogin(loginEmailRef.current.value, e)}>
      <input
        className="form-control"
        ref={loginEmailRef}
        type="email"
        placeholder="Email"
        required
      />
      <button className="btn btn-primary" type="submit">
        Send
      </button>
    </form>
  );
};

export default SharedEmailForm;
