/* eslint-disable react/jsx-no-useless-fragment */
import React, {useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import _ from 'lodash';

import {getAds, updateAd} from './slice';
import {SetupSelectPage, DcoHeader} from './components';
import {ChangedSetupModal} from './Tabs/components/Modals';
import {conformAdToSetup, updatePreflight} from './helpers';
import {LastChangedUserProvider} from '../../../hooks/useLastChangedUser';
import {getFiles} from '../../file/slice';
import api from '../../../api';

const DcoProject = ({ad, file, dispatch, parents, projectId}) => {
  const [tab, setTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [setups, setSetups] = useState([]);
  const [assets, setAssets] = useState({
    files: [],
    urls: [],
  });
  const [selectedSetup, setSelectedSetup] = useState({
    setup: null,
    continue: false,
  });
  const [currentAd, setCurrentAd] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [preflight, setPreflight] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const {payload: adData} = await dispatch(getAds({parents: {...parents, projectId}}));
        const setupsData = await api.dco.getSetups({
          parents: {brandId: parents.brandId},
        });
        await dispatch(getFiles({type: 'project', id: projectId}));
        setSetups(setupsData.data);
        const setupId = adData?.[0]?.setup;
        const setup = setupsData.data.find(s => s._id === setupId);

        if (!setup) {
          setLoading(false);
          return;
        }

        setSelectedSetup({
          setup: {
            ...setup,
            shotStructure: Object.fromEntries(
              _.orderBy(
                Object.entries(setup.shotStructure),
                o => {
                  return o[1].order;
                },
                'asc'
              )
            ),
          },
          continue: true,
        });
        let adVersionsMissMatches = 0;
        const mismatchedAds = [];
        const setupVsplit = setup.version.split('.').map(v => parseInt(v, 10));
        for (let aV = 0; aV < adData.length; aV += 1) {
          const adVer = adData[aV].version.split('.').map(v => parseInt(v, 10));
          if (setupVsplit[0] > adVer[0] || setupVsplit[1] > adVer[1]) {
            adVersionsMissMatches += 1;
            mismatchedAds.push(adData[aV]);
          }
        }
        if (adVersionsMissMatches) {
          const prefl = updatePreflight(setup, mismatchedAds[0]);
          setPreflight(prefl);
          console.log('preflight:', prefl);
          setShowModal(true);
        }
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
      setLoading(false);
    })();
  }, [dispatch, parents, projectId]);

  useEffect(() => {
    (async () => {
      if (!selectedSetup.setup) {
        return;
      }
      try {
        const assetData = (
          await Promise.all(
            selectedSetup.setup.sizes.map(async size => api.asset.getFile(size.assetId))
          )
        ).map(a => a.data);

        const filesKV = {};
        assetData.forEach(aFile => {
          filesKV[aFile._id] = aFile;
        });

        const newAssets = {
          files: filesKV,
          urls: assetData.map(aFile => {
            const version = aFile.versions[aFile.versions.length - 1];
            return `${version.dir}/${version.indexFile}`;
          }),
        };

        setAssets(newAssets);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [selectedSetup.setup]);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
    history.push('/');
  }, [history]);

  const handleModalSave = useCallback(
    async remaps => {
      console.log('remaps in save:', remaps);
      try {
        await dispatch(
          updateAd(
            ad.items.map(row => conformAdToSetup(selectedSetup.setup, row, file.items, remaps))
          )
        );
        setShowModal(false);
      } catch (error) {
        console.error(error);
      }
    },
    [ad.items, dispatch, file.items, selectedSetup.setup]
  );

  return (
    <LastChangedUserProvider>
      <div className={loading ? 'loading' : undefined}>
        {loading && <span>Loading...</span>}
        {!loading && !showModal && (
          <div>
            {!loading && selectedSetup.continue && selectedSetup.setup ? (
              <DcoHeader
                selectedSetup={selectedSetup}
                tab={tab}
                t={tab}
                setTab={setTab}
                currentAd={currentAd}
                dispatch={dispatch}
                parents={{...parents, projectId}}
                assets={assets}
                setCurrentAd={setCurrentAd}
              />
            ) : (
              <SetupSelectPage
                setups={setups}
                selectedSetup={selectedSetup}
                setSelectedSetup={setSelectedSetup}
              />
            )}
          </div>
        )}
        <ChangedSetupModal
          when={showModal}
          onClose={handleModalClose}
          onSave={handleModalSave}
          preflight={preflight}
          changes={selectedSetup?.setup?.changesPerVersion}
          version={selectedSetup?.setup?.version}
        />
      </div>
    </LastChangedUserProvider>
  );
};

export default connect(store => ({
  ad: store.ad,
  file: store.file,
}))(DcoProject);
