/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useCallback, useMemo, useState} from 'react';
import Select, {components} from 'react-select';
import {Button, Modal} from 'react-bootstrap';
import {Formik, Form} from 'formik';

import {ShotThreeDot} from '../TableComponents';
import {EditInput, SizeSelect} from '../Inputs';
import {
  addNewShot,
  deleteSelectedShots,
  duplicateSelectedShots,
  findElementById,
} from '../../../helpers';
import {replaceElementInArrayById} from '../../../../../brand/Dco/helpers';
import SingleInputForm from '../../../../../../helpers/singleInputForm';
import {customInputStyles, editAdStates} from '../../../consts';
import {LoaderButton} from '../../../../../../components';
import useValidation from '../../../../../../hooks/useValidation';
import {editTabValidationSchema} from './validation';
import Access from '../../../../../../helpers/access';

export const CustomOption = props => {
  const {data, selectProps} = props;

  const published = selectProps?.selectProps?.publishedDiffId === data?.value?._id;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      <span className="d-flex justify-content-between align-items-center">
        {
          // eslint-disable-next-line react/destructuring-assignment
          props.data.label
        }
        {published && (
          <span
            className="primary-text"
            style={{
              fontSize: '1.5rem',
            }}
          >{`\u25CF`}</span>
        )}
      </span>
    </components.Option>
  );
};

const EditTabHeader = ({
  selectedRows,
  setup,
  currentAd,
  setCurrentAd,
  handleChange,
  currentAdChanged,
  handleCancelClicked,
  setShowWarningModal,
  handleSave,
  assets,
  setShowDraftModal,
  versioningArr,
  setSelectedRows,
  deselectAll,
  setShowAlreadyPublishedModal,
  loaderButtonStatus,
  reloadTable,
  adVersions,
  setAdVersions,
  setInitialEditDate,
  initialEditDate,
  handleNextPreviousAd,
  getIsDirectionDisabled,
  parents,
}) => {
  const [showForm, setShowNameForm] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [versioning, handleUndo, handleRedo] = versioningArr;
  const [errors, handleValidation] = useValidation();

  const addShotsDisabled = useMemo(() => setup.maxShots <= currentAd?.shots?.length, [
    currentAd?.shots,
    setup.maxShots,
  ]);

  const {next: nextDisabled, previous: previousDisabled} = useMemo(
    () => getIsDirectionDisabled(currentAd?._id),
    [getIsDirectionDisabled, currentAd?._id]
  );

  const publishedDiffId = useMemo(() => {
    const id = currentAd?.published?.diffId;
    const idx = adVersions.findIndex(v => v._id === id);
    return adVersions[idx - 1]?._id;
  }, [adVersions, currentAd?.published?.diffId]);

  const handleVersionChange = version => {
    if (version?.value?.current) {
      setCurrentAd(version.value.current);
      setAdVersions(old => [...old.slice(1)]);
      setCurrentVersion(null);
      return;
    }
    let innerAdVersions = [...adVersions];

    if (adVersions?.[0]?.current) {
      innerAdVersions = [...innerAdVersions.slice(1)];
    } else {
      setAdVersions(old => [{current: currentAd}, ...old]);
    }

    const currentState = adVersions?.[0]?.current ?? currentAd;

    const index = innerAdVersions.findIndex(v => v.time === version.value.time);

    const newAd = {...currentState};

    const transformed = innerAdVersions
      .slice(0, index + 1)
      .map(i => ({...Object.values(i.diff)}))
      .map(v =>
        Object.values(v).map(i => {
          return i;
        })
      );

    transformed.forEach(change => {
      change.forEach(element => {
        Object.entries(element).forEach(([k, v]) => {
          if (k === 'columns') {
            newAd[k] = {...newAd[k], ...v};
          }
          if (k === 'sizes') {
            newAd[k] = newAd[k].map((s, sIndex) => ({
              ...s,
              ...(v?.[sIndex] || {}),
            }));
          }
          if (k === 'published') {
            const {lastChange} = v;
            if (lastChange) {
              newAd[k] = {...newAd[k], ...v};
            }
          }

          if (k === 'shots') {
            newAd[k] = newAd[k].map((s, shotIndex) => {
              if (!v?.[shotIndex]) {
                return s;
              }
              let currentShotCopy = [...s.shotSizes];
              const currentShotV = {...v[shotIndex]};

              Object.values(currentShotV).forEach(diffs => {
                const sizeId = Object.keys(diffs)[0];
                const currentSize = findElementById(currentShotCopy, sizeId);
                currentShotCopy = replaceElementInArrayById(currentShotCopy, {
                  ...currentSize,
                  ...diffs[sizeId],
                });
              });

              return {...s, shotSizes: currentShotCopy};
            });
          }
        });
      });
    });

    setCurrentVersion(version);
    setCurrentAd({
      ...newAd,
    });
    setInitialEditDate('Test');
  };
  const handleAdColumnChange = (key, value) => {
    handleChange(old => ({...old, columns: {...old.columns, [key]: value}}));
  };

  const handleAddNewShot = () => {
    setCurrentAd(old => ({
      ...old,
      shots: addNewShot(currentAd.shots, setup),
    }));
  };

  const handleSizeChange = sizes => {
    handleChange(old => ({
      ...old,
      sizes,
    }));
    reloadTable();
  };

  const handleBackClicked = () => {
    if (!currentAdChanged) {
      handleCancelClicked();
      return;
    }
    setShowWarningModal(true);
  };

  const handleNextAdClicked = () => {
    if (!currentAdChanged) {
      handleNextPreviousAd('next');
      return;
    }
    setShowWarningModal({fn: () => handleNextPreviousAd('next')});
  };
  const handlePreviousAdClicked = () => {
    if (!currentAdChanged) {
      handleNextPreviousAd('previous');
      return;
    }
    setShowWarningModal({fn: () => handleNextPreviousAd('previous')});
  };

  const handleUndoClicked = useCallback(() => {
    if (versioning.undo.length) {
      setCurrentAd(old => ({
        ...old,
        ...handleUndo(currentAd),
      }));
    }
  }, [versioning.undo.length, setCurrentAd, handleUndo, currentAd]);

  const handleRedoClicked = useCallback(() => {
    if (versioning.redo.length) {
      setCurrentAd(old => ({
        ...old,
        ...handleRedo(currentAd),
      }));
    }
  }, [versioning.redo.length, setCurrentAd, handleRedo, currentAd]);

  const handleThreeDotEvents = event => {
    switch (event) {
      case 'duplicate':
        handleChange(old => ({
          ...old,
          shots: duplicateSelectedShots(selectedRows, currentAd.shots),
        }));
        break;
      case 'delete':
        handleChange(old => ({
          ...old,
          shots: deleteSelectedShots(selectedRows, currentAd.shots),
        }));
        break;
      default:
        break;
    }
    setSelectedRows([]);
    deselectAll();
  };

  const handleSaveVerification = async () => {
    const isValid = await handleValidation(
      currentAd.columns,
      editTabValidationSchema(setup.adColumns)
    );
    if (!isValid) {
      return;
    }

    if (
      initialEditDate === currentAd?.published?.lastChange &&
      !currentVersion &&
      currentAd?.published?.csv &&
      currentAd?.published?.PublishedState === 'Published'
    ) {
      setShowAlreadyPublishedModal(true);
      return;
    }
    if (currentAd?.columns?.State === 'Draft') {
      setShowDraftModal(true);
      return;
    }
    await handleSave();
  };

  return (
    <Access type="dco" parents={parents}>
      {({isAllowed}) => (
        <>
          <Modal show={!currentAd.columns['Ad Name']} size="lg" centered>
            <Modal.Header style={{justifyContent: 'space-between'}}>
              <Modal.Title>Ad Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SingleInputForm
                value={currentAd.columns['Ad Name'] || 'My New Ad'}
                placeholder="Ad Name"
                show={setShowNameForm}
                onSubmit={value => {
                  handleAdColumnChange('Ad Name', value);
                }}
                className="dco-modal-field"
                mandatory
              />
            </Modal.Body>
          </Modal>
          <div className="d-flex justify-content-between flex-column align-items-baseline mb-4">
            <div className="d-flex justify-content-between w-100  align-items-baseline">
              <div className="d-flex align-items-center">
                <button
                  onClick={handleBackClicked}
                  type="button"
                  className="unsetButton d-flex align-items-center primary-text  mb-3"
                >
                  <div
                    style={{width: '25px', height: '25px'}}
                    className="icon icon-hamburger primary-background"
                  />
                  List of ads
                </button>
                <button
                  onClick={handlePreviousAdClicked}
                  type="button"
                  disabled={previousDisabled}
                  className={`unsetButton d-flex align-items-center primary-text mx-4 mb-3 ${previousDisabled &&
                    'icon-button-disabled'}`}
                >
                  <div
                    style={{width: '25px', height: '25px'}}
                    className="icon icon-arrow-left primary-background"
                  />
                  Previous ad
                </button>

                <button
                  onClick={handleNextAdClicked}
                  type="button"
                  className={`unsetButton d-flex align-items-center primary-text mb-3 ${nextDisabled &&
                    'icon-button-disabled'}`}
                  disabled={nextDisabled}
                >
                  Next ad
                  <div
                    style={{width: '25px', height: '25px'}}
                    className="icon icon-arrow-right primary-background"
                  />
                </button>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className={`dco-undo ${!versioning.undo.length && 'disabled'}`}
                  onClick={handleUndoClicked}
                >
                  <span className="icon icon-undo mr-1 p-2" />
                  <span>UNDO</span>
                </div>
                <div
                  className={`dco-undo ${!versioning.redo.length && 'disabled'}`}
                  onClick={handleRedoClicked}
                >
                  <span className="icon icon-redo mr-1 p-2" />
                  <span>REDO</span>
                </div>
                <Select
                  isClearable={false}
                  styles={customInputStyles}
                  isSearchable={false}
                  selectProps={{publishedDiffId}}
                  className="mr-2 mw-10"
                  defaultValue={{label: '', value: ''}}
                  cacheOptions={false}
                  value={currentVersion}
                  placeholder="Select Version"
                  onChange={handleVersionChange}
                  components={{Option: CustomOption}}
                  options={
                    adVersions.map((v, i) => ({
                      label: v.time ? `${v.time} - Version ${adVersions.length - i}` : 'Current',
                      value: v,
                    })) || []
                  }
                />

                <Select
                  isClearable={false}
                  styles={customInputStyles}
                  isSearchable={false}
                  className="mr-4 mw-10"
                  defaultValue={{label: 'Draft', value: 'Draft'}}
                  cacheOptions={false}
                  isDisabled={!isAllowed('edit')}
                  value={{label: currentAd.columns.State, value: currentAd.columns.State}}
                  placeholder="Status"
                  onChange={e => {
                    handleAdColumnChange('State', e.value);
                  }}
                  options={editAdStates.map(v => ({label: v, value: v})) || []}
                />
                {isAllowed('edit') && (
                  <LoaderButton
                    defaultText="Save"
                    disabled={!currentAdChanged || !currentAd.shots.length}
                    loadingText="Saving..."
                    loaderButtonStatus={loaderButtonStatus}
                    completedText="Saved!!"
                    onClick={handleSaveVerification}
                  />
                )}
              </div>
            </div>
            {!currentAd.shots.length && (
              <p
                style={{
                  color: !currentAd.shots.length ? 'red' : 'black',
                  display: 'flex',
                  justifyContent: 'end',
                  width: '100%',
                }}
              >
                you need to have at least 1 shot in order to save{' '}
                {versioning?.undo?.length ? '(use undo)' : null}
              </p>
            )}
            {!showForm ? (
              <div className="d-flex align-items-baseline">
                <h1 className="font-weight-bold">{currentAd.columns['Ad Name'] || 'My New Ad'} </h1>
                {isAllowed('edit') && (
                  <div
                    style={{width: '25px', height: '25px', minWidth: '25px', minHeight: '25px'}}
                    className="icon icon-edit ml-2 cursor-pointer"
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowNameForm(true)}
                  />
                )}
              </div>
            ) : (
              <div className="w-50">
                <SingleInputForm
                  value={currentAd.columns['Ad Name']}
                  placeholder="Ad Name"
                  show={setShowNameForm}
                  onSubmit={value => {
                    handleAdColumnChange('Ad Name', value);
                  }}
                />
              </div>
            )}
          </div>

          <div>
            <Formik key={currentAd.id} initialValues={{}} enableReinitialize>
              {() => (
                <Form className="form-group mt-1 d-flex align-items-end flex-wrap">
                  {Object.entries(setup?.adColumns).map(([key, column]) => {
                    return (
                      <EditInput
                        column={column}
                        label={key}
                        disabled={!isAllowed('edit')}
                        value={currentAd.columns[key]}
                        handleChange={handleAdColumnChange}
                        currentAd={currentAd}
                        errors={errors}
                        debounced
                      />
                    );
                  })}
                  <div style={{width: '20%'}} className="form-group pr-4 ">
                    <span className="dco-edit-label">Sizes</span>

                    <SizeSelect
                      handleChange={handleSizeChange}
                      sizes={currentAd.sizes}
                      assets={assets}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="d-flex justify-content-start align-items-center mb-4">
            <ShotThreeDot
              selectedRows={selectedRows}
              onEventClicked={handleThreeDotEvents}
              duplicateDisabled={
                (selectedRows.length || 0) + (currentAd.shots.length || 0) > setup.maxShots
              }
            />
            <Button
              onClick={handleAddNewShot}
              disabled={addShotsDisabled}
              type="button"
              className={`dco-button primary ${addShotsDisabled && 'disabled'} ml-4`}
            >
              + ADD SHOT
            </Button>
          </div>
        </>
      )}
    </Access>
  );
};

export default EditTabHeader;
