/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {MentionsInput, Mention} from 'react-mentions';
import 'emoji-mart/css/emoji-mart.css';
import data from 'emoji-mart/data/apple.json';
import {NimblePicker, NimbleEmojiIndex} from 'emoji-mart';
import Attachment from './../../helpers/attachment';
import {
  createComment,
  updateComment,
  deleteComment,
  uploadAttachment,
  deleteAttachment,
} from './slice';

new NimbleEmojiIndex(data);
const styles = {
  control: {
    minHeight: '120px',
  },
  suggestions: {
    list: {
      padding: '0',
      margin: '0',
      fontSize: '0.75rem',
      color: '#000000',
      backgroundColor: '#ffffff',
      backgroundClip: 'padding-box',
      border: '1px solid rgba(0, 0, 0, 0.15)',
    },
    item: {
      padding: '0.25rem 1.5rem',
      clear: 'both',
      color: '#4a4a4a',
      textSlign: 'inherit',
      whiteSpace: 'nowrap',
      backgroundColor: 'transparent',
      border: 0,
      '&focused': {
        backgroundColor: '#eaeff1',
      },
    },
  },
};

const CommentForm = ({
  dispatch,
  comment,
  users,
  type,
  id,
  commentId,
  value = '',
  versionId = null,
  parentId = null,
  hide,
  attachments = [],
  adData = null,
  isSharedPageComment = false,
}) => {
  const emojiPicker = useRef();
  const [form, setForm] = useState(value);
  const [inProgress, setAttachmentStatus] = useState({});
  const [deleted, setDeleted] = useState([]);
  const [showEmoji, toggleEmoji] = useState(false);
  const [UUID, setUUID] = useState(uuidv4());
  const [error, setError] = useState(false);

  const maxCommentChar = 999;

  useEffect(() => {
    const handleClickOutside = event => {
      if (emojiPicker.current && !emojiPicker.current.contains(event.target)) toggleEmoji(false);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [emojiPicker]);

  const submit = async () => {
    let submitResult;
    if (adData && isSharedPageComment) {
      // must remove /adId at the end
      const [previewId] = id.split('/');
      submitResult = await dispatch(
        createComment({
          type,
          id: previewId,
          commentId,
          form: {text: form, tmpKey: UUID, parentId, ...adData},
        })
      );
    } else if (commentId && !isSharedPageComment) {
      submitResult = await dispatch(
        updateComment({type, id, commentId, form: {text: form, tmpKey: UUID}})
      );
    } else {
      submitResult = await dispatch(
        createComment({type, id, form: {parentId, versionId, text: form, tmpKey: UUID}})
      );
    }
    // console.log('submitResult', submitResult);
    if (submitResult.error) {
      if (submitResult.payload.data['undefined']) {
        setError(submitResult.payload.data['undefined']);
      }
    } else {
      setUUID(uuidv4());
      setForm('');
      setAttachmentStatus({});
      setError(false);
    }
    if (hide) hide();
  };

  const upload = files => {
    const fileSet = {};
    files.forEach(file => {
      const tmpId = Math.random()
        .toString(32)
        .slice(2);
      const formData = {tmpKey: UUID, file};
      fileSet[tmpId] = file;
      // dispatch(uploadAttachment({tmpId, type, id, formData}));
      dispatch(uploadAttachment({tmpId, type, id, form: formData}));
    });
    setAttachmentStatus({...inProgress, ...fileSet});
  };

  const destroy = async cId => {
    const res = await dispatch(deleteAttachment({cId, tmpKey: UUID}));
    if (deleteAttachment.fulfilled.match(res)) setDeleted([...deleted, cId]);
  };

  const destroyComment = async cId => {
    const res = await dispatch(deleteComment({type, id, cId}));
    if (deleteComment.fulfilled.match(res)) hide();
  };

  const isUploading = () => {
    let uploading = false;
    attachments.forEach(f => {
      if ((comment.deleting[f._id] || {}).fetching) uploading = true;
    });

    Object.entries(inProgress).map(([k]) => {
      if (
        comment.attachment[k].fetching ||
        (comment.attachment[k].file &&
          comment.deleting[comment.attachment[k].file._id] &&
          comment.deleting[comment.attachment[k].file._id].fetching)
      )
        uploading = true;
    });
    return uploading;
  };

  return (
    <div className={`comment-textarea ${comment.fetching && 'loading'}`}>
      <MentionsInput
        value={form}
        onChange={e => {
          if (e.target.value.length >= maxCommentChar + 1) {
            setForm(e.target.value.slice(0, maxCommentChar));
            return;
          }
          setForm(e.target.value);
        }}
        placeholder="Start typing..."
        // onSelect={(e) => setCurrsorPosition({ start: e.target.selectionStart, end: e.target.selectionEnd })}
        className="textinput"
        allowSuggestionsAboveCursor
        style={styles}
      >
        <Mention trigger="@" data={users} className="bg-info" />
        <Mention
          trigger=":"
          markup=":[__id__]"
          displayTransform={(id, display) =>
            data.emojis[id].unified.indexOf('-') === -1
              ? String.fromCodePoint(data.emojis[id].unified ? `0x${data.emojis[id].unified}` : '')
              : String.fromCodePoint.apply(
                  String,
                  data.emojis[id].unified.split('-').map(x => `0x${x}`)
                )
          }
          data={(search, callback) => {
            if (search)
              callback(
                Object.entries(data.emojis)
                  .filter(([key, value]) => value.search.includes(search))
                  .slice(0, 20)
                  .map(([key, value]) => ({
                    id: key,
                    display: `${
                      value.unified.indexOf('-') === -1
                        ? String.fromCodePoint(value.unified ? `0x${value.unified}` : '')
                        : String.fromCodePoint.apply(
                            String,
                            value.unified.split('-').map(x => `0x${x}`)
                          )
                    }:${key}`,
                  }))
              );
            else
              callback(
                Object.entries(data.emojis)
                  .slice(0, 20)
                  .map(([key, value]) => ({
                    id: key,
                    display: `${
                      value.unified.indexOf('-') === -1
                        ? String.fromCodePoint(value.unified ? `0x${value.unified}` : '')
                        : String.fromCodePoint.apply(
                            String,
                            value.unified.split('-').map(x => `0x${x}`)
                          )
                    }:${key}`,
                  }))
              );
          }}
        />
      </MentionsInput>
      <div className="comment-attachments flex-wrap">
        {Object.entries(inProgress).map(([k, v]) => {
          if (comment.attachment[k].file && deleted.includes(comment.attachment[k].file._id))
            return null;
          return (
            <Attachment
              key={k}
              tmp={v}
              state={
                comment.attachment[k].file
                  ? comment.deleting[comment.attachment[k].file._id]
                  : comment.attachment[k]
              }
              file={comment.attachment[k].file}
              onDelete={() => destroy(comment.attachment[k].file._id)}
            />
          );
        })}

        {attachments.length > 0 &&
          attachments
            .filter(att => !deleted.includes(att._id))
            .map(att => (
              <Attachment
                key={att._id}
                state={comment.deleting[att._id]}
                file={att}
                onDelete={() => destroy(att._id)}
              />
            ))}
      </div>
      <div className="comment-buttons d-flex justify-content-end position-relative">
        <div
          className="chat-button d-flex justify-content-center align-items-center"
          onClick={() => toggleEmoji(true)}
        >
          <div className="icon icon-smile" />
          <div ref={emojiPicker}>
            {showEmoji && (
              <NimblePicker
                emojiTooltip={false}
                showPreview={false}
                showSkinTones={false}
                sheetSize={64}
                emojiSize={18}
                data={data}
                onSelect={emoji => setForm(`${form} :[${emoji.id}]`)}
                style={{position: 'absolute', right: 0, bottom: 30}}
              />
            )}
          </div>
        </div>
        <div className="chat-button d-flex justify-content-center align-items-center">
          <Dropzone onDrop={upload}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} multiple />
                <div className="icon icon-attachment" />
              </div>
            )}
          </Dropzone>
        </div>
        {!!commentId && (
          <div
            className="chat-button d-flex justify-content-center align-items-center bg-danger"
            onClick={() => destroyComment(commentId)}
            title="Delete"
          >
            <FontAwesomeIcon icon="trash" className="text-white" />
          </div>
        )}
        {!!(commentId || parentId) && (
          <div
            className="chat-button d-flex justify-content-center align-items-center "
            style={{
              backgroundColor: '#FFBE00',
            }}
            onClick={hide}
            title="Cancel"
          >
            <FontAwesomeIcon
              icon="times"
              className="text-white"
              style={{
                display: 'block',
              }}
            />
          </div>
        )}
        <button
          type="button"
          className="chat-button d-flex justify-content-center align-items-center btn-primary border-0"
          disabled={isUploading()}
          onClick={submit}
          title="Submit"
        >
          <FontAwesomeIcon icon="chevron-right" className="text-white" />
        </button>
        <p
          className={`m-0 pl-2 text-nowrap align-self-end ${
            form.length === maxCommentChar ? 'text-danger' : ''
          }`}
          style={{width: 70, color: '#a7a7a7', fontSize: '0.75rem'}}
        >
          {form.length} / {maxCommentChar}
        </p>
      </div>
      {error &&
        (error.map ? error.map(e => <p className="text-danger">{e}</p>) : JSON.stringify(error))}
    </div>
  );
};

export default connect(store => ({
  comment: store.comment,
  file: store.file,
}))(CommentForm);
