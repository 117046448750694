/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Button, Modal, Form, Overlay, Tooltip} from 'react-bootstrap';

import {copyToClipboard} from '../../../../../helpers/copyToClipboard';
import {shareTypes} from './consts';
import {Switch} from 'components';

export const ShareModal = ({
  onClose = () => {},
  onShare = () => {},
  show,
  ads = [],
  shareState = {},
  setShareState,
}) => {
  const {type: shareType, filters} = shareState.settings;
  const handleShare = () => {
    switch (shareType) {
      case shareTypes.ALL:
        onShare(ads);
        break;
      case shareTypes.SELECTED:
        onShare(shareState.selectedAds);
        break;
      case shareTypes.FILTERED:
        onShare(shareState.filteredAds);
        break;
      default:
        break;
    }
  };

  const setShareType = useCallback(
    type => {
      setShareState(({...state}) => {
        return {
          ...state,
          settings: {
            ...state.settings,
            type,
          },
        };
      });
    },
    [setShareState]
  );

  useEffect(() => {
    if (shareState?.selectedAds?.length) {
      setShareType(shareTypes.SELECTED);
    } else if (shareState?.filteredAds?.length) {
      setShareType(shareTypes.FILTERED);
    } else {
      setShareType(shareTypes.ALL);
    }
  }, [shareState?.selectedAds, shareState?.filteredAds, setShareType]);

  const handleChange = e => {
    setShareType(e.target.value);
  };

  const handleTitleChange = e => {
    setShareState(({...state}) => {
      return {
        ...state,
        title: e.target.value,
      };
    });
  };

  console.log('shareState', shareState);
  return (
    <Modal show={show} onHide={onClose} size="md" centered>
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <h4>Title</h4>
          <button
            onClick={onClose}
            type="button"
            style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
          >
            x
          </button>
        </div>
        <div className="d-flex align-items-center mt-3">
          <Form.Control
            type="text"
            value={shareState?.title}
            style={{
              width: '70%',
            }}
            onChange={handleTitleChange}
          />
        </div>
        <h4 className="mt-4">Settings</h4>

        <Switch
          label="auto update"
          checked={shareState?.settings?.autoUpdate}
          handleChange={() => {
            setShareState(old => ({
              ...old,
              settings: {...old.settings, autoUpdate: !old.settings.autoUpdate},
            }));
          }}
        />
        <div className="mt-3">
          <Form.Check
            inline
            label={`All ads in project (${ads.length})`}
            disabled={!ads.length}
            name="group1"
            type="radio"
            value={shareTypes.ALL}
            onChange={handleChange}
            checked={shareType === shareTypes.ALL}
            id={`inline-${'radio'}-1`}
          />
          {!!shareState?.selectedAds?.length && (
            <Form.Check
              inline
              label={`Selected ads (${shareState?.selectedAds?.length || 0})`}
              disabled={!shareState?.selectedAds?.length}
              name="group1"
              type="radio"
              value={shareTypes.SELECTED}
              onChange={handleChange}
              checked={shareType === shareTypes.SELECTED}
              id={`inline-${'radio'}-2`}
            />
          )}
          {!!shareState?.filteredAds?.length && (
            <Form.Check
              inline
              disabled={!shareState?.filteredAds?.length}
              label={`Applied filters (${shareState?.filteredAds?.length || 0})`}
              name="group1"
              type="radio"
              value={shareTypes.FILTERED}
              onChange={handleChange}
              checked={shareType === shareTypes.FILTERED}
              id={`inline-${'radio'}-3`}
            />
          )}
        </div>
        {filters && (
          <>
            <h4 className="mt-3">Filters</h4>

            <div className="d-flex flex-column">
              {Object.values(filters)
                .filter(f => f?.value)
                .map(filter => {
                  return (
                    <div
                      className="d-flex mb-2"
                      style={{
                        width: '50%',
                        borderRadius: '0.3125rem',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 0.4rem',
                        backgroundColor: '#f5f5f5',
                      }}
                    >
                      <span>{filter?.label}:</span>
                      <span
                        style={{
                          padding: '0.4rem 0.8rem',
                          borderTopRightRadius: '0.3125rem',
                          borderBottomRightRadius: '0.3125rem',
                          backgroundColor: '#081ee0',
                          width: '40%',
                          color: 'white',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        {Array.isArray(filter?.value)
                          ? filter?.value?.filter(v => v.enabled).length
                          : filter?.value?.toString()}
                      </span>
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button className="dco-button gray-600-background" onClick={onClose}>
          Cancel
        </Button>
        <Button className="dco-button primary-background" onClick={handleShare}>
          Share
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ShareModalCopy = ({url, show, onClose}) => {
  const refInfo = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  const onInfoClicked = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header style={{justifyContent: 'space-between'}}>
        <Modal.Title style={{fontWeight: 'bold'}}>Succesfully shared public page</Modal.Title>
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <Form.Control disabled type="text" value={url} />

          <Overlay target={refInfo.current} show={showInfo} placement="top">
            {overlayProps => (
              <Tooltip id="overlay-example" {...overlayProps}>
                Successfully copied to clipboard
              </Tooltip>
            )}
          </Overlay>
          <span
            className="mx-2 icon icon-chain gray-600-background"
            tabIndex={0}
            role="button"
            ref={refInfo}
            onClick={() => {
              copyToClipboard(url);
              onInfoClicked();
            }}
          />
          <a target="_blank" href={url} rel="noreferrer">
            <span
              className="mx-1 icon icon-new-window gray-600-background"
              // onClick={onNewWindowClicked}
              tabIndex={0}
              role="button"
            />
          </a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="dco-button gray-600-background" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
