/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState, useRef} from 'react';
import {Overlay, Tooltip} from 'react-bootstrap';

const HeaderCellRenderer = props => {
  const {showColumnMenu, displayName, note} = props;
  const [showInfo, setShowInfo] = useState(false);

  const refButton = useRef(null);
  const refInfo = useRef(null);

  const onMenuClicked = () => {
    showColumnMenu(refButton.current);
  };

  const onInfoClicked = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };

  return (
    <div className="d-flex ag-cell-label-container">
      <span
        ref={refButton}
        className="ag-header-icon ag-header-cell-menu-button"
        onClick={() => onMenuClicked()}
      >
        <span className="ag-icon ag-icon-menu" />
      </span>
      {note && (
        <>
          <span
            style={{width: '12px', height: '12px', cursor: 'pointer'}}
            onClick={onInfoClicked}
            ref={refInfo}
            className="icon icon-questionmark gray-600-background  cursor-pointer mr-2 "
          />
          <Overlay target={refInfo.current} show={showInfo} placement="top">
            {overlayProps => (
              <Tooltip id="overlay-example" {...overlayProps}>
                {note}
              </Tooltip>
            )}
          </Overlay>
        </>
      )}
      <div className="ag-header-cell-label">
        <span className=" ag-header-cell-text">{displayName}</span>
      </div>
    </div>
  );
};

export default HeaderCellRenderer;
