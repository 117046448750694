import axios from 'axios';
import {ConfirmationModal} from 'components';
import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';

const ShareNavbar = ({guest, onSignIn}) => {
  const [isVisible, setIsVisible] = useState(false);
  const renameRef = useRef('');
  const handleLogout = () => {
    localStorage.removeItem('guestUser');
    window.location.reload();
  };

  const handleRenameSubmit = async () => {
    try {
      const result = await axios.post(
        `https://${process.env.REACT_APP_API_DOMAIN}/api/dco/preview/createPublicUser`,
        {
          name: renameRef.current.value,
          email: guest.email,
        }
      );
      const newGuestUser = {
        ...guest,
        id: result.data.id,
        token: result.data.token,
        name: renameRef.current.value,
      };

      window.localStorage.setItem('guestUser', JSON.stringify(newGuestUser));
      window.location.reload();
    } catch (error) {
      console.log('rename error: ', error);
    }
  };

  const renderRenameForm = () => {
    return (
      <form
        className="d-flex align-items-center"
        onSubmit={e => {
          e.preventDefault();
          handleRenameSubmit();
        }}
      >
        <input className="form-control" ref={renameRef} placeholder="Enter new name" />
        <button type="submit" className="btn btn-primary">
          Rename
        </button>
      </form>
    );
  };

  return (
    <nav className="shared-navbar-wrapper">
      <img
        src={`${process.env.PUBLIC_URL}/images/logo-blue.png`}
        alt="logo"
        height={20}
        width={40}
      />
      <div className="d-flex align-items-center" style={{columnGap: '1rem'}}>
        <h5 className="m-0 ">Hello {guest?.name || 'there!'}</h5>
        {guest && !guest.isGrid8user && (
          <button className="btn btn-secondary" type="button" onClick={() => setIsVisible(true)}>
            Rename
          </button>
        )}
        {guest ? (
          <button type="button" className="btn btn-primary" onClick={handleLogout}>
            Log out
          </button>
        ) : (
          <button type="button" className="btn btn-primary" onClick={onSignIn}>
            Sign in as guest
          </button>
        )}
        <h5 className="m-0">|</h5>
        <Link to="/login">
          <h5 className="m-0">Sign in grid8</h5>
        </Link>
      </div>
      <ConfirmationModal
        show={isVisible}
        title="Enter your new name"
        onCancel={() => setIsVisible(false)}
        onClose={() => setIsVisible(false)}
        customBody={renderRenameForm()}
        showFooter={false}
        /*  onContinue={() => handleDelete(deleteId)} */
      />
    </nav>
  );
};

export default ShareNavbar;

{
  /* <form className="d-flex align-items-center">
            <input className="form-control" ref={renameRef} placeholder="Enter new name" />
            <button type="submit" className="btn btn-primary">
              Rename
            </button>
          </form> */
}
