/* eslint-disable import/prefer-default-export */
import {reviewAgGridBaseDefaultColumnDefs} from 'pages/project/DcoProject/Tabs/agHelpers';
import {AdCellRenderer} from 'pages/project/DcoProject/Tabs/components/TableComponents/AgCellRenderers';

export const createSharedColumnDefs = ad => {
  if (!ad) return [];
  const columns = [];

  Object.entries(ad?.columns).forEach(([key, column]) => {
    const cellDefs = reviewAgGridBaseDefaultColumnDefs(key, column);
    if (key === 'Unique ID' || key === 'Ad ID') {
      return;
    }
    if (key === 'Ad Name') {
      columns.push({
        ...cellDefs,
        cellClass: 'dco-table-first-cell',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: AdCellRenderer,
        },
      });
      return;
    }
    if (key === 'State') {
      columns.push({
        ...cellDefs,
        headerName: 'Review State',
      });
      columns.push({
        ...cellDefs,
        field: 'State',
        headerName: 'Published State',
        valueGetter: valueParams => {
          return valueParams.data?.published?.PublishedState;
        },
      });
      return;
    }

    columns.push({
      ...cellDefs,
    });
  });

  // insert comments column at index 2
  columns.splice(2, 0, {
    headerName: 'Comments',
    valueGetter: valueParams => valueParams.data?.sharedAdData.comments,
  });

  return columns;
};

export const updateRowDataWithAdState = (sharedAds, updatedAdsArr) => {
  return sharedAds.map(shareAd => {
    const copyObj = updatedAdsArr.find(shared => shared._id === shareAd._id);
    if (copyObj) {
      return {...shareAd, columns: {...shareAd.columns, State: copyObj.columns.State}};
    }
    return shareAd;
  });
};

export const updateSharedAdsState = (sharedAds, updatedAdsArr) => {
  return sharedAds.map(shareAd => {
    const copyObj = updatedAdsArr.find(shared => shared._id === shareAd.adId);
    if (copyObj) {
      return {...shareAd, status: copyObj.columns.State};
    }
    return shareAd;
  });
};
