import React, {useEffect, useMemo, useState} from 'react';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import api from '../api';
import FileSelect from './FileSelect';
import CreativeExport from '../pages/file/CreativeExportModal/CreativeExport';
import moment from 'moment';

const CreativePreview = ({creative, multipleVariants, setVersion}) => {
  const [version, setFileVersion] = useState(creative.versions.length - 1);
  const [key, setKey] = useState(Math.random());
  const [user, setUser] = useState([]);

  const getUser = async () => {
    if (creative?.versions?.[version]?.createdBy) {
      const response = await api.user.getUserBasic(creative.versions[version].createdBy);
      setUser(response.data);
    }
  };

  useEffect(() => {
    getUser();
  }, [creative, version]);

  const urlToPromise = url => {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) reject(err);
        else resolve(data);
      });
    });
  };

  const downloadFile = () => {
    const fileToDownload = creative;
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${fileToDownload.displayName}.zip`;

    const urls = [];
    console.log(fileToDownload);
    if (['FILE', 'FONT'].includes(fileToDownload.kind)) {
      urls.push({
        fileName: `${fileToDownload.path.replace('/', '')}/${fileToDownload.displayName}`.replace(
          /^\//,
          ''
        ),
        url: `${process.env.REACT_APP_S3_URL}/${
          fileToDownload.versions[version].name
        }?x=${Math.random()}`,
      });
    } else if (fileToDownload.kind === 'CREATIVE') {
      fileToDownload.versions[version].files.forEach(f =>
        urls.push({
          fileName: `${fileToDownload.path.replace('/', '')}/${fileToDownload.displayName}/${
            fileToDownload.creativeTitle
          }${f}`.replace(/^\//, ''),
          url: `${process.env.REACT_APP_S3_URL}/${
            fileToDownload.versions[version].dir
          }${encodeURIComponent(f)}?x=${Math.random()}`,
        })
      );
    }

    urls.forEach(({url, fileName}) => {
      zip.file(fileName, urlToPromise(url), {binary: true});
      count += 1;
      if (count === urls.length) {
        zip.generateAsync({type: 'blob'}).then(content => {
          FileSaver(content, zipFilename);
        });
      }
    });
  };

  const createdBy = useMemo(() => {
    if (!user) {
      return '';
    }

    return `Uploaded by ${user.name} ${user.lastName}`;
  }, [user]);

  useEffect(() => {
    if (setVersion) setVersion(version);
  }, [version]);

  return (
    <div className="mr-2 ">
      <div className="d-flex">
        <CreativeExport creative={creative} version={version} />
      </div>
      <div className="">{creative.creativeTitle}</div>
      <div>
        <select
          className="text-uppercase small form-control custom-select custom-select-sm border-0 mb-1 text-muted pl-0"
          value={version}
          style={{maxWidth: '200px'}}
          onChange={e => setFileVersion(e.target.value)}
        >
          {creative.versions.map((v, k) => (
            <option value={k} key={k}>
              [{moment(creative.versions[k].createdAt).format('YY/MM/DD HH:mm')}] version {k + 1}{' '}
              {k === creative.versions.length - 1 && '(latest)'}
            </option>
          ))}
        </select>
      </div>
      <div className="small text-muted">
        <a
          href={`${process.env.REACT_APP_S3_URL}/${creative.versions[version].dir}/${creative.versions[version].indexFile}`}
          target="_blank"
        >
          {creative.versions[version].width} x {creative.versions[version].height}
        </a>
      </div>
      <div className="small text-primary" onClick={() => setKey(Math.random())}>
        Reload
      </div>
      <iframe
        key={key}
        anonymous="true"
        className="flex-grow-1 flex-shrink-0 border-0"
        src={`https://${process.env.REACT_APP_API_DOMAIN}/api/tools/proxy/${creative.versions[version].dir}/${creative.versions[version].indexFile}`}
        id={key}
        style={{
          width: creative.versions[version].width,
          height: creative.versions[version].height,
          marginRight: '15px',
          padding: 0,
          // transform: 'scale(0.5)',
          // transformOrigin: '0 0',
          // transform: 'translateX((540 * (0.5 - 1))/2) scale(0.5)',
          // transformOrigin: '0 0',
        }}
      />
      {!!multipleVariants && (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <span
            className="downloadtxtbtn"
            style={{
              marginRight: 10,
            }}
            onClick={() => downloadFile()}
          >
            Download
          </span>

          <span
            className="dco-edit-label"
            style={{
              textTransform: 'none',
            }}
          >
            {createdBy}
          </span>
        </div>
      )}
    </div>
  );
};

export default CreativePreview;
