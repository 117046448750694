import React from 'react';
import {DpaErrorFeed} from './types';

const FeedsError: React.FC<DpaErrorFeed> = props => {
  const {error} = props;

  // TODO check types

  const transformToUnexpectedValue = (str: any) => {
    const strArray = str.split('/');
    return strArray[strArray.length - 1] || 'undefined value';
  };

  return (
    <div className="feeds-error-wrapper">
      <p>This feed is stuck. When input is correct click on publish.</p>
      {/* <p>An email has been sent to all team members on: 04-01-2022 15:30</p> */}
      {Array.isArray(error) &&
        error.length &&
        error.map(err => {
          if (typeof err === 'string') {
            return <p className="text-danger mb-0">{err}</p>;
          }
          if (typeof err.error?.errorText === 'string') {
            return (
              <p className="text-danger mb-0">error: {err.error?.errorText || 'undefined value'}</p>
            );
          }

          return (
            <p className="text-danger mb-0">
              Status Code ({err.error?.status ? err.error.status : 'Status missing'}) : Unexpected
              value {err.error?.url ? transformToUnexpectedValue(err.error.url) : 'Wrong value'}
            </p>
          );
        })}
    </div>
  );
};

export default FeedsError;
