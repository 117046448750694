/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/prefer-default-export */
import React, {useEffect, useState, useRef} from 'react';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Overlay, Tooltip} from 'react-bootstrap';

import TooltipHelper from '../../../helpers/tooltip';
import {copyToClipboard} from '../../../helpers/copyToClipboard';
import transformToVersionOptions from './helpers';
import TextArea from 'components/TextArea';
import api from 'api';

export const EditFeed = ({
  feedFromProps,
  creatives,
  // updateFeed,
  updateSelectedFeed,
  disableAll = false,
  disableNote = false,
}) => {
  if (!feedFromProps) return null;
  const [feed, setFeed] = useState(false);
  const [manifest, setManifest] = useState(false);
  const [isTitleCustom, setIsTitleCustom] = useState(false);
  const [isDescriptionCustom, setIsDescriptionCustom] = useState(false);
  const [isPriceCustom, setIsPriceCustom] = useState(false);
  const [isFeedLinkCustom, setIsFeedLinkCustom] = useState(false);
  const [isImageLinkCustom, setIsImageLinkCustom] = useState(false);
  const [isBrandCustom, setIsBrandCustom] = useState(false);
  const [isCustomLabel0Custom, setIsCustomLabel0Custom] = useState(false);
  const [isCustomLabel1Custom, setIsCustomLabel1Custom] = useState(false);
  const [isCustomLabel2Custom, setIsCustomLabel2Custom] = useState(false);
  const [isCustomLabel3Custom, setIsCustomLabel3Custom] = useState(false);
  const [isCustomLabel4Custom, setIsCustomLabel4Custom] = useState(false);

  const refInfo = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  const customStyles = {
    control: styles => ({...styles, width: 150, marginRight: 32}),
  };

  const onInfoClicked = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };

  const getFeed = async () => {
    setFeed((await api.dpa.getFeed(feedFromProps._id)).data);
  };

  const updateFeed = async feed => {
    const newFeed = (
      await api.dpa.updateFeed({
        ...feed,
      })
    ).data;
    setFeed(newFeed);
    console.log('newfeed', newFeed)
    updateSelectedFeed(newFeed);
  };

  const handleCreativeTemplateChange = async e => {
    setFeed(
      (
        await api.dpa.updateFeed({
          _id: feed._id,
          template: e.value,
          creativeMap: {},
        })
      ).data
    );
  };

  const handleTemplateVersionChange = async e => {
    console.log(
      'setting templateVersion to:',
      e.value + 1 === feed.template?.versions.length ? null : e.value
    );
    setFeed(
      (
        await api.dpa.updateFeed({
          _id: feed._id,
          templateVersion: e.value + 1 === feed.template?.versions.length ? null : e.value,
          creativeMap: {},
        })
      ).data
    );
  };

  const formatOptionLabel = ({value}) => {
    return (
      <div className="d-flex align-items-center gap-1">
        {feed.itemFields.includes(value) ? (
          <div className="icon icon-document-outline" />
        ) : (
          <div className="icon icon-star" />
        )}
        <div>{value}</div>
      </div>
    );
  };

  const handlePreview = (feedValue, field) => {
    if (!feedValue) {
      return null;
    }
    // sometimes empty cell in remote sheet end with \r or \n so we must remove it
    if (feedValue.endsWith('\r') || feedValue.endsWith('\n')) {
      feedValue = feedValue.replace(/[\r\n]+$/, '');
    }

    // check in original json items if it's empty
    if (feed?.originalItemsInJson[0]?.[feedValue] === '') {
      return (
        <p>
          <span>
            <strong>Sample value:</strong>
          </span>
        </p>
      );
    }

    // check in original json items
    if (feed?.originalItemsInJson[0]?.[feedValue]) {
      return (
        <p>
          <span>
            <strong>Sample value:</strong>{' '}
          </span>
          {feed.originalItemsInJson[0][feedValue]}
        </p>
      );
    }

    // else return from feed.map
    return (
      <p>
        <span>
          <strong>Sample value:</strong>{' '}
        </span>
        {feed.map[field]}
      </p>
    );
  };

  useEffect(() => {
    if (feed.template) {
      console.log(feed.template.versions, feed.templateVersion);
      fetch(
        `${process.env.REACT_APP_S3_URL}/${
          feed.template.versions[
            (feed.templateVersion || feed.tempateVersion === 0) &&
            feed.template.versions[feed.templateVersion]
              ? feed.templateVersion
              : feed.template.versions.length - 1
          ].dir
        }/manifest.js`
      )
        .then(r => r.text())
        .then(r => {
          try {
            const j = JSON.parse(r.substring(r.indexOf('{')));
            setManifest(j);
          } catch (e) {
            console.error(e);
          }
        })
        .catch(console.error);
    }
  }, [feed]);

  useEffect(() => {
    console.log('feed changed, templateVersion:', feed.templateVersion, feed.template);
  }, [feed]);

  useEffect(() => {
    getFeed();
  }, []);

  return !feed ? (
    'loading...'
  ) : (
    <div className="d-flex feeds-edit-wrapper">
      {/* Edit feed left */}
      <div className="feeds-edit-left w-40 pr-5 mt-4">
        {/* Left header */}
        <div className="mb-5 mt-3">
          <div className="d-flex gap-1">
            <h4>Input feed</h4>
            <TooltipHelper
              placement="top"
              content={
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div>
                    <FontAwesomeIcon icon="file-excel" className="mr-2" size="lg" />
                    <span>Takes values from the input feed</span>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div
                      style={{border: '1px solid red', marginLeft: 5}}
                      className="icon icon-star"
                    />
                    <span style={{marginRight: 25}}>
                      Applies the value to all the generated images
                    </span>
                  </div>
                </div>
              }
            >
              <p className="icon icon-info" />
            </TooltipHelper>
          </div>
          <div className="feeds-main-input-wrapper">
            <input
              className="feeds-main-input"
              value={feed.inputUrl}
              type="text"
              placeholder="Enter xml link here..."
              disabled={disableAll}
            />
            <Overlay target={refInfo.current} show={showInfo} placement="right">
              {overlayProps => (
                <Tooltip id="overlay-example" {...overlayProps}>
                  Successfully copied to clipboard
                </Tooltip>
              )}
            </Overlay>
            <div
              className="d-flex justify-items-center align-items-center "
              style={{
                height: '100%',
                cursor: 'pointer',
              }}
              ref={refInfo}
            >
              <span
                className="icon icon-chain"
                style={{height: 18}}
                tabIndex={0}
                role="button"
                onClick={() => {
                  copyToClipboard(feed.inputUrl);
                  onInfoClicked();
                }}
              />
            </div>
          </div>
        </div>
        {/* Left body */}
        <div>
          <div className="d-flex justify-content-between">
            <h3>Input feed</h3>
            <h3>{'>'}</h3>
            <h3 className="pr-5">DPA feed</h3>
          </div>
          <div className="d-flex justify-content-between pr-4">
            <div className="input-fields-wrapper" style={{minWidth: 200, width: '100%'}}>
              {/* Title */}
              <div className="facebook-feeds-wrapper">
                {isTitleCustom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter title..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.title = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      maxLength="65"
                      disabled={disableAll}
                    />
                    <div className="edit-feed-undo" onClick={() => setIsTitleCustom(false)} />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.title
                          ? {
                              label: feed.map.title,
                              value: feed.map.title,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsTitleCustom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.title = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.title, 'title') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.title, 'title')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Title" className="description-input" />
              </div>
              {/* Description */}
              <div className="facebook-feeds-wrapper">
                {isDescriptionCustom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter description..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.description = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div className="edit-feed-undo" onClick={() => setIsDescriptionCustom(false)} />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper d-flex align-items-center">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.description
                          ? {
                              label: feed.map.description,
                              value: feed.map.description,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsDescriptionCustom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.description = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.description, 'description') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.description, 'description')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Description" className="description-input" />
              </div>
              {/* Availability */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                <div className="facebook-select-wrapper">
                  <Select
                    styles={customStyles}
                    value={
                      feed.map && feed.map.availability
                        ? {
                            label: feed.map.availability,
                            value: feed.map.availability,
                          }
                        : ''
                    }
                    options={[
                      {label: 'In stock', value: 'in-stock'},
                      {label: 'Out of stock', value: 'out-of-stock'},
                      ...feed.itemFields.map(f => {
                        return {label: f, value: f};
                      }),
                    ]}
                    onChange={e => {
                      if (!feed.map) feed.map = {};
                      feed.map.availability = e.value;
                      updateFeed({
                        _id: feed._id,
                        map: feed.map,
                      });
                    }}
                    isDisabled={disableAll}
                    formatOptionLabel={formatOptionLabel}
                  />
                  {handlePreview(feed.map.availability, 'availability') !== null && (
                    <TooltipHelper
                      placement="top"
                      content={handlePreview(feed.map.availability, 'availability')}
                    >
                      <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                    </TooltipHelper>
                  )}
                </div>
                <input readOnly placeholder="Availability" className="description-input" />
              </div>
              {/* Condition */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                <div className="facebook-select-wrapper">
                  <Select
                    styles={customStyles}
                    value={
                      feed.map && feed.map.condition
                        ? {
                            label: feed.map.condition,
                            value: feed.map.condition,
                          }
                        : ''
                    }
                    options={[
                      {label: 'New', value: 'new'},
                      {label: 'Refurbished', value: 'refurbished'},
                      {label: 'Used', value: 'used'},
                      ...feed.itemFields.map(f => {
                        return {label: f, value: f};
                      }),
                    ]}
                    onChange={e => {
                      if (!feed.map) feed.map = {};
                      feed.map.condition = e.value;
                      updateFeed({
                        _id: feed._id,
                        map: feed.map,
                      });
                    }}
                    isDisabled={disableAll}
                    formatOptionLabel={formatOptionLabel}
                  />
                  {handlePreview(feed.map.condition, 'condition') !== null && (
                    <TooltipHelper
                      placement="top"
                      content={handlePreview(feed.map.condition, 'condition')}
                    >
                      <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                    </TooltipHelper>
                  )}
                </div>
                <input readOnly placeholder="Condition" className="description-input" />
              </div>
              {/* Price */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isPriceCustom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter price..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.price = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div className="edit-feed-undo" onClick={() => setIsPriceCustom(false)} />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.price
                          ? {
                              label: feed.map.price,
                              value: feed.map.price,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsPriceCustom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.price = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.price, 'price') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.price, 'price')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Price" className="description-input" />
              </div>
              {/* Link */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isFeedLinkCustom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter link..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.link = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div className="edit-feed-undo" onClick={() => setIsFeedLinkCustom(false)} />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.link
                          ? {
                              label: feed.map.link,
                              value: feed.map.link,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsFeedLinkCustom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.link = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.link, 'link') !== null && (
                      <TooltipHelper placement="top" content={handlePreview(feed.map.link, 'link')}>
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Link" className="description-input" />
              </div>
              {/* Image_link */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isImageLinkCustom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter image link..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.image_link = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div className="edit-feed-undo" onClick={() => setIsImageLinkCustom(false)} />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.image_link
                          ? {
                              label: feed.map.image_link,
                              value: feed.map.image_link,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsImageLinkCustom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.image_link = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.image_link, 'image_link') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.image_link, 'image_link')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Image_link" className="description-input" />
              </div>
              {/* Brand */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isBrandCustom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter brand..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.brand = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div className="edit-feed-undo" onClick={() => setIsBrandCustom(false)} />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.brand
                          ? {
                              label: feed.map.brand,
                              value: feed.map.brand,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsBrandCustom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.brand = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.brand, 'brand') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.brand, 'brand')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Brand" className="description-input" />
              </div>
              {/* Custom Label 0 */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isCustomLabel0Custom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter custom label 0..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_0 = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div
                      className="edit-feed-undo"
                      onClick={() => setIsCustomLabel0Custom(false)}
                    />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.custom_label_0
                          ? {
                              label: feed.map.custom_label_0,
                              value: feed.map.custom_label_0,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsCustomLabel0Custom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_0 = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.custom_label_0, 'custom_label_0') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.custom_label_0, 'custom_label_0')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Custom_label_0" className="description-input" />
              </div>
              {/* Custom Label 1 */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isCustomLabel1Custom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter custom label 1..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_1 = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div
                      className="edit-feed-undo"
                      onClick={() => setIsCustomLabel1Custom(false)}
                    />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.custom_label_1
                          ? {
                              label: feed.map.custom_label_1,
                              value: feed.map.custom_label_1,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsCustomLabel1Custom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_1 = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.custom_label_1, 'custom_label_1') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.custom_label_1, 'custom_label_1')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Custom_label_1" className="description-input" />
              </div>
              {/* Custom Label 2 */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isCustomLabel2Custom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter custom label 2..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_2 = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div
                      className="edit-feed-undo"
                      onClick={() => setIsCustomLabel2Custom(false)}
                    />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.custom_label_2
                          ? {
                              label: feed.map.custom_label_2,
                              value: feed.map.custom_label_2,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsCustomLabel2Custom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_2 = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.custom_label_2, 'custom_label_2') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.custom_label_2, 'custom_label_2')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Custom_label_2" className="description-input" />
              </div>
              {/* Custom Label 3 */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isCustomLabel3Custom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter custom label 3..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_3 = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div
                      className="edit-feed-undo"
                      onClick={() => setIsCustomLabel3Custom(false)}
                    />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.custom_label_3
                          ? {
                              label: feed.map.custom_label_3,
                              value: feed.map.custom_label_3,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsCustomLabel3Custom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_3 = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.custom_label_3, 'custom_label_3') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.custom_label_3, 'custom_label_3')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Custom_label_3" className="description-input" />
              </div>
              {/* Custom Label 4 */}
              <div className="facebook-feeds-wrapper d-flex justify-content-between">
                {isCustomLabel4Custom ? (
                  <div className="feeds-custom-option">
                    <input
                      placeholder="Enter custom label 4..."
                      onBlur={e => {
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_4 = e.target.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                    />
                    <div
                      className="edit-feed-undo"
                      onClick={() => setIsCustomLabel4Custom(false)}
                    />
                  </div>
                ) : (
                  <div className="facebook-select-wrapper">
                    <Select
                      styles={customStyles}
                      value={
                        feed.map && feed.map.custom_label_4
                          ? {
                              label: feed.map.custom_label_4,
                              value: feed.map.custom_label_4,
                            }
                          : ''
                      }
                      options={[
                        {
                          label: 'custom',
                          value: 'custom',
                        },
                        ...feed.itemFields.map(f => {
                          return {label: f, value: f};
                        }),
                      ]}
                      onChange={e => {
                        if (e.value === 'custom') {
                          setIsCustomLabel4Custom(true);
                          return;
                        }
                        if (!feed.map) feed.map = {};
                        feed.map.custom_label_4 = e.value;
                        updateFeed({
                          _id: feed._id,
                          map: feed.map,
                        });
                      }}
                      isDisabled={disableAll}
                      formatOptionLabel={formatOptionLabel}
                    />
                    {handlePreview(feed.map.custom_label_4, 'custom_label_4') !== null && (
                      <TooltipHelper
                        placement="top"
                        content={handlePreview(feed.map.custom_label_4, 'custom_label_4')}
                      >
                        <p className="icon icon-eye-warning m-0 sm-screen-hide" />
                      </TooltipHelper>
                    )}
                  </div>
                )}
                <input readOnly placeholder="Custom_label_4" className="description-input" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit right */}
      <div className="edit-feed-right w-40 pl-5 pr-5 mt-5">
        <div className="d-flex">
          <div className="flex-1 mr-4">
            <h4>Creative Template</h4>
            <Select
              value={
                (feed?.template?.versions && {
                  label: `${feed.template.creativeTitle}_${
                    feed.template.versions[feed.template.versions.length - 1].width
                  }x${feed.template.versions[feed.template.versions.length - 1].height}`,
                  value: feed.template,
                }) ||
                (feed && feed.template
                  ? {
                      label: feed.template.creativeTitle,
                      value: feed.template.creativeTitle,
                    }
                  : '')
              }
              options={creatives.map(section => ({
                value: section,
                label: `${section.creativeTitle}_${
                  section.versions[section.versions.length - 1].width
                }x${section.versions[section.versions.length - 1].height}`,
              }))}
              onChange={handleCreativeTemplateChange}
              isDisabled={disableAll}
              className="mb-2"
            />
          </div>
          <div className="flex-1">
            <h4>Select version</h4>
            {feed.template && feed.template.versions && (
              <Select
                value={[
                  {
                    value: `${
                      feed.templateVersion || feed.templateVersion === 0
                        ? feed.templateVersion + 1
                        : feed.template?.versions.length
                    }`,
                    label: `${
                      feed.templateVersion || feed.templateVersion === 0
                        ? feed.templateVersion + 1
                        : feed.template?.versions.length
                    }`,
                  },
                ]}
                options={transformToVersionOptions(feed.template?.versions.length)}
                onChange={handleTemplateVersionChange}
                className="mb-4"
                isDisabled={disableAll}
              />
            )}
          </div>
        </div>

        <div className="mt-3">
          <div className="d-flex justify-content-between">
            <h3>DPA Feed</h3>
            <h3 className="ml-4">{'>'}</h3>
            <h3>Creative Template</h3>
          </div>
          {/* Old manifest */}
          {/* Check if it's new or old by manifest.settings.version */}
          {manifest &&
            !manifest.settings.version &&
            manifest.layers.map((layer, i) => {
              return (
                <div key={i} className="eidt-feed-manifest-wrapper">
                  {layer.isDynamic && (
                    <div className="d-flex justify-content-between mt-2 mb-2 manifest-box">
                      <Select
                        defaultOptions
                        isClearable
                        cacheOptions={false}
                        styles={{
                          menuPortal: base => ({
                            ...base,
                            zIndex: 9999,
                          }),
                          container: provided => ({
                            ...provided,
                            width: 175,
                            marginRight: 32,
                          }),
                        }}
                        placeholder="Select"
                        value={
                          feed.creativeMap && feed.creativeMap[layer.dynamicValue]
                            ? {
                                label: feed.creativeMap[layer.dynamicValue],
                                value: feed.creativeMap[layer.dynamicValue],
                              }
                            : ''
                        }
                        onChange={e => {
                          if (!feed.creativeMap) feed.creativeMap = {};
                          feed.creativeMap[layer.dynamicValue] = e?.value;
                          updateFeed({
                            _id: feed._id,
                            creativeMap: feed.creativeMap,
                          });
                        }}
                        options={Object.keys(feed.map).map(field => {
                          return {
                            label: field,
                            value: field,
                          };
                        })}
                        isDisabled={disableAll}
                      />
                      <input placeholder={layer.dynamicValue || layer.name} disabled />
                    </div>
                  )}
                </div>
              );
            })}
          {/* New manifest */}
          {manifest &&
            manifest.settings.version &&
            manifest.settings?.dynamicValues?.map((layer, i) => {
              return (
                <div key={i} className="eidt-feed-manifest-wrapper">
                  {true && (
                    <div className="d-flex justify-content-between mt-2 mb-2 manifest-box">
                      <Select
                        defaultOptions
                        isClearable
                        cacheOptions={false}
                        styles={{
                          menuPortal: base => ({
                            ...base,
                            zIndex: 9999,
                          }),
                          container: provided => ({
                            ...provided,
                            width: 175,
                            marginRight: 32,
                          }),
                        }}
                        placeholder="Select"
                        value={
                          feed.creativeMap && feed.creativeMap[layer.name]
                            ? {
                                label: feed.creativeMap[layer.name],
                                value: feed.creativeMap[layer.name],
                              }
                            : ''
                        }
                        onChange={e => {
                          if (!feed.creativeMap) feed.creativeMap = {};
                          feed.creativeMap[layer.name] = e?.value;
                          updateFeed({
                            _id: feed._id,
                            creativeMap: feed.creativeMap,
                          });
                        }}
                        options={Object.keys(feed.map).map(field => {
                          return {
                            label: field,
                            value: field,
                          };
                        })}
                        isDisabled={disableAll}
                      />
                      <input placeholder={layer.name} disabled />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="iframe-wrapper d-flex justify-content-center pt-5 pb-5">
          {feed && feed.template && feed.template.versions && feed.creativeMap ? (
            <>
              <button type="button" className="btn btn-primary" disabled={disableAll}>
                <a
                  href={`${process.env.REACT_APP_S3_URL}/${
                    feed.template.versions[feed.template.versions.length - 1].dir
                  }/index.html?${Object.keys(feed.creativeMap)
                    .map(f => {
                      return `${f}=${encodeURIComponent(
                        feed.items && feed.items.length
                          ? feed.items[0][feed.creativeMap[f]] || feed.map[feed.creativeMap[f]]
                          : feed.map[feed.creativeMap[f]] === 'image-url'
                            ? 'https://picsum.photos/200'
                            : feed.map[feed.creativeMap[f]]
                      )}`;
                    })
                    .join('&')}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View example
                </a>
              </button>
              {/* <iframe
                title="Preview"
                src={`${process.env.REACT_APP_S3_URL}/${
                  feed.template.versions[feed.template.versions.length - 1].dir
                }/index.html?${Object.keys(feed.creativeMap)
                  .map(
                    f =>
                      `${f}=${escape(
                        feed.items[0][feed.creativeMap[f]] || feed.map[feed.creativeMap[f]]
                      )}`
                  )
                  .join('&')}`}
              /> */}
            </>
          ) : (
            <p>Please fill the missing blanks</p>
          )}
        </div>
        <div className="edit-notes-wrapper">
          <h4>Notes</h4>
          {/* <SingleTextAreaForm
            value={feed.notes}
            onSubmit={note => {
              updateFeed({_id: feed._id, notes: note});
            }}
            readOnly={disableNote}
          /> */}
          <TextArea
            defaultValue={feed.notes ?? ''}
            readOnly={disableNote}
            showButtons
            onSubmit={note => {
              updateFeed({_id: feed._id, notes: note});
            }}
          />
        </div>
      </div>
    </div>
  );
};
