/* eslint-disable import/no-unresolved */
/* eslint-disable react/function-component-definition */
import React from 'react';
import Loadable from 'react-loadable';
import {useSelector, useDispatch} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {Switch, Route, matchPath, withRouter, Link} from 'react-router-dom';

import BrandsPage from 'pages/brand/BrandsPage';
import BrandDetailsPage from 'pages/brand/BrandDetailsPage';
import DashboardPage from 'pages/dashboard/DashboardPage';
import TeamDetailsPage from 'pages/team/TeamDetailsPage';
import TeamsPage from 'pages/team/TeamsPage';
import ProjectListPage from 'pages/project/ProjectListPage';
import ProjectDetailsPage from 'pages/project/ProjectDetailsPage';
import PrivateSharePage from 'pages/privateShare/PrivateSharePage';
import NotificationMenu from 'components/NotificationMenu';
import Avatar from './avatar';
import SideBar from './sideBarPrivate';
import {logout} from '../pages/login/slice';

const Profile = Loadable({loader: () => import('../pages/profile'), loading: () => null});
const NotFound = Loadable({loader: () => import('../pages/404'), loading: () => null});

function PrivateLayout({location}) {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  return (
    <div className="container-fluid d-flex m-0 p-0">
      <SideBar className={matchPath(location.pathname, {path: '/', exact: true}) ? '' : ''} />
      <div className="content-container">
        <div className="d-flex justify-content-between topbar">
          <div />
          <div style={{display: 'flex', alignItems: 'center'}}>
            <NotificationMenu userId={auth.user._id} />
            <Dropdown>
              <Dropdown.Toggle className="d-flex justify-content-center align-items-center bg-transparent border-0 text-dark">
                <strong>{auth.user && auth.user.name}</strong>
                <Avatar
                  disableClick
                  type="user"
                  id={auth.user._id}
                  className="profile-img mr-2 ml-2"
                  onClick={e => e.stopPropagation()}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="rounded-0">
                <Link to="/profile" className="text-dark dropdown-item">
                  Profile
                </Link>
                <Dropdown.Item onClick={() => dispatch(logout())}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="pl-5 pr-5 pt-0 pb-5">
          <Switch>
            <Route exact path="/:action(create)?" component={BrandsPage} />
            <Route exact path="/projects" component={DashboardPage} />
            <Route exact path="/shared/:id" component={PrivateSharePage} />
            <Route path="/brand/:action(view)/:id" component={BrandDetailsPage} />
            <Route exact path="/team/:action(create)?" component={TeamsPage} />
            <Route path="/team/:action(view)/:id" component={TeamDetailsPage} />
            <Route path="/project/:id/(file)?/:fileId?" component={ProjectDetailsPage} />
            <Route path="/project" component={ProjectListPage} />
            <Route path="/profile" component={Profile} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PrivateLayout);
