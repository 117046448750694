/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-globals */
import React, {useEffect, useMemo, useState, useRef} from 'react';
import moment from 'moment';
import {Tabs, Tab, Overlay, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Spinner from '../spinner';
import api from '../../../api';
import {ViewFeed} from './ViewFeed';
import {EditFeed} from './EditFeed';
import {copyToClipboard} from '../../../helpers/copyToClipboard';
import FeedsError from './FeedsError.tsx';
import TooltipHelper from 'helpers/tooltip';
import Access from '../../../helpers/access';
import FeedsStatusBar from './FeedsStatusBar';
import {replaceElementInArray} from 'pages/brand/Dco/helpers';

function FeedAdder({parents, projectId, getFeeds, setSearchValue, disableAddFeed}) {
  const [adding, setAdding] = useState(false);
  const [inputUrl, setInputUrl] = useState('');
  const [name, setName] = useState('');
  const [spin, setSpin] = useState(false);
  const [error, setError] = useState(false);

  /*   const oldAddFeed = () => {
    setError(false);
    setSpin(true);
    api.dpa
      .addFeed({
        parents: {brandId: parents.brandId, teamId: parents.teamId, projectId},
        inputUrl,
        name,
      })
      .then(r => {
        getFeeds();
        setAdding(false);
        setName('');
        setInputUrl('');
      })
      .catch(e => {
        if (e.response?.data?.msg) {
          setError(e.response.data.msg);
          return;
        }
        setError('Something went wrong, please check your input url and try again.');
      });
    setSpin(false);
  }; */

  const addFeed = async () => {
    setError(false);
    setSpin(true);
    try {
      await api.dpa.addFeed({
        parents: {brandId: parents.brandId, teamId: parents.teamId, projectId},
        inputUrl,
        name,
      });
      getFeeds();
      setAdding(false);
      setName('');
      setInputUrl('');
    } catch (e) {
      if (e.response?.data?.msg) {
        setError(e.response.data.msg);
        return;
      }
      setError('Something went wrong, please check your input url and try again.');
    }
    setSpin(false);
  };

  return !spin ? (
    <div>
      {adding ? (
        <div>
          <form
            className="add-new-feed pl-2"
            onSubmit={e => {
              e.preventDefault();
              addFeed();
            }}
          >
            <input
              value={name}
              placeholder="Feed name"
              style={{width: 200}}
              onChange={evt => setName(evt.target.value)}
              required
            />
            <input
              value={inputUrl}
              style={{minWidth: 400}}
              type="url"
              placeholder="Input feed url"
              onChange={evt => setInputUrl(evt.target.value)}
              required
            />
            <TooltipHelper
              placement="top"
              content='Enter a valid url starting with "https://" or "http://"'
            >
              <p className="icon icon-info m-0 mr-4" />
            </TooltipHelper>
            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={disableAddFeed || spin}
            >
              Add
            </button>
            <button
              type="submit"
              className="btn btn-secondary"
              onClick={() => {
                setAdding(false);
                setName('');
                setInputUrl('');
              }}
            >
              Cancel
            </button>
          </form>
          {error && <p>{error}</p>}
        </div>
      ) : (
        <div className="d-flex gap-2 my-4">
          <button
            type="button"
            className="d-flex btn btn-primary gap-1"
            style={{cursor: 'pointer', borderRadius: '1.5rem'}}
            onClick={() => setAdding(true)}
            disabled={disableAddFeed}
          >
            <span>Add feed</span>
            <FontAwesomeIcon icon="circle-plus" size="lg" className="mr-2" />
          </button>
          <div className="d-flex align-items-center">
            <div className="icon icon-search mr-2" style={{color: 'white'}} />
            <input
              className="description-input"
              style={{border: 'none', borderBottom: '1px solid black'}}
              placeholder="Search"
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <Spinner />
  );
}

function FeedsTable({parents, projectId}) {
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(0);
  const [selectedFeed, setSelectedFeed] = useState();
  const [feeds, setFeeds] = useState([]);
  const [creatives, setCreatives] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchTemplateValue, setSearchTemplateValue] = useState('');

  const refInfo = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  const baseOutputUrl = `https:/${process.env.REACT_APP_API_DOMAIN}/api/dpa/feeds/xml`;

  const getStateColor = state => {
    switch (state) {
      case 'updating':
        return '#FFBE00';
      case 'error':
        return '#FF6B6B';
      case 'active':
        return '#1DD1A1';
      case 'stopped':
      case 'paused':
        return '#AFAFAF';
      // case 'stopped':
      //   return '#FFA700';
      // return 'rgb(255, 107, 107)';
      default:
        return 'black';
    }
  };

  const onInfoClicked = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };

  const getFeed = async fId => {
    if (fId !== 0) setSelectedFeed((await api.dpa.getFeed(fId)).data);
  };

  const getFeeds = () => {
    setLoading(true);
    api.dpa
      .getFeeds({brandId: parents.brandId, teamId: parents.teamId, projectId})
      .then(r => {
        setFeeds(r.data);
        setLoading(false);
      })
      .catch(console.error);
  };

  const getCreatives = () => {
    const filter = {
      ignoreTemplates: false,
    };
    api.asset
      .getFiles('brand', parents.brandId, filter)
      .then(r => {
        setCreatives(r.data.filter(f => f.kind === 'CREATIVE'));
      })
      .catch(console.error);
  };

  const updateFeed = feed => {
    api.dpa
      .updateFeed({
        ...feed,
        parents: {brandId: parents.brandId, teamId: parents.teamId, projectId},
      })
      .then(({data}) => {
        /*
        const updatedIndex = feeds.findIndex(f => f._id === data._id);
        // Replace feed with updated index
        console.log('replace:', feeds[updatedIndex], 'with:', data);
        const newFeedsList = replaceElementInArray(feeds, updatedIndex, data);
        setFeeds(newFeedsList);
        */
      })
      .catch(err => {
        console.log('update error:', err);
      });
  };

  const publishFeed = feed => {
    api.dpa
      .publishFeed({_id: feed._id})
      .then(() => location.reload())
      .catch(e => {
        console.error(e);
      });
  };

  const deleteFeed = id => {
    api.dpa
      .deleteFeed(id)
      .then(() => location.reload())
      .catch(error => console.error(error));
  };

  const filteredFeeds = useMemo(() => {
    console.log('filtered feeds updated');
    if (!`${searchValue}${searchTemplateValue}`.trim()) {
      return feeds.filter(feed => feed.state !== 'noAccessToProject');
    }

    const searchRegex = new RegExp(searchValue, 'i');
    const searchTemplateRegex = new RegExp(searchTemplateValue, 'i');

    return feeds.filter(
      feed =>
        feed.state !== 'noAccessToProject' &&
        searchRegex.test(feed.name) &&
        searchTemplateRegex.test(feed.templateName)
    );
  }, [feeds, searchValue, searchTemplateValue]);

  useEffect(() => {
    getFeeds();
    getCreatives();
  }, []);

  useEffect(() => {
    getFeed(expanded);
  }, [expanded]);
  return (
    <Access type="project" parents={parents}>
      {({isAllowed}) => (
        <div style={{paddingBottom: 20}}>
          <div>
            <FeedAdder
              parents={parents}
              projectId={projectId}
              getFeeds={getFeeds}
              setSearchValue={setSearchValue}
              disableAddFeed={!isAllowed('create')}
            />
          </div>
          <div
            style={{
              backgroundColor: '#f8f9fa',
              marginBottom: 20,
            }}
          >
            {loading && <Spinner />}
            {!loading && (
              <div className="feeds-table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th style={{width: 120}}>Title</th>
                      <th style={{width: 200}}>
                        <div className="d-flex align-items-center">
                          <div className="icon icon-search mr-2" style={{color: 'white'}} />
                          <input
                            className="description-input"
                            style={{
                              border: 'none',
                              borderBottom: '1px solid black',
                              backgroundColor: 'transparent',
                            }}
                            placeholder="Template"
                            onChange={e => setSearchTemplateValue(e.target.value)}
                          />
                        </div>
                      </th>
                      <th style={{width: 200}}>Created</th>
                      <th style={{width: 120}}>State</th>
                      <th style={{width: 120}}>Last generated image</th>
                      <th style={{width: 120}}>Last published</th>
                      <th style={{width: 120}}>Items</th>
                      <th style={{width: 120}}>Output feed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(filteredFeeds) ? (
                      filteredFeeds.map(feed => (
                        <React.Fragment key={feed._id}>
                          <tr
                            style={{height: 30}}
                            onClick={() => setExpanded(expanded !== feed._id ? feed._id : false)}
                          >
                            <td style={{width: 200}}>{feed.name}</td>
                            <td style={{width: 150}}>{feed?.templateName}</td>
                            <td style={{width: 200}}>
                              {moment(feed.createdAt).format('YY/MM/DD HH:mm')}
                            </td>
                            <td style={{width: 100, color: getStateColor(feed.state)}}>
                              {feed.state}
                            </td>
                            <td style={{width: 150}}>
                              {moment(feed.lastTimeRegenerated || feed.createdAt).fromNow()}
                            </td>
                            <td style={{width: 250}}>
                              {feed.lastTimeUserPublished
                                ? moment(feed.lastTimeUserPublished).fromNow()
                                : ''}
                            </td>
                            <td style={{width: 50}}>{feed.items || '-'}</td>
                            <td>
                              {/* <div
                            className="copy-link-icon"
                            onClick={e => {
                              e.stopPropagation();
                              copyToClipboard(`${baseOutputUrl}${feed._id}`);
                            }}
                          /> */}
                              <Overlay target={refInfo.current} show={showInfo} placement="top">
                                {overlayProps => (
                                  <Tooltip
                                    id="overlay-example"
                                    {...overlayProps}
                                    style={{position: 'absolute', top: 220, right: 50}}
                                  >
                                    Successfully copied to clipboard
                                  </Tooltip>
                                )}
                              </Overlay>
                              <div
                                style={{
                                  cursor: 'pointer',
                                }}
                                ref={refInfo}
                              >
                                <span
                                  className="icon icon-chain"
                                  style={{height: 18}}
                                  tabIndex={0}
                                  role="button"
                                  onClick={e => {
                                    e.stopPropagation();
                                    copyToClipboard(`${baseOutputUrl}/${feed._id}`);
                                    onInfoClicked();
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                          {expanded === feed._id && selectedFeed && (
                            <tr className="position-relative">
                              <td colSpan={8} className="feeds-table-expanded">
                                {feed.state === 'error' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <div className="feeds-table-setup-wrapper">
                                      <FeedsError error={feed.errors} />
                                      <EditFeed
                                        feedFromProps={feed}
                                        creatives={creatives}
                                        updateSelectedFeed={setSelectedFeed}
                                        publishFeed={publishFeed}
                                        deleteFeed={deleteFeed}
                                        disableAll={!isAllowed('update')}
                                        disableNote={!isAllowed('update')}
                                      />
                                    </div>
                                  </>
                                )}
                                {feed.state === 'initial' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <div className="pt-2 d-flex align-items-center">
                                      <Spinner animation="border" role="status" variant="primary" />
                                      <h3 className="ml-2 mb-0">Updating</h3>
                                    </div>
                                  </>
                                )}
                                {feed.state === 'updating' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <div className="pt-2 d-flex align-items-center">
                                      <Spinner animation="border" role="status" variant="primary" />
                                      <h3 className="ml-2 mr-5 mb-0">Updating</h3>
                                      {feed?.misc?.progress !== true ? (
                                        <h4 className="mb-0">{feed?.misc?.progress || ''}</h4>
                                      ) : null}
                                    </div>
                                    <Tabs
                                      defaultActiveKey="view-feed"
                                      id="uncontrolled-tab-example"
                                      className=" mt-3 pl-4"
                                    >
                                      <Tab eventKey="view-feed" title="View Feed">
                                        <ViewFeed feed={feed} updateFeed={updateFeed} disableAll />
                                      </Tab>
                                      <Tab eventKey="edit-feed" title="Edit Feed">
                                        <div className="feeds-table-setup-wrapper">
                                          <EditFeed
                                            feedFromProps={feed}
                                            creatives={creatives}
                                            updateSelectedFeed={setSelectedFeed}
                                            publishFeed={publishFeed}
                                            deleteFeed={deleteFeed}
                                            disableAll
                                            disableNote={!isAllowed('update')}
                                          />
                                        </div>
                                      </Tab>
                                    </Tabs>
                                  </>
                                )}
                                {feed.state === 'active' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateSelectedFeed={updateFeed}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <Tabs
                                      defaultActiveKey="view-feed"
                                      id="uncontrolled-tab-example"
                                      className=" mt-3 pl-4"
                                    >
                                      <Tab eventKey="view-feed" title="View Feed">
                                        <ViewFeed feed={feed} updateFeed={updateFeed} />
                                      </Tab>
                                      <Tab eventKey="edit-feed" title="Edit Feed">
                                        <div className="feeds-table-setup-wrapper">
                                          <EditFeed
                                            feedFromProps={feed}
                                            creatives={creatives}
                                            updateSelectedFeed={setSelectedFeed}
                                            publishFeed={publishFeed}
                                            deleteFeed={deleteFeed}
                                            disableAll
                                            disableNote={!isAllowed('update')}
                                          />
                                        </div>
                                      </Tab>
                                    </Tabs>
                                  </>
                                )}
                                {feed.state === 'setup' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <div className="feeds-table-setup-wrapper">
                                      <EditFeed
                                        feedFromProps={feed}
                                        creatives={creatives}
                                        updateSelectedFeed={setSelectedFeed}
                                        publishFeed={publishFeed}
                                        deleteFeed={deleteFeed}
                                        disableAll={!isAllowed('update')}
                                        disableNote={!isAllowed('update')}
                                      />
                                    </div>
                                  </>
                                )}
                                {feed.state === 'stopped' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <Tabs
                                      defaultActiveKey="view-feed"
                                      id="uncontrolled-tab-example"
                                      className=" mt-3 pl-4"
                                    >
                                      <Tab eventKey="view-feed" title="View Feed">
                                        <ViewFeed feed={feed} updateFeed={updateFeed} />
                                      </Tab>
                                      <Tab eventKey="edit-feed" title="Edit Feed">
                                        <div className="feeds-table-setup-wrapper">
                                          <EditFeed
                                            feedFromProps={feed}
                                            creatives={creatives}
                                            updateSelectedFeed={setSelectedFeed}
                                            publishFeed={publishFeed}
                                            deleteFeed={deleteFeed}
                                            disableAll={!isAllowed('update')}
                                            disableNote={!isAllowed('update')}
                                          />
                                        </div>
                                      </Tab>
                                    </Tabs>
                                  </>
                                )}
                                {feed.state === 'paused' && (
                                  <>
                                    <FeedsStatusBar
                                      getFeeds={getFeeds}
                                      deleteFeed={deleteFeed}
                                      publishFeed={publishFeed}
                                      feedId={feed._id}
                                      feed={selectedFeed}
                                      status={feed.state}
                                      updateFeed={updateFeed}
                                      disabled={!isAllowed('update')}
                                    />
                                    <Tabs
                                      defaultActiveKey="view-feed"
                                      id="uncontrolled-tab-example"
                                      className=" mt-3 pl-4"
                                    >
                                      <Tab eventKey="view-feed" title="View Feed">
                                        <ViewFeed feed={feed} updateFeed={updateFeed} />
                                      </Tab>
                                      <Tab eventKey="edit-feed" title="Edit Feed">
                                        <div className="feeds-table-setup-wrapper">
                                          <EditFeed
                                            feedFromProps={feed}
                                            creatives={creatives}
                                            updateSelectedFeed={setSelectedFeed}
                                            publishFeed={publishFeed}
                                            deleteFeed={deleteFeed}
                                            disableAll={!isAllowed('update')}
                                            disableNote={!isAllowed('update')}
                                          />
                                        </div>
                                      </Tab>
                                    </Tabs>
                                  </>
                                )}
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <p className="text-danger">Something went wrong</p>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </Access>
  );
}

export default FeedsTable;
