/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useMemo, useRef} from 'react';
import {Accordion} from 'react-bootstrap';
import {resolveInitialFilterOptions} from '../../../helpers';
import Filter from './Filter';

const Filters = props => {
  const {setup, filters, setFilters} = props;

  const [show, setShow] = useState(false);

  const currentFilterRef = useRef(2);

  const initialFilterOptions = useMemo(() => resolveInitialFilterOptions(setup), [setup]);

  const options = useMemo(
    () => initialFilterOptions.filter(o => !Object.values(filters).some(f => f.field === o.field)),
    [initialFilterOptions, filters]
  );

  const addFilterDisabled = useMemo(() => options.length <= Object.keys(filters).length, [
    options,
    filters,
  ]);

  const handleAddFilterClicked = () => {
    if (addFilterDisabled) {
      return;
    }

    currentFilterRef.current += 1;

    setFilters(old => ({
      ...old,
      [`filter${currentFilterRef.current}`]: {field: null, value: null, label: null},
    }));
  };

  return (
    <Accordion
      activeKey={show}
      className="p-0 border-0 mr-4 dco-acc gray-200-background"
      defaultActiveKey={false}
      alwaysOpen
    >
      <Accordion.Item className="" eventKey>
        <Accordion.Header
          className="dco-filters-header"
          onClick={() => {
            setShow(old => !old);
          }}
        >
          <span
            className="icon icon-arrow-left dco-filters-header-icon x10 primary-background mr-2"
            style={{transform: show ? 'rotate(-270deg)' : 'rotate(270deg)'}}
          />
          <span>
            Filters<span className="gray-600-text">{`  (${Object.keys(filters).length})`}</span>
          </span>
          {show && (
            <div
              role="button"
              tabIndex={-1}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleAddFilterClicked();
              }}
              className={`ml-3 d-flex primary-text ${addFilterDisabled && 'gray-600-text cursor-'}`}
            >
              <div
                className={`icon ${
                  addFilterDisabled ? 'icon-plus-gray' : 'icon-plus-blue'
                } mr-1 align-items-center`}
              />
              <p style={{fontSize: 'small', margin: 0}}>Add Filter</p>
            </div>
          )}
        </Accordion.Header>
        <Accordion.Body className="d-flex flex-wrap">
          {Object.keys(filters).map(filterId => {
            return <Filter key={filterId} filterId={filterId} options={options} {...props} />;
          })}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default Filters;
