import React, {useEffect, useMemo, useState} from 'react';
import {unwrapResult} from '@reduxjs/toolkit';
import Select from 'react-select/async-creatable';
import {Dropdown, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Formik, Form, ErrorMessage, Field} from 'formik';
import {
  deleteAccess,
  getAccesses,
  getUsersBasic,
  grandAccess,
  updateAccess,
} from '../pages/user/slice';
import Access from '../helpers/access';
import {ErrorMessages, IconError} from '../helpers/error';
import Avatar from '../helpers/avatar';
import CustomToggle from '../helpers/customToggle';

const roles = ['member', 'projectManager', 'teamManager', 'brandManager', 'support', 'admin'];
const defaultRoleByType = {
  team: {
    value: 'teamManager',
    label: 'Team Manager',
  },
  project: {
    value: 'projectManager',
    label: 'Project Manager',
  },
  brand: {
    value: 'brandManager',
    label: 'Brand Manager',
  },
};

const availableRolesByType = {
  team: [
    {
      value: 'teamManager',
      label: 'Team Manager',
    },
  ],
  project: [
    {
      value: 'projectManager',
      label: 'Project Manager',
    },
    {
      value: 'member',
      label: 'Member',
    },
  ],
  brand: [
    {
      value: 'brandManager',
      label: 'Brand Manager',
    },
  ],
};

const adminRoles = ['admin', 'support'];

const customStyles = {
  control: () => ({
    backgroundColor: '#fdfffe',
    backgroundClip: 'padding-box',
    border: '1px solid #eaeff1',
    borderRadius: '0.3125rem',
    cursor: 'text',
    display: 'flex',
  }),
  dropdownIndicator: () => ({
    padding: '4px',
  }),
  clearIndicator: () => ({
    padding: '0',
    paddingRight: '8px',
  }),
};

const InvitationForm = ({dispatch, user, hide, id, parents, type}) => {
  const [isNew, setUserType] = useState(false);

  const groupedUsers = useMemo(() => {
    const grouped = _.groupBy(
      user.accesses.filter(item => !(item.user && item.user.active === false)),
      'role'
    );
    if (grouped['admin'] && grouped['support']) {
      grouped['support'] = [...grouped['admin'], ...grouped['support']];
      delete grouped['admin'];
    }
    return grouped;
  }, [user.accesses]);

  const refreshList = async () => {
    await dispatch(getAccesses({key: `${type}/${id}`, type: type, id}));
  };

  useEffect(() => {
    (async () => await dispatch(getAccesses({key: `${type}/${id}`, type: type, id})))();
  }, [id]);

  const formatRoleTitle = role => {
    switch (role) {
      case 'teamManager':
        return 'Team Managers';
      case 'projectManager':
        return 'Project Managers';
      case 'brandManager':
        return 'Brand Managers';
      case 'member':
        return 'Members';
      case 'admin':
      case 'support':
        return 'Support';
      default:
        return '';
    }
  };

  const submit = async (form, formik) => {
    const formObj = {...form};
    if (form?.email?.__isNew__) {
      formObj.email = form.email.value;
    } else if (form?.email?.item?._id) {
      formObj.id = form.email.item._id;
    }

    console.log('submit');
    try {
      const res = await dispatch(
        grandAccess({
          key: `${type}/access/create/${id}`,
          type: type,
          id,
          form: formObj,
        })
      );
      console.log('follow this');
      if (grandAccess.rejected.match(res)) {
        console.log(res.payload);
        formik.setErrors(res.payload.data);
        formik.setSubmitting(false);
      } else formik.resetForm({});
    } catch (e) {
      console.log(e);
    }
    refreshList();
  };

  const create = async (role, email) => {
    return dispatch(
      grandAccess({key: `${type}/access/create/${id}`, id: id, type: type, form: {email, role}})
    );
  };

  const update = async (id, role) =>
    dispatch(updateAccess({key: `${type}/access/update/${id}`, id, form: {role}}));

  const changeRole = async (userId, role, relativeType, email) => {
    if (relativeType !== type) {
      create(role, email);
    } else {
      update(userId, role);
    }
  };
  const remove = async id => await dispatch(deleteAccess({key: `${type}/access/${id}`, id}));

  const loadUsers = async q => {
    const exclude = user.accesses.map(item => item.userId).filter(item => !!item);
    const res = await dispatch(getUsersBasic({q, active: true}));
    const users = unwrapResult(res);
    return users.items
      .filter(item => !exclude.includes(item._id.toString()))
      .map(i => ({value: i._id, label: `${i.name} ${i.lastName}`, item: i}));
  };

  const canUserEditRole = (userRole, memberRole) =>
    roles.indexOf(userRole) >= roles.indexOf(memberRole);
  return (
    <Access type={type} parents={parents}>
      {({isAllowed, role}) => (
        <Modal show onHide={hide}>
          <Modal.Header style={{justifyContent: 'space-between'}}>
            <Modal.Title>Invite users to the {type}</Modal.Title>
            <button
              onClick={hide}
              style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
            >
              x
            </button>
          </Modal.Header>
          <Modal.Body>
            {isAllowed('invite') && (
              <Formik
                initialValues={{email: '', role: defaultRoleByType[type].value}}
                onSubmit={submit}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
              >
                {({isSubmitting, setFieldValue, values}) => {
                  // console.log(values);
                  return (
                    <Form className={`${!!isSubmitting && 'loading'}`}>
                      <div className="d-flex">
                        <div className="form-group col-6 pl-0">
                          <Select
                            defaultOptions
                            isClearable
                            cacheOptions={false}
                            styles={customStyles}
                            placeholder="Enter name or email"
                            value={values.email}
                            onChange={selected => {
                              setFieldValue('email', selected);
                              setUserType(!!selected?.__isNew__);
                            }}
                            loadOptions={loadUsers}
                          />
                          <ErrorMessage
                            name="email"
                            render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                          />
                        </div>
                        <div className="form-group col-6 p-0">
                          <Field
                            as="select"
                            name="role"
                            placeholder="role"
                            className="form-control custom-select"
                            disabled={values.role === 'admin'}
                          >
                            {availableRolesByType[type].map(option => (
                              <option value={option.value}>{option.label}</option>
                            ))}

                            {(role === 'admin' || role === 'support') && type === 'brand' && (
                              <option value="support">Support</option>
                            )}
                          </Field>
                          <ErrorMessage
                            name="role"
                            render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                          />
                        </div>
                      </div>
                      <div>
                        <button className="btn btn-primary" type="submit">
                          {isNew ? 'Send invite' : 'Add'}
                        </button>
                      </div>
                      <ErrorMessages
                        error={(user.asyncState[`${type}/access/create/${id}`] || {}).error}
                        ignore={[422]}
                        className="mt-2"
                      />
                    </Form>
                  );
                }}
              </Formik>
            )}
            {Object.entries(groupedUsers)
              .sort(([firstRole], [secondRole]) => {
                return roles.indexOf(secondRole) - roles.indexOf(firstRole);
              })
              .map(([roleGroup, items]) => (
                <div>
                  <h4 className="mt-4 text-capitalize">{formatRoleTitle(roleGroup)}</h4>
                  <ErrorMessages error={(user.asyncState[`${type}/${id}`] || {}).error} />
                  {items.map(item => (
                    <div className="mb-2" key={item._id}>
                      <div className="row p-0 m-0 flex-nowrap align-items-center">
                        <IconError
                          asyncState={user.asyncState[`${type}/access/${item._id}`]}
                          fontSize="12"
                          className="mr-2"
                        />
                        <IconError
                          asyncState={user.asyncState[`${type}/access/update/${item._id}`]}
                          fontSize="12"
                          className="mr-2"
                        />
                        <Avatar
                          type="user"
                          id={item.userId}
                          key={item.userId}
                          className="avatar rounderd mr-2"
                          disableClick
                        />
                        <span className="flex-grow-1 pl-0 text-nowrap">
                          <span className="text-primary">
                            {item.status === 'ACCEPTED' ? item.user.email : item.email}
                          </span>
                          <div className="font-weight-light small">
                            {item.status === 'ACCEPTED' ? (
                              <>
                                <span className="badge badge-info">Grid8</span>
                                {item.user.name} {item.user.lastName}
                              </>
                            ) : (
                              <span className="text-info">Pending</span>
                            )}
                          </div>
                        </span>
                        <span className="col-4">
                          <span className="badge badge-secondary">{item.role}</span>
                        </span>
                        <span className="col-1 d-flex pr-0 align-items-end flex-column">
                          {isAllowed('invite') && canUserEditRole(role, item.role) && (
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} />
                              <Dropdown.Menu className="rounded-0">
                                <Dropdown.Item
                                  className="d-flex align-items-center text-primary"
                                  onClick={() =>
                                    changeRole(
                                      item._id,
                                      item.role === 'member'
                                        ? defaultRoleByType[type].value
                                        : 'member',
                                      item.relativeType,
                                      item?.user?.email
                                    )
                                  }
                                >
                                  <div className="icon bg-transparent pr-2 x16 mr-3" />
                                  Change role to{' '}
                                  {item.role === 'member'
                                    ? defaultRoleByType[type].label
                                    : 'Member'}
                                </Dropdown.Item>
                                {!adminRoles.includes(item.role) &&
                                  adminRoles.includes(role) &&
                                  type === 'brand' && (
                                    <Dropdown.Item
                                      className="d-flex align-items-center text-primary"
                                      onClick={() =>
                                        update(
                                          item._id,
                                          'support',
                                          item.relativeType,
                                          item?.user?.email
                                        )
                                      }
                                    >
                                      <div className="icon bg-transparent pr-2 x16 mr-3" />
                                      Change role to Support
                                    </Dropdown.Item>
                                  )}
                                <Dropdown.Item
                                  onClick={() => remove(item._id)}
                                  className="d-flex align-items-center text-muted"
                                >
                                  <div className="icon icon-circle-with-cross pr-2 x16 mr-3" />{' '}
                                  Remove
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </Modal.Body>
        </Modal>
      )}
    </Access>
  );
};

export default connect(store => ({
  user: store.user,
}))(InvitationForm);
