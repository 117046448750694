/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useEffect, useMemo, useState} from 'react';
import axios from 'axios';
import {useParams, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import queryString from 'query-string';
import Draggable from 'react-draggable';
import {Spinner, Toast} from 'react-bootstrap';
import _ from 'lodash';

import TooltipHelper from 'helpers/tooltip';
import api from 'api';
import {Switch} from 'components';
import Pagination from 'helpers/pagination';
import {initalPrivateShareFilter, initalShareFilter} from 'pages/shared/consts';
import {filterPreviews, getFilteredAds, getUniqueDimensions} from 'pages/shared/helpers';
import {getAds} from 'pages/project/DcoProject/slice';
import {getSharedPages} from 'pages/project/DcoProject/components/SharedPage/slice';
import {reviewAgGridColumns} from 'pages/project/DcoProject/Tabs/agHelpers';
import Comments from 'pages/comment/Comments';
import SharedFilters from 'pages/shared/components/SharedFilters';
import {initialPrivateSharedData} from './consts';
import PrivateShareAd from './components/PrivateShareAd';
import PrivateShareTable from './components/PrivateShareTable';
import {createSharedColumnDefs} from './helpers';

const PrivateSharePage = ({ads, shared, dispatch, auth, user}) => {
  const {id: sharedId} = useParams();
  const location = useLocation();

  const [sharedData, setSharedData] = useState(initialPrivateSharedData);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuickReview, setIsQuickReview] = useState(false);
  const [showAllAds, setShowAllAds] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [setup, setSetup] = useState({});
  const [sharedParents, setSharedParents] = useState(null);
  const [assets, setAssets] = useState({});
  const [filters, setFilters] = useState(initalPrivateShareFilter);
  const [adId, setAdId] = useState(null);
  const [adName, setAdName] = useState('');
  const [currentSharedAdId, setCurrentSharedAdId] = useState('');
  const [filter, setFilter] = useState({
    page: parseInt(queryString.parse(location.search).page, 10)
      ? parseInt(queryString.parse(location.search).page, 10)
      : 1,
    limit: 1,
  });

  const adData = {
    parents: sharedParents,
    relativeId: currentSharedAdId,
    secondaryId: adId,
    relativeType: 'preview',
  };

  const filteredData = useMemo(() => {
    if (!sharedData.ads) {
      return [];
    }

    // filter pagination ads data
    return getFilteredAds(sharedData.ads, filters);
  }, [sharedData.ads, filters]);

  const isFilterApplyed = useMemo(() => {
    return !_.isEqual(filters, initalPrivateShareFilter);
  }, [filters]);

  const adsInReview = useMemo(() => {
    if (showAllAds) {
      return rowData;
    }
    return rowData.filter(innerAd => innerAd?.columns?.State === 'In review');
  }, [rowData, showAllAds]);

  const applyFilter = params => {
    setFilter({...filter, ...params});
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const result = await axios.get(
          `https://${
            process.env.REACT_APP_API_DOMAIN
          }/api/dco/preview/${sharedId}?page=${'0'}&limit=${'250'}`
        );

        console.log(result, 'result');
        const adParents = result.data.ads[0].authed.parents;
        const newPreviews = filterPreviews(result.data);
        setSharedData(newPreviews);
        setSharedParents(adParents);

        const {payload: sharedPage} = await dispatch(getSharedPages({parents: {...adParents}}));
        const {payload: allAds} = await dispatch(getAds({parents: {...adParents}}));
        const newSetup = await api.dco.getSetup(sharedPage[0].setup);

        const filteredAds = sharedPage.filter(ad => ad.publicId === sharedId);
        const filteredArr = filteredAds[0].ads;

        const adsWithShareAdData = allAds
          .filter(ad => filteredArr.includes(ad._id))
          .map(ad => {
            const sharedAd = newPreviews.ads.find(cAd => cAd.adId === ad._id);
            if (sharedAd) {
              return {...ad, sharedAdData: {...sharedAd, sharedAdId: filteredAds[0]._id}};
            }
            return ad;
          });

        const sharedColumnDefs = createSharedColumnDefs(adsWithShareAdData[0]);

        setCurrentSharedAdId(filteredAds[0]._id);
        setColumnDefs(sharedColumnDefs);
        setRowData(adsWithShareAdData);
        setSetup(newSetup.data[0]);
      } catch (err) {
        console.log('shared error', err);
      }
      setIsLoading(false);
    })();
  }, [dispatch, sharedId]);

  useEffect(() => {
    (async () => {
      if (_.isEmpty(setup)) {
        return;
      }
      try {
        const assetData = (
          await Promise.all(setup.sizes.map(async size => api.asset.getFile(size.assetId)))
        ).map(a => a.data);

        const filesKV = {};
        assetData.forEach(aFile => {
          filesKV[aFile._id] = aFile;
        });

        const newAssets = {
          files: filesKV,
          urls: assetData.map(aFile => {
            const version = aFile.versions[aFile.versions.length - 1];
            return `${version.dir}/${version.indexFile}`;
          }),
        };

        setAssets(newAssets);
      } catch (error) {
        console.error('assets error', error);
      }
    })();
  }, [setup]);

  return (
    <main className="private-shared-wrapper">
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden" />
          </Spinner>
        </div>
      ) : (
        <>
          {/* Page title */}
          <div style={{width: 'fit-content', marginBottom: '2rem'}}>
            <TooltipHelper
              placement="right"
              content={
                <div style={{textAlign: 'start'}}>
                  <p className="m-0">
                    <strong>Brand</strong>: {sharedData.brand}
                  </p>
                  <p className="m-0">
                    <strong>Team</strong>: {sharedData.team}
                  </p>
                  <p className="m-0">
                    <strong>Project</strong>: {sharedData.project}
                  </p>
                  <p className="m-0">
                    <strong>Shared by</strong>: {sharedData.sharedBy}
                  </p>
                  <p className="m-0">
                    <strong>DCO setup</strong>: {sharedData.dcoSetupName}
                  </p>
                </div>
              }
            >
              <h3>{sharedData.title}</h3>
            </TooltipHelper>
          </div>
          {/* Filters & Quick review */}
          <div className="d-flex align-items-center mb-5" style={{position: 'relative'}}>
            {/* Filters */}
            <div style={{width: '100%'}}>
              <SharedFilters
                filters={filters}
                setFilters={setFilters}
                sizes={getUniqueDimensions(sharedData.ads)}
                isFilterApplyed={isFilterApplyed}
                privateFilter
                disableAll={isQuickReview}
              />
            </div>
            {/* Quick review */}
            <div style={{maxWidth: 200, position: 'absolute', right: 16, top: 8}}>
              <Switch
                label="Quick Review"
                checked={isQuickReview}
                handleChange={checked => {
                  setIsQuickReview(checked);
                }}
              />
            </div>
            {/* Show all ads */}
            {isQuickReview && (
              <div style={{maxWidth: 200, position: 'absolute', right: 175, top: 8}}>
                <Switch
                  label="Show all ads"
                  checked={showAllAds}
                  handleChange={checked => {
                    setShowAllAds(checked);
                  }}
                />
              </div>
            )}
          </div>
          {/* Pagination */}
          {!isQuickReview && (
            <Pagination
              applyFilter={applyFilter}
              total={filteredData.length}
              localName="privateSharePageLimit"
              filter={filter}
              options={[1, 5, 10, 20, 50, 100]}
              className="dco-pagination"
            />
          )}
          {/* Ad List or AG Grid */}
          {isQuickReview ? (
            <PrivateShareTable
              rowData={adsInReview || []}
              columnDefs={columnDefs}
              setup={setup}
              assets={assets}
              setRowData={setRowData}
              setSharedData={setSharedData}
            />
          ) : (
            <div className="d-flex flex-column">
              {filteredData.length ? (
                filteredData
                  .slice(
                    (filter.page - 1) * Number(filter.limit),
                    filter.page * Number(filter.limit)
                  )
                  .map((ad, index) => {
                    return (
                      <PrivateShareAd
                        key={ad.adId}
                        ad={ad}
                        adId={adId}
                        setAdId={setAdId}
                        setAdName={setAdName}
                      />
                    );
                  })
              ) : (
                <p>no results</p>
              )}
            </div>
          )}
        </>
      )}

      {/* Comments Modal */}
      {adId && (
        <Draggable cancel=".shared-comment">
          <Toast
            style={{
              position: 'fixed',
              left: '10%',
              top: '30%',
              transform: 'translate(-50%, -50%)',
              minWidth: 700,
              width: 700,
              maxHeight: '50vh',
              overflowY: 'auto',
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center pt-3 pb-3 px-4"
              style={{
                borderBottom: '1px solid lightgray',
                cursor: 'grab',
                backgroundColor: '#f9f9f9',
                position: 'sticky',
                top: 0,
                zIndex: 2,
              }}
            >
              <h3 className="m-0">{adName}</h3>
              <p className="m-0 cursor-pointer p-2" onClick={() => setAdId(null)}>
                x
              </p>
            </div>
            <Toast.Body>
              <Comments
                type="preview"
                parents={sharedParents}
                id={`${currentSharedAdId}/${adId}`}
                adData={adData}
                isSharedPageComment
              />
            </Toast.Body>
          </Toast>
        </Draggable>
      )}
    </main>
  );
};

export default connect(store => ({
  ads: store.ad.items,
  shared: store.shared.items,
  auth: store.auth,
  user: store.user,
}))(PrivateSharePage);
