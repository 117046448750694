/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import React, {useRef, useState} from 'react';
import {Overlay, Tooltip} from 'react-bootstrap';

import UserCellRenderer from './UserCellRenderer';
import {copyToClipboard} from '../../../../../../../helpers/copyToClipboard';

const ShareCellRenderer = props => {
  const {value, data, handleClick, type} = props;
  const refInfo = useRef(null);
  const [showInfo, setShowInfo] = useState(false);

  const onInfoClicked = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };

  switch (type) {
    case 'title':
      return (
        <span
          tabIndex={0}
          className={`dco-table-title-cell ${handleClick && 'hoverable'}`}
          role="button"
          onClick={() => handleClick(data)}
        >
          {value}
        </span>
      );
    case 'user': {
      return <UserCellRenderer {...props} />;
    }
    case 'link': {
      return (
        <a href={value} target="_blank" rel="noreferrer">
          {value}
        </a>
      );
    }
    case 'boolean':
      return (
        <span className={` ${value ? 'primary-text' : 'gray-600-text'}`}>
          <span>{`\u25CF`} </span>
        </span>
      );

    case 'comment':
      return (
        <div>
          <span>{value?.commentsActive} / </span>

          <span className="gray-600-text">
            {(value?.commentsTotal || 0) - (value?.commentsActive || 0)}
          </span>
        </div>
      );
    case 'copy':
      return (
        <>
          <Overlay target={refInfo.current} show={showInfo} placement="top">
            {overlayProps => (
              <Tooltip id="overlay-example" {...overlayProps}>
                Successfully copied to clipboard
              </Tooltip>
            )}
          </Overlay>
          <div
            className="d-flex justify-items-center align-items-center "
            style={{
              height: '100%',
              cursor: 'pointer',
            }}
            ref={refInfo}
          >
            <span
              className="icon icon-chain"
              tabIndex={0}
              role="button"
              onClick={() => {
                copyToClipboard(value);
                onInfoClicked();
              }}
            />
          </div>
        </>
      );

    default:
      return <span>{value}</span>;
  }
};

export default ShareCellRenderer;
