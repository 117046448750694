import React from 'react';
import Select from 'react-select';
import Switch from '../../../../../../components/Switch';
import FileSelect from '../../../../../../helpers/FileSelect';
import {customInputStyles} from '../../../consts';
import {SizeSelect} from '../Inputs';

const FilterInput = ({value, field = {}, name, handleChange, projectId, assets}) => {
  const {type = '', options = [], default: defaultValue, feedOptions = []} = field;

  switch (type) {
    case 'url':
      return (
        <input
          type="text"
          value={value || ''}
          className="form-control form-control-sm dco-edit-input"
          placeholder="All"
          onChange={e => {
            handleChange(e.target.value);
          }}
        />
      );
    case 'image':
      return (
        <FileSelect
          value={value}
          defaultValue={defaultValue}
          filesId={projectId}
          setValue={handleChange}
        />
      );
    case 'sizes':
      return <SizeSelect sizes={value} handleChange={handleChange} assets={assets} />;
    case 'boolean':
      return <Switch checked={value || false} handleChange={checked => handleChange(checked)} />;
    case 'Published State':
      return (
        <Select
          isClearable={false}
          styles={customInputStyles}
          isSearchable={false}
          value={{label: value, value}}
          cacheOptions={false}
          menuPortalTarget={document.body}
          placeholder="Select"
          onChange={selectValue => {
            handleChange(selectValue.value);
          }}
          options={['Published', 'Unpublished', 'Changed', 'Never Published'].map(s => ({
            label: s,
            value: s,
          }))}
        />
      );
    case 'preset':
      return (
        <Select
          isClearable={false}
          styles={customInputStyles}
          isSearchable={false}
          value={{label: value, value}}
          cacheOptions={false}
          menuPortalTarget={document.body}
          placeholder="Select"
          onChange={selectValue => {
            handleChange(selectValue.value);
          }}
          options={options.map(s => ({label: s, value: s}))}
        />
      );
    case 'dynamic':
      return (
        <Select
          isClearable={false}
          styles={customInputStyles}
          isSearchable={false}
          value={{label: value, value}}
          cacheOptions={false}
          menuPortalTarget={document.body}
          placeholder="Select"
          onChange={selectValue => {
            handleChange(selectValue.value);
          }}
          options={feedOptions.map(s => ({label: s, value: s}))}
        />
      );
    default:
      return (
        <input
          type="text"
          name={name}
          value={value || ''}
          className="form-control form-control-sm dco-edit-input"
          placeholder="All"
          onChange={e => {
            handleChange(e.target.value);
          }}
        />
      );
  }
};

export default FilterInput;
