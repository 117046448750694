/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

const transformShots = shots =>
  shots.map((shot, idx) => ({
    _id: idx,
    shotSizes: shot.map(shotSize => {
      const [key, value] = Object.entries(shotSize)[0];

      return {
        _id: key,
        ...value,
      };
    }),
  }));

export const transformToAds = ad => {
  let publishStatus = '';
  const cvsBoolean = !!ad.published?.csv;
  if (ad.published?.lastPublish) {
    publishStatus = 'Unpublished';
  }
  if (cvsBoolean) {
    publishStatus = 'Published';
  }
  if (
    cvsBoolean &&
    ad.published?.lastChange.slice(0, -5) !== ad.published?.lastPublish.slice(0, -5)
  ) {
    publishStatus = 'Changed';
  }
  return {
    ...ad,
    shots: transformShots(ad.shots),
    published: {
      ...(ad.published || {}),
      PublishedState: publishStatus,
    },
  };
};

export const transformToRequestAd = ad => {
  const {shots, ...rest} = _.omit(ad, 'id');
  return {
    ...rest,
    shots: shots.map(shot =>
      shot.shotSizes.map(shotSize => {
        const {_id, ...values} = shotSize;
        return {[shotSize._id]: values};
      })
    ),
  };
};
