import React from 'react';
import {Link, Route} from 'react-router-dom';

const CustomLink = ({activeStyle, children, to, activeOnlyWhenExact}) => (
  <Route
    path={to}
    exact={activeOnlyWhenExact}
    children={(
      {match} // eslint-disable-line
    ) => (
      <Link to={to} className={match ? 'active nav-link' : 'nav-link'} style={match && activeStyle}>
        {children}
      </Link>
    )}
  />
);

const SideBar = () => {
  return (
    <React.Fragment>
      <nav className="nav flex-column">
        <CustomLink to="/" activeOnlyWhenExact>
          Welcome
        </CustomLink>
        <CustomLink to="/login">Login</CustomLink>
        {/* <CustomLink to="/privacy">Privacy</CustomLink> */}
      </nav>
    </React.Fragment>
  );
};

export default SideBar;
