import React, {useState, useEffect} from 'react';
import {useLastChangedUser} from '../../../../../../../hooks/useLastChangedUser';

const UserCellRenderer = props => {
  const {value} = props;
  const [userName, setUserName] = useState('');
  const {getUserName} = useLastChangedUser();

  useEffect(() => {
    (async () => {
      const user = await getUserName(value);
      setUserName(user);
    })();
  }, [value, getUserName]);

  return <span>{userName}</span>;
};

export default UserCellRenderer;
