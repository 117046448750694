import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {LicenseManager} from 'ag-grid-enterprise';

import Auth from 'helpers/Auth';
import store from './store';
import './scss/main.scss';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

library.add(fas);
String.prototype.trunc = function(n) {
  return this.substr(0, n - 1) + (this.length > n ? '...' : '');
};

const maintenance = false;
const maintenanceMsg = <><h2>Maintenance!</h2><p>we'll be back online by 1:30 pm (Central European Time) </p></>;

ReactDOM.render(maintenance?maintenanceMsg :
  <Provider store={store}>
    <BrowserRouter>
      <Auth />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
