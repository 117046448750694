/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import {ConfirmationModal} from 'components';
import {createTeam} from 'pages/team/slice';

const CreateTeamForm = ({id, callbackFn = false}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [name, setName] = useState('');

  const dispatch = useDispatch();

  const handleClose = () => setIsVisible(false);

  const handleChange = e => {
    setName(e.target.value);
  };

  const createNewTeam = async () => {
    try {
      const result = await dispatch(createTeam({id, form: {name, brandId: id}}));
      if (callbackFn) {
        callbackFn(result.payload);
      }
    } catch (error) {
      console.log('create new team error: ', error);
    }

    handleClose();
    setName('');
  };

  const renderCustomBody = () => {
    return (
      <div className="d-flex align-items-center justify-content-center" style={{height: 100}}>
        <input
          className="form-control"
          placeholder="Team Name"
          value={name}
          onChange={handleChange}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className="d-flex align-items-center ml-5 cursor-pointer"
        onClick={() => setIsVisible(true)}
      >
        <div className="icon icon-plus-blue ml-2" />
        <span className="ml-1 small text-primary text">Create new team</span>
      </div>
      <ConfirmationModal
        show={isVisible}
        title="Create new team"
        onContinue={createNewTeam}
        onCancel={handleClose}
        cancelColor="secondary"
        onClose={handleClose}
        continueText="Create"
        customBody={renderCustomBody()}
      />
    </>
  );
};

export default CreateTeamForm;
