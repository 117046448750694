import React, {useEffect, useState} from 'react';
import Loadable from 'react-loadable';
import {connect} from 'react-redux';
import {Link, Switch, Route, useHistory} from 'react-router-dom';
import SingleInputForm from '../../helpers/singleInputForm';
import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import {getProject, dismissError, updateProject} from './slice';
// import api from '../../api';
import Queue from './queue';
import FeedsTable from './FeedsTable/FeedsTable';
import DcoProject from './DcoProject/DcoProject';
import Users from '../../helpers/users';
import {getAccesses} from '../user/slice';
import InvitationForm from '../../components/InvitationForm';

const Files = Loadable({loader: () => import('../file'), loading: () => null});
const Comments = Loadable({loader: () => import('../comment'), loading: () => null});
const VideobuilderTemplatesList = Loadable({
  loader: () => import('../videobuilder'),
  loading: () => null,
});
const VideobuilderTabs = Loadable({
  // loader: () => import('../team/videobuilder'),
  loader: () => import('./Videobuilder'),
  loading: () => null,
});

const ProjectDetailsPage = ({dispatch, auth, project, user, match}) => {
  const [showForm, showNameForm] = useState(false);
  const [inVideobuilder, setInVideobuilder] = useState(false);
  const [selected, select] = useState(false);
  const [showInvitationForm, show] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const result = await dispatch(getProject(match.params.id));
      await dispatch(
        getAccesses({key: `project/${match.params.id}`, type: 'project', id: match.params.id})
      );

      if (result.error) {
        history.push('/error');
      }
    })();
  }, [match.params.id, dispatch]);

  useEffect(() => {
    if (match.url.indexOf('videobuilder') !== -1) {
      setInVideobuilder(true);
    } else setInVideobuilder(false);
  }, [match.url]);

  useEffect(() => {
    if (selected) history.push(`/project/${match.params.id}/videobuilder`);
  }, [selected]);

  const updateProjectName = async (id, name) =>
    dispatch(updateProject({key: `${id}/name`, id, form: {name}}));

  if (project.item?._id !== match.params.id) return null;
  /*
  const setRendPriority = (uid, priority) => {
    console.log('priority to set', priority);
    api.videobuilder
      .setRendPriority(uid, priority)
      .then(r => console.log('priority set'))
      .catch(console.error);
  };
*/
  return (
    <>
      <div className="project-breadcrumb text-muted text-uppercase">
        <span>{project.item?.brand?.name}</span>
        <span className="pl-2 pr-2">/</span>
        <Link to={`/team/view/${project.item.parents.teamId}`} className="text-rimary">
          {project.item?.team?.name}
        </Link>
        <span className="pl-2 pr-2">/</span>
        {!inVideobuilder ? (
          <span> {project.item?.name}</span>
        ) : (
          <Link to={`/project/${match.params.id}`} className="text-rimary">
            {project.item?.name}
          </Link>
        )}
        {inVideobuilder && <span className="pl-2 pr-2">/</span>}
        {inVideobuilder && <span>videobuilder</span>}
        {showInvitationForm && (
          <InvitationForm
            hide={() => show(false)}
            id={match.params.id}
            parents={project.item.parents}
            type="project"
          />
        )}
      </div>
      <Access type="project" parents={project.item.parents}>
        {({isAllowed, role}) => (
          <div className="team-container w-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="w-100 d-flex align-items-center">
                <Avatar type="project" id={match.params.id} className="avatar-head mr-4" />
                {!showForm && (
                  <div
                    role="button"
                    tabIndex={0}
                    className="m-0 p-0  d-flex align-items-center"
                    onClick={() => (isAllowed('update') ? showNameForm(true) : null)}
                  >
                    <h1 className="detail-title m-0 p-0">{(project.item || {}).name}</h1>
                    {isAllowed('update') && <div className="icon icon-edit ml-4" />}
                  </div>
                )}
                {isAllowed('update') && showForm && (
                  <div className="m-0 p-0 flex-grow-1 d-flex align-items-center team-name">
                    <SingleInputForm
                      value={(project.item || {}).name}
                      placeholder="Team name"
                      asyncState={project.asyncState[`${match.params.id}/name`]}
                      dismiss={() => dispatch(dismissError(`${match.params.id}/name`))}
                      onSubmit={name => updateProjectName(match.params.id, name)}
                      show={showNameForm}
                    />
                  </div>
                )}
                <span className="template-text-gray ml-4">{role ?? auth?.user?.role}</span>
              </div>
              <Users
                users={user.accesses}
                show={show}
                allowAdd={isAllowed('invite')}
                shownRoles={['projectManager']}
              />
            </div>
          </div>
        )}
      </Access>
      <Switch>
        <Route
          exact
          path="/project/:id/videobuilder"
          render={() => <VideobuilderTabs dispatch match project selected={selected} />}
        />
        <Route
          path="/project/:id"
          render={() => (
            <div style={{marginTop: 20}}>
              <Switch>
                <Route
                  exact
                  path="/project/:id/(file)?/:fileId?"
                  render={() => (
                    <>
                      {project.item.type === 'videobuilder' && (
                        <div className="d-flex">
                          <div className="col-6 pl-0">
                            <Access type="project" parents={project.item.parents}>
                              {({role}) => (
                                <Queue project={project} admin={role === 'admin.admin'} />
                              )}
                            </Access>
                            <h4>Assets</h4>
                            <Files
                              type="project"
                              id={match.params.id}
                              smartCropperBrandId={project?.item?.parents?.brandId}
                            />
                          </div>
                          <div className="col-6 pt-0 mt-0 pr-0">
                            <h4>Templates</h4>
                            <p>
                              Select a template from brand library to start creating your own
                              content
                            </p>
                            <VideobuilderTemplatesList
                              key={match.params.id}
                              type="videobuilder"
                              id={match.params.id}
                              parents={project.item.parents || {}}
                              select={select}
                            />
                          </div>
                        </div>
                      )}
                      {project.item.type === 'regular' && (
                        <div className="d-flex">
                          <div className="col-6 pl-0">
                            <h4>Assets</h4>
                            <Files
                              type="project"
                              id={match.params.id}
                              smartCropperBrandId={project?.item?.parents?.brandId}
                            />
                          </div>
                          <div className="col-6 pt-0 mt-0 pr-0">
                            <h4>Discussion</h4>
                            <Comments
                              key={match.params.id}
                              type="project"
                              id={match.params.id}
                              parents={project.item.parents || {}}
                            />
                          </div>
                        </div>
                      )}
                      {project.item.type === 'socialdpa' && (
                        <div>
                          <h4>Feeds</h4>
                          <FeedsTable
                            parents={project.item.parents || {}}
                            projectId={match.params.id}
                          />
                        </div>
                      )}
                      {project.item.type === 'dco' && (
                        <DcoProject
                          dispatch={dispatch}
                          projectId={match.params.id}
                          parents={project.item.parents || {}}
                        />
                      )}
                    </>
                  )}
                />
                <Route path="/project/:id/(social|display|dc|sc)/:id?" component={() => 'o.O'} />
              </Switch>
            </div>
          )}
        />
      </Switch>
    </>
  );
};

export default connect(store => ({
  auth: store.auth,
  project: store.project,
  user: store.user,
}))(ProjectDetailsPage);
