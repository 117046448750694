import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import {NavLink, Link} from 'react-router-dom';
import moment from 'moment';
import Select from 'react-select/async';
import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import CustomToggle from '../../helpers/customToggle';
import Pagination from '../../helpers/pagination';
import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import Confirm from '../../helpers/confirmation';
import Th from '../../helpers/th';
import ProjectView from '../project/ProjectDetailsPage';
import {getProjects, deleteProject, updateProject} from '../project/slice';
import {getBrands} from '../brand/slice';
import {getTeams} from '../team/slice';

const customStyles = {
  input: provided => ({
    ...provided,
    width: 100,
    margin: '1px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 4px',
  }),
  control: () => ({
    backgroundColor: '#fdfffe',
    backgroundClip: 'padding-box',
    border: '1px solid #eaeff1',
    borderRadius: '0.3125rem',
    cursor: 'text',
    display: 'flex',
  }),
  clearIndicator: () => ({
    padding: '0',
    paddingRight: '8px',
  }),
};

function DashboardPage({history, dispatch, team, brand, project, location, match}) {
  const [filter, setFilter] = useState({
    q: queryString.parse(location.search).q,
    brandId: queryString.parse(location.search).brandId || null,
    teamId: queryString.parse(location.search).teamId || null,
    sortType: queryString.parse(location.search).sortType || 'desc',
    sortField: queryString.parse(location.search).sortField || 'createdAt',
    page: parseInt(queryString.parse(location.search).page, 10)
      ? parseInt(queryString.parse(location.search).page, 10)
      : 1,
    limit: localStorage.getItem('projectsLimit') || queryString.parse(location.search).limit || 20,
  });

  useEffect(() => {
    (async () => await dispatch(getProjects(filter)))();
  }, [filter]);

  const destroy = async id => await dispatch(deleteProject({key: `delete/${id}`, id}));
  const update = async (id, status) =>
    await dispatch(updateProject({key: `status/${id}`, id, form: {status}}));

  const applyFilter = params => {
    history.replace({
      ...location,
      search: queryString.stringify({...queryString.parse(location.search), ...params}),
    });
    setFilter({...filter, ...params});
  };

  const toggleRow = id => {
    if (match.params.projectId === id) return history.replace(`/project${location.search}`);
    return history.push(`/project/${id}${location.search}`);
  };

  const loadBrands = async q => {
    const res = await dispatch(getBrands({q, limit: 20}));
    const brands = unwrapResult(res);
    return brands.items.map(i => ({value: i._id, label: i.name}));
  };

  const loadTeams = async q => {
    const res = await dispatch(getTeams({q, limit: 20}));
    const teams = unwrapResult(res);
    return teams.items.map(i => ({value: i._id, label: i.name}));
  };

  const typeToPrettyName = name => {
    switch (name) {
      case 'socialdpa':
        return 'Social DPA';
      case 'regular':
        return 'Standard';
      case 'videobuilder':
        return 'Videobuilder';
      case 'dco':
        return 'DCO';
      default:
        return 'Unknown';
    }
  };

  return (
    <Access type="project" action="list" overWritRole={false}>
      {({isAllowed}) => (
        <div className="m-3 tableview">
          {/* {((match.params.id || ['create', 'edit'].includes(match.params.action)) && match.params.action !== 'view') && isAllowed('create') &&
                      <Form hide={() => history.push(`/team/${location.search}`)} id={match.params.id} />
                    } */}
          <div className="row mt-2 mb-2 tableview-head">
            <div className="col-2 d-flex align-items-baseline">
              <h3 className="mb-0">Projects</h3>
            </div>
            <div className="col-10 d-flex align-items-baseline justify-content-end">
              <div className="row flex-grow-1 justify-content-end mr-3">
                <div className="col-3">
                  <Select
                    defaultOptions
                    isClearable
                    cacheOptions={false}
                    styles={customStyles}
                    placeholder="Select a brand"
                    value={
                      filter.brandId
                        ? {
                            value: filter.brandId,
                            label:
                              (brand.items.find(i => i._id === filter.brandId) || {}).name ||
                              'Selected Brand',
                          }
                        : null
                    }
                    onChange={selected =>
                      selected
                        ? applyFilter({brandId: selected.value, page: 1})
                        : applyFilter({brandId: null})
                    }
                    loadOptions={loadBrands}
                  />
                </div>
                <div className="col-3">
                  <Select
                    defaultOptions
                    isClearable
                    cacheOptions={false}
                    styles={customStyles}
                    placeholder="Select a team"
                    value={
                      filter.teamId
                        ? {
                            value: filter.teamId,
                            label:
                              (team.items.find(i => i._id === filter.teamId) || {}).name ||
                              'Selected Brand',
                          }
                        : null
                    }
                    onChange={selected =>
                      selected
                        ? applyFilter({teamId: selected.value, page: 1})
                        : applyFilter({teamId: null})
                    }
                    loadOptions={loadTeams}
                  />
                </div>
                <input
                  type="text"
                  className="form-control form-control-sm col-2"
                  placeholder="search"
                  onChange={e => applyFilter({q: e.target.value})}
                  value={filter.q}
                />
              </div>
            </div>
          </div>
          <table
            className={`table ${
              !match.params.id && !match.params.action && team.fetching ? 'loading' : ''
            }`}
          >
            <thead>
              <tr>
                <th style={{width: 24}} />
                <Th name="name" filter={filter} onSelect={applyFilter} className="text-nowrap">
                  Project Name
                </Th>
                <th>Brand</th>
                <th>Team</th>
                <th>Type</th>
                <th>State</th>
                <Th name="createdAt" filter={filter} onSelect={applyFilter}>
                  Created at
                </Th>
                <th aria-label=" " />
              </tr>
            </thead>
            <tbody>
              {project.items.map(item => (
                <React.Fragment key={item._id}>
                  <NavLink to={`/project/${item._id}`} style={{display: 'contents'}}>
                    <tr
                      /* onClick={() => toggleRow(item._id)} */
                      className={`project-list-link ${
                        item._id === match.params.id && match.params.action === 'view'
                          ? 'bg-secondary-tr'
                          : ''
                      }`}
                    >
                      <td>
                        <Avatar type="project" id={item._id} className="avatar" />
                      </td>
                      <td className="text-nowrap project-link-name">{item.name}</td>
                      <td>{item.brand?.name}</td>
                      <td>{item.team?.name}</td>
                      <td>{typeToPrettyName(item.type)}</td>
                      <td>
                        <div className="badge badge-secondary">{item.status}</div>
                      </td>
                      <td className="text-nowrap">
                        {moment
                          .utc(item.createdAt)
                          .local()
                          .format('DD-MM-YYYY HH:mm')}
                      </td>
                      <td className="text-right text-nowrap" key={item.updatedAt}>
                        {isAllowed('delete') && (
                          <Dropdown onClick={e => e.stopPropagation()}>
                            <Dropdown.Toggle as={CustomToggle} right />
                            <Dropdown.Menu className="rounded-0">
                              <div style={{padding: '0.25rem 1.5rem'}}>
                                <div className="btn-group">
                                  <button
                                    type="button"
                                    className={`${item.status === 'toDo' &&
                                      'active'} btn btn-sm btn-secondary`}
                                    onClick={() => update(item._id, 'toDo')}
                                  >
                                    To Do
                                  </button>
                                  <button
                                    type="button"
                                    className={`${item.status === 'inProgress' &&
                                      'active'} btn btn-sm btn-secondary`}
                                    onClick={() => update(item._id, 'inProgress')}
                                  >
                                    In progress
                                  </button>
                                  <button
                                    type="button"
                                    className={`${item.status === 'done' &&
                                      'active'} btn btn-sm btn-secondary`}
                                    onClick={() => update(item._id, 'done')}
                                  >
                                    Done
                                  </button>
                                </div>
                              </div>
                              <Confirm
                                onConfirm={() => dispatch(deleteProject({id: item._id, filter}))}
                              >
                                <div
                                  role="listitem"
                                  className="dropdown-item"
                                  onClick={() => destroy(item._id)}
                                >
                                  <React.Fragment>
                                    {project.asyncState[`delete/${project.item?._id}`] ? (
                                      <FontAwesomeIcon icon="spinner" spin />
                                    ) : (
                                      'Delete'
                                    )}
                                  </React.Fragment>
                                </div>
                              </Confirm>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </td>
                    </tr>
                  </NavLink>
                  {item._id === match.params.projectId && match.params.action === 'view' && (
                    <tr>
                      <td colSpan="7" className="pl-3">
                        <Route path="/project/:projectId" component={ProjectView} />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <div className="tableview-foot">
            <Pagination
              applyFilter={applyFilter}
              total={project.total}
              filter={filter}
              localName="projectsLimit"
            />
          </div>
        </div>
      )}
    </Access>
  );
}

export default connect(store => ({
  project: store.project,
  team: store.team,
  brand: store.brand,
  auth: store.auth,
}))(DashboardPage);
