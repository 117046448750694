import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from 'api';
import axios from 'axios';
import {initialDcoSetupState} from './consts';

export const createSetup = createAsyncThunk(
  'setup/createSetup',
  async (formData: any, {rejectWithValue}) => {
    try {
      const result = await api.dco.createSetupForm({...formData});
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const {data, status, statusText} = err.response;
        return rejectWithValue({data, status, statusText});
      }
      console.log('get setups redux fail: ', err);
    }
  }
);

export const getSetups = createAsyncThunk(
  'setup/getSetups',
  async (brandId: string, {rejectWithValue}) => {
    try {
      const result = await api.dco.getSetupsWithAdRefs({parents: {brandId}});
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const {data, status, statusText} = err.response;
        return rejectWithValue({data, status, statusText});
      }
      console.log('get setups redux fail: ', err);
    }
  }
);

export const getSetup = createAsyncThunk(
  'setup/updateSetup',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await api.dco.getSetup(id);
      console.log('getSetup result:', result.data);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const {data, status, statusText} = err.response;
        return rejectWithValue({data, status, statusText});
      }
      console.log('get setups redux fail: ', err);
    }
  }
);

export const updateSetup = createAsyncThunk(
  'setup/updateSetup',
  async (formData: any, {rejectWithValue}) => {
    try {
      const result = await api.dco.updateSetupForm(formData);
      console.log('updateSetup result:', result.data);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const {data, status, statusText} = err.response;
        return rejectWithValue({data, status, statusText});
      }
      console.log('get setups redux fail: ', err);
    }
  }
);

export const deleteSetup = createAsyncThunk(
  'setup/deleteSetup',
  async (deleteData: any, {rejectWithValue}) => {
    const {setupId, brandId} = deleteData;
    try {
      const result = await api.dco.deleteSetupForm(setupId, {parents: {brandId}});
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const {data, status, statusText} = err.response;
        return rejectWithValue({data, status, statusText});
      }
      console.log('delete setup redux fail: ', err);
    }
  }
);

export const updateLockedSetupForm = createAsyncThunk(
  'setup/updateLockedSetupForm',
  async (formData: any, {rejectWithValue}) => {
    try {
      const result = await api.dco.updateLockedSetupForm(formData);
      return result.data;
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const {data, status, statusText} = err.response;
        return rejectWithValue({data, status, statusText});
      }
      console.log('delete setup redux fail: ', err);
    }
  }
);

export const setups = createSlice({
  name: 'setup',
  initialState: initialDcoSetupState,
  reducers: {
    addCurrentSetup: (state, action: any) => {
      state.activeSetup = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getSetups.fulfilled, (state, {payload}) => {
      return {
        ...state,
        setupList: payload,
        fetching: false,
        error: null,
      };
    }),
      builder.addCase(getSetups.pending, state => {
        return {...state, fetching: true, error: null};
      }),
      builder.addCase(getSetups.rejected, (state, {error}) => {
        return {
          ...state,
          fetching: false,
          error: error.message,
        };
      }),
      builder.addCase(deleteSetup.fulfilled, (state, {payload}) => {
        return {
          ...state,
          setupList: payload,
          fetching: false,
          error: null,
        };
      }),
      builder.addCase(deleteSetup.pending, state => {
        return {...state, fetching: true, error: null};
      }),
      builder.addCase(deleteSetup.rejected, (state, {error}) => {
        return {
          ...state,
          fetching: false,
          error: error.message,
        };
      }),
      builder.addCase(updateSetup.fulfilled, (state, {payload}) => {
        return {
          ...state,
          activeSetup: payload,
        };
      }),
      builder.addCase(updateSetup.pending, state => {
        return {...state, fetching: true, error: null};
      }),
      builder.addCase(updateSetup.rejected, (state, {error}) => {
        return {
          ...state,
          fetching: false,
          error: error.message,
        };
      }),
      builder.addCase(createSetup.fulfilled, (state, {payload}) => {
        return {
          ...state,
          activeSetup: payload,
        };
      }),
      builder.addCase(createSetup.pending, state => {
        return {...state, fetching: true, error: null};
      }),
      builder.addCase(createSetup.rejected, (state, {error}) => {
        return {
          ...state,
          fetching: false,
          error: error.message,
        };
      }),
      builder.addCase(updateLockedSetupForm.fulfilled, (state, {payload}) => {
        return {
          ...state,
          activeSetup: payload,
        };
      }),
      builder.addCase(updateLockedSetupForm.pending, state => {
        return {...state, fetching: true, error: null};
      }),
      builder.addCase(updateLockedSetupForm.rejected, (state, {error}) => {
        return {
          ...state,
          fetching: false,
          error: error.message,
        };
      });
  },
});

export const {addCurrentSetup} = setups.actions;
export default setups.reducer;
