import {getCurrentDateFormatted} from './helpers';

export const shareTypes = {
  ALL: 'all',
  SELECTED: 'selected',
  FILTERED: 'filtered',
};

export const initialShareState = {
  title: getCurrentDateFormatted(),
  settings: {
    type: shareTypes.ALL,
    autoUpdate: true,
  },
};
