/* eslint-disable default-param-last */
/* eslint-disable no-undef */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

import {replaceElementInArray, replaceElementInArrayById} from '../../brand/Dco/helpers';
/**
 * This is an abstraction of array.find, pass the array as first param and
 * second is id to find on objects in array
 * @param {Array} array: array in which to search for id
 * @param {String} id: id to search
 */
export const findElementById = (array = [], id) => array.find(e => e._id === id);

export const resolveDefaultShotValues = (shotStructure, shot, overrides, adShotData) => {
  const shotObj = {};

  Object.entries(shotStructure).forEach(([k, v]) => {
    if (shot && k in shot) {
      shotObj[k] = shot[k];
      return;
    }

    if (v.type === 'image') {
      shotObj[k] = {
        url: v.default,
        name: 'Default Image',
      };
      return;
    }
    if (v.type === 'studioAsset') {
      shotObj[k] = {
        url: v.default,
        name: v.default,
      };
      return;
    }
    if (overrides?.[k]) {
      console.log('override exists for', k);
      shotObj[k] = adShotData[overrides[k]] || v.default;
    } else shotObj[k] = v.default || '';
  });
  return shotObj;
};

export const createSizes = (sizes, shotStructure, idx = 0) => {
  const shotSizes = sizes.map(size => ({
    _id: size._id,
    ...resolveDefaultShotValues(shotStructure, null),
  }));
  return {
    _id: idx,
    shotSizes,
  };
};

export const createAdFromSetup = setup => {
  const sizes = [...setup.sizes.map(s => ({...s, enabled: true}))];

  const shots = [createSizes(sizes, setup.shotStructure)];
  const columns = {};
  Object.entries(setup.adColumns).map(([k, value]) => {
    if (value.type === 'dynamic') {
      columns[k] = value.remoteOption || value.default;
      return null;
    }
    columns[k] = value.default;
    return null;
  });

  return {id: -1, columns, shots, sizes, version: setup.version};
};

export const addNewShot = (shots, setup) => {
  if (setup.maxShots <= shots.length) {
    return shots;
  }

  return [...shots, createSizes(setup.sizes, setup.shotStructure, shots.length)];
};

export const buildPreviewData = (
  size,
  file,
  shotStructure,
  adSizeId,
  columnsUsedInPreview,
  adColumns
) => {
  const version = file?.versions.at(-1);
  const wh = `${version.width}x${version.height}`;
  const keys = Object.keys(shotStructure);
  const values = size
    .flatMap((shot, index) => {
      const prefix = `s${index}`;
      return keys.map(key => {
        return `${encodeURIComponent(`${prefix}_${key}`)}=${encodeURIComponent(
          shot?.[key]?.url === '' ? shot[key].url : shot?.[key]?.url || shot[key]
        ).replaceAll('&', '%26')}`;
      });
    })
    .join('&');
  const columns = columnsUsedInPreview
    ?.map(key => {
      const value =
        adColumns[key] && adColumns[key].toString().includes('[size]')
          ? adColumns[key].toString().replaceAll('[size]', wh)
          : adColumns[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value).replaceAll('&', '%26')}`;
    })
    .join('&');

  const asset = `${version?.dir}/${version?.indexFile}`;

  return {
    sizeId: adSizeId,
    size: file?.creativeSetupTitle,
    src: `${values}&${columns}`,
    asset,
  };
};

export const duplicateSelectedAds = selectedAds => {
  return selectedAds.map(ad => {
    const {_id, v, published, ...rest} = ad;
    return rest;
  });
};

export const changeValueInSelectedAds = (selectedAds, field, newValue, originalArray = []) => {
  return selectedAds.map(ad => {
    if (originalArray.length) {
      const originalAd = originalArray.find(a => a._id === ad._id);
      if (originalAd) {
        return {
          ...ad,
          columns: {...originalAd.columns, [field]: newValue},
        };
      }
    }
    return {
      ...ad,
      columns: {...ad.columns, [field]: newValue},
    };
  });
};

export const archiveSelectedAds = selectedAds => {
  return selectedAds.map(ad => {
    return {
      ...ad,
      archived: true,
    };
  });
};

export const duplicateSelectedShots = (selectedShots, shots) => {
  return [...shots, ...selectedShots.map((shot, index) => ({...shot, _id: shots.length + index}))];
};

export const deleteSelectedShots = (selectedShots, shots) =>
  shots
    .filter(shot => !selectedShots.some(s => s._id === shot._id))
    .map((shot, index) => ({...shot, _id: index}));

/**
 * Replaces updated items in a list by id and appends new items
 * @param {Array} items - List of items
 * @param {Array} payload - List of updated and created items
 * @return {Array} - List of updated and created items
 */

export const replaceUpdatedItemsById = (items, payload) => {
  const [updatedItems, createdItems] = _.partition([payload].flat(), item =>
    items.find(i => i._id === item._id)
  );

  const replaced = items
    .map(item => {
      const updatedItem = updatedItems.find(i => i._id === item._id);
      if (updatedItem) {
        return updatedItem;
      }
      return item;
    })
    .filter(i => !i.archived);

  return [...replaced, ...createdItems];
};
/* no idea what's happening here, potential bug nest, here's a summary of my wild guess from slack conversation
 * if it might help in the future debugging episode:
 * it kinda looks like
12:05
if the image was added to shot structure
12:05
and the master creative does not have it
12:06
and it fails on copying that from the master on ad update
12:06
but that's just a guess */
const resolveMasterImageFilesForSize = (masterSize, shotStructure, files) => {
  if (!masterSize) {
    return [];
  }
  const imageKeys = Object.entries(shotStructure)
    .filter(([, v]) => {
      return v.type === 'image';
    })
    .map(([k]) => k);
  const obj = {};
  imageKeys.map(key => {
    const keyImage = masterSize?.[key]?.url?.replace(`${process.env.REACT_APP_S3_URL}/`, '');
    obj[key] = files.find(f => f.versions.some(v => v.name === keyImage));
    return {[key]: files.find(f => f.versions.some(v => v.name === keyImage))};
  });

  return obj;
};

const updateImagesByMasterRow = (size, masterSizeFiles, files) => {
  if (!files.length) {
    return {};
  }
  const obj = {};
  Object.entries(masterSizeFiles).forEach(([imageKey, file]) => {
    if (!file) {
      return;
    }
    const imgName = file.displayName?.replace(/[0-9\(\)]+x[0-9\(\)]+/, '');
    const differentSizeFiles = files.filter(f => f.displayName?.includes(imgName));
    const sizeFile = differentSizeFiles.find(f =>
      f.displayName?.includes(`${size.width}x${size.height}`)
    );

    if (sizeFile) {
      obj[imageKey] = {
        url: `${process.env.REACT_APP_S3_URL}/${
          sizeFile.versions[sizeFile.versions.length - 1].name
        }`,
        name: sizeFile.displayName,
      };
    }
  });
  return obj;
};

/**
 * check for changes between setup and ad, currently only for columns
 */
export const updatePreflight = (setup, ad) => {
  const columns = {};
  const extras = Object.keys(ad.columns);
  console.log(setup.adColumns);
  Object.entries(setup.adColumns).forEach(([k, value]) => {
    if (k !== 'changes') {
      if (Object.prototype.hasOwnProperty.call(ad.columns, k)) {
        columns[k] = 'same';
        extras.splice(extras.indexOf(k), 1);
      } else {
        columns[k] = `default|${value.default || ''}`;
      }
    }
  });

  const shotStructure = {};
  const randomShot = ad.shots[0].shotSizes[0];
  const extrasShots = Object.keys(randomShot).filter(key => key !== '_id');
  // console.log('randomShot', randomShot);
  Object.entries(setup.shotStructure).forEach(([k, value]) => {
    if (k !== '_id') {
      if (Object.prototype.hasOwnProperty.call(randomShot, k)) {
        shotStructure[k] = 'same';
        extrasShots.splice(extrasShots.indexOf(k), 1);
      } else {
        shotStructure[k] = `default|${value.default}`;
      }
    }
  });
  // make the same for shots, if new shot is detected, choose if you want to enable it or disable

  const extraShotSizes = [];
  const deletedSizes = [...ad.sizes];
  // console.log('randomShot', randomShot);
  setup.sizes.forEach(size => {
    if (!ad.shots[0].shotSizes.find(sh => sh._id === size._id)) {
      extraShotSizes.push(size);
    } else
      deletedSizes.splice(
        deletedSizes.findIndex(ds => ds._id === size._id),
        1
      );
  });

  console.log('deletedsizes:', deletedSizes);

  return {
    changes: {columns, extras, shotStructure, extrasShots, extraShotSizes, deletedSizes},
    previousVersion: ad.version,
  };
  // return {changes: {columns, extras}};
};

export const conformAdToSetup = (setup, ad, files, overrides) => {
  const masterSize = setup.sizes.find(size => size.mastersize);
  const sizes = setup.sizes.map(size => {
    const adSize = ad.sizes.find(s => s._id === size?._id);
    if (adSize) {
      return {
        ...adSize,
        ...size,
        enabled:
          overrides?.sizeOverrides?.[size._id] !== undefined
            ? overrides.sizeOverrides[size._id]
            : adSize.enabled,
      };
    }
    return {
      ...size,
      enabled:
        overrides?.sizeOverrides?.[size._id] !== undefined
          ? overrides.sizeOverrides[size._id]
          : true,
    };
  });

  const shots = ad.shots.map((shot, shId) => {
    const shotSizes = [];
    sizes.forEach((size, siId) => {
      console.log(' replace shot', shot);
      console.log(' replace size', size);
      console.log(' replace', shId, siId);
      if (findElementById(ad.sizes, size._id)) {
        shotSizes.push({
          _id: size._id,
          ...resolveDefaultShotValues(
            setup.shotStructure,
            findElementById(shot.shotSizes, size._id),
            overrides,
            ad.shots[shId].shotSizes[siId]
          ),
        });
      } else if (
        overrides?.shotReplace?.[size._id] &&
        overrides?.shotReplace?.[size._id] !== 'none'
      ) {
        const toReplace = findElementById(
          ad.shots[shId].shotSizes,
          overrides.shotReplace[size._id]
        );
        console.log('replace existing size with id', toReplace);
        shotSizes.push({
          ...toReplace,
          _id: size._id,
        });
      } else {
        const masterShotSize = findElementById(shotSizes, masterSize._id);
        const first = resolveDefaultShotValues(
          setup.shotStructure,
          masterShotSize,
          overrides,
          ad.shots[shId].shotSizes[siId]
        );
        const second = updateImagesByMasterRow(
          size,
          resolveMasterImageFilesForSize(masterShotSize, setup.shotStructure, files),
          files
        );
        const merged = {...first, ...second};

        shotSizes.push({
          _id: size._id,
          ...merged,
        });
      }
    });
    return {...shot, shotSizes};
  });
  const columns = {};
  Object.entries(setup.adColumns).forEach(([k, value]) => {
    console.log('k', k, 'value', value);
    if (overrides && Object.hasOwnProperty.call(overrides, k)) {
      columns[k] = ad.columns[overrides[k]];
    } else if (Object.prototype.hasOwnProperty.call(ad.columns, k)) {
      columns[k] = ad.columns[k];
    } else columns[k] = value.default;
  });
  // console.log(overrides, shots);
  // ad.intentional.breakage.here = 'poormans debug breakpoint';
  return {...ad, columns: {...columns}, shots, sizes, version: setup.version};
};

export const updateImageVersions = (ads, setup, files) => {
  const uniqueValues = new Set([]);
  const oldImages = new Map();
  const newOldImage = new Map();
  const newAds = [...ads];
  const updatedAds = [];
  const imgKeys = (Object.entries(setup?.shotStructure || {}) || [])
    .filter(([, v]) => v.type === 'image')
    .map(([k]) => k);

  newAds.forEach((item, itemIndex) => {
    item.shots.forEach((shotArray, shotIndex) => {
      shotArray.shotSizes.forEach((shotSize, sizeIndex) => {
        imgKeys.forEach(k => {
          if (shotSize[k]) {
            uniqueValues.add(shotSize[k].url);
            oldImages.set(
              {itemIndex, shotIndex, sizeIndex, sizeKey: shotSize._id, k},
              shotSize[k].url
            );
          }
        });
      });
    });
  });

  const cutvalues = [...uniqueValues].map(v => v?.replace(`${process.env.REACT_APP_S3_URL}/`, ''));

  files.forEach(i => {
    const versionIndex = i.versions.findIndex(v => cutvalues.includes(v.name));
    if (versionIndex >= 0 && versionIndex !== i.versions.length - 1) {
      newOldImage.set(i.versions[versionIndex].name, i.versions[i.versions.length - 1].name);
    }
  });

  oldImages.forEach((v, k) => {
    const newImg = newOldImage.get(v.replace(`${process.env.REACT_APP_S3_URL}/`, ''));

    if (newImg) {
      const {shotSizes} = newAds?.[k.itemIndex]?.shots?.[k.shotIndex] || {};
      const currShotSize = shotSizes?.[k.sizeIndex] || findElementById(shotSizes, k.sizeKey);

      if (!currShotSize) {
        return;
      }
      const newData = {
        _id: [k.sizeKey],
        ...currShotSize,
        [k.k]: {url: `${process.env.REACT_APP_S3_URL}/${newImg}`, name: v.displayName || v.name},
      };
      const index = updatedAds.findIndex(i => i._id === newAds[k.itemIndex]._id);
      if (index > -1) {
        updatedAds[index] = {
          ...updatedAds[index],
          shots: replaceElementInArray(updatedAds[index].shots, k.shotIndex, {
            ...newAds[k.itemIndex].shots[k.shotIndex],
            shotSizes: replaceElementInArray(
              updatedAds[index].shots[k.shotIndex].shotSizes,
              k.sizeIndex,
              newData
            ),
          }),
        };
      } else {
        updatedAds.push({
          ...newAds[k.itemIndex],
          columns: {...newAds[k.itemIndex].columns, State: 'In review'},
          shots: replaceElementInArray(newAds[k.itemIndex].shots, k.shotIndex, {
            ...newAds[k.itemIndex].shots[k.shotIndex],
            shotSizes: replaceElementInArray(
              newAds[k.itemIndex].shots[k.shotIndex].shotSizes,
              k.sizeIndex,
              newData
            ),
          }),
        });
      }
    }
  });
  return updatedAds;
};

export const autoFillImagesBySize = (old, files, fillAllModal) => {
  const imgName = fillAllModal.imgName.replace(/[0-9\(\)]+x[0-9\(\)]+/, '');
  const differentSizeFiles = files
    .filter(f => f.path === fillAllModal.path)
    .filter(f => f.displayName.includes(imgName));

  console.log('helpers-autoFillImagesBySize', imgName, differentSizeFiles);
  if (!differentSizeFiles.length) {
    return old;
  }

  const {shotIndex} = fillAllModal;

  if (!shotIndex && shotIndex !== 0) {
    return old;
  }

  const shot = old.shots[shotIndex];
  let updatedSizes = [...shot.shotSizes];

  old.sizes.forEach(size => {
    const value = findElementById(shot.shotSizes, size._id);

    const sizeFile = differentSizeFiles.find(f =>
      f.displayName.includes(`${size.width}x${size.height}`)
    );
    if (sizeFile) {
      const srcValue = `${process.env.REACT_APP_S3_URL}/${
        sizeFile?.versions?.[sizeFile.versions.length - 1]?.name
      }`;
      const newSize = {
        ...value,
        [fillAllModal.key]: {
          name: sizeFile.displayName,
          url: srcValue,
        },
      };
      updatedSizes = replaceElementInArrayById(updatedSizes, newSize);
    }
  });

  return {
    ...old,
    shots: replaceElementInArray(old.shots, shotIndex, {...shot, shotSizes: updatedSizes}),
  };
};

/// check all before this tODO

const resolveFilterLabel = field => {
  switch (field) {
    case 'State':
      return 'Review State';
    case 'sizes':
      return 'Sizes';
    default:
      return field;
  }
};

export const resolveInitialFilterOptions = setup => {
  if (!setup) {
    return [];
  }

  return [
    'Search',
    'sizes',
    'Published State',
    ...Object.keys(setup?.adColumns),
    ...Object.keys(setup?.shotStructure),
  ].map(field => {
    return {
      field,
      value: null,
      label: resolveFilterLabel(field),
    };
  });
};
