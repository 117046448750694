import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({
  total,
  filter,
  applyFilter,
  options = [],
  className = '',
  localName = 'limit',
}) => {
  return (
    <div className={`d-flex ${className}`}>
      <select
        className="custom-select custom-select-sm mr-2"
        value={filter.limit}
        onChange={e => {
          localStorage.setItem(localName, +e.target.value || 20);
          applyFilter({limit: e.target.value, page: 1});
        }}
      >
        {options.length ? (
          <>
            {options.map(o => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </>
        ) : (
          <>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
          </>
        )}
      </select>
      <ReactPaginate
        pageCount={Math.ceil(total / filter.limit)}
        onPageChange={({selected}) => applyFilter({page: selected + 1})}
        forcePage={filter.page - 1}
        pageClassName="page-item"
        nextClassName="page-item"
        previousClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        containerClassName="pagination pagination-sm"
        activeClassName="active"
        activeLinkClassName="active"
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
      />
    </div>
  );
};

export default Pagination;
