/* eslint-disable react/function-component-definition */
import React, {useEffect, useState} from 'react';
import Loadable from 'react-loadable';
import {connect} from 'react-redux';
import {NavLink, Switch, Route} from 'react-router-dom';
import {getTeam, updateTeam, dismissError} from './slice';
import SingleInputForm from '../../helpers/singleInputForm';
import {ErrorMessages} from '../../helpers/error';
import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import InvitationForm from '../../components/InvitationForm';

const View = Loadable({loader: () => import('./view'), loading: () => null});
const ProjectList = Loadable({
  loader: () => import('../project/ProjectListPage'),
  loading: () => null,
});
const Users = Loadable({loader: () => import('../../helpers/users'), loading: () => null});

function TeamDetailsPage({dispatch, match, project, team, user, auth}) {
  const [showInvitationForm, show] = useState(false);
  const [showForm, showNameForm] = useState(false);

  useEffect(() => {
    (async () => dispatch(getTeam({id: match.params.id})))();
  }, [dispatch, match.params.id]);

  const updateName = async name =>
    dispatch(updateTeam({key: `${match.params.id}/name`, id: match.params.id, form: {name}}));

  if (team.error) return <ErrorMessages error={team.error} />;
  if ((!team.item._id || team.fetching) && !team.error)
    return <span className="ml-2">loading...</span>;

  return (
    <div className="team d-flex">
      {showInvitationForm && (
        <InvitationForm
          hide={() => show(false)}
          id={match.params.id}
          parents={{...team.item.parents, teamId: match.params.id}}
          type="team"
        />
      )}

      <div>
        <NavLink
          to={`/brand/view/${team.item.parents.brandId}/teams`}
          className="nav-link mb-3 pl-0"
        >
          <h5 className="text-dark d-flex align-items-center">
            <span className="icon icon-arrow-left x10 mr-2" />
            {team.item.brand.name}
          </h5>
        </NavLink>
        <nav className="nav flex-column pr-2 submenu">
          <NavLink to={`/team/view/${match.params.id}`} exact className="nav-link">
            Projects
          </NavLink>
          <NavLink to={`/team/view/${match.params.id}/settings`} exact className="nav-link">
            Settings
          </NavLink>
          {/* <NavLink to={`/team/view/${match.params.id}/videobuilder`} exact className="nav-link">Video builder</NavLink> */}
          {/* <Route path="/team/:action(view)/:id/project/:projectId">
            <NavLink
              className="nav-link sublink"
              to={`/team/view/${(project?.item?.parents || {})?.teamId}/project/${
                project?.item?._id
              }`}
            >
              Overview
            </NavLink>
          </Route> */}
        </nav>
      </div>

      <div className="team-container w-100">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Access
            type="team"
            parents={{brandId: team.item.parents.brandId, teamId: match.params.id}}
          >
            {({isAllowed, role}) => (
              <div className="w-100 d-flex align-items-center">
                <Avatar type="team" id={match.params.id} className="avatar-head mr-4" />
                {!showForm && (
                  <div
                    className="m-0 p-0  d-flex align-items-center"
                    onClick={() => (isAllowed('update') ? showNameForm(true) : null)}
                  >
                    <h1 className="detail-title m-0 p-0">{team.item.name}</h1>
                    {isAllowed('update') && <div className="icon icon-edit ml-4" />}
                  </div>
                )}
                {isAllowed('update') && showForm && (
                  <div className="m-0 p-0 flex-grow-1 d-flex align-items-center team-name">
                    <SingleInputForm
                      value={team.item.name}
                      placeholder="Team name"
                      asyncState={team.asyncState[`${match.params.id}/name`]}
                      dismiss={() => dispatch(dismissError(`${match.params.id}/name`))}
                      onSubmit={updateName}
                      show={showNameForm}
                    />
                  </div>
                )}
                <span className="template-text-gray ml-4">{role ?? auth?.user?.role}</span>
              </div>
            )}
          </Access>

          <Access
            type="team"
            parents={{brandId: (team.item.parents || {}).brandId, teamId: match.params.id}}
          >
            {({isAllowed}) => (
              <Users
                users={user.accesses}
                show={show}
                allowAdd={isAllowed('invite')}
                shownRoles={['teamManager']}
              />
            )}
          </Access>
        </div>

        <Switch>
          <Route exact path="/team/:action(view)/:id" component={ProjectList} />
          {/* <Route exact path="/team/:action(view)/:id/videobuilder" component={Videobuilder} /> */}
          {/* <Route path="/team/:action(view)/:id/project/:projectId" component={ProjectView} /> */}
          <Route exact path="/team/:action(view)/:id/(file)?/:fileId?/settings" component={View} />
          <Route
            exact
            path="/team/:action(view)/:id/(videobuilder)?/:templateId?"
            component={View}
          />
        </Switch>
      </div>
    </div>
  );
}

export default connect(store => ({
  team: store.team,
  project: store.project,
  user: store.user,
  auth: store.auth,
}))(TeamDetailsPage);
