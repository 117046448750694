import {DcoSetupInitialType} from './types';

export const initialDcoSetupState: DcoSetupInitialType = {
  asyncState: {},
  setupList: [],
  activeSetup: null,
  fetching: false,
  deleting: {},
  error: null,
};
