import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Private from './PrivateLayout';
import Public from './PublicLayout';
import {getUser} from '../pages/login/slice';

const Auth = ({dispatch, auth}) => {
  useEffect(() => {
    if (localStorage.getItem('access-token') && !auth.user && !auth.fething && !auth.fetched) {
      dispatch(getUser());
    }
  }, [auth.fetched, auth.fething, auth.user, dispatch]);

  if (auth.user && localStorage.getItem('access-token')) {
    return <Private />;
  }

  if (auth.fetched || !localStorage.getItem('access-token')) {
    return <Public />;
  }

  return null;
};

export default connect(store => ({
  auth: store.auth,
}))(withRouter(Auth));
