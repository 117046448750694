/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useEffect, useState, useRef} from 'react';
import {Carousel, Spinner, Overlay, Tooltip} from 'react-bootstrap';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import api from '../../../api';
import {copyToClipboard} from '../../../helpers/copyToClipboard';
import SingleTextAreaForm from '../../../helpers/singleTextAreaForm';
import TextArea from 'components/TextArea';

// eslint-disable-next-line import/prefer-default-export
export const ViewFeed = ({feed, updateFeed, disableAll = false}) => {
  const outputFeedLink = `https://${process.env.REACT_APP_API_DOMAIN}/api/dpa/feeds/xml/${feed._id}`;

  const [inputFeed, setInputFeed] = useState(feed.inputUrl);
  const [outputFeed, setOutputFeed] = useState(outputFeedLink);
  const [screenshots, setScreenshots] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);

  const refInfo = useRef(null);
  const outputRef = useRef(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showOutput, setshowOutput] = useState(false);
  const [downloadBtnTxt, setDownloadBtnTxt] = useState('Download all');

  const onInfoClicked = isOutputFeed => {
    if (isOutputFeed) {
      setshowOutput(true);
      setTimeout(() => {
        setshowOutput(false);
      }, 3000);
      return;
    }
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };

  const urlToPromise = (url, delay) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        JSZipUtils.getBinaryContent(url + '?t=' + Date.now(), (err, data) => {
          if (err) reject(err);
          else resolve(data);
        });
      }, delay);
    });
  };

  const handleDownload = () => {
    if (!screenshots.length) {
      return;
    }
    setDownloadBtnTxt('Downloading...');
    const zip = new JSZip();
    const zipFilename = `screenshots.zip`;
    const urls = [...screenshots];
    urls.forEach((url, i) => {
      zip.file(url.split('/').pop(), urlToPromise(url, i * 50), {binary: true});
    });
    zip.generateAsync({type: 'blob'}).then(content => {
      setDownloadBtnTxt('Download all');
      FileSaver(content, zipFilename);
    });
  };

  useEffect(() => {
    const getScreenShots = async id => {
      try {
        const response = await api.dpa.getFeedScreenshots(id);
        console.log(
          'screenshots',
          response.data.map(s => `${s}?t=${Date.now()}`)
        );
        setScreenshots(response.data);
      } catch (error) {
        console.log('error code ? ', error);
        // if 403, display error msg
        // add status to feeds table
      }
    };

    getScreenShots(feed._id);
  }, [feed.id]);

  return (
    <div className="view-feed-wrapper">
      <div className="d-flex pt-5 pb-5">
        <div className="w-40 mr-5">
          <h4>Input feed</h4>
          <div className="feeds-main-input-wrapper">
            <input
              className="feeds-main-input"
              type="text"
              placeholder="Enter xml link here..."
              value={inputFeed}
              onChange={e => setInputFeed(e.target.value)}
              disabled={disableAll}
            />
            <div>
              <Overlay target={refInfo.current} show={showInfo} placement="right">
                {overlayProps => (
                  <Tooltip id="overlay-example" {...overlayProps}>
                    Successfully copied to clipboard
                  </Tooltip>
                )}
              </Overlay>
              <div
                className="d-flex justify-items-center align-items-center "
                style={{
                  height: '100%',
                  cursor: 'pointer',
                }}
                ref={refInfo}
              >
                <span
                  className="icon icon-chain"
                  style={{height: 18}}
                  tabIndex={0}
                  role="button"
                  onClick={() => {
                    copyToClipboard(inputFeed);
                    onInfoClicked();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {['active', 'updating', 'paused', 'stopped'].indexOf(feed.state) >= 0 ? (
          <div className="w-40">
            <h4>Output feed</h4>
            <div className="feeds-main-input-wrapper">
              <input
                value={outputFeed}
                onChange={e => setOutputFeed(e.target.value)}
                className="feeds-main-input"
                type="text"
                placeholder="Enter xml link here..."
                disabled={disableAll}
              />
              <div>
                <Overlay target={outputRef.current} show={showOutput} placement="right">
                  {overlayProps => (
                    <Tooltip id="overlay-example" {...overlayProps}>
                      Successfully copied to clipboard
                    </Tooltip>
                  )}
                </Overlay>
                <div
                  className="d-flex justify-items-center align-items-center "
                  style={{
                    height: '100%',
                    cursor: 'pointer',
                  }}
                  ref={outputRef}
                >
                  <span
                    className="icon icon-chain"
                    style={{height: 18}}
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      copyToClipboard(outputFeed);
                      onInfoClicked(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div className="feeds-view-img-notes d-flex">
        {['active', 'updating', 'paused'].indexOf(feed.state) >= 0 ? (
          <div className="w-40 d-flex mr-5">
            <div className="feeds-img-box mr-5">
              <h4>Output image</h4>
              <div style={{height: 50, width: 150}} />
              {Array.isArray(screenshots) && screenshots.length ? (
                <div className="carousel-wrapper">
                  <Carousel
                    prevLabel=""
                    nextLabel=""
                    prevIcon={
                      <div className="icon icon-arrow-left" style={{height: 16, width: 16}} />
                    }
                    nextIcon={
                      <div className="icon icon-arrow-right" style={{height: 16, width: 16}} />
                    }
                    indicators={false}
                    onSelect={eventKey => setCurrentStep(eventKey + 1)}
                  >
                    {screenshots.map(imgSrc => (
                      <Carousel.Item key={imgSrc}>
                        <img
                          src={imgSrc}
                          alt="slide"
                          crossOrigin="anonymous"
                          onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = '/images/failed-to-load.png';
                          }}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="pt-2 d-flex align-items-center">
                  <Spinner animation="border" role="status" variant="primary" />
                  <h3 className="ml-2 mb-0">Updating</h3>
                </div>
              )}
            </div>
            {Array.isArray(screenshots) && screenshots.length && (
              <div>
                <h4>Items</h4>
                <h1 className="text-success">
                  {currentStep}/{screenshots.length}
                </h1>
                <div className="icon-btn primary-text" style={{cursor: 'pointer'}}>
                  <div className="icon icon-download-blue"></div>
                  <p
                    style={{marginTop: 12, marginLeft: 5}}
                    className="downloadtxtbtn"
                    onClick={e => handleDownload()}
                  >
                    {downloadBtnTxt}
                  </p>
                </div>
              </div>
            )}
          </div>
        ) : (
          <h4 className="w-40">
            {feed.state === 'stopped' ? 'Image output is not available' : 'Finish setup'}
          </h4>
        )}
        <div className="w-40 feeds-notes-wrapper">
          <h4>Notes</h4>
          <TextArea
            defaultValue={feed.notes ?? ''}
            showButtons
            onSubmit={note => {
              updateFeed({_id: feed._id, notes: note});
            }}
          />
        </div>
      </div>
    </div>
  );
};
