import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../../api';
import {replaceUpdatedItemsById} from './helpers';
import {transformToAds, transformToRequestAd} from './transformations';
import _ from 'lodash';

export const getAds = createAsyncThunk('ad/getAds', async (parents, {rejectWithValue}) => {
  try {
    const response = await api.dco.getAds(parents);
    return Array.isArray(response.data)
      ? response.data.map(i => transformToAds(i))
      : transformToAds(response.data);
  } catch (e) {
    const {data, status, statusText, message} = e.response;
    return rejectWithValue({data, status, statusText, message});
  }
});

export const createAd = createAsyncThunk('ad/createAd', async (form, {rejectWithValue}) => {
  try {
    const response = await api.dco.createAd(
      Array.isArray(form)
        ? form.map(formItem => transformToRequestAd(formItem))
        : transformToRequestAd(form)
    );
    return Array.isArray(response.data)
      ? response.data.map(i => transformToAds(i))
      : transformToAds(response.data);
  } catch (e) {
    const {data, status, statusText, message} = e.response;
    return rejectWithValue({data, status, statusText, message});
  }
});
export const updateAd = createAsyncThunk('ad/updateAd', async (form, {rejectWithValue}) => {
  const omit =
    !form?.initialEditDate || form?.initialEditDate === form?.published?.lastChange
      ? ['published.lastChange']
      : [];
  try {
    const response = await api.dco.updateAd(
      Array.isArray(form)
        ? form.map(formItem => transformToRequestAd(_.omit(formItem, omit)))
        : transformToRequestAd(_.omit(form, omit))
    );

    return Array.isArray(response.data)
      ? response.data.map(i => transformToAds(i))
      : transformToAds(response.data);
  } catch (e) {
    const {data, status, statusText, message} = e.response;
    return rejectWithValue({data, status, statusText, message});
  }
});

export const publishAds = createAsyncThunk(
  'ad/publishAds',
  async ({ids, parents, rejectWithValue}) => {
    try {
      await api.dco.publishAds(ids);
      const response = await api.dco.getAds({parents});
      return Array.isArray(response.data)
        ? response.data.map(i => transformToAds(i))
        : transformToAds(response.data);
    } catch (e) {
      console.error(e);
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);
export const unpublishAds = createAsyncThunk(
  'ad/unpublishAds',
  async ({ids, parents, rejectWithValue}) => {
    try {
      await api.dco.unpublishAds(ids);
      const response = await api.dco.getAds({parents});
      return Array.isArray(response.data)
        ? response.data.map(i => transformToAds(i))
        : transformToAds(response.data);
    } catch (e) {
      console.error(e);
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const ad = createSlice({
  name: 'ad',
  initialState: {
    asyncState: {},
    items: [],
    fetching: false,
    deleting: {},
    error: null,
  },
  reducers: {},
  extraReducers: {
    //
    [getAds.pending]: state => ({...state, fetching: true, error: null}),
    [getAds.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload,
      fetching: false,
      error: null,
    }),
    [getAds.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    [createAd.pending]: state => ({...state, fetching: true, error: null}),
    [createAd.fulfilled]: (state, {payload}) => {
      state.items = replaceUpdatedItemsById(state.items, payload);
      state.fetching = false;
      state.error = null;
    },
    [createAd.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
    [updateAd.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateAd.fulfilled]: (state, {payload}) => {
      state.items = replaceUpdatedItemsById(state.items, payload);
      state.fetching = false;
      state.error = null;
    },
    [updateAd.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
    [publishAds.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [publishAds.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload,
      fetching: false,
      error: null,
    }),
    [publishAds.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
    [unpublishAds.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [unpublishAds.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload,
      fetching: false,
      error: null,
    }),
    [unpublishAds.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
  },
});

// export const {} = file.actions;
export default ad.reducer;
