import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Attachment from '../../../helpers/attachment';

const SharedComment = ({comment, onAddComment, guest, adIndex, isResolved = false}) => {
  const [commentText, setCommentText] = useState('');
  const [showReplyForm, setShowReplyForm] = useState(false);

  const handleAddComment = e => {
    e.preventDefault();
    onAddComment(
      {
        parentId: comment.commentId || null, // set parentId as the current comment's _id
        resolved: false,
        createdAt: new Date().toISOString(),
        text: commentText,
        userId: guest.id,
        createdBy: guest.name || 'Guest user', // set the createdBy as 'user'
      },
      adIndex
    );
    setCommentText('');
    setShowReplyForm(false);
  };

  return (
    <div
      className="shared-comment"
      style={{
        marginLeft: 20,
        borderLeft: '1px solid gray',
        padding: 8,
        background: comment.resolved || isResolved ? '#fafafa' : '',
        opacity: comment.resolved || isResolved ? 0.5 : 1,
      }}
    >
      <div>
        <span style={{color: 'gray'}}>{moment(comment.createdAt).fromNow()}</span> -{' '}
        {comment.createdBy}
      </div>

      <div style={{overflowWrap: 'break-word'}}>{comment.text}</div>
      {comment.attachments.length > 0 &&
        comment.attachments.map((att, index) => (
          <Attachment
            key={index}
            paperclip
            file={{fileName: att.fileName, displayName: att.displayName}}
          />
        ))}
      <div>
        <button
          type="button"
          onClick={() => (comment.resolved || isResolved ? null : setShowReplyForm(!showReplyForm))}
          style={{border: 'none', background: 'none', margin: '8px 0'}}
        >
          <div className="d-flex">
            {comment.resolved || isResolved ? (
              <h6>RESOLVED</h6>
            ) : (
              <>
                <FontAwesomeIcon color="#1dd1a1" icon="reply" className="mr-2" />
                <h6 style={{color: '#1dd1a1', margin: 0}}>REPLY</h6>
              </>
            )}
          </div>
        </button>
      </div>
      {showReplyForm && (
        <form onSubmit={handleAddComment} className="my-2">
          <div className="my-2">
            <span className="m-0">
              Add a reply:
              <br />
              <textarea
                value={commentText}
                onChange={e => setCommentText(e.target.value)}
                maxLength={512}
              />
            </span>
          </div>
          <div style={{marginTop: 5}}>
            <button className="btn btn-primary mr-2" type="submit">
              Send
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setShowReplyForm(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      )}
      {comment.replys && (
        <div style={{marginLeft: 20}}>
          {comment.replys.map(reply => (
            <SharedComment
              key={reply._id}
              comment={reply}
              onAddComment={onAddComment}
              guest={guest}
              adIndex={adIndex}
              isResolved={comment.resolved}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SharedComment;
