/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, {useMemo, useEffect} from 'react';
import {Button} from 'react-bootstrap';

import SharedPreview from './SharedPreview';
import {stateColorCoding} from 'pages/project/DcoProject/consts';
import SharedColumns from 'pages/shared/components/SharedColumns';

const SharedAd = ({
  ad,
  setAdId,
  index,
  setAdIndex,
  setIsModalVisible,
  isModalVisible,
  setActiveCommentsNumber,
  setResolvedCommentsNumber,
}) => {
  const {comments = []} = ad;
  const numComments = useMemo(() => {
    const commentsToObjects = {};
    comments.forEach(cmnt => {
      commentsToObjects[cmnt.commentId] = cmnt;
    });
    const checkForResolvedAncestor = cmnt => {
      if (cmnt.resolved) return true;
      if (cmnt.parentId) {
        if (commentsToObjects[cmnt.parentId].resolved) return true;
        return checkForResolvedAncestor(commentsToObjects[cmnt.parentId]);
      }
      return false;
    };
    const commentsTotal = comments.length;
    const commentsResolved = comments.map(c => checkForResolvedAncestor(c)).filter(c => c).length;

    setResolvedCommentsNumber(commentsResolved);
    setActiveCommentsNumber(commentsTotal - commentsResolved);

    return commentsTotal - commentsResolved;
  }, [comments]);

  useEffect(() => {
    return () => {
      setIsModalVisible(false);
    };
  }, [setIsModalVisible]);

  return (
    <div
      key={ad.adId}
      style={{
        position: 'relative',
      }}
    >
      <div className="shared-actions-wrapper">
        <div className="d-flex">
          <h3>{ad.name}</h3>
          <span className={`font-weight-bold m-0 ${stateColorCoding[ad.publishedStatus]} ml-4`}>
            <span>{`\u2022`} </span>
            {ad.publishedStatus}
          </span>
        </div>
        <div>
          <Button
            type="button"
            onClick={() => {
              setAdId(ad.adId);
              setAdIndex(index);
              if (isModalVisible) {
                setIsModalVisible(false);
                return;
              }
              setIsModalVisible(true);
            }}
            className="mr-4 dco-button shared-comment-btn"
          >
            Comments {numComments}
          </Button>
        </div>
      </div>
      <SharedColumns columns={ad.columns} />
      <div className="shared-ads-wrapper">
        {ad.previews.map((preview, i) => {
          if (!preview.enabled) {
            return null;
          }
          return <SharedPreview preview={preview} key={i} />;
        })}
      </div>
    </div>
  );
};

export default SharedAd;
