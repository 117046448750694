import React, {useEffect, useMemo, useState} from 'react';
import {Button, Modal, Accordion, Tabs, Tab} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import FileSelect from '../../../helpers/FileSelect';
import api from '../../../api';
import {useDispatch} from 'react-redux';
import {getFiles} from '../slice';
import _ from 'lodash';
import ExportNotificationModal from './ExportNotificationModal';
import DraggableList from 'components/DraggableList';
import {GifFrameItem} from './CreativeExportModal';
const maxSize = 600;

const DcoPreviewExportModal = ({show, onClose, creative, version, parents, q2a, nameoverride}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [key] = useState(Math.random());
  const [name, setName] = useState(nameoverride || 'ExportedCreative');
  const [defaultedTab, setDefaultedTab] = useState('ad');
  const [notificationModalOpened, setNotificationModalOpened] = useState(false);
  const [addingAudio, setAddingAudio] = useState(false);
  const [audioTrack, setAudioTrack] = useState(false);
  const [audioLoop, setAudioLoop] = useState(false);
  const [audioFadeOut, setAudioFadeOut] = useState(0);
  const type = 'project';
  const id = parents.projectId;
  // console.log('q2a', q2a, parents);
  const [showGifOptions, setShowGifOptions] = useState(false);
  const [gifFps, setGifFps] = useState(15);
  const [gifFrames, setGifFrames] = useState([
    {
      start: '0.00',
      length: '1.5',
    },
  ]);

  const exportToVideo = async () => {
    setNotificationModalOpened(true);

    try {
      const result = await api.asset.videoConverterWithQueryParams(
        creative._id,
        version,
        q2a,
        'empty',
        parents,
        name,
        {
          audioTrack,
          audioLoop,
          audioFadeOut,
        }
      );
      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
        };
        await dispatch(getFiles({type: type, id: id, filter}));
      }
    } catch (error) {
      console.log('video converter error', error);
    }
  };

  /** this will only work on the same domain, using localhost you will always get frameValue 0.0 because contentDocument will be null and parsed to number that will be zero
   */
  const exportScreenshot = async () => {
    try {
      const element = document.getElementById(key);
      const frameValue = Number(
        element?.contentDocument?.getElementById('debug-label')?.textContent?.slice(0, 4) || 0
      );
      // console.log('frameValue', frameValue, key); // look at comment 8 lines above
      if (!typeof frameValue === 'number' || Number.isNaN(frameValue)) {
        return;
      }
      setNotificationModalOpened(true);
      // console.log('parents', parents);
      const result = await api.asset.videoConverterWithQueryParams(
        creative._id,
        version,
        q2a,
        frameValue.toFixed(2),
        parents,
        name
      );

      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
        };
        await dispatch(getFiles({type: type, id: id, filter}));
      }
    } catch (error) {
      console.log('screenshot error', error);
    }
  };

  const exportToGif = async slideShow => {
    setNotificationModalOpened(true);
    const isGif = true;

    try {
      const result = await api.asset.videoConverterWithQueryParams(
        creative._id,
        version,
        q2a,
        'empty',
        parents,
        name,
        {
          audioTrack,
          audioLoop,
          audioFadeOut,
        },
        isGif,
        gifFps,
        slideShow ? gifFrames : undefined
      );
      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
        };
        await dispatch(getFiles({type: type, id: id, filter}));
      }
    } catch (error) {
      console.log('video converter error', error);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered size="xl">
      <Modal.Header style={{justifyContent: 'space-between'}}>
        <Modal.Title style={{fontWeight: 'bold'}}>Dynamic Creative Export</Modal.Title>
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex flex-column w-40">
            <div style={{width: '100%', height: '50px'}} className="form-group pr-4 ">
              <span className="dco-edit-label">Name</span>
              <input
                type="text"
                value={name}
                className="form-control form-control-sm dco-edit-input"
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div
              className="iframe-holder"
              style={{
                display: 'block',
                maxWidth: `calc(1px *${
                  creative.versions[version].width
                } * ${maxSize} / max(${maxSize}, ${creative.versions[version].height + 50},${
                  creative.versions[version].width
                }))`,
                maxHeight: `calc(1px * ${creative.versions[version].height +
                  50} * ${maxSize} / max(${maxSize}, ${creative.versions[version].height + 50},${
                  creative.versions[version].width
                }))`,
              }}
            >
              <iframe
                key={key}
                title={key}
                anonymous="true"
                className=" border-0"
                src={`https://${process.env.REACT_APP_API_DOMAIN}/api/tools/proxy/${creative.versions[version].dir}/${creative.versions[version].indexFile}?showDebug=true&${q2a}`}
                id={key}
                style={{
                  width: creative.versions[version].width,
                  height: creative.versions[version].height + 50,
                  marginRight: '15px',
                  padding: 0,
                  transformOrigin: '0 0',
                  transform: `scale(calc(${maxSize} / max(${maxSize}, ${creative.versions[version]
                    .height + 50}, ${creative.versions[version].width})))`,
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column w-40">
            <h4>Export as</h4>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <p style={{width: 50}}>Image</p>{' '}
              <Button type="button" className="dco-button primary " onClick={exportScreenshot}>
                Render this frame
              </Button>
            </div>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <p style={{width: 50}}>Video</p>
              <Button type="button" className="dco-button primary" onClick={exportToVideo}>
                Render video
              </Button>
              <div>
                {!addingAudio ? (
                  <a
                    type="button"
                    className="simple-txt-btn"
                    style={{color: 'black', textDecoration: 'underline', marginLeft: 15}}
                    onClick={() => setAddingAudio(true)}
                  >
                    {audioTrack ? audioTrack.name : 'Add audio'}
                  </a>
                ) : (
                  <a
                    type="button"
                    className="simple-txt-btn"
                    style={{color: 'black', textDecoration: 'underline', marginLeft: 15}}
                    onClick={() => {
                      setAddingAudio(false);
                      setAudioTrack(false);
                    }}
                  >
                    {audioTrack ? `${audioTrack.name}` : 'Add audio'}
                    {audioTrack ? <span style={{color: 'red', marginLeft: 4}}>X</span> : null}
                  </a>
                )}
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <p style={{width: 50}}>Gif</p>

              <a
                type="button"
                className="simple-txt-btn"
                style={{color: 'black', textDecoration: 'underline'}}
                onClick={() => setShowGifOptions(true)}
              >
                Select frames
              </a>
            </div>
            {addingAudio ? (
              <div style={{marginTop: 15}}>
                <div style={{marginBottom: 10}}>
                  <FileSelect
                    value={audioTrack}
                    setValue={value => {
                      // console.log('audio track value', value);
                      setAudioTrack(value);
                      // setAddingAudio(false);
                    }}
                    handleCallback={() => {}}
                    audio
                    autoOpen
                    type={
                      location.pathname.indexOf('file') === -1 &&
                      location.pathname.indexOf('brand') !== -1
                        ? 'brand'
                        : undefined
                    }
                    filesId={id}
                  />
                </div>
                {audioTrack && (
                  <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
                    <span className="dco-edit-label">Fade Out (seconds)</span>
                    <input
                      type="number"
                      value={audioFadeOut}
                      onChange={e =>
                        parseInt(e.currentTarget.value, 10) > 0
                          ? setAudioFadeOut(e.currentTarget.value)
                          : setAudioFadeOut(0)
                      }
                      className="form-control form-control-sm"
                    />
                    <div style={{display: 'flex', alignItems: 'center', height: 50}}>
                      <span className="dco-edit-label">Loop</span>
                      <input
                        type="checkbox"
                        value={audioLoop}
                        onChange={e => setAudioLoop(!audioLoop)}
                        style={{marginLeft: 10, marginBottom: 5}}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {showGifOptions && (
              <div style={{padding: 10}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p>choose frames and duration of each frame</p>
                  <DraggableList
                    items={gifFrames.map((gf, i) => (
                      <GifFrameItem
                        start={gf.start}
                        length={gf.length}
                        index={i}
                        setFrames={setGifFrames}
                        getTimeFromPlayer={e => {
                          console.log(e, key);
                          const element = document.getElementById(key);
                          return element.contentWindow.grid8player.timelineMaster._time;
                        }}
                      />
                    ))}
                    setFrames={setGifFrames}
                  />{' '}
                  <button
                    className="simple-txt-btn"
                    style={{margin: 5, padding: 10}}
                    onClick={() => {
                      setGifFrames(old => [
                        ...old,
                        {start: old?.at(-1)?.start || '0.00', length: '1.5'},
                      ]);
                    }}
                  >
                    + add extra frame
                  </button>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <button
                      // style={{margin: 5, padding: 10}}
                      className="dco-button primary "
                      onClick={() => exportToGif(true)}
                    >
                      Make gif slide show
                    </button>
                  </div>
                </div>
                {false && (
                  <div>
                    <p>Or set a fixed fps:</p>
                    <div style={{width: 120}}>
                      <div
                        className="form-group"
                        style={{display: 'flex', flexDirection: 'column'}}
                      >
                        <span className="dco-edit-label">FPS</span>
                        <input
                          type="number"
                          value={gifFps}
                          onChange={e => setGifFps(e.currentTarget.value)}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <button className="dco-button primary " onClick={() => exportToGif(false)}>
                        Make gif video
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="dco-button gray-600-background" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
      <ExportNotificationModal
        when={notificationModalOpened}
        onClose={() => {
          setNotificationModalOpened(false);
        }}
      />
    </Modal>
  );
};

export default DcoPreviewExportModal;
