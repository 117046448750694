import React, {useState, useRef} from 'react';
import {Overlay, Popover} from 'react-bootstrap';

const Confirmation = ({children, onConfirm}) => {
  const [show, setVisibility] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = event => {
    setVisibility(!show);
    setTarget(event.target);
  };

  return (
    <span ref={ref}>
      <Overlay
        show={show}
        onHide={() => setVisibility(false)}
        target={target}
        placement="left"
        container={ref.current}
        rootClose={true}
      >
        <Popover id={Math.random()}>
          <Popover.Content>
            Are you sure ?
            <div className="mt-2">
              <button className="btn btn-danger btn-sm mr-2" onClick={onConfirm}>
                Yes
              </button>
              <button className="btn btn-secondary btn-sm" onClick={() => handleClick(!show)}>
                Cancel
              </button>
            </div>
          </Popover.Content>
        </Popover>
      </Overlay>
      <span style={{cursor: 'pointer'}}>
        {React.cloneElement(children, {onClick: handleClick})}
      </span>
    </span>
  );
};

export default Confirmation;
