import React from 'react';
import {Button} from 'react-bootstrap';

import {
  archiveSelectedAds,
  changeValueInSelectedAds,
  duplicateSelectedAds,
  updateImageVersions,
} from '../../../helpers';
import {createAd, getAds, updateAd} from '../../../slice';

import {EditThreeDot} from '../TableComponents';
import {AD_LIMIT} from '../../../consts';
import Access from '../../../../../../helpers/access';
import UsageLabel from './UsageLabel';

const EditTabHeader = ({
  selectedRows,
  setup,
  fileItems,
  parents,
  afterEffects,
  quickSearchValue,
  dispatch,
  handleNewAd,
  setQuickSerchValue,
  setRowData,
  setShowUnpublishModal,
  handleBulkEditSaveVerification,
  hasChangedAds,
  numOfAds,
  showQuickSearch,
  setShowWarningModal,
  changed,
  ads,
}) => {
  const handleThreeDotEvents = async (event, value) => {
    let fn = null;
    switch (event) {
      case 'duplicate':
        fn = async () => {
          await dispatch(
            createAd(
              duplicateSelectedAds(selectedRows).map(s => ({
                ...s,
                columns: {
                  ...s.columns,
                  State: 'Draft',
                  'Ad Name': `${s.columns['Ad Name']}-copy`,
                },
                parents,
                setup: setup._id,
              }))
            )
          );
          afterEffects();
        };

        break;
      case 'state':
        const originalAds = changed ? [] : ads;
        fn = () =>
          dispatch(updateAd(changeValueInSelectedAds(selectedRows, 'State', value, originalAds)));

        break;
      case 'update':
        fn = () =>
          dispatch(
            updateAd(
              updateImageVersions(
                ads.map(row =>
                  row?.columns?.State === 'Approved' || row?.columns?.State === 'Rejected'
                    ? {...row, columns: {...row.columns, State: 'In review'}}
                    : row
                ),
                setup,
                fileItems
              )
            )
          );

        break;
      case 'archive':
        if (selectedRows.some(s => s.published?.csv)) {
          setShowUnpublishModal(true);
          break;
        }
        fn = async () => {
          await dispatch(updateAd(archiveSelectedAds(selectedRows)));
          afterEffects();
        };
        break;

      default:
        break;
    }
    if (changed) {
      setShowWarningModal({
        fn: async () => {
          fn();
          try {
            const {payload: data} = await dispatch(getAds({parents}));
            setRowData(data || []);
          } catch (error) {
            console.error(error);
          }
        },
      });
    } else {
      try {
        fn();
        const {payload: data} = await dispatch(getAds({parents}));
        setRowData(data || []);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Access type="dco" parents={parents}>
      {({isAllowed}) => (
        <div className="d-flex justify-content-start align-items-center mb-4">
          {(isAllowed('edit') || isAllowed('create') || isAllowed('delete')) && (
            <EditThreeDot
              selectedRows={selectedRows}
              setup={setup}
              onEventClicked={handleThreeDotEvents}
              numOfAds={numOfAds}
              parents={parents}
            />
          )}
          {isAllowed('create') && (
            <Button
              disabled={numOfAds >= AD_LIMIT}
              className={`dco-button primary border-0 ml-4 ${numOfAds >= AD_LIMIT &&
                'gray-600-background'} mr-2`}
              onClick={handleNewAd}
            >
              + NEW AD
            </Button>
          )}
          {showQuickSearch && (
            <div className="d-flex w-100 ml-4 align-items-end">
              <div style={{width: '20px', height: '20px'}} className="icon icon-search mr-2" />
              <input
                type="text"
                className="form-control form-control-sm dco-search col-2"
                placeholder="Search"
                onChange={e => setQuickSerchValue(e.target.value)}
                value={quickSearchValue}
              />
            </div>
          )}
          <UsageLabel numOfAds={numOfAds} />
          <div style={{marginLeft: 'auto'}}>
            {hasChangedAds && (
              <Button
                className={`dco-button primary border-0 ${!hasChangedAds &&
                  'gray-600-background'} mr-2 ml-4`}
                onClick={handleBulkEditSaveVerification}
                disabled={!hasChangedAds}
              >
                Save all
              </Button>
            )}
          </div>
        </div>
      )}
    </Access>
  );
};

export default EditTabHeader;
