import React from 'react';

const Welcome = () => {
  return (
    <div className="welcome h-100">
      <div className="welcome-carousel h-100">
        <div className="welcome-img-wrapper" />
      </div>
    </div>
  );
};

export default Welcome;
