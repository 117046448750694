import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const FileThumb = ({asset, onClick, variants = [], isSelected = false}) => {
  const checkbox = (
    <div
      onClick={e => {
        onClick(e, asset, false, true);
        e.stopPropagation();
      }}
      className="checkbox"
    >
      <input type="radio" checked={isSelected} readOnly />
      <label className="radio circle">
        <span className="big">
          <span className="small" />
        </span>
      </label>
    </div>
  );
  const ext = asset.displayName
    .split('.')
    .pop()
    .toLowerCase();

  if (asset.kind === 'CREATIVE') {
    return (
      <div className={`thumb flex-column ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        <img src={`${process.env.PUBLIC_URL}/images/creative-set.svg`} />
        <span className="text-white text-uppercase small mt-1 position-absolute d-flex flex-column align-items-center">
          <h4 className="font-weight-bold">{variants.length}</h4>
          <span>{variants.length === 1 ? 'creative' : 'creatives'}</span>
        </span>
      </div>
    );
  } else if (asset.kind === 'URL') {
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/link.png`} />
      </div>
    );
  } else if (['tif', 'tiff', 'jpg', 'jpeg', 'gif', 'png', 'svg'].includes(ext)) {
    return (
      <div className={`thumb chess-blue ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img
          src={`${process.env.REACT_APP_S3_URL}/${asset.versions[asset.versions.length - 1].name}`}
        />
      </div>
    );
  } else if (['mp4', 'mov', 'wmv', 'flv', 'webm', 'avi'].includes(ext)) {
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/video.svg`} />
      </div>
    );
  } else if (['zip', '7z', 'gz'].includes(ext)) {
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/zip.png`} />
      </div>
    );
  } else if (['ai'].includes(ext)) {
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/ai.png`} />
      </div>
    );
  } else if (['psd'].includes(ext)) {
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/psd.png`} />
      </div>
    );
  } else if (['pdf'].includes(ext)) {
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/pdf.png`} />
      </div>
    );
  } else
    return (
      <div className={`thumb ${isSelected ? 'selected' : ''}`}>
        {checkbox}
        {asset.versions.length > 1 && (
          <div className="version badge bg-white">v{asset.versions.length}</div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/file.svg`} />
      </div>
    );
};

export default FileThumb;
