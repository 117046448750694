import React from 'react';
import {connect} from 'react-redux';
import {NavLink, Link} from 'react-router-dom';

const SideBar = ({className = '', auth}) => {
  return (
    <div className={`sidebar-private ${className}`}>
      <div className="sidebar-contant h-100">
        <div className="logo">
          <Link
            to={`${
              auth?.user?.misc?.lastBrandId
                ? `/brand/view/${auth.user.misc.lastBrandId}/teams`
                : '/brand'
            }`}
          >
            <img src={`${process.env.PUBLIC_URL}/images/grid8logo.svg`} width="20" alt="" />
          </Link>
        </div>
        <nav className="nav flex-column">
          <NavLink exact to="/" className="nav-link d-flex align-items-center">
            <div className="icon icon-brand" />
            <span className="nav-item-label">All brands</span>
          </NavLink>
          <NavLink
            to="/team"
            activeClassName="active"
            className="nav-link d-flex align-items-center"
          >
            <div className="icon icon-teams" />
            <span className="nav-item-label">All teams</span>
          </NavLink>
          <NavLink
            to="/projects"
            exact
            activeClassName="active"
            className="nav-link d-flex align-items-center"
          >
            <div className="icon icon-dashboard-teams" />
            <span className="nav-item-label">All projects</span>
          </NavLink>
        </nav>
        <div style={{position: 'absolute', bottom: 30}}>
          <a
            style={{color: 'white'}}
            href="https://docs.google.com/document/d/e/2PACX-1vQzY6hSmsYN7Bcb1fP6qtwXz-c1r-a8_MVTITGSlq8XfVMbZFJXxZ7FmUAikCGpd6TKQgug9k_XNQgO/pub"
            target="_blank"
            rel="noreferrer"
          >
            V1.7
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect(store => ({
  auth: store.auth,
}))(SideBar);
