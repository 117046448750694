/* eslint-disable react/function-component-definition */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import Select from 'react-select/async';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import NoResult from '../../helpers/noResult';
import Pagination from '../../helpers/pagination';
import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import {getTeams} from './slice';
import {getBrands} from '../brand/slice';
import Form from './form';

const customStyles = {
  input: provided => ({
    ...provided,
    width: 100,
    margin: '1px',
    padding: 0,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: '0 4px',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#eaeff1',
  }),
  control: () => ({
    backgroundColor: '#fdfffe',
    backgroundClip: 'padding-box',
    border: 'none',
    cursor: 'text',
    display: 'flex',
  }),
  clearIndicator: () => ({
    padding: '0',
    paddingRight: '8px',
  }),
};

function TeamsPage({history, dispatch, team, brand, location, match}) {
  const [filter, setFilter] = useState({
    q: queryString.parse(location.search).q,
    brandId: queryString.parse(location.search).brandId || null,
    sortType: queryString.parse(location.search).sortType || 'desc',
    sortField: queryString.parse(location.search).sortField || 'createdAt',
    page: parseInt(queryString.parse(location.search).page, 10)
      ? parseInt(queryString.parse(location.search).page, 10)
      : 1,
    limit: localStorage.getItem('teamsLimit') || queryString.parse(location.search).limit || 20,
  });

  useEffect(() => {
    (async () => dispatch(getTeams(filter)))();
  }, [dispatch, filter]);

  const applyFilter = params => {
    history.replace({
      ...location,
      search: queryString.stringify({...queryString.parse(location.search), ...params}),
    });
    setFilter({...filter, ...params});
  };

  const loadBrands = async q => {
    const res = await dispatch(getBrands({q, limit: 20}));
    const brands = unwrapResult(res);
    return brands.items.map(i => ({value: i._id, label: i.name}));
  };

  // if (match.params.id && match.params.action === 'view')
  //   return <Route path="/team/:action(view)/:id" component={Layout} />
  return (
    <Access type="team" action="list" overWritRole={false}>
      {({isAllowed}) => (
        <div>
          {(match.params.id || ['create', 'edit'].includes(match.params.action)) &&
            match.params.action !== 'view' &&
            isAllowed('create') && (
              <Form hide={() => history.push(`/team/${location.search}`)} id={match.params.id} />
            )}
          <div className="row mb-2">
            <div className="col-2 d-flex align-items-baseline">
              <h3 className="mb-0">Teams</h3>
            </div>
            <div className="col-10 d-flex align-items-baseline justify-content-end">
              {isAllowed('create') && (
                <Link to={`/team/create/${location.search}`} className="icon-btn">
                  <div className="icon icon-plus-blue ml-2" />
                  <span className="ml-1 small text-primary text">Create new team</span>
                </Link>
              )}
            </div>
          </div>

          <div className="mb-2 d-flex">
            <input
              type="text"
              className="form-control border-0 col-2 pl-0"
              placeholder="search"
              onChange={e => applyFilter({q: e.target.value})}
              value={filter.q}
            />
            <div className="col-2">
              <Select
                defaultOptions
                isClearable
                cacheOptions={false}
                styles={customStyles}
                placeholder="Select a brand"
                value={
                  filter.brandId
                    ? {
                        value: filter.brandId,
                        label:
                          (brand.items.find(i => i._id === filter.brandId) || {}).name ||
                          'Selected Brand',
                      }
                    : null
                }
                onChange={selected =>
                  selected ? applyFilter({brandId: selected.value}) : applyFilter({brandId: null})
                }
                loadOptions={loadBrands}
              />
            </div>
          </div>

          {!team.items.length && !team.fetching && (
            <div className="mt-4">
              <NoResult />
            </div>
          )}

          <div className={`mb-2 ${team.fetching ? 'loading tiles' : 'tiles'}`}>
            {team.items.map(item => (
              <Link to={`/team/view/${item._id}`} key={item._id} className="tile">
                <Avatar type="team" id={item._id} className="avatar" disableClick />
                <div className="title">{item.name}</div>
              </Link>
            ))}
          </div>

          <div>
            <Pagination
              applyFilter={applyFilter}
              total={team.total}
              filter={filter}
              localName="teamsLimit"
            />
          </div>
        </div>
      )}
    </Access>
  );
}

export default connect(store => ({
  team: store.team,
  brand: store.brand,
}))(TeamsPage);
