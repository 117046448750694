import React from 'react';

import File from '../../../file';

const Assets = ({projectId, brandId}) => {
  return (
    <>
      <h3>Assets</h3>
      <File type="project" id={projectId} smartCropperBrandId={brandId} />
    </>
  );
};

export default Assets;
