import React, {useRef} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import {login} from './slice';

const Login = ({history, dispatch, auth}) => {
  const initialForm = {email: '', password: '', token: ''};
  const recaptchaRef = useRef();

  const submit = async (values, formik) => {
    const token = await recaptchaRef.current.executeAsync().catch(() => {
      formik.setErrors({token: 'something went wrong'});
      formik.setSubmitting(false);
    });
    const userData = await dispatch(login({...values, token}));
    if (login.fulfilled.match(userData)) {
      if (userData.payload.misc?.lastBrandId) {
        history.push({pathname: `/brand/view/${userData.payload.misc.lastBrandId}/teams`});
        return;
      }

      history.push({pathname: '/'});
    } else {
      recaptchaRef.current.reset();
      formik.setErrors(userData.payload || {token: 'Invalid credentials'});
      formik.setSubmitting(false);
    }
  };

  return (
    <div className="login">
      <div className="header">Make advertising fun again</div>
      <Formik initialValues={initialForm} onSubmit={submit}>
        {({isSubmitting}) => (
          <Form className={isSubmitting ? 'loading-blue' : ''}>
            <div className="form-group">
              <Field type="text" name="email" placeholder="Email" className="form-control" />
              <ErrorMessage
                name="email"
                render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
              />
            </div>
            <div className="form-group">
              <Field
                type="password"
                name="password"
                placeholder="Password"
                className="form-control"
              />
              <ErrorMessage
                name="password"
                render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
              />
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              size="invisible"
            />
            <ErrorMessage
              name="token"
              render={msg => <div className="alert alert-danger mt-1">{msg}</div>}
            />
            <div className="form-group">
              <button className="btn btn-primary btn-block" type="submit">
                Login
              </button>
            </div>
            {auth.error && auth.error.status && !isSubmitting && (
              <div className="alert alert-danger margins-no mt-4 mb-4 rounded-0 p-2">
                {auth.error.message}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect(store => ({
  auth: store.auth,
}))(withRouter(Login));
