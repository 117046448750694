import React from 'react';
import {Dropdown} from 'react-bootstrap';
import CustomToggle from '../../../../../../helpers/customToggle';

const ShareTableThreeDot = props => {
  const {selectedRows, onEventClicked} = props;
  return (
    <Dropdown style={{paddingLeft: '18px'}}>
      <Dropdown.Toggle as={CustomToggle} disabled={!selectedRows.length} />
      <Dropdown.Menu className="rounded-0">
        <Dropdown.Item className="primary-text" onClick={() => onEventClicked('update')}>
          Update selected shared pages
        </Dropdown.Item>

        <Dropdown.Item className="danger-text" onClick={() => onEventClicked('delete')}>
          Delete selected shared pages
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShareTableThreeDot;
