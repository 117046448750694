import * as yup from 'yup';

const urlReg = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9.\-\[\]_-]+(\.[a-zA-Z]+)/;

export const editTabValidationSchema = adColumns => {
  const validation = {};
  Object.entries(adColumns).forEach(([name, {type}]) => {
    switch (type) {
      case 'url':
        validation[name] = yup.lazy(value => {
          if (value) {
            return yup.string().matches(urlReg, 'Please enter a valid URL');
          }
          return yup
            .string()
            .optional()
            .notRequired();
        });
        break;
      default:
        break;
    }
  });
  return yup.object().shape({
    ...validation,
  });
};
