import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../api';
// import { findAvatars } from './../../pages/file/slice';
import {getAccesses} from '../user/slice';

export const getTeams = createAsyncThunk('team/get', async (filter, {dispatch}) => {
  const response = await api.team.getTeams(filter);
  const brands = response.data.items.map(b => b.parents.brandId);
  const teams = [...new Set(response.data.items.map(p => p._id))];

  console.log(response, 'test');
  // await Promise.all([
  //   dispatch(findAvatars({ key: `team/avatars`, type: 'team', ids: teams })),
  // ]);
  return response.data;
});

export const getTeam = createAsyncThunk('team/getTeam', async ({id}, {dispatch}) => {
  const response = await api.team.getTeam(id);
  await Promise.all([
    dispatch(getAccesses({key: `team/${id}`, type: 'team', id})),
    // dispatch(findAvatars({  key: `team/${id}`, type: 'team', ids: [id] })),
  ]);
  return response.data;
});

export const createTeam = createAsyncThunk('team/createTeam', async ({form}, {rejectWithValue}) => {
  try {
    const response = await api.team.createTeam(form);
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const updateTeam = createAsyncThunk(
  'team/updateTeam',
  async ({id, form}, {rejectWithValue}) => {
    try {
      const response = await api.team.update(id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

// export const updateTeamName = createAsyncThunk(
//   'team/updateName',
//   async ({ id, form }, { rejectWithValue }) => {
//     try {
//       const response = await api.team.updateName(id, form);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

export const deleteTeam = createAsyncThunk('team/deleteTeam', async ({id, filter}, {dispatch}) => {
  const response = await api.team.deleteTeam(id);
  await dispatch(getTeams(filter));
  return response.data;
});

export const addPage = createAsyncThunk(
  'team/addPage',
  async ({teamId, form}, {rejectWithValue}) => {
    try {
      const response = await api.team.addPage(teamId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);
export const updatePage = createAsyncThunk(
  'team/updatePage',
  async ({teamId, id, form}, {rejectWithValue}) => {
    try {
      const response = await api.team.updatePage(teamId, id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);
export const deletePage = createAsyncThunk(
  'team/deletePage',
  async ({teamId, id}, {rejectWithValue}) => {
    try {
      const response = await api.team.deletePage(teamId, id);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const team = createSlice({
  name: 'team',
  initialState: {
    asyncState: {},
    item: {},
    items: [],
    total: 0,
    fetching: false,
    deleting: {},
    error: null,
    uploadError: null,
    uploading: false,
    images: {},
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
  },
  extraReducers: {
    //
    [getTeams.pending]: state => ({...state, fetching: true, error: null}),
    [getTeams.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload.items,
      total: payload.total,
      fetching: false,
      error: null,
    }),
    [getTeams.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    //
    [getTeam.pending]: state => ({...state, item: {}, fetching: true, error: null}),
    [getTeam.fulfilled]: (state, {payload}) => ({
      ...state,
      item: payload,
      fetching: false,
      error: null,
    }),
    [getTeam.rejected]: (state, {error}) => ({...state, item: {}, error, fetching: false}),
    //
    [createTeam.pending]: state => ({...state, fetching: true, error: null}),
    [createTeam.fulfilled]: (state, {payload}) => {
      state.items.unshift(payload);
      state.fetching = false;
      state.error = null;
    },
    [createTeam.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
    //
    // [updateTeamName.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [updateTeamName.fulfilled]: (state, { payload, meta }) => {
    //   state.items = state.items.map((item) => (item._id === payload._id ? payload : item))
    //   state.item = payload;
    //   delete state.asyncState[meta.arg.key];
    // },
    // [updateTeamName.rejected]: (state, { payload, meta }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },
    // //
    [addPage.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [addPage.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [addPage.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [updateTeam.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateTeam.fulfilled]: (state, {payload, meta}) => {
      state.items = state.items.map(item => (item._id === payload._id ? payload : item));
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [updateTeam.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [deletePage.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deletePage.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [deletePage.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [deleteTeam.pending]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: true},
      error: null,
    }),
    [deleteTeam.fulfilled]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: false},
      error: null,
    }),
    [deleteTeam.rejected]: (state, {meta, error}) => ({
      ...state,
      error,
      deleting: {[meta.arg.id]: false},
    }),
  },
});

export const {dismissError} = team.actions;
export default team.reducer;
