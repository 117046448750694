/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, {useState, useEffect, useRef} from 'react';
import Select from 'react-select';
import {useDebouncedCallback} from 'use-debounce';
import {Overlay, Tooltip} from 'react-bootstrap';

import Switch from '../../../../../../components/Switch';
import {customInputStyles} from '../../../consts';
import DynamicInput from './DynamicInput';

// todo rewrite this component
const EditInput = ({
  column,
  value,
  label,
  handleChange,
  errors,
  nameEdit = false,
  debounced = false,
  disabled = false,
}) => {
  const [lastValue, setLastValue] = useState(value);
  const [showInfo, setShowInfo] = useState(false);

  const refInfo = useRef(null);

  const {type, options = [], note} = column;

  if (
    (!nameEdit && label === 'Ad Name') ||
    label === 'State' ||
    label === 'Unique ID' ||
    label === 'Ad ID'
  ) {
    return null;
  }

  useEffect(() => {
    setLastValue(value);
  }, [value]);

  const handleInputChange = (name, newValue) => {
    handleChange(name, newValue);
  };

  const debouncedOnChange = useDebouncedCallback(name => {
    handleChange(name, lastValue);
  }, 300);

  const debouncedInputChange = (name, newValue) => {
    setLastValue(newValue);
    debouncedOnChange(name, lastValue);
  };

  const onInfoClicked = () => {
    setShowInfo(true);
  };

  const renderOverlay = () =>
    note && (
      <>
        <span
          style={{width: '12px', height: '12px', cursor: 'pointer'}}
          onClick={onInfoClicked}
          ref={refInfo}
          className="icon icon-questionmark gray-600-background  cursor-pointer mr-2 "
        />
        <Overlay target={refInfo.current} show={showInfo} placement="top">
          {overlayProps => (
            <Tooltip id="overlay-example" {...overlayProps}>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => {
                      setShowInfo(false);
                    }}
                    type="button"
                    style={{backgroundColor: 'inherit', border: 'none'}}
                  >
                    x
                  </button>
                </div>
                <span className="px-2">{note}</span>
              </div>
            </Tooltip>
          )}
        </Overlay>
      </>
    );
  switch (type) {
    case 'preset': {
      return (
        <div style={{width: '20%', height: '50px'}} className="form-group pr-4 ">
          <span className="dco-edit-label">
            {label}
            {renderOverlay()}
          </span>
          <Select
            isClearable={false}
            styles={customInputStyles}
            isSearchable={false}
            value={{label: value, value}}
            cacheOptions={false}
            isDisabled={disabled}
            menuPortalTarget={document.body}
            placeholder="Status"
            onChange={selectValue => {
              handleInputChange(label, selectValue.value);
            }}
            options={options.map(s => ({label: s, value: s}))}
          />
        </div>
      );
    }
    case 'boolean': {
      return (
        <div style={{width: '20%', height: '50px'}} className="form-group pr-4 ">
          <span className="dco-edit-label">
            {label}
            {renderOverlay()}
          </span>
          <Switch
            checked={value}
            handleChange={checked => handleInputChange(label, checked)}
            disabled={disabled}
          />
        </div>
      );
    }
    case 'url': {
      return (
        <div style={{width: '20%', height: '50px'}} className="form-group pr-4 ">
          <span className="dco-edit-label">
            {label}
            {renderOverlay()}
          </span>
          <input
            type="text"
            name={label}
            value={value}
            disabled={disabled}
            onChange={e => {
              handleInputChange(label, e.target.value);
            }}
            className={`form-control form-control-sm dco-edit-input ${errors[label] &&
              'alert-danger'}`}
          />
          {errors[label] && <span className="alert-danger-text">{errors[label]}</span>}
        </div>
      );
    }
    case 'dynamic': {
      return (
        <div style={{width: '20%', height: '50px'}} className="form-group pr-4 ">
          <span className="dco-edit-label">
            {label} | {column?.default}
            {renderOverlay()}
          </span>
          <DynamicInput
            value={value}
            disabled={disabled}
            handleInputChange={handleInputChange}
            label={label}
            column={column}
          />
        </div>
      );
    }
    default: {
      return (
        <div style={{width: '20%', height: '50px'}} className="form-group pr-4 ">
          <span className="dco-edit-label">
            {label}
            {renderOverlay()}
          </span>
          <input
            type="text"
            disabled={disabled}
            name={label}
            value={debounced ? lastValue || value : value}
            className="form-control form-control-sm dco-edit-input"
            onChange={e => {
              debounced
                ? debouncedInputChange(label, e.target.value)
                : handleInputChange(label, e.target.value);
            }}
          />
        </div>
      );
    }
  }
};

export default EditInput;
