/* eslint-disable import/prefer-default-export */

export const fetchCSVData = async (remoteUrl, optionsArray = null, option = null) => {
  const rawResult = await fetch(remoteUrl);
  const csvRawResult = await rawResult.text();
  const csvData = csvRawResult.split(/\r\n/);
  const csvResultKeys = csvData[0].split(',');
  const csvAllRows = csvData.map(row => row.split(',').map(op => op.replace(/\"/g, '')));

  if (option && optionsArray) {
    const csvIndex = optionsArray.findIndex(o => o === option);
    const allOptions = csvAllRows
      .map(op => op[csvIndex])
      .filter(op => op !== undefined)
      .slice(1);
    const uniqueOptions = [...new Set(allOptions)];
    return {
      csvRawResult,
      csvData,
      csvResultKeys,
      csvAllRows,
      csvIndex,
      uniqueOptions,
    };
  }

  if (option) {
    const csvIndex = csvResultKeys.findIndex(o => o === option);
    const allOptions = csvAllRows
      .map(op => op[csvIndex])
      .filter(op => op !== undefined)
      .slice(1);
    const uniqueOptions = [...new Set(allOptions)];
    return {
      csvRawResult,
      csvData,
      csvResultKeys,
      csvAllRows,
      csvIndex,
      uniqueOptions,
    };
  }

  return {
    csvRawResult,
    csvData,
    csvResultKeys,
    csvAllRows,
  };
};
