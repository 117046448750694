/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/function-component-definition */
import React, {useState, useEffect, useMemo} from 'react';
import Loadable from 'react-loadable';
import {connect, useSelector} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import {Dropdown, Col, Row, Toast} from 'react-bootstrap';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import {useWebSocket} from 'react-use-websocket/dist/lib/use-websocket';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import CustomToggle from '../../helpers/customToggle';
import NoResult from '../../helpers/noResult';
import FileThumb from '../../helpers/fileThumb';
import {ErrorMessages} from '../../helpers/error';
import Access from '../../helpers/access';
import View from './view';
import {getDeletedFiles, getFiles} from './slice';
import api from '../../api';

const FileForm = Loadable({loader: () => import('./forms/fileForm'), loading: () => null});
const FolderForm = Loadable({loader: () => import('./forms/folderForm'), loading: () => null});
const LinkForm = Loadable({loader: () => import('./forms/linkForm'), loading: () => null});
const CreativeForm = Loadable({loader: () => import('./forms/creativeForm'), loading: () => null});

function FileList({
  dispatch,
  file,
  match,
  type,
  id,
  project,
  team,
  compact,
  onFileClick,
  creativesHidden = false,
  fontsHidden = false,
  smartCropperBrandId,
}) {
  const [showToast, setShowToast] = useState(false);
  const auth = useSelector(state => state.auth);
  const {lastMessage, readyState} = useWebSocket(
    `wss://${process.env.REACT_APP_API_DOMAIN}/api/ws/server/?id=${
      auth.user.userId
    }&token=${`Bearer ${localStorage.getItem('access-token')}`}`,
    {
      heartbeat: true,
      reconnectInterval: 1000,
      share: true,
      shouldReconnect: () => true,
    }
  );

  useEffect(() => {
    // console.log('(ws.file) readyState:', readyState);
  }, [readyState]);

  const [formType, setFormType] = useState('files');
  const [currentPath, setcurrentPath] = useState('/');
  const [filter, setFilter] = useState({
    type: !compact ? null : 'media',
    q: null,
    ignoreTemplates: true,
  });

  const [isCmdPressed, setCmdState] = useState(false);
  const [isShiftPressed, setShiftState] = useState(false);
  const [selected, select] = useState({names: [], assets: []});
  const [searchResult, setSearchResult] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [binTabActive, setBinTabActive] = useState(false);

  useEffect(() => {
    (async () => {
      await dispatch(getFiles({key: `${type}/${id}`, type, id, filter}));
      await dispatch(getDeletedFiles({key: `${type}/${id}`, type, id, filter}));
    })();
  }, [id]);

  useEffect(() => {
    const keydown = e => {
      if (e.key === 'Meta' || e.key === 'Control') setCmdState(true);
      else if (e.key === 'Shift') setShiftState(true);
      else setCmdState(false);
    };
    const keyup = e => {
      // if(e.key === 'Meta' || e.key === 'Control') setCmdState(false);
      // else if(e.key === 'Shift') setShiftState(false);
      setCmdState(false);
      setShiftState(false);
    };
    window.addEventListener('keydown', keydown);
    window.addEventListener('keyup', keyup);
    return () => {
      window.removeEventListener('keydown', keydown);
      window.removeEventListener('keyup', keyup);
    };
  }, []);

  const proccessWSMsg = async wsmsg => {
    if (
      wsmsg.type === 'renderFinished' &&
      wsmsg.data.relativeType === type &&
      wsmsg.data.relativeId === id
    ) {
      setShowToast(true);
      // await dispatch(getFiles({key: `${type}/${id}`, type, id, filter}));
      // const fileItems = await getFiles({key: `${type}/${id}`, type, id, filter});
      // console.log('fileItems', fileItems, 'file', file);
    }
  };

  useEffect(() => {
    try {
      const wsmsg = JSON.parse(lastMessage?.data);
      console.log(wsmsg, wsmsg.data);
      proccessWSMsg(wsmsg.data);
    } catch (error) {
      if (error.message.indexOf('JSON') === -1) {
        console.log(error);
      }
    }
    // console.log('(ws.file)', lastMessage?.data);
    // if(lastMessage?.data.type==="")
  }, [lastMessage]);

  const applyFilter = params => {
    setFilter({...filter, ...params});
  };

  const links = {
    brand: item => `/brand/view/${item.parents.brandId}/file/${item._id}?trash=${binTabActive}`,
    team: item => `/team/view/${item.parents.teamId}/file/${item._id}?trash=${binTabActive}`,
    project: item => `/project/${item.parents.projectId}/file/${item._id}?trash=${binTabActive}`,
  };

  const extractDirName = (currentPath, fullPath) => {
    const path = fullPath.replace(currentPath, '').replace(/^\//, '');
    return path.indexOf('/') === -1 ? path : path.split('/')[0];
  };

  const esc = str => {
    const chars = [
      '+',
      '@',
      '$',
      '%',
      '^',
      '&',
      '*',
      '(',
      ')',
      '=',
      '{',
      '}',
      '[',
      ']',
      '-',
      '|',
      '~',
      '!',
      '#',
      ':',
      '_',
    ];
    let out = str;
    chars.forEach(c => (out = out.replace(c, `\\${c}`)));
    return out;
  };

  const getFilteredAssets = useMemo(() => {
    const creatives = {};
    let items = (binTabActive ? file.deletedItems || [] : file.items || [])
      .filter(f => {
        const r = currentPath === '/' ? '^/.*' : new RegExp(`^${esc(currentPath)}(\/.*)?$`);
        if (f.kind === 'CREATIVE' && f.path === currentPath) return true;
        else if (f.kind !== 'CREATIVE' && f.path && f.path.match(r) !== null) return true; //f.path.indexOf(currentPath) === 0
        return false;
      })
      .filter(f => {
        if (f.kind === 'CREATIVE' && !creatives[f.displayName]) {
          creatives[f.displayName] = 1;
          return true;
        } else if (f.kind === 'CREATIVE' && creatives[f.displayName]) {
          creatives[f.displayName] += 1;
          return false;
        } else if (f.kind !== 'CREATIVE') return true;
      });
    if (filter.type === 'link') items = items.filter(i => i.kind === 'URL');
    else if (filter.type === 'creative') items = items.filter(i => i.kind === 'CREATIVE');
    else if (filter.type === 'other') {
      items = items.filter(i => i.kind !== 'URL' && i.kind !== 'CREATIVE');
      items = items.filter(i => i.mime.indexOf('image') === -1 && i.mime.indexOf('video') === -1);
    } else if (filter.type === 'media') {
      items = items.filter(
        i => i && i.mime && (i.mime.indexOf('image') !== -1 || i.mime.indexOf('video') !== -1)
      );
    } else if (filter.type !== null)
      items = items.filter(
        i => i.kind !== 'URL' && i.kind !== 'CREATIVE' && i.mime.indexOf(filter.type) !== -1
      );
    return [
      ...new Set(
        items.map(item =>
          currentPath === item.path ? item : extractDirName(currentPath, item.path)
        )
      ),
    ].filter(i => !!i);
  }, [binTabActive, file.deletedItems, file.items, filter.type, currentPath]);

  const renderSearchResults = value => {
    if (!value) {
      // console.log('no value happend');
      setSearchResult([]);
      setNoResults(false);
      return;
    }
    const regex = new RegExp(value, 'i');
    const results = getFilteredAssets
      .filter(asset => {
        return regex.test(asset.displayName);
      })
      .filter(r => typeof r !== 'string');

    if (!results.length) {
      setNoResults(true);
      return;
    }
    setNoResults(false);
    setSearchResult(results);
  };

  const getParents = () => {
    if (type === 'brand') return {brandId: id};
    else if (type === 'team') return {brandId: (team.item.parents || {}).brandId, teamId: id};
    else if (type === 'project') return {...project.item.parents, projectId: id};
  };

  if (file.asyncState[`${type}/${id}`] && file.asyncState[`${type}/${id}`].fetching)
    return <div className="p-3 bg-light">loading...</div>;
  else if (file.asyncState[`${type}/${id}`] && file.asyncState[`${type}/${id}`].error) {
    return (
      <div className="p-3 bg-light">
        <ErrorMessages error={file.asyncState[`${type}/${id}`].error} />
      </div>
    );
  } else if (!file.asyncState[`${type}/${id}`]) return null;

  const selectAsset = (e, asset, isFolder, forceSelect) => {
    const items = binTabActive ? file.deletedItems || [] : file.items || [];

    // console.log({asset}, 'selecting');

    if (isCmdPressed || forceSelect) {
      e.preventDefault();
      let name = null;
      let assets = [];

      if (isFolder) {
        name = `${asset}_folder`;
        const pathToFind =
          currentPath === '/' ? `${currentPath}${asset}/` : `${currentPath}/${asset}/`;
        // console.log(currentPath, 'path to find', pathToFind);
        assets = items.filter(f => {
          // console.log(f.path);
          return (
            ['FILE', 'CREATIVE', 'FONT', 'URL'].includes(f.kind) &&
            (f.path ===
              (currentPath === '/' ? `${currentPath}${asset}` : `${currentPath}/${asset}`) ||
              f.path.indexOf(pathToFind) === 0) &&
            f.displayName !== 'empty'
          );
        });
        if (assets.length === 0) {
          assets = items.filter(f => {
            // console.log(f.path);
            return (
              ['FILE', 'CREATIVE', 'FONT', 'URL'].includes(f.kind) &&
              (f.path ===
                (currentPath === '/' ? `${currentPath}${asset}` : `${currentPath}/${asset}`) ||
                f.path.indexOf(pathToFind) === 0)
            );
          });
        }
      } else {
        name = `${asset.displayName}_${asset.kind}`;
        if (['FILE', 'FONT', 'URL'].includes(asset.kind)) assets = [asset];
        else if (asset.kind === 'CREATIVE')
          assets = items.filter(
            f =>
              f.kind === 'CREATIVE' && f.displayName === asset.displayName && f.path === currentPath
          );
      }
      if (selected.names.includes(name)) {
        select({
          names: selected.names.filter(a => a !== name) || [],
          assets: selected.assets.filter(a => !assets.map(({_id}) => _id).includes(a._id)) || [],
        });
      } else {
        // console.log('or this?', selected.names, name, selected.assets, assets);
        select(() => {
          return {names: [...selected.names, name], assets: [...selected.assets, ...assets]};
        });
      }
    } else if (isShiftPressed) {
      // const items = getFilteredAssets();
    } else {
      if (isFolder)
        setcurrentPath(currentPath === '/' ? `${currentPath}${asset}` : `${currentPath}/${asset}`);
      select({names: [], assets: []});
    }
  };

  const deleteAssets = async () => {
    try {
      await Promise.allSettled(selected?.assets?.map(asset => api.asset.deleteAsset(asset._id)));
      await dispatch(getFiles({key: `${type}/${id}`, type, id, filter}));
      await dispatch(getDeletedFiles({key: `${type}/${id}`, type, id, filter}));

      select({names: [], assets: []});
    } catch (error) {
      console.error(error);
    }
  };

  const urlToPromise = url => {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(url, (err, data) => {
        if (err) reject(err);
        else resolve(data);
      });
    });
  };

  const download = () => {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = 'zipFilename.zip';

    const urls = [];
    selected.assets.forEach(asset => {
      if (['FILE', 'FONT'].includes(asset.kind)) {
        urls.push({
          fileName: `${asset.path.replace(currentPath, '')}/${asset.displayName}`.replace(
            /^\//,
            ''
          ),
          url: `${process.env.REACT_APP_S3_URL}/${
            asset.versions[asset.versions.length - 1].name
          }?x=${Math.random()}`,
        });
      } else if (asset.kind === 'CREATIVE') {
        asset.versions[asset.versions.length - 1].files.forEach(f =>
          urls.push({
            fileName: `${asset.path.replace(currentPath, '')}/${asset.displayName}/${
              asset.creativeTitle
            }${f}`.replace(/^\//, ''),
            url: `${process.env.REACT_APP_S3_URL}/${
              asset.versions[asset.versions.length - 1].dir
            }${encodeURIComponent(f)}?x=${Math.random()}`,
          })
        );
      }
    });

    urls.forEach(({url, fileName}) => {
      const emptyCheck = fileName.split('/').at(-1);
      console.log('fileName', fileName, emptyCheck);
      if (emptyCheck !== 'empty') {
        zip.file(fileName, urlToPromise(url), {binary: true});
        count += 1;
        if (count === urls.length) {
          zip.generateAsync({type: 'blob'}).then(content => {
            FileSaver(content, zipFilename);
            select({names: [], assets: []});
          });
        }
      }
    });
  };

  const restoreAssets = async ids => {
    if (!ids) {
      try {
        await api.asset.restoreAllDeletedAssets(type, id);
      } catch (error) {
        console.error(error);
      }
      return;
    }

    try {
      await api.asset.restoreDeletedAssets(ids, getParents());
    } catch (error) {
      console.error(error);
    }

    await dispatch(getFiles({key: `${type}/${id}`, type, id, filter}));
    await dispatch(getDeletedFiles({key: `${type}/${id}`, type, id, filter}));
    select({names: [], assets: []});
  };

  const permaDeleteDeletedAssets = async ids => {
    if (!ids) {
      try {
        await api.asset.permaDeleteAllDeletedAssets(type, id);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        await api.asset.premaDeletedAssets(ids, getParents());
      } catch (error) {
        console.error(error);
      }
    }

    await dispatch(getFiles({key: `${type}/${id}`, type, id, filter}));
    await dispatch(getDeletedFiles({key: `${type}/${id}`, type, id, filter}));
    select({names: [], assets: []});
  };

  return (
    <Access type="file" parents={getParents()}>
      {({isAllowed}) => (
        <div
          className={`p-3 bg-light ${binTabActive && 'info-border'}`}
          ref={el => (el ? el.focus() : null)}
        >
          {match.params.fileId && (
            <View id={match.params.fileId} smartCropperBrandId={smartCropperBrandId} />
          )}
          {showToast && (
            <div
              style={{
                backgroundColor: '#ffe48e',
                display: 'flex',
                height: 50,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              role="button"
              onClick={() => {
                dispatch(getFiles({key: `${type}/${id}`, type, id, filter}));
                setShowToast(false);
              }}
            >
              <FontAwesomeIcon icon="refresh" size="lg" style={{marginRight: 10}} />
              New files where added,{'  '}
              <a style={{textDecoration: 'underline', paddingLeft: 2}}>{` click to refresh`}</a>
            </div>
          )}
          <div className="file-asset">
            <div className="file-asset-breadcrumb ">
              {currentPath.split('/').map((p, i) => {
                const dirs = currentPath.split('/');
                if (i === 0)
                  return !binTabActive ? (
                    <div
                      key="root"
                      className={`icon ${
                        currentPath !== '/' ? 'icon-folder-blue' : 'icon-folder-black'
                      }`}
                      onClick={() => {
                        setSearchResult([]);
                        setSearchValue('');
                        setcurrentPath('/');
                        setNoResults(false);
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon="trash"
                      size="lg"
                      onClick={() => {
                        setSearchResult([]);
                        setSearchValue('');
                        setcurrentPath('/');
                        setNoResults(false);
                      }}
                      className={`ml-2 ${currentPath !== '/' ? 'text-primary' : ''}`}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  );
                if (!p) return;
                if (i === dirs.length - 1 && !!p.trim())
                  return (
                    <span key="last" className="breadcrumb-i active">
                      {p}
                    </span>
                  );
                return (
                  <span
                    key={i}
                    className="breadcrumb-i"
                    onClick={() => {
                      setSearchResult([]);
                      setSearchValue('');
                      setNoResults(false);
                      setcurrentPath(dirs.slice(0, i + 1).join('/'));
                    }}
                  >
                    {p}
                  </span>
                );
              })}
            </div>

            {isAllowed('upload') && !binTabActive && !compact && (
              <div className="dropzone p-2">
                <div className="file-asset-tabs">
                  <span
                    className={`tab-item ${formType === 'files' && 'text-primary'}`}
                    onClick={() => setFormType('files')}
                  >
                    Files
                  </span>
                  <span
                    className={`tab-item ${formType === 'folder' && 'text-primary'}`}
                    onClick={() => setFormType('folder')}
                  >
                    Folder
                  </span>
                  <span
                    className={`tab-item ${formType === 'link' && 'text-primary'}`}
                    onClick={() => setFormType('link')}
                  >
                    Link
                  </span>
                  {!creativesHidden && (
                    <span
                      className={`tab-item ${formType === 'creative' && 'text-primary'}`}
                      onClick={() => setFormType('creative')}
                    >
                      Creative
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <div style={{display: formType === 'files' ? 'block' : 'none'}}>
                    <FileForm type={type} id={id} path={currentPath} />
                  </div>
                  <div style={{display: formType === 'folder' ? 'block' : 'none'}}>
                    <FolderForm type={type} id={id} path={currentPath} />
                  </div>
                  <div style={{display: formType === 'link' ? 'block' : 'none'}} className="mt-4">
                    <LinkForm type={type} id={id} path={currentPath} items={file.items} />
                  </div>
                  {!creativesHidden && formType === 'creative' && (
                    <div
                      style={{display: formType === 'creative' ? 'block' : 'none'}}
                      className="mt-4"
                    >
                      <CreativeForm
                        key={`${type}${id}${currentPath}`}
                        type={type}
                        id={id}
                        path={currentPath}
                        items={file.items}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            {(file.items || []).length ? (
              <>
                <div className="file-asset-tabs filtes mb-3 d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1">
                    <input
                      value={searchValue}
                      style={{
                        background: '#F8F9FA',
                        border: 'none',
                        borderBottom: '1px solid #757575',
                        marginRight: '1rem',
                        marginBottom: '0.5rem',
                        width: '50%',
                        maxWidth: 500,
                        transform: 'translateY(-2px)',
                      }}
                      placeholder="Search this folder"
                      onChange={e => {
                        setSearchValue(e.target.value);
                        renderSearchResults(e.target.value);
                      }}
                    />
                    <span className="mr-2 text-body">View:</span>
                    <select
                      className="form-control pl-0 pb-0 pt-0 w-auto custom-select custom-select-sm border-0 bg-transparent w-25"
                      onChange={e => applyFilter({type: e.target.value || null})}
                    >
                      {!compact ? (
                        <option value="">All assets</option>
                      ) : (
                        <option value="media">All media</option>
                      )}
                      <option value="image">Images</option>
                      <option value="video">Videos</option>
                      {!compact && <option value="link">Links</option>}
                      {!compact && <option value="creative">Creatives</option>}
                      {!compact && <option value="other">Other</option>}
                    </select>
                  </div>
                  {!compact && (
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} />
                      <Dropdown.Menu className="rounded-0">
                        {isAllowed('bin') && process.env.REACT_APP_ENV === 'development' && (
                          <Dropdown.Item
                            onClick={() => {
                              setBinTabActive(!binTabActive);
                              setSearchResult([]);
                              setSearchValue('');
                              setcurrentPath('/');
                              setNoResults(false);
                              select({names: [], assets: []});
                            }}
                            className="primary-text d-flex align-items-center"
                          >
                            {binTabActive ? (
                              <span
                                className={`mr-2 icon ${
                                  binTabActive ? 'icon-folder-blue' : 'icon-folder-black'
                                }`}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon="trash"
                                size="lg"
                                className={`mr-2 ${binTabActive ? 'text-primary' : ''}`}
                                style={{
                                  cursor: 'pointer',
                                }}
                              />
                            )}
                            {binTabActive ? 'Leave Bin' : 'Go to Bin (Admin only)'}
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={download}
                          disabled={!(selected.assets || []).filter(a => a.kind !== 'URL').length}
                          className="primary-text d-flex align-items-center"
                        >
                          <div
                            className={`icon ${
                              !selected.assets?.length ? 'icon-download-gray' : 'icon-download-blue'
                            } mr-2`}
                          />
                          Download selected{' '}
                          {(selected.assets || []).filter(a => a.kind !== 'URL').length}
                        </Dropdown.Item>

                        {isAllowed('bin') && binTabActive && (
                          <Dropdown.Item
                            onClick={() => restoreAssets()}
                            disabled={!file?.deletedItems?.length}
                            className="success-text d-flex align-items-center"
                          >
                            <FontAwesomeIcon
                              icon="trash-restore"
                              size="lg"
                              className={`mr-2 ${
                                !file?.deletedItems?.length ? 'disabled-text' : 'success-text'
                              } `}
                            />
                            Restore all
                          </Dropdown.Item>
                        )}
                        {isAllowed('bin') && binTabActive && (
                          <Dropdown.Item
                            onClick={() => restoreAssets(selected?.assets?.map(a => a?._id))}
                            disabled={!selected.assets?.length}
                            className="success-text d-flex align-items-center"
                          >
                            <FontAwesomeIcon
                              icon="trash-restore"
                              size="lg"
                              className={`mr-2 ${
                                !selected.assets?.length ? 'disabled-text' : 'success-text'
                              } `}
                            />
                            Restore selected {selected.assets.length}
                          </Dropdown.Item>
                        )}
                        {isAllowed('delete') && (
                          <Dropdown.Item
                            onClick={
                              binTabActive
                                ? () => permaDeleteDeletedAssets(selected?.assets?.map(a => a?._id))
                                : deleteAssets
                            }
                            disabled={!selected.assets.length}
                            className="danger-text d-flex align-items-center"
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              size="lg"
                              className={`mr-2 ${
                                !selected.assets.length ? 'disabled-text' : 'danger-text'
                              } `}
                            />
                            {binTabActive && 'Permanently'} Delete selected {selected.assets.length}
                          </Dropdown.Item>
                        )}

                        {isAllowed('bin') && binTabActive && (
                          <Dropdown.Item
                            onClick={() => permaDeleteDeletedAssets()}
                            disabled={!file?.deletedItems?.length}
                            className="danger-text d-flex align-items-center"
                          >
                            <FontAwesomeIcon
                              icon="trash"
                              size="lg"
                              className={`mr-2 ${
                                !file?.deletedItems?.length ? 'disabled-text' : 'danger-text'
                              } `}
                            />
                            Permanently delete all
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  {compact && (
                    <span onClick={e => onFileClick()} style={{cursor: 'pointer'}}>
                      X
                    </span>
                  )}
                </div>
                <div className="assets">
                  {Array.isArray(searchResult) && searchResult.length ? (
                    searchResult.map((item, i) => {
                      if (item.hash === 'd41d8cd98f00b204e9800998ecf8427e') {
                        return null;
                      }
                      if (onFileClick && currentPath === item.path)
                        return (
                          <div key={item._id} onClick={e => onFileClick(item)}>
                            <FileThumb
                              asset={item}
                              isSelected={selected.names.includes(
                                `${item.displayName}_${item.kind}`
                              )}
                              onClick={onFileClick}
                              variants={file.items.filter(
                                f => f.displayName === item.displayName && f.path === currentPath
                              )}
                            />
                            <div className="name" title={item.displayName}>
                              {item.displayName}
                            </div>
                          </div>
                        );
                      else if (onFileClick)
                        return (
                          <div key={item} onClick={e => selectAsset(e, item, true)}>
                            <div
                              className={`thumb folder ${
                                selected.names.includes(`${item}_folder`) ? 'selected' : ''
                              }`}
                            />
                            <div className="name" title={item}>
                              {item}
                            </div>
                          </div>
                        );
                      else if (currentPath === item.path)
                        return (
                          <div key={item._id}>
                            <Link
                              onClick={e => selectAsset(e, item)}
                              to={links[type](item)}
                              className="d-flex flex-column"
                            >
                              <FileThumb
                                asset={item}
                                isSelected={selected.names.includes(
                                  `${item.displayName}_${item.kind}`
                                )}
                                onClick={selectAsset}
                                variants={file.items.filter(
                                  f => f.displayName === item.displayName && f.path === currentPath
                                )}
                              />
                              <div className="name" title={item.displayName}>
                                {item.displayName}
                              </div>
                            </Link>
                          </div>
                        );
                      else
                        return (
                          <div key={item} onClick={e => selectAsset(e, item, true)}>
                            <div
                              className={`thumb folder ${
                                selected.names.includes(`${item}_folder`) ? 'selected' : ''
                              }`}
                            />
                            <div className="name" title={item}>
                              {item}
                            </div>
                          </div>
                        );
                    })
                  ) : !noResults ? (
                    getFilteredAssets.map(item => {
                      if (item.hash === 'd41d8cd98f00b204e9800998ecf8427e') {
                        return null;
                      }

                      if (fontsHidden && item === 'fonts') {
                        return null;
                      }
                      if (onFileClick && currentPath === item.path)
                        return (
                          <div key={item._id} onClick={e => onFileClick(item)}>
                            <FileThumb
                              asset={item}
                              isSelected={selected.names.includes(
                                `${item.displayName}_${item.kind}`
                              )}
                              onClick={onFileClick}
                              variants={file.items.filter(
                                f => f.displayName === item.displayName && f.path === currentPath
                              )}
                            />
                            <div className="name" title={item.displayName}>
                              {item.displayName}
                            </div>
                          </div>
                        );
                      else if (onFileClick)
                        return (
                          <div key={item} onClick={e => selectAsset(e, item, true)}>
                            <div
                              className={`thumb folder ${
                                selected.names.includes(`${item}_folder`) ? 'selected' : ''
                              }`}
                            />
                            <div className="name" title={item}>
                              {item}
                            </div>
                          </div>
                        );
                      else if (currentPath === item.path)
                        return (
                          <div key={item._id}>
                            <Link
                              onClick={e => selectAsset(e, item)}
                              to={links[type](item)}
                              className="d-flex flex-column"
                            >
                              <FileThumb
                                asset={item}
                                isSelected={selected.names.includes(
                                  `${item.displayName}_${item.kind}`
                                )}
                                onClick={selectAsset}
                                variants={file.items.filter(
                                  f => f.displayName === item.displayName && f.path === currentPath
                                )}
                              />
                              <div className="name" title={item.displayName}>
                                {item.displayName}
                              </div>
                            </Link>
                          </div>
                        );
                      else
                        return (
                          <div key={item} onClick={e => selectAsset(e, item, true)}>
                            <div
                              className={`thumb folder ${
                                selected.names.includes(`${item}_folder`) ? 'selected' : ''
                              }`}
                            />
                            <div className="name" title={item}>
                              {item}
                            </div>
                          </div>
                        );
                    })
                  ) : (
                    <p className="ml-3">No results</p>
                  )}
                </div>
              </>
            ) : (
              <div className="mt-4">
                <NoResult />
              </div>
            )}
          </div>
        </div>
      )}
    </Access>
  );
}

export default connect(store => ({
  file: store.file,

  project: store.project,
  team: store.team,
}))(withRouter(FileList));
