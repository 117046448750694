import React from 'react';
import Comments from '../../../comment/Comments';
import ShareTable from '../components/SharedPage/ShareTable';

const ProjectTab = ({parents}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        gap: '40px',

        justifyContent: 'space-between',
      }}
    >
      <ShareTable parents={parents} />
      <div
        className="d-flex flex-column"
        style={{
          width: '30%',
          minWidth: '700px',
          height: '35vh',
          overflow: 'auto',
        }}
      >
        <h3 className="mb-4">Discussion</h3>

        <Comments type="project" id={parents.projectId} parents={parents} />
      </div>
    </div>
  );
};

export default ProjectTab;
