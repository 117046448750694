export const regularPreset = {
  id: 1,
  name: 'Display default',
  sizes: [
    {width: 300, height: 250, enabled: true, _id: 1, name: '300x250'},
    {width: 336, height: 280, enabled: true, _id: 2, name: '336x280'},
    {width: 320, height: 240, enabled: true, _id: 3, name: '320x240'},
    {width: 300, height: 600, enabled: true, _id: 4, name: '300x600'},
    {width: 160, height: 600, enabled: true, _id: 5, name: '160x600'},
    {width: 120, height: 600, enabled: true, _id: 6, name: '120x600'},
    {width: 970, height: 250, enabled: true, _id: 7, name: '970x250'},
    {width: 728, height: 90, enabled: true, _id: 8, name: '728x90'},
    {width: 320, height: 100, enabled: true, _id: 9, name: '320x100'},
    {width: 300, height: 50, enabled: true, _id: 10, name: '300x50'},
  ],
};

const videoPreset = {
  id: 2,
  name: 'Video default',
  sizes: [
    {width: 1080, height: 1080, enabled: true, _id: 1, name: '1080x1080'},
    {width: 1080, height: 1350, enabled: true, _id: 2, name: '1080x1350'},
    {width: 1080, height: 1920, enabled: true, _id: 3, name: '1080x1920'},
    {width: 1920, height: 1080, enabled: true, _id: 4, name: '1920x1080'},
  ],
};

export const defaultCropperPresets = [regularPreset, videoPreset];
