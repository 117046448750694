import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import moment from 'moment';
import QueueRow from './queueRow';
import Spinner from './spinner';
import style from './queueRow.module.css';
import api from '../../api';
import {useInterval} from '../../hooks/useInterval';

const Queue = ({project, admin}) => {
  const history = useHistory();

  const [files, setFiles] = useState();
  const [queue, setQueue] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [batches, setBatches] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useInterval(() => {
    api.videobuilder
      .getQueue({brandId: project.item.parents.brandId, projectId: project.item._id})
      .then(r => {
        setQueue(r.data);
        setLoading(false);
      })
      .catch(e => {
        // console.error(e);
        setError({component: 'list', details: e});
      });
  }, 1000 * 3);

  const groupBy = (items, key) =>
    items.reduce(
      (result, item) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  useEffect(() => {
    if (queue && queue.length) {
      setBatches(groupBy(queue, 'batchId'));
      api.asset
        .getFiles('project', project.item._id)
        .then(r => {
          setFiles(r.data);
        })
        .catch(e => {
          setError({component: 'func', details: e});
        });
    }
  }, [queue, project.item._id]);

  const download = (batchName, batch) => {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${batchName}.zip`;

    // console.log('batch', batch);

    const urls = [];
    batch.forEach(qItem => {
      const asset =
        files[
          files.findIndex(f => {
            if (qItem.filename && f.displayName === qItem.filename) {
              return true;
            }
            if (
              !qItem.filename &&
              f.displayName === `${qItem.templateName}_${qItem.preset}_${qItem.composition}.mp4`
            ) {
              return true;
            }
            return false;
          })
        ];
      if (asset) {
        urls.push({
          fileName:
            qItem.filename || `${qItem.templateName}_${qItem.preset}_${qItem.composition}.mp4`,
          url: `${process.env.REACT_APP_S3_URL}/${
            asset.versions[asset.versions.length - 1].name
          }?x=${Math.random()}`,
        });
      }
    });

    const urlToPromise = url => {
      return new Promise((resolve, reject) => {
        JSZipUtils.getBinaryContent(url, (err, data) => {
          if (err) reject(err);
          else resolve(data);
        });
      });
    };

    urls.forEach(({url, fileName}) => {
      zip.file(fileName, urlToPromise(url), {binary: true});
      count += 1;
      if (count === urls.length) {
        zip.generateAsync({type: 'blob'}).then(content => {
          FileSaver(content, zipFilename);
        });
      }
    });
  };

  const downloadFileName = (fileName, awsFilename) => {
    // console.log('fileName', fileName);
    const fileIndex = files.findIndex(f => f.displayName === fileName);
    if (fileIndex !== -1) {
      // const file = files[fileIndex];
      // let url = `${process.env.REACT_APP_S3_URL}/${file.versions[file.versions.length -1].name}?x=${Math.random()}`
      const url = `${process.env.REACT_APP_S3_URL}/${awsFilename}?x=${Math.random()}`;
      FileSaver.saveAs(url, fileName);
    }
  };

  const preview = fileName => {
    const fileIndex = files.findIndex(f => f.displayName === fileName);
    if (fileIndex !== -1) {
      const file = files[fileIndex];
      // console.log(project, file);
      history.push(`/project/${file.parents.projectId}/file/${file._id}`);
    }
  };

  return (
    <div style={{paddingBottom: 20}}>
      <h4>Recent exports</h4>
      <div style={{backgroundColor: '#f8f9fa', maxHeight: 240, overflow: 'auto', marginBottom: 20}}>
        {loading && <Spinner />}
        {!loading && (
          <table
            style={{
              borderCollapse: 'separate !important',
              borderSpacing: 0,
              borderRadius: '10px 10px 0 0',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: '#0000B4',
                  height: 40,
                  color: 'white',
                  fontSize: 'regular',
                }}
              >
                <th style={{width: 20}} aria-label="Icon" />
                <th style={{width: 200}}>Name</th>
                <th style={{width: 200}}>Videos</th>
                <th style={{width: 80}}>Actions</th>
                {/* <th style={{width:80}}>actions</th> */}
              </tr>
            </thead>
            <tbody>
              {batches &&
                Object.keys(batches).map(bKey => {
                  return (
                    <>
                      <tr
                        style={{backgroundColor: 'white', height: 30}}
                        onClick={() => setExpanded(expanded !== bKey ? bKey : false)}
                      >
                        <td style={{width: 20}}>{expanded === bKey ? '-' : '+'}</td>
                        <td style={{width: 200}}>
                          {batches[bKey][0].batchName || (
                            <div>
                              <span>Untitled </span>
                              <span style={{color: 'grey', fontSize: 'smaller'}}>
                                {moment(batches[bKey][0].createdAt).format('YY/MM/DD HH:mm')}
                              </span>
                            </div>
                          )}
                        </td>
                        <td style={{width: 20}}>{batches[bKey].length}</td>
                        <td>
                          {batches[bKey].filter(q => q.state !== 'finished').length === 0 ? (
                            <div
                              role="button"
                              aria-label="Download batch"
                              tabIndex={0}
                              className={`icon icon-download-blue ml-2 ${style.hvr}`}
                              onClick={e => {
                                e.stopPropagation();
                                if (batches[bKey].filter(q => q.state !== 'finished').length === 0)
                                  download(bKey, batches[bKey]);
                              }}
                            />
                          ) : (
                            `${batches[bKey].length -
                              batches[bKey].filter(q => q.state !== 'finished').length}/${
                              batches[bKey].length
                            } ready`
                          )}
                        </td>
                      </tr>
                      {expanded === bKey && (
                        <tr>
                          <td colSpan={4}>
                            <table style={{width: '100%'}}>
                              <tr style={{fontSize: 'regular'}}>
                                <th style={{width: 40, height: 30}} aria-label="-" />
                                <th style={{width: 40, height: 30}} aria-label="--" />
                                <th style={{width: 200, height: 30}}>Composition</th>
                                <th style={{width: 200, height: 30}}>Created</th>
                                <th style={{width: 80, height: 30}}>State</th>
                                {/* <th style={{width:80}}>actions</th> */}
                              </tr>
                              {batches[bKey].map(q => {
                                return (
                                  <QueueRow
                                    data={q}
                                    setExpanded={setExpanded}
                                    expanded={expanded}
                                    download={downloadFileName}
                                    preview={preview}
                                    admin={admin}
                                  />
                                );
                              })}
                            </table>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
      {error && <div>Error: {error.details.message}</div>}
    </div>
  );
};

export default Queue;
