import React, {useState} from 'react';
import _ from 'lodash';

import Select from 'react-select';
import {Button, Modal} from 'react-bootstrap';
import {customInputStyles, customInputStylesDynamic} from '../../../consts';
import {fetchCSVData} from '../../../../../../helpers/csv/fetchCsv';

const DynamicInput = ({column, handleInputChange, label, value, disabled = false}) => {
  const {
    default: columnDefault,
    feedOptions = [],
    options: otherOptions,
    remoteUrl,
    remoteOption,
  } = column;
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tempValue, setTempValue] = useState(remoteOption);
  const [disableModalSelect, setDisableModalSelect] = useState(false);

  const [remoteFeed, setRemoteFeed] = useState({
    error: null,
    options: feedOptions,
    remoteKeys: otherOptions,
    feedData: [],
    default: columnDefault,
    defaultOption: remoteOption,
  });

  const handleDefaultChange = async newDefault => {
    if (remoteUrl.endsWith('format=csv')) {
      const {uniqueOptions, csvResultKeys, csvAllRows} = await fetchCSVData(
        remoteUrl,
        remoteFeed.remoteKeys,
        newDefault
      );

      setRemoteFeed(old => ({
        ...old,
        error: null,
        feedData: csvAllRows,
        remoteKeys: csvResultKeys,
        default: newDefault,
        options: uniqueOptions,
      }));

      return;
    }

    const groupedOptions = _.groupBy(remoteFeed.feedData, newDefault);
    setRemoteFeed(prevState => ({
      ...prevState,
      default: newDefault,
      options: Object.keys(groupedOptions),
    }));
    setTempValue(null);
  };

  const handleSelectedValueChange = newValue => {
    setTempValue(newValue);
  };

  const handleModalSave = () => {
    setShowModal(false);
    handleInputChange(label, tempValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setTempValue(remoteOption);
  };
  const getRemoteFeed = async () => {
    try {
      setIsLoading(true);
      setShowModal(true);

      if (remoteUrl.endsWith('format=csv')) {
        const {uniqueOptions, csvIndex, csvResultKeys, csvAllRows} = await fetchCSVData(
          remoteUrl,
          null,
          remoteFeed.default
        );

        if (!(csvIndex > -1)) {
          setRemoteFeed(prevState => ({
            ...prevState,
            default: null,
            feedData: csvAllRows,
            remoteKeys: csvResultKeys,
            defaultOption: null,
            error: `The column “${columnDefault}” that has been set in the setup is missing in the feed. You can select a new column for this ad and use that value this time, but this could lead to unexpected results in the setup. To fix it properly bring back the original column in the feed, or ask the setup manager to check the setup.`,
          }));
          setIsLoading(false);
          setDisableModalSelect(false);
          setTempValue(null);

          return;
        }
        setDisableModalSelect(true);
        setRemoteFeed({
          error: null,
          feedData: csvAllRows,
          remoteKeys: csvResultKeys,
          isLoading: false,
          default: remoteFeed.default,
          options: uniqueOptions,
          defaultOption: remoteFeed.defaultOption,
        });
        setIsLoading(false);

        return;
      }
      const rawResult = await fetch(remoteUrl);
      const result = await rawResult.json();

      if (Array.isArray(result) && !result.length) {
        setRemoteFeed(prevState => ({
          ...prevState,
          error: 'Update failed. Feed is empty, please check the url',
        }));
        setIsLoading(false);

        return;
      }
      const remoteKeys = Object.entries(result[0]).map(([key]) => key);

      if (!columnDefault || !remoteKeys.includes(columnDefault)) {
        setRemoteFeed(prevState => ({
          ...prevState,
          feedData: result,
          remoteKeys,
          disabled: true,
          default: null,
          error: `The column “${columnDefault}” that has been set in the setup is missing in the feed. You can select a new column for this ad and use that value this time, but this could lead to unexpected results in the setup. To fix it properly bring back the original column in the feed, or ask the setup manager to check the setup.`,
        }));
        setShowModal(true);
        setIsLoading(false);
        setDisableModalSelect(false);
        setTempValue(null);

        return;
      }
      const groupedOptions = _.groupBy(result, columnDefault);
      setDisableModalSelect(true);

      setRemoteFeed({
        error: null,
        feedData: result,
        remoteKeys,
        isLoading: false,
        default: columnDefault,
        options: Object.keys(groupedOptions),
        defaultOption: remoteFeed.defaultOption,
      });

      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Select
          isClearable={false}
          styles={customInputStylesDynamic}
          isSearchable={false}
          isDisabled={remoteFeed.disabled || isLoading}
          value={{label: value, value}}
          cacheOptions={false}
          isDisabled={disabled}
          menuPortalTarget={document.body}
          placeholder="Status"
          onChange={selectValue => {
            handleInputChange(label, selectValue.value);
          }}
          options={remoteFeed?.options?.map(s => ({label: s, value: s}))}
        />

        <div
          style={{
            padding: '0.375rem 0.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #081ee0',
            borderRadius: '0.625rem',
            marginLeft: '0.5rem',
            pointerEvents: isLoading ? 'none' : 'auto',
            opacity: isLoading ? 0.5 : 1,
          }}
          tabIndex={0}
          role="button"
          onClick={getRemoteFeed}
        >
          <span
            className="icon icon-reload primary-background"
            style={{
              color: 'inherit',
            }}
          />
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose} size="md" centered>
        <Modal.Header
          style={{justifyContent: 'end', padding: '0', paddingTop: '0.5rem', border: 'none'}}
        >
          <button
            onClick={handleModalClose}
            type="button"
            style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <span className="dco-edit-label mb-1">Link to Feed</span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-wrap text-break "
            href={remoteUrl}
          >
            {remoteUrl}
          </a>

          <span className="dco-edit-label mt-4 ">Please select a default column</span>
          <span className="dco-edit-label danger-text text-lowercase mt-1 ">
            {remoteFeed.error}
          </span>
          <Select
            isClearable={false}
            styles={customInputStyles}
            isDisabled={disableModalSelect}
            isSearchable={false}
            value={{label: remoteFeed.default, value: remoteFeed.default}}
            cacheOptions={false}
            placeholder="Status"
            onChange={selectValue => {
              handleDefaultChange(selectValue.value);
            }}
            options={remoteFeed?.remoteKeys?.map(s => ({label: s, value: s}))}
          />
          <span className="dco-edit-label mt-4">Preview of the feed data.</span>
          <Select
            isClearable={false}
            styles={customInputStyles}
            isSearchable={false}
            value={{label: tempValue, value: tempValue}}
            cacheOptions={false}
            placeholder="Status"
            onChange={selectValue => {
              handleSelectedValueChange(selectValue.value);
            }}
            options={remoteFeed?.options?.map(s => ({label: s, value: s}))}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="dco-button primary-background" onClick={handleModalSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DynamicInput;
