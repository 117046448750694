import _ from 'lodash';

export const transformFilesToSizes = files => {
  if (!Array.isArray(files)) return null;

  const filterSize = files.flatMap(f => f.path.split('/')).filter(s => s.includes('x'));
  const templateDimensions = filterSize.filter(size => {
    const [height] = size.split('x');
    if (!Number.isNaN(Number(height))) {
      return true;
    }
    return false;
  });

  return [...new Set(templateDimensions)];
};

export const isNumeric = value => {
  return /^-?\d+$/.test(value);
};

export const transformSizes = sizes => {
  if (!Array.isArray(sizes)) return null;

  return sizes.map(s => {
    const [height, width] = s.split('x');
    return {
      height: Number(height),
      width: Number(width),
    };
  });
};

export const removeElementFromArray = (array, index, value) => {
  const innerArray = [...array];
  const newIndex = index || innerArray.indexOf(value);

  if (newIndex >= 0) {
    innerArray.splice(newIndex, 1);
  }

  return innerArray;
};

export const replaceElementInArray = (array, index, newValue) => {
  const innerArray = [...array];

  innerArray.splice(index, 1, newValue);

  return innerArray;
};

export const replaceElementInArrayById = (array, newValue) => {
  const innerArray = [...array];
  const arrayIndex = innerArray.findIndex(item => item._id === newValue._id);

  if (arrayIndex >= 0) {
    innerArray.splice(arrayIndex, 1, newValue);
  }

  return innerArray;
};

export const replacePropertyInArray = (array, index, newValue, property) => {
  const newObject = {...array[index], [property]: newValue};

  return replaceElementInArray(array, index, newObject);
};

export const mergeArrayAtIndex = (arr, index, newItem) => [
  ...arr.slice(0, index),
  ...newItem,
  ...arr.slice(index),
];

export const readFromFile = async file => {
  return new Promise(resolve => {
    var reader = new FileReader();

    reader.onload = e => {
      var content = e.target.result;
      var intern = JSON.parse(content);
      resolve(intern);
    };
    reader.readAsText(file);
  });
};

export const getFileExtension = filename => filename.split('.').pop();

const compareAdColumnsNameAndType = (newAdColumns, originalAdColumns) => {
  const newNamesAndTypesArr = Object.entries(newAdColumns).map(([key, value]) => ({
    name: key,
    type: value.type,
  }));
  const originalNamesAndTypesArr = Object.entries(originalAdColumns).map(([key, value]) => ({
    name: key,
    type: value.type,
  }));

  if (
    newNamesAndTypesArr.length === originalNamesAndTypesArr.length &&
    _.isEqual(newNamesAndTypesArr, originalNamesAndTypesArr)
  ) {
    return false;
  }
  console.log('ad columns diff', originalNamesAndTypesArr, newNamesAndTypesArr);
  return true;
};

const compareShotStructureNameAndType = (newShotStructure, originalShotStructure) => {
  const newNamesAndTypesArr = Object.entries(newShotStructure).map(([key, value]) => ({
    name: key,
    type: value.type,
  }));
  const originalNamesAndTypesArr = Object.entries(originalShotStructure).map(([key, value]) => ({
    name: key,
    type: value.type,
  }));

  if (
    newNamesAndTypesArr.length === originalNamesAndTypesArr.length &&
    _.isEqual(newNamesAndTypesArr, originalNamesAndTypesArr)
  ) {
    return false;
  }
  return true;
};

export const getNextVersionIfSaved = (newSetup, originalSetup, isChanged) => {
  // get all decimals, defalut version 1.0.0
  let [firstDecimal, secondDecimal, thirdDecimal] = newSetup.version.split('.');
  let type = false;

  const isMaxShotsChanged = newSetup.maxShots !== originalSetup.maxShots;

  const isAdColumnsChanged = compareAdColumnsNameAndType(
    newSetup.adColumns,
    originalSetup.adColumns
  );
  console.log('isAdColumnsChanged', isAdColumnsChanged);

  const isShotStructureChanged = compareShotStructureNameAndType(
    newSetup.shotStructure,
    originalSetup.shotStructure
  );
  console.log('isShotStructureChanged', isShotStructureChanged);

  const isSizesChanged = !_.isEqual(newSetup.sizes, originalSetup.sizes);
  console.log('isSizesChanged', isSizesChanged);

  if (isMaxShotsChanged || isAdColumnsChanged || isShotStructureChanged || isSizesChanged) {
    secondDecimal = parseInt(secondDecimal, 10) + 1;
    type = 'minor';
  } else if (newSetup.setupName !== originalSetup.setupName || isChanged) {
    type = 'patch';
    thirdDecimal = parseInt(thirdDecimal, 10) + 1;
  }
  console.log(type, isChanged);

  return {value: `${firstDecimal}.${secondDecimal}.${type === 'minor' ? 0 : thirdDecimal}`, type};
};

export const handleSetupVersionChange = (newSetup, originalSetup) => {
  const newSetupCopy = _.cloneDeep(newSetup);
  // get all decimals, defalut version 1.0.0
  let [firstDecimal, secondDecimal, thirdDecimal] = newSetup.version.split('.');

  const isMaxShotsChanged = newSetup.maxShots !== originalSetup.maxShots;

  const isAdColumnsChanged = compareAdColumnsNameAndType(
    newSetup.adColumns,
    originalSetup.adColumns
  );

  const isShotStructureChanged = compareShotStructureNameAndType(
    newSetup.shotStructure,
    originalSetup.shotStructure
  );

  const isSizesChanged = !_.isEqual(newSetup.sizes, originalSetup.sizes);

  if (isMaxShotsChanged || isAdColumnsChanged || isShotStructureChanged || isSizesChanged) {
    secondDecimal = parseInt(secondDecimal, 10) + 1;
  }

  return {...newSetupCopy, version: `${firstDecimal}.${secondDecimal}.${thirdDecimal}`};
};

export const diffInVariantFields = (obj1, obj2) => {
  const diffInFields = Object.entries(obj2).filter(
    ([field, obj2Value]) => obj1[field] !== obj2Value
  );
  return diffInFields;
};
