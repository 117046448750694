import React from 'react';
import {Button} from 'react-bootstrap';
import Select from 'react-select';

import {customInputStyles} from '../consts';

const SetupSelectPage = props => {
  const {setups, selectedSetup, setSelectedSetup} = props;

  return (
    <>
      <span>Select a Setup to get started</span>
      <Select
        isClearable
        isSearchable
        defaultOptions
        className="w-50"
        styles={customInputStyles}
        cacheOptions={false}
        placeholder="Select Setup"
        value={selectedSetup.setup}
        onChange={i => {
          if (!i) {
            setSelectedSetup({setup: null, continue: false});
            return;
          }
          setSelectedSetup(old => ({
            ...old,
            setup: i,
          }));
        }}
        options={setups.filter(s => s.state === 'ready')}
        getOptionLabel={s => s.setupName}
        getOptionValue={s => s._id}
      />
      <Button
        type="button"
        disabled={!selectedSetup.setup}
        onClick={() => {
          setSelectedSetup(old => ({...old, continue: true}));
        }}
        className="dco-button primary mt-4"
      >
        Create your first Ad
      </Button>
    </>
  );
};

export default SetupSelectPage;
