import React, {useState, useMemo, createContext, useContext} from 'react';
import api from '../api';

const LastChangedUserContext = createContext({
  users: [],
  getUser: async id => {},
  getUserName: id => '',
  preloadUsers: ids => null,
});

export const LastChangedUserProvider = ({children}) => {
  const [users, setUsers] = useState({});

  const getUser = async id => {
    if (!id) {
      return null;
    }
    if (users[id]) {
      return users[id];
    }
    try {
      const user = await api.user.getUser(id);
      setUsers(old => ({...old, [id]: user.data}));
      return user.data;
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const getUserName = async id => {
    const user = await getUser(id);
    return user?.name || '';
  };
  const preloadUsers = async ids => {
    await Promise.all(ids.map(id => getUser(id)));
  };
  const providerValue = useMemo(
    () => ({
      users,
      getUser,
      getUserName,
      preloadUsers,
    }),
    [users, getUser, getUserName]
  );

  return (
    <LastChangedUserContext.Provider value={providerValue}>
      {children}
    </LastChangedUserContext.Provider>
  );
};

export const useLastChangedUser = () => {
  return useContext(LastChangedUserContext);
};
