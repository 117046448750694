/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/alt-text */
import React, {useCallback, useReducer, useMemo, useRef, useEffect} from 'react';
import {Button} from 'react-bootstrap';

import useLoaderButton from '../../hooks/useLoaderButton';
import {LoaderButton} from '../LoaderButton';
import {uploadFiles} from '../../pages/file/slice';
import SmartCrop from './SmartCrop';
import {defaultCropperPresets, regularPreset} from './consts';
import ConfirmationModal from '../ConfirmationModal';
import api from '../../api';
import Joyride, {CallBackProps, STATUS, Step} from 'react-joyride';

const types = {
  project: 'project',
  brand: 'brand',
  team: 'team',
};

const ImageCrop = props => {
  const {sizes: setupSizes, dispatch, match, asset, version, onClose, smartCropperBrandId} = props;
  const [showModal, setShowModal] = React.useState(null);
  const [preset, setPreset] = React.useState(regularPreset);
  const [presets, setPresets] = React.useState(defaultCropperPresets);

  const generateRef = useRef();
  const initialState = {
    sizes: preset.sizes,
    preset: preset,
    quality: preset.quality || 70,
    imageName: asset.displayName.split('.').shift() || 'Image',
    format: preset.format || 'JPG',
    scale: preset.retina || 1,
    retina: false,
  };
  const image = useMemo(() => `${process.env.REACT_APP_S3_URL}/${asset.versions[version]?.name}`, [
    asset,
    version,
  ]);

  const [
    loaderButtonStatus,
    setLoaderButtonCompleted,
    setLoaderButtonError,
    setLoaderButtonLoading,
  ] = useLoaderButton();

  const {type, id} = useMemo(() => {
    const {id: typeId} = match.params;
    const typeValue = types[match.path.split('/')[1]];
    return {type: typeValue, id: typeId};
  }, [match]);

  useEffect(() => {
    (async () => {
      const response = await api.presets.getPresets(smartCropperBrandId);
      const configPresets = response.data.config.map((p, indx) => ({
        id: indx + 1 + defaultCropperPresets.length,
        ...p,
        sizes: p.sizes.map((s, idx) => ({...s, _id: idx + 1})),
      }));
      setPresets([...defaultCropperPresets, ...configPresets]);
      if (configPresets.length) {
        setPreset(configPresets[0]);
      }
    })();
  }, [smartCropperBrandId]);

  const generateCroppedImages = useCallback(async () => {
    const state = generateRef.current.getState();
    console.log('generate state', state);
    const path = `/Smartcropper Exports/${state.imageName}`;
    setLoaderButtonLoading();
    try {
      const blobs = (await generateRef.current.generate()) || [];
      const files = blobs.map(myBlob => new File([myBlob.blob], myBlob.name, {type: myBlob.mime}));
      await Promise.all(
        files.map(file => {
          const tmpId = Math.random()
            .toString(32)
            .slice(2);

          return dispatch(uploadFiles({tmpId, type, id, file, path, filePath: file.name}));
        })
      );

      setLoaderButtonCompleted();
      setShowModal(files.length);
    } catch (error) {
      console.error(error);
      setLoaderButtonError();
    }
  }, [setLoaderButtonLoading, setLoaderButtonCompleted, dispatch, type, id, setLoaderButtonError]);

  if (!image) {
    return null;
    /**
     * (
      <div className="d-flex justify-content-center">
        <LoaderButton
          className="mr-3"
          defaultText={`Upload image`}
          // disabled={showAll || !selectedRows.length}
          loadingText="Generating..."
          loaderButtonStatus={loaderButtonStatus}
          completedText="Published!"
          // onClick={() => handleAction('Publish')}
        />
      </div>
    );
     */
  }
  console.log('image', presets);
  return (
    <div
      id="image-crop"
      style={{
        backgroundColor: 'rgba(0,0,0,0.6)',
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        margin: 'auto',
        zIndex: 1000,
        padding: '2.5% 5%',
      }}
      className="overflow-auto"
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          padding: '2.5% 5%',
          borderRadius: '15px',
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className=" my-first-step">ORIGINAL TITLE</p>
            <h3>{asset.displayName}</h3>
          </div>
          <div className="d-flex">
            <Button className="loader-button gray-600-background " onClick={onClose}>
              Cancel
            </Button>
            <LoaderButton
              defaultText="Generate"
              className="mr-4 ml-4 joyride-generate"
              loadingText="Generating..."
              loaderButtonStatus={loaderButtonStatus}
              completedText="Generated!"
              onClick={() => generateCroppedImages()}
            />
          </div>
        </div>
        {/* state?.preset?.id  */}
        {image && type && id && (
          <SmartCrop
            ref={generateRef}
            key={preset.id}
            initialState={initialState}
            preset={preset}
            presets={presets}
            image={image}
            onPresetChange={newPreset => {
              setPreset(newPreset);
            }}
            generateCroppedImages={generateCroppedImages}
          />
        )}
      </div>

      <ConfirmationModal
        show={showModal !== null}
        title="Generating finished"
        text={`All done, ${showModal} images have been cropped!
All exported files can be found in the folder: Smartcropper exports`}
        continueText="Continue editing image"
        cancelText="Close screen"
        cancelColor="disabled"
        onClose={() => {
          setShowModal(null);
        }}
        onContinue={() => {
          setShowModal(null);
        }}
        onCancel={() => {
          setShowModal(null);
          onClose();
        }}
      />
    </div>
  );
};
export default ImageCrop;
