import React from 'react';
import {Dropdown} from 'react-bootstrap';
import CustomToggle from '../../../../../../helpers/customToggle';
import {AD_LIMIT, editAdStates} from '../../../consts';
import Access from '../../../../../../helpers/access';

const EditThreeDot = props => {
  const {setup, selectedRows, onEventClicked, numOfAds, parents} = props;
  return (
    <Access type="dco" parents={parents}>
      {({isAllowed}) => (
        <Dropdown style={{paddingLeft: '18px'}}>
          <Dropdown.Toggle as={CustomToggle} disabled={!selectedRows.length} />
          <Dropdown.Menu className="rounded-0">
            {isAllowed('create') && (
              <Dropdown.Item
                disabled={selectedRows.length + numOfAds > AD_LIMIT}
                className="primary-text"
                onClick={() => onEventClicked('duplicate')}
              >
                Duplicate {selectedRows.length} ad(s)
              </Dropdown.Item>
            )}
            {setup && setup?.adColumns?.State && isAllowed('edit') && (
              <Dropdown.Item
                className="primary-text"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Dropdown>
                  <Dropdown.Toggle as="span">
                    <span>Change state of {selectedRows.length} ad(s)</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="rounded-0" style={{marginLeft: '10rem'}}>
                    {editAdStates.map(state => (
                      <Dropdown.Item
                        className="primary-text"
                        key={state}
                        onClick={() => onEventClicked('state', state)}
                      >
                        {state}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Item>
            )}
            {isAllowed('edit') && (
              <Dropdown.Item className="primary-text" onClick={() => onEventClicked('update')}>
                Update image versions
              </Dropdown.Item>
            )}
            {isAllowed('delete') && (
              <Dropdown.Item className="danger-text" onClick={() => onEventClicked('archive')}>
                Delete {selectedRows.length} ad(s)
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Access>
  );
};

export default EditThreeDot;
