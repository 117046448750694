import React from 'react';
import {Dropdown} from 'react-bootstrap';
import CustomToggle from '../../../../../../helpers/customToggle';

const ShotThreeDot = props => {
  const {selectedRows, onEventClicked, duplicateDisabled} = props;

  return (
    <Dropdown style={{paddingLeft: '18px'}}>
      <Dropdown.Toggle as={CustomToggle} disabled={!selectedRows.length} />
      <Dropdown.Menu className="rounded-0">
        <Dropdown.Item
          className="primary-text"
          onClick={() => onEventClicked('duplicate')}
          disabled={!!duplicateDisabled}
        >
          Duplicate {selectedRows.length} shot(s)
        </Dropdown.Item>
        <Dropdown.Item className="danger-text" onClick={() => onEventClicked('delete')}>
          Delete {selectedRows.length} shot(s)
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShotThreeDot;
