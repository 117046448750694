/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal, Form, Overlay, Tooltip} from 'react-bootstrap';

import {shareTypes} from './consts';
import {copyToClipboard} from '../../../../../helpers/copyToClipboard';
import TooltipHelper from '../../../../../helpers/tooltip';
import {Switch} from 'components';

const ShareEditModal = ({
  onClose = () => {},
  onSave = () => {},
  show,
  shareState = {},
  setShareState,
}) => {
  const refInfo = useRef(null);
  // info about state of copied to clipboard
  const [showInfo, setShowInfo] = useState(false);

  const {settings} = shareState || {};

  const handleTitleChange = e => {
    setShareState(old => {
      return {
        ...old,
        title: e.target.value,
      };
    });
  };

  const onInfoClicked = () => {
    setShowInfo(true);
    setTimeout(() => {
      setShowInfo(false);
    }, 3000);
  };

  useEffect(() => {
    console.log('share state changed', shareState);
    if (shareState?.settings?.autoUpdate) onSave(true);
  }, [shareState?.settings?.autoUpdate, shareState?.title]);

  return (
    <Modal show={show} onHide={onClose} size="md" centered>
      <Modal.Header
        style={{justifyContent: 'end', padding: '0', paddingTop: '0.5rem', border: 'none'}}
      >
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h4 className="m-0">Title</h4>
          <div className="d-flex align-items-center">
            <div style={{marginRight: 10}}>
              <Switch
                label="auto update"
                checked={settings?.autoUpdate}
                handleChange={() => {
                  setShareState(old => ({
                    ...old,
                    settings: {...old.settings, autoUpdate: !old.settings.autoUpdate},
                  }));
                }}
              />
            </div>
            {!settings?.autoUpdate && (
              <Button className="dco-button primary-background" onClick={onSave}>
                Update
              </Button>
            )}
            <TooltipHelper
              content="Shared pages show the most recent versions of the ads. To ensure that deleted or newly added ads are properly reflected, it is required to refresh the shared pages
."
            >
              <span
                className="icon icon-questionmark info-background ml-2  cursor-pointer  "
                style={{
                  height: '16px',
                  width: '16px',
                  padding: '0.375rem',
                }}
              />
            </TooltipHelper>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Form.Control type="text" value={shareState?.title} onChange={handleTitleChange} />

          {/* <span className="ml-4 icon icon-chain gray-600-background" tabIndex={0} role="button" /> */}
        </div>
        <h4 className="mt-3">Settings</h4>
        <div className="d-flex align-items-center">
          <div className="mt-3">
            <Form.Check
              inline
              label="All ads in project"
              disabled
              name="group1"
              type="radio"
              value={shareTypes.ALL}
              checked={settings?.type === shareTypes.ALL}
              id={`inline-${'radio'}-1`}
            />
            <Form.Check
              inline
              label="Selected ads"
              disabled
              name="group1"
              type="radio"
              value={shareTypes.SELECTED}
              checked={settings?.type === shareTypes.SELECTED}
              id={`inline-${'radio'}-2`}
            />
            <Form.Check
              inline
              disabled
              label="Applied filters"
              name="group1"
              type="radio"
              value={shareTypes.FILTERED}
              checked={settings?.type === shareTypes.FILTERED}
              id={`inline-${'radio'}-3`}
            />
          </div>
        </div>
        {settings?.filters && (
          <>
            <h4 className="mt-3">Filters</h4>

            <div className="d-flex flex-column">
              {Object.values(settings?.filters)
                .filter(f => f?.value)
                .map(filter => {
                  return (
                    <div
                      className="d-flex mb-2"
                      style={{
                        width: '50%',
                        borderRadius: '0.3125rem',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 0.4rem',
                        backgroundColor: '#f5f5f5',
                      }}
                    >
                      <span>{filter?.label}:</span>
                      <span
                        style={{
                          padding: '0.4rem 0.8rem',
                          borderTopRightRadius: '0.3125rem',
                          borderBottomRightRadius: '0.3125rem',
                          backgroundColor: '#081ee0',
                          width: '40%',
                          color: 'white',
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        {Array.isArray(filter?.value)
                          ? filter?.value?.filter(v => v.enabled).length
                          : filter?.value?.toString()}
                      </span>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        <h4 className="mt-3">Link</h4>
        <div className="d-flex align-items-center">
          <Form.Control disabled type="text" value={shareState?.link} />

          <Overlay target={refInfo.current} show={showInfo} placement="top">
            {overlayProps => (
              <Tooltip id="overlay-example" {...overlayProps}>
                Successfully copied to clipboard
              </Tooltip>
            )}
          </Overlay>

          <span
            className="mx-2 icon icon-chain gray-600-background"
            tabIndex={0}
            role="button"
            ref={refInfo}
            onClick={() => {
              copyToClipboard(shareState?.link);
              onInfoClicked();
            }}
          />
          <a target="_blank" href={shareState?.link} rel="noreferrer">
            <span
              className="mx-1 icon icon-new-window gray-600-background"
              // onClick={onNewWindowClicked}
              tabIndex={0}
              role="button"
            />
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareEditModal;
