import React, {useRef} from 'react';

const Switch = ({label, checked, handleChange, disabled = false}) => {
  const checkboxRef = useRef(null);

  return (
    <div role="button" tabIndex={0} className="switch" onClick={() => checkboxRef?.current.click()}>
      <input
        type="checkbox"
        ref={checkboxRef}
        checked={!!checked}
        disabled={disabled}
        onChange={() => handleChange(!checked)}
        id="switch"
      />
      <p />
      {label}
    </div>
  );
};

export default Switch;
