import React from 'react';
import Avatar from './avatar';

const Users = ({users = [], show, allowAdd, shownRoles = []}) => (
  <div className="user-accesses" onClick={() => show(true)}>
    {allowAdd && <div className="icon icon-plus-blue mr-3" />}
    {users
      .filter(
        ({status, role, user}) =>
          status === 'ACCEPTED' && user && user?.active && shownRoles.includes(role)
      )
      .map(item => (
        <Avatar
          type="user"
          id={item.userId}
          key={item.userId}
          className="avatar user-empty-thumb rounderd"
          disableClick
        />
      ))}
  </div>
);

export default Users;
