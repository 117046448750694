import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tooltip from './tooltip';

const BlockError = ({asyncState = {}, size}) => {
  if (!Object.keys(asyncState).length) return null;
  return (
    <div
      className={`w-100 h-100 position-absolute d-flex justify-content-center align-items-center ${asyncState.fetching &&
        'loading'} ${asyncState.error && 'border-danger'}`}
      style={{bottom: '100%', top: 0, zIndex: 1002}}
    >
      <IconError asyncState={asyncState} size={size} />
    </div>
  );
};

const IconError = ({asyncState = {}, className, filed, showFirstMessage, fontSize = 16}) => {
  if (!Object.keys(asyncState).length) return null;
  if (asyncState.fetching)
    return <FontAwesomeIcon icon="spinner" className={`${className}`} style={{fontSize}} spin />;
  return (
    <Tooltip
      content={
        <ErrorMessages
          error={asyncState.error}
          filed={filed}
          showFirstMessage={showFirstMessage}
          className="p-0 m-1 bg-transparent border-0"
        />
      }
    >
      <FontAwesomeIcon
        icon="info-circle"
        className={`${className} text-danger p-0`}
        style={{fontSize}}
      />
    </Tooltip>
  );
};

const ErrorMessages = ({
  error,
  filed = null,
  showFirstMessage = true,
  className = '',
  ignore = [],
  only = [],
}) => {
  if (!error) return null;
  if (ignore.includes(error.status)) return null;
  if (only.length && !only.includes(error.status)) return null;
  if (error.status === 422) {
    if (filed && showFirstMessage && error.data[filed] && error.data[filed].length > 0)
      return (
        <div className={`${className} alert alert-danger text-left`}>{error.data[filed][0]}</div>
      );
    else if (filed && !showFirstMessage && error.data[filed] && error.data[filed].length > 0)
      return error.data[filed].map((msg, i) => (
        <div key={i} className={`${className} alert alert-danger text-left`}>
          {msg}
        </div>
      ));
    else if ((!filed || !showFirstMessage) && error.data[filed])
      return (
        <div className={`${className} alert alert-danger`}>
          {Object.keys(error.data).map(k =>
            error.data[k].map((msg, i) => (
              <div key={i} className="w-100 text-left">
                {msg}
              </div>
            ))
          )}
        </div>
      );
    else if (!filed && showFirstMessage)
      return (
        <div className={`${className} alert alert-danger`}>
          {Object.keys(error.data).map(k =>
            error.data[k].map((msg, i) => (
              <div key={i} className="w-100 text-left">
                {msg}
              </div>
            ))
          )}
        </div>
      );
    else return null;
  }
  console.log(error);
  return (
    <div className={`${className} alert alert-danger`}>
      <span className="font-weight-bold mr-2 text-left">{error.status}</span>
      <span>
        {(typeof error.data === 'string' && error.data) || error.message || error.statusText}
      </span>
    </div>
  );
};

export {BlockError, IconError, ErrorMessages};
