import React from 'react';
import CreativePreview from './creativePreview';

function FilePreviw({asset, version, setVersion}) {
  const multipleVariants = asset?.variants?.length > 1;
  const ext = asset.displayName
    .split('.')
    .pop()
    .toLowerCase();
  if (asset.kind === 'CREATIVE') {
    return (
      <div className="file-preview d-flex overflow-auto justify-content-start align-items-start">
        {asset.variants.map(variant => (
          <CreativePreview
            key={variant._id}
            creative={variant}
            multipleVariants={multipleVariants}
            setVersion={setVersion}
          />
        ))}
      </div>
    );
  }
  if (asset.kind === 'CREATIVESETUP') {
    return (
      <div className="file-preview d-flex overflow-auto justify-content-start align-items-start">
        <CreativePreview key={asset._id} creative={asset} />
      </div>
    );
  }
  if (asset.kind === 'URL') {
    return (
      <div className="file-preview d-flex justify-content-center align-items-center">
        {asset.versions[version]?.embed ? (
          <iframe
            title="preview"
            className="border-0"
            src={`${asset.versions[version]?.url}`}
            style={{width: '100%', height: '100%', minHeight: '80vh', padding: 0}}
          />
        ) : (
          <a href={asset.versions[version]?.url} target="_blank">
            {asset.versions[version]?.url}
          </a>
        )}
      </div>
    );
  }
  if (ext === 'pdf') {
    return (
      <div className="file-preview d-flex justify-content-center align-items-center">
        <iframe
          title="preview"
          className="border-0"
          src={`${process.env.REACT_APP_S3_URL}/${asset.versions[version]?.name}`}
          style={{width: '100%', height: '100%', minHeight: '600px', padding: 0}}
        />
      </div>
    );
  }
  if (asset.mime?.indexOf('image/') !== -1 && !['image/vnd.adobe.photoshop'].includes(asset.mime)) {
    return (
      <div>
        {/* <button type="button">smartCrop</button> */}
        <div
          className={`file-preview ${
            ['png', 'svg'].includes(ext) ? 'chess' : ''
          } d-flex justify-content-center align-items-center`}
        >
          <img
            src={`${process.env.REACT_APP_S3_URL}/${asset.versions[version]?.name}`}
            alt="preview"
            id="filePreviewImg"
          />
        </div>
      </div>
    );
  }
  if (asset.mime.indexOf('video/') !== -1) {
    return (
      <div className="file-preview d-flex justify-content-center align-items-center">
        <video
          style={{width: '100%', maxHeight: '600px'}}
          controls
          key={version}
          id="filePreviewVideo"
        >
          <source
            src={`${process.env.REACT_APP_S3_URL}/${asset.versions[version]?.name}`}
            type="video/mp4"
          />
        </video>
      </div>
    );
  }
  return (
    <div className="file-preview d-flex justify-content-center align-items-center">
      <a href={`${process.env.REACT_APP_S3_URL}/${asset.versions[version]?.name}`} target="_blank">
        <img src={`${process.env.PUBLIC_URL}/icons/download.svg`} width="131" />
      </a>
    </div>
  );
}

export default FilePreviw;
