import React, {useEffect, useState} from 'react';

const TextArea = ({onSubmit, readOnly, showButtons, defaultValue}) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(value);
  };

  const showButtonsIfNeeded = () => {
    return showButtons && value !== defaultValue;
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <textarea
        className="w-100"
        rows={7}
        readOnly={readOnly}
        value={value}
        onChange={handleChange}
      />
      {showButtonsIfNeeded() && (
        <div>
          <button className="btn btn-primary mr-2" type="button" onClick={handleSubmit}>
            Save
          </button>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => setValue(defaultValue)}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default TextArea;
