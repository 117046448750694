/* eslint-disable import/no-unresolved */
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {useDispatch} from 'react-redux';
import {Button} from 'react-bootstrap';

import {agHelpers} from 'pages/project/DcoProject/Tabs/agHelpers';
import {updateAd} from 'pages/project/DcoProject/slice';
import {changeValueInSelectedAds} from 'pages/project/DcoProject/helpers';
import {defaultColDef} from '../consts';
import SharedReviewTableAd from './SharedReviewTableAd';
import {updateRowDataWithAdState, updateSharedAdsState} from '../helpers';

const PrivateShareTable = ({columnDefs, rowData, setRowData, setSharedData}) => {
  const gridRef = useRef();
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const {deselectAll, setColumnWidth, getSelectedRows} = useMemo(() => agHelpers(gridRef), [
    gridRef,
  ]);

  const selectedRowsFilter = useMemo(() => selectedRows.map(({sharedAdData, ...rest}) => rest), [
    selectedRows,
  ]);

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const handleSelectedChange = useCallback(() => setSelectedRows(getSelectedRows()), [
    getSelectedRows,
  ]);

  const handleAction = useCallback(
    async state => {
      try {
        const {payload: updatedAds} = await dispatch(
          updateAd(changeValueInSelectedAds(selectedRowsFilter, 'State', state))
        );

        setRowData(old => {
          return updateRowDataWithAdState(old, updatedAds);
        });
        setSharedData(old => {
          return {...old, ads: updateSharedAdsState(old.ads, updatedAds)};
        });
      } catch (error) {
        console.error(error);
      }
      deselectAll();
      setSelectedRows([]);
    },
    [deselectAll, dispatch, selectedRowsFilter, setRowData, setSharedData]
  );

  return (
    <div className="pt-5">
      <div className="d-flex mb-4 align-items-center">
        <Button
          disabled={!selectedRows.length}
          type="button"
          className="dco-button success"
          onClick={() => handleAction('Approved')}
        >
          Approve ({selectedRows.length}) ads
        </Button>
        <Button
          disabled={!selectedRows.length}
          type="button"
          className="dco-button danger ml-3"
          onClick={() => handleAction('Rejected')}
        >
          Reject ({selectedRows.length}) ads
        </Button>
      </div>
      <div className="ag-theme-alpine banner">
        <AgGridReact
          masterDetail
          ref={gridRef}
          rowData={rowData}
          rowHeight={64}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          detailCellRenderer={SharedReviewTableAd}
          onRowSelected={handleSelectedChange}
          suppressRowClickSelection
          suppressRowTransform
          detailRowAutoHeight
          suppressContextMenu
          rowSelection="multiple"
          domLayout="autoHeight"
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default PrivateShareTable;
