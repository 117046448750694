// eslint-disable-next-line import/prefer-default-export
export const shareFilterData = (data, filters) =>
  data.filter(item => {
    let result = true;
    Object.values(filters).forEach(filt => {
      if (!result) {
        return;
      }

      const {field, value} = filt;

      if (!field || !value) {
        return;
      }

      if (field === 'Published State') {
        if (value === 'Never Published') {
          result = item.published?.PublishedState === '';
        } else {
          result = item?.published?.PublishedState === value;
        }
        if (result) {
          return;
        }
      }

      if (field === 'Search') {
        result = Object.values(item.columns).some(val => {
          return String(val)
            ?.toLowerCase()
            .includes(String(value)?.toLowerCase());
        });
        if (result) {
          return;
        }

        item.shots.forEach(shot => {
          shot.shotSizes.forEach(shotItem => {
            if (result) {
              return;
            }

            result = Object.values(shotItem).some(val =>
              String(val)
                ?.toLowerCase()
                .includes(String(value)?.toLowerCase())
            );
          });
        });
        return;
      }

      if (field === 'sizes') {
        result = true;
        return;
      }

      if (field in item.columns) {
        result = String(item.columns[field])
          ?.toLowerCase()
          .includes(String(value)?.toLowerCase());
        return;
      }
      item.shots.forEach(shot => {
        result = shot.shotSizes.some(shotItem => {
          if (field in shotItem) {
            return String(shotItem[field])
              ?.toLowerCase()
              .includes(String(value)?.toLowerCase());
          }
          return false;
        });
      });
    });

    return result;
  });

export function getCurrentDateFormatted() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();

  return `${dd}/${mm}/${yyyy}`;
}
