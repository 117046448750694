import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Attachment = ({tmp, file, state = {}, onDelete, paperclip = false}) => {
  // console.log(state);
  // if (state.deleted) return null;
  return (
    <span className="comment-attachment  d-flex align-items-center mb-2">
      {state.error && <FontAwesomeIcon icon="exclamation-circle" className="ml-2 text-danger" />}
      {state.fetching && <FontAwesomeIcon icon="spinner" spin className="mr-2" />}
      {file ? (
        <a
          target="_blank"
          href={`${process.env.REACT_APP_S3_URL}/${file.fileName}`}
          rel="noreferrer"
        >
          <div className="pb-0">
            {paperclip && <FontAwesomeIcon icon="paperclip" className="mr-2" />} {file.displayName}
          </div>
        </a>
      ) : (
        <div className="pb-0 text-dark">
          {paperclip && <FontAwesomeIcon icon="paperclip" className="mr-2" />} {tmp.name}
        </div>
      )}
      {!!(file && file._id && onDelete) && (
        <FontAwesomeIcon icon="times" className="ml-2 text-danger delete" onClick={onDelete} />
      )}
    </span>
  );
};
//
export default Attachment;
