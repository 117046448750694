import React, {useState} from 'react';
import {Button} from 'react-bootstrap';

import ImageCrop from './ImageCrop';

const ImageCropModal = props => {
  const {hide} = props;
  const [show, setShow] = useState(false);
  const onClose = () => {
    setShow(false);
    hide?.();
  };
  const onShow = () => {
    setShow(true);
  };
  return show ? (
    <ImageCrop {...props} onClose={onClose} />
  ) : (
    <Button
      onClick={onShow}
      className="loader-button brainsight white mb-3"
      style={{
        minHeight: '45px',
      }}
    >
      Smart Crop
    </Button>
  );
};

export default ImageCropModal;
