/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import {editAdStates} from '../consts';
import {
  AdCellRenderer,
  CellEditor,
  MasterSizeCell,
  PresetCellRenderer,
  ShareCellRenderer,
} from './components/TableComponents/AgCellRenderers';
import {findElementById} from '../helpers';
import {
  replaceElementInArray,
  replacePropertyInArray,
  replaceElementInArrayById,
} from '../../../brand/Dco/helpers';
import FileSelect from '../../../../helpers/FileSelect';

// ### Helper functions ###
const agDeselectAll = gridRef => gridRef?.current?.api?.deselectAll();

const agSetColumnWidth = gridRef => gridRef.current?.api?.sizeColumnsToFit();

const agGetSelectedRows = gridRef => gridRef?.current?.api?.getSelectedRows();

const agReloadTable = gridRef => gridRef?.current?.api?.redrawRows();

const agHandleFilterChange = gridRef => gridRef?.current?.api?.onFilterChanged();

export const agHelpers = gridRef => ({
  deselectAll: () => agDeselectAll(gridRef),
  setColumnWidth: () => agSetColumnWidth(gridRef),
  getSelectedRows: () => agGetSelectedRows(gridRef),
  reloadTable: () => agReloadTable(gridRef),
  handleFilterChange: () => agHandleFilterChange(gridRef),
});

// ### EDIT TAB ###
const editTabAgGridBaseDefaultColumnDefs = (key, column, setRowData, errors) => ({
  editable: true,
  field: key,
  cellRenderer: AdCellRenderer,
  cellRendererParams: {
    type: column.type,
    errors,
  },
  valueGetter: valueParams => valueParams.data.columns[key],
  valueSetter: valueParams => {
    const {id: rowIndex} = valueParams.node;
    setRowData(prevState => {
      return replaceElementInArray(prevState, rowIndex, {
        ...prevState[rowIndex],
        columns: {
          ...prevState[rowIndex].columns,
          [key]: valueParams.newValue,
        },
      });
    });

    return true;
  },
});

export const editTabAgGridColumns = (
  setup,
  setRowData,
  handleCellClicked,
  initialData,
  onSave,
  onCancel,
  errors
) => {
  const columns = [];
  console.log({errors}, 'inner');
  Object.entries(setup?.adColumns).forEach(([key, column]) => {
    if (key === 'Unique ID' || key === 'Ad ID') {
      return;
    }
    if (key === 'Ad Name') {
      columns.push({
        ...editTabAgGridBaseDefaultColumnDefs(key, column, setRowData),
        cellClass: 'dco-table-first-cell',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: 'left',
        minWidth: 250,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: AdCellRenderer,
          handleClick: handleCellClicked,
        },
      });
      return;
    }
    if (key === 'State') {
      columns.push({
        ...editTabAgGridBaseDefaultColumnDefs(key, column, setRowData),
        headerName: 'Review State',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: editAdStates,
          formatValue: value => value?.toUpperCase(),
          searchDebounceDelay: 500,
        },
      });
      columns.push({
        field: 'Published State',
        headerName: 'Published State',
        cellRenderer: AdCellRenderer,
        valueGetter: valueParams => valueParams.data?.published?.PublishedState,
      });
      return;
    }

    if (column.type === 'preset') {
      columns.push({
        ...editTabAgGridBaseDefaultColumnDefs(key, column, setRowData),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: column.options,
          formatValue: value => value?.toUpperCase(),
          searchDebounceDelay: 500,
        },
      });
      return;
    }

    if (column.type === 'dynamic') {
      columns.push({
        ...editTabAgGridBaseDefaultColumnDefs(key, column, setRowData),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: column.feedOptions,
          formatValue: value => value?.toUpperCase(),
          searchDebounceDelay: 500,
        },
      });
      return;
    }

    if (column.type === 'boolean') {
      columns.push({
        ...editTabAgGridBaseDefaultColumnDefs(key, column, setRowData),
        cellEditor: CellEditor,
        cellEditorParams: {
          type: column.type,
        },
      });
      return;
    }

    columns.push({
      ...editTabAgGridBaseDefaultColumnDefs(key, column, setRowData, errors),
    });
  });
  columns.push({
    field: '',
    pinned: 'right',
    maxWidth: 100,
    cellRenderer: AdCellRenderer,
    cellRendererParams: {
      initialData,
      type: 'actions',
      onSave,
      onCancel,
    },
  });
  return columns;
};

// ### SHOT TABLE  ###

const shotTableAgGridBaseDefaultColumnDefs = (k, rowIndex, setCurrentAd) => ({
  editable: true,
  field: k,
  headerName: k,

  valueGetter: valueParams => {
    return valueParams.data[k];
  },
  valueSetter: valueParams => {
    const {data: value} = valueParams;
    setCurrentAd(old => {
      const currentShot = {...old.shots[rowIndex - 1]};

      const updatedShotSizes = replacePropertyInArray(
        currentShot.shotSizes,
        currentShot.shotSizes.indexOf(value),
        valueParams.newValue || '',
        k
      );

      return {
        ...old,
        shots: replaceElementInArray(old.shots, rowIndex - 1, {
          ...currentShot,
          shotSizes: updatedShotSizes,
        }),
      };
    });
    return true;
  },
});

export const shotTableAgGridColumns = (
  setup,
  sizes,
  rowIndex,
  projectId,
  assets,
  setCurrentAd,
  setFillAllModal
) => {
  const columns = [
    {
      headerName: 'Size',
      // pinned: 'left',
      field: 'size',
      cellRenderer: MasterSizeCell,
      cellRendererParams: {
        sizes: setup.sizes,
      },
      valueGetter: valueParams => {
        const {data: value} = valueParams;
        const size = findElementById(sizes, value._id);

        return (
          assets?.files?.[size?.assetId]?.creativeSetupTitle || `${size?.width}x${size?.height}`
        );
      },
    },
  ];

  _.orderBy(Object.entries(setup.shotStructure), o => o?.[1]?.order).forEach(([k, v]) => {
    let cellDefs = shotTableAgGridBaseDefaultColumnDefs(k, rowIndex, setCurrentAd);
    if (v.type === 'preset') {
      cellDefs = {
        ...cellDefs,
        cellRenderer: PresetCellRenderer,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: v.options,
          formatValue: value => value?.toUpperCase(),
          searchDebounceDelay: 500,
        },
      };
    }
    if (v.type === 'image') {
      cellDefs = {
        ...cellDefs,
        field: k,
        editable: false,
        cellRenderer: FileSelect,
        cellRendererParams: {
          filesId: projectId,
          defaultValue: v.default,
          handleCallback: file => {
            if (file?.displayName.match(/([0-9]x[0-9])/)) {
              setFillAllModal(old => {
                return {
                  ...old,
                  imgName: file?.displayName,
                  path: file?.path,
                  key: k,
                  shotIndex: rowIndex - 1,
                };
              });
            }
          },
        },
      };
    }
    if (v.type === 'studioAsset') {
      cellDefs = {
        ...cellDefs,
        field: k,
        editable: false,
        cellRenderer: FileSelect,
        cellRendererParams: {
          filesId: projectId,
          defaultValue: v.default,
          img: false,
          handleCallback: file => {
            if (file?.displayName.match(/([0-9]x[0-9])/)) {
              setFillAllModal(old => {
                return {
                  ...old,
                  imgName: file?.displayName,
                  path: file?.path,
                  key: k,
                  shotIndex: rowIndex - 1,
                  type: 'studioAsset',
                };
              });
            }
          },
        },
      };
    }
    if (k === 'Maincopy') {
      cellDefs = {...cellDefs, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true};
    }

    columns.push({
      ...cellDefs,
    });
  });

  return columns;
};

// ### REVIEW   ###

export const reviewAgGridBaseDefaultColumnDefs = (k, column) => ({
  field: k,
  headerName: k,
  cellRendererParams: {
    type: column.type,
  },
  cellRenderer: AdCellRenderer,
  valueGetter: valueParams => valueParams.data.columns[k],
});

export const reviewAgGridColumns = setup => {
  const columns = [];
  Object.entries(setup?.adColumns).forEach(([key, column]) => {
    const cellDefs = reviewAgGridBaseDefaultColumnDefs(key, column);
    if (key === 'Unique ID' || key === 'Ad ID') {
      return;
    }
    if (key === 'Ad Name') {
      columns.push({
        ...cellDefs,
        cellClass: 'dco-table-first-cell',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: AdCellRenderer,
        },
      });
      return;
    }
    if (key === 'State') {
      columns.push({
        ...cellDefs,
        headerName: 'Review State',
      });
      columns.push({
        ...cellDefs,
        field: 'Published State',
        headerName: 'Published State',
        valueGetter: valueParams => valueParams.data?.published?.PublishedState,
      });
      return;
    }
    columns.push({
      ...cellDefs,
    });
  });

  return columns;
};
// ### PUBLISH   ###

export const publishAgGridColumns = () => [
  {
    field: 'Ad Name',
    cellClass: 'dco-table-first-cell',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: {
      innerRenderer: AdCellRenderer,
    },
    valueGetter: valueParams => valueParams.data.columns['Ad Name'],
  },
  {
    field: 'State',
    headerName: 'Review State',
    cellRenderer: AdCellRenderer,
    valueGetter: valueParams => valueParams.data.columns?.State,
  },
  {
    field: 'Published State',
    headerName: 'Published State',
    cellRenderer: AdCellRenderer,
    valueGetter: valueParams => valueParams.data?.published?.PublishedState,
  },
  {
    field: 'Last Changed',
    cellRenderer: AdCellRenderer,
    valueGetter: valueParams => valueParams.data?.published?.lastChange?.slice(0, -5),
  },
  {
    field: 'Last Published',
    cellRenderer: AdCellRenderer,
    valueGetter: valueParams => valueParams.data?.published?.lastPublish?.slice(0, -5),
  },
  {
    field: 'Last Edited By',
    cellRenderer: AdCellRenderer,
    valueGetter: valueParams => valueParams.data?.published?.lastEditBy,
  },
];

// ### EDIT ###

const editAgGridBaseDefaultColumnDefs = (
  k,
  v,
  masterSizeId,
  handleChange,
  callback = () => {}
) => ({
  editable: true,
  field: k,
  headerName: k,
  headerComponentParams: {note: v.note},

  valueGetter: valueParams => {
    const {shotSizes} = valueParams.data;

    const masterSize = findElementById(shotSizes, masterSizeId);
    return masterSize?.[k] || '';
  },
  valueSetter: valueParams => {
    const {id: shotIndex} = valueParams.node;

    const updatedShot = {
      ...valueParams.data,
      shotSizes: valueParams.data.shotSizes.map(shotSize => ({
        ...shotSize,
        [k]: valueParams.newValue || '',
      })),
    };

    handleChange(old => {
      return {
        ...old,
        shots: replaceElementInArrayById(old.shots, updatedShot),
      };
    });

    callback(shotIndex);

    return true;
  },
});

export const editAgGridColumns = (
  setup,
  projectId,
  masterSizeId,
  handleChange,
  setFillAllModal
) => {
  const columns = [
    {
      headerName: '',
      field: 'shotId',
      sortable: true,
      rowDrag: true,
      maxWidth: 110,
      // pinned: 'left',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      valueGetter: () => {
        return '';
      },
      cellRenderer: 'agGroupCellRenderer',
      cellStyle: () => ({
        display: 'flex',
        alignItems: 'center',
      }),
    },
  ];

  _.orderBy(Object.entries(setup.shotStructure), o => o?.[1]?.order).forEach(([k, v]) => {
    let cellDefs = {};
    if (v.type === 'preset') {
      cellDefs = {
        cellRenderer: PresetCellRenderer,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: v.options,
          formatValue: value => value?.toUpperCase(),
          // cellRenderer: MyCellRenderer,
          searchDebounceDelay: 500,
        },
      };
    }

    if (k === 'Maincopy') {
      cellDefs = {...cellDefs, cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true};
    }
    if (v.type === 'image') {
      columns.push({
        ...editAgGridBaseDefaultColumnDefs(k, v, masterSizeId, handleChange, shotIndex => {
          setFillAllModal(old => {
            return {
              ...old,
              shotIndex,
            };
          });
        }),
        editable: false,
        cellRenderer: FileSelect,
        cellRendererParams: {
          filesId: projectId,
          defaultValue: v.default,
          handleCallback: imgFile => {
            if (imgFile?.displayName.match(/([0-9]x[0-9])/)) {
              setFillAllModal(old => {
                return {
                  ...old,
                  imgName: imgFile?.displayName,
                  path: imgFile?.path,
                  key: k,
                };
              });
            }
          },
        },
      });
      return;
    }

    if (v.type === 'studioAsset') {
      columns.push({
        ...editAgGridBaseDefaultColumnDefs(k, v, masterSizeId, handleChange, shotIndex => {
          setFillAllModal(old => {
            return {
              ...old,
              shotIndex,
            };
          });
        }),
        editable: false,
        cellRenderer: FileSelect,
        cellRendererParams: {
          filesId: projectId,
          defaultValue: v.default,
          img: false,
          handleCallback: imgFile => {
            if (imgFile?.displayName.match(/([0-9]x[0-9])/)) {
              setFillAllModal(old => {
                return {
                  ...old,
                  imgName: imgFile?.displayName,
                  path: imgFile?.path,
                  key: k,
                  type: 'studioAsset',
                };
              });
            }
          },
        },
      });
      return;
    }

    columns.push({
      ...editAgGridBaseDefaultColumnDefs(k, v, masterSizeId, handleChange),
      ...cellDefs,
    });
  });

  return columns;
};

// ### PROJECT TAB   ###

const shareTableAgGridBaseDefaultColumnDefs = (key, column) => ({
  editable: false,
  cellRenderer: ShareCellRenderer,
  cellRendererParams: {
    type: column.type,
  },
  valueGetter: valueParams => valueParams.data[key],
});

export const shareTableAgGridColumns = handleClick => [
  {
    field: 'Title',
    cellClass: 'dco-table-first-cell',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    cellRenderer: 'agGroupCellRenderer',
    pinned: 'left',
    cellRendererParams: {
      innerRenderer: ShareCellRenderer,
      handleClick,
      type: 'title',
    },
    valueGetter: valueParams => valueParams.data.title,
  },
  {
    field: '',
    pinned: 'left',
    resizable: false,
    maxWidth: 50,
    ...shareTableAgGridBaseDefaultColumnDefs('link', {type: 'copy'}),
  },
  {
    field: 'Settings',
    ...shareTableAgGridBaseDefaultColumnDefs('filters', {type: 'text'}),
  },
  {
    field: 'Comments Active / Resolved',
    ...shareTableAgGridBaseDefaultColumnDefs('totalComments', {type: 'comment'}),
  },

  // {
  //   field: 'Active',
  //   ...shareTableAgGridBaseDefaultColumnDefs('active', {type: 'boolean'}),
  // },
  // {
  //   field: 'Viewed',
  //   headerComponentParams: {
  //     template: '<span class="icon icon-eye-blue text-center inverted-ag-black "></span>',
  //   },

  //   ...shareTableAgGridBaseDefaultColumnDefs('viewed', {type: 'text'}),
  // },
  {
    field: 'Last Changed By',

    ...shareTableAgGridBaseDefaultColumnDefs('lastChangedBy', {type: 'text'}),
  },
  // {
  //   field: 'Date Updated',

  //   ...shareTableAgGridBaseDefaultColumnDefs('dateUpdated', {type: 'text'}),
  // },
  {
    field: 'Link',
    minWidth: 200,
    ...shareTableAgGridBaseDefaultColumnDefs('link', {type: 'link'}),
  },
];
