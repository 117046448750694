/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
import React, {useEffect, useState, useMemo, useRef} from 'react';
import Dropzone from 'react-dropzone';
import {useDispatch} from 'react-redux';
import Select from 'react-select';
import {Button} from 'react-bootstrap';

import {isNumeric} from '../pages/brand/Dco/helpers';
import {initialHtmlForm} from '../pages/brand/Assets/Templates/consts';
import {updateCreative, uploadCreative} from '../pages/file/slice';

const CreativeForm = ({form, onClose, submitCallbackFn, brandId}) => {
  const customStyles = {
    dropdownIndicator: provided => ({
      ...provided,
      padding: '0 4px',
    }),
    control: () => ({
      backgroundColor: '#fdfffe',
      backgroundClip: 'padding-box',
      border: '1px solid #eaeff1',
      borderRadius: '0.3125rem',
      cursor: 'text',
      display: 'flex',
      alignItems: 'center',
      minWidth: 200,
      maxWidth: 500,
    }),
    clearIndicator: () => ({
      padding: '0',
      paddingRight: '8px',
    }),
  };

  const [creativeForm, setCreativeForm] = useState(initialHtmlForm);
  const [isFolderVisible, setIsFolderVisible] = useState(false);
  const isFolderChanged = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPath, setLastPath] = useState(false);

  const dispatch = useDispatch();

  const isEdit = useMemo(() => {
    return !!form?._id;
  }, [form]);

  const handleCreativeFormChange = e => {
    setCreativeForm(old => ({...old, [e.target.name]: e.target.value}));
  };

  const getPreferredHTMLFileName = fileNames => {
    const htmlFiles = fileNames.filter(fileName => fileName.endsWith('.html'));

    switch (true) {
      case htmlFiles.includes('index.html'):
        return 'index.html';
      case htmlFiles.includes('dco.html'):
        return 'dco.html';
      default:
        return htmlFiles.length > 0 ? htmlFiles[0] : null;
    }
  };

  const handleSubmit = async e => {
    setIsLoading(true);
    e.preventDefault();

    let editForm;

    if (isEdit) {
      if (isFolderChanged.current) {
        editForm = {
          id: creativeForm._id,
          creativeTitle: creativeForm.creativeTitle,
          width: creativeForm.width,
          height: creativeForm.height,
          indexFile: creativeForm.indexFile,
          files: creativeForm.files,
          tags: [],
        };
      } else {
        editForm = {
          id: creativeForm._id,
          creativeTitle: creativeForm.creativeTitle,
          width: creativeForm.width,
          height: creativeForm.height,
          indexFile: creativeForm.indexFile,
          tags: [],
        };
      }

      const params = {type: 'brand', id: creativeForm._id, form: editForm};
      await dispatch(updateCreative(params));
      if (submitCallbackFn) {
        submitCallbackFn();
      }

      isFolderChanged.current = false;
      setCreativeForm(initialHtmlForm);
      setIsLoading(false);
      return;
    }

    try {
      const params = {type: 'brand', id: brandId, form: creativeForm};
      await dispatch(uploadCreative({...params}));
      if (submitCallbackFn) {
        submitCallbackFn();
      }
      setCreativeForm(initialHtmlForm);
    } catch (err) {
      console.log('upload creative error: ', err);
    }
    setIsLoading(false);
  };

  const handleFolderChange = files => {
    const [size] = files[0].path.split('/');
    setLastPath(size);
    const fileOptions = Object.values(files).map(f => f.name);
    const defaultFile = getPreferredHTMLFileName(fileOptions);
    console.log('defaultFile', defaultFile);

    // console.log('path:', files[0].path, 'numbers', files[0].path.match(/\d+/g));
    const dimensionCandidates = files[0]?.path?.match(/\d+/g)?.filter(v => v >= 50 && v < 1950);

    setCreativeForm(old => ({
      ...old,
      displayName: old.creativeTitle ? old.creativeTitle : size,
      creativeTitle: old.creativeTitle ? old.creativeTitle : size,
      width: dimensionCandidates?.[0] ? dimensionCandidates[0] : '',
      height: dimensionCandidates?.[1] ? dimensionCandidates[1] : '',
      fileOptions,
      files,
      indexFile: defaultFile,
    }));

    setIsFolderVisible(true);
    isFolderChanged.current = true;
  };

  const handleFileChange = e => {
    setCreativeForm(old => ({...old, indexFile: e.value}));
  };

  useEffect(() => {
    if (!form) return;
    setCreativeForm(form);
  }, [form]);

  return (
    <>
      {isFolderVisible && lastPath && <p>Selected folder: {lastPath}</p>}
      <form className="creative-form my-3 gap-1">
        <div className="d-flex gap-1  align-items-center">
          <Dropzone onDrop={files => handleFolderChange(files)}>
            {({getRootProps, getInputProps}) => (
              <div {...getRootProps()} className="creative-form-wrapper">
                <input
                  {...getInputProps()}
                  directory=""
                  webkitdirectory=""
                  mozdirectory=""
                  style={{display: 'none'}}
                />
                <div className="icon icon-folder-blue " />
              </div>
            )}
          </Dropzone>
          <input
            className="form-control"
            name="creativeTitle"
            placeholder="Creative Title"
            onChange={handleCreativeFormChange}
            value={creativeForm.creativeTitle}
          />
        </div>
        <div className="d-flex gap-1 ">
          <input
            className="form-control"
            name="width"
            placeholder="Width"
            onChange={handleCreativeFormChange}
            value={creativeForm.width}
          />
          <input
            className="form-control"
            name="height"
            placeholder="Height"
            onChange={handleCreativeFormChange}
            value={creativeForm.height}
          />
        </div>
        <Select
          value={
            creativeForm.indexFile
              ? [{value: creativeForm.indexFile, label: creativeForm.indexFile}]
              : []
          }
          styles={customStyles}
          options={creativeForm.fileOptions?.map(f => ({value: f, label: f}))}
          onChange={handleFileChange}
          placeholder="Select primary HTML file"
        />
        <div className="d-flex gap-1 ">
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={
              !creativeForm.creativeTitle ||
              !creativeForm.height ||
              !creativeForm.width ||
              !creativeForm.indexFile ||
              isLoading
            }
          >
            Upload
          </Button>
          <Button className="btn btn-secondary" type="button" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default CreativeForm;
