import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Tabs, Tab, Button, Modal} from 'react-bootstrap';
import {connect} from 'react-redux';

import _ from 'lodash';
import {OverviewTab, EditTab, ReviewTab, PublishTab, AssetsTab, ProjectTab} from '../Tabs';
import TooltipHelper from '../../../../helpers/tooltip';
import {useLastChangedUser} from '../../../../hooks/useLastChangedUser';
import {ShareModal, ShareModalCopy} from './SharedPage';
import {createSharedPage, getSharedPages} from './SharedPage/slice';
import {initialShareState} from './SharedPage/consts';

const DcoHeader = props => {
  const {
    match,
    ad,
    tab,
    setTab,
    currentAd,
    dispatch,
    parents,
    assets,
    setCurrentAd,
    selectedSetup,
  } = props;

  const [shareState, setShareState] = useState(initialShareState);
  const [showCopyModal, setShowCopyModal] = useState('');
  const shareRef = useRef({
    overview: useRef({}),
    edit: useRef({}),
    review: useRef({}),
    publish: useRef({}),
  });
  const {preloadUsers} = useLastChangedUser();
  const [showChangesModal, setShowChangesModal] = useState(false);

  const hasAdsInReview = useMemo(() => ad.items.some(a => a?.columns?.State === 'In review'), [
    ad.items,
  ]);

  const hasAdsToPublish = useMemo(
    () =>
      ad.items.some(
        a => a?.columns?.State === 'Approved' && a?.published?.PublishedState !== 'Published'
      ),
    [ad.items]
  );

  useEffect(() => {
    (async () => {
      const ids = [...new Set(ad.items.map(item => item.published?.lastEditBy))];
      preloadUsers(ids);
    })();
  }, [ad.items, preloadUsers]);

  const handleShareClicked = () => {
    const {current: refObj} = shareRef;
    if (!refObj[tab]?.current) {
      setShareState(old => ({
        ...old,
        filteredAds: [],
        selectedAds: [],
      }));
      return;
    }
    const data = refObj[tab].current.getData();
    switch (tab) {
      case 'overview':
        setShareState(old => ({
          ...old,
          filteredAds: data?.filteredData,
          settings: {
            ...old.settings,
            filters: data?.filters,
          },
          selectedAds: [],
        }));

        break;

      default:
        setShareState(old => ({
          ...old,
          selectedAds: data,
          filteredAds: [],
        }));

        break;
    }
  };

  const handleShareConfirmed = async adsToShare => {
    const {_id: setup} = selectedSetup.setup;
    if (!setup || !adsToShare.length || !parents) {
      return;
    }

    const settings =
      tab === 'overview' ? shareState.settings : _.omit(shareState.settings, 'filters');

    const form = {
      parents,
      setup,
      settings,
      ads: adsToShare.map(a => a._id),
      title: shareState.title,
    };

    try {
      const res = await dispatch(createSharedPage(form));
      await dispatch(getSharedPages({parents}));
      setShareState(initialShareState);
      const publicId = res?.payload?.publicId;
      if (publicId) {
        setShowCopyModal(`https://${process.env.REACT_APP_API_DOMAIN}/shared/${publicId}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-end align-items-center ">
        <span onClick={() => setShowChangesModal(true)} className="text-right gray-600-text mr-4">
          {selectedSetup.setup.setupName}
        </span>
        <Button
          className="dco-button d-flex justify-content-between align-items-center info-background"
          onClick={handleShareClicked}
        >
          <span className="icon icon-redo bg-white mr-2" />
          Share
        </Button>
      </div>
      <div className="dco-tabs">
        <Tabs
          activeKey={tab}
          onSelect={t => setTab(t)}
          className="bts-tabs dco-project-tabs mb-3 align-items-center "
        >
          <Tab eventKey="overview" title="Overview">
            <OverviewTab
              setup={selectedSetup.setup}
              assets={assets}
              setCurrentAd={setCurrentAd}
              setTab={setTab}
              currentAd={currentAd}
              parents={parents}
              ref={shareRef.current.overview}
            />
          </Tab>
          <Tab
            eventKey="edit"
            title={
              <div className="d-flex align-items-center">
                Edit
                {currentAd && (
                  <TooltipHelper content="Your ad is still in edit mode">
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      className="icon icon-warning warning-background ml-2 cursor-pointer"
                    />
                  </TooltipHelper>
                )}
              </div>
            }
          >
            <EditTab
              setup={selectedSetup.setup}
              dispatch={dispatch}
              parents={parents}
              currentAd={currentAd}
              assets={assets}
              setCurrentAd={setCurrentAd}
              tab={tab}
              setTab={setTab}
              ref={shareRef.current.edit}
            />
          </Tab>
          <Tab
            eventKey="review"
            title={
              <div className="d-flex align-items-center">
                Review
                {hasAdsInReview && (
                  <TooltipHelper content="There are ads in review">
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      className="icon icon-warning info-background ml-2 cursor-pointer"
                    />
                  </TooltipHelper>
                )}
              </div>
            }
          >
            <ReviewTab
              setup={selectedSetup.setup}
              assets={assets}
              setCurrentAd={setCurrentAd}
              setTab={setTab}
              parents={parents}
              ref={shareRef.current.review}
            />
          </Tab>
          <Tab
            eventKey="preview"
            title={
              <div className="d-flex  align-items-center">
                Publish
                {hasAdsToPublish && (
                  <TooltipHelper content="There are ads to be published">
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                      className="icon icon-warning primary-background ml-2 cursor-pointer"
                    />
                  </TooltipHelper>
                )}
              </div>
            }
          >
            <PublishTab
              setup={selectedSetup.setup}
              assets={assets}
              parents={parents}
              setCurrentAd={setCurrentAd}
              ref={shareRef.current.preview}
            />
          </Tab>
          <Tab eventKey="assets" title="Assets">
            <AssetsTab projectId={parents.projectId} brandId={parents.brandId} />
          </Tab>
          <Tab eventKey="project" title="Project">
            <ProjectTab match={match} parents={parents} projectId={parents.projectId} />
          </Tab>
          {/* <Tab eventKey="changelog" title="Changelog" disabled /> */}
        </Tabs>
      </div>
      <ShareModal
        ads={ad.items}
        shareState={shareState}
        setShareState={setShareState}
        show={!_.isEmpty(_.omit(shareState, ['title', 'settings']))}
        onClose={() => setShareState(initialShareState)}
        onShare={handleShareConfirmed}
      />

      <ShareModalCopy
        url={showCopyModal}
        show={!!showCopyModal}
        onClose={() => setShowCopyModal('')}
      />
      <Modal show={showChangesModal} onHide={() => setShowChangesModal(false)} size="md" centered>
        <Modal.Body>
          {selectedSetup?.setup?.changesPerVersion &&
            Object.keys(selectedSetup.setup.changesPerVersion)
              .map(version => {
                return (
                  <p>
                    {version} {JSON.stringify(selectedSetup.setup.changesPerVersion[version])}
                  </p>
                );
              })
              .reverse()
              .splice(0, 5)}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(store => ({
  ad: store.ad,
}))(DcoHeader);
