/* eslint-disable no-unused-expressions */
import React from 'react';
import {Modal, Button, ModalDialog} from 'react-bootstrap';
import DraggableModal from './DraggableModal';

const ConfirmationModal = props => {
  const {
    show,
    title,
    text,
    onContinue,
    onCancel,
    onClose,
    continueColor,
    continueText,
    cancelColor,
    cancelText,
    customBody = false,
    showFooter = true,
    isDraggable = false,
  } = props;

  return (
    <Modal
      show={show}
      dialogAs={isDraggable ? DraggableModal : ModalDialog}
      onHide={onClose || onCancel}
      centered
      backdrop={!isDraggable}
    >
      <Modal.Header
        style={{
          justifyContent: 'space-between',
          cursor: isDraggable ? 'grab' : '',
        }}
      >
        <Modal.Title style={{fontWeight: 'bold'}}>{title}</Modal.Title>
        <button
          onClick={onClose || onCancel}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body
        style={{
          boxShadow: isDraggable ? 'rgba(0, 0, 0, 0.3) 20px 50px 60px -3px' : 'none',
        }}
      >
        {customBody || <p>{text}</p>}
      </Modal.Body>
      {showFooter && (
        <Modal.Footer style={{justifyContent: 'space-between'}}>
          <Button
            type="button"
            className={`dco-button ${cancelColor || 'danger'}`}
            onClick={onCancel}
          >
            {cancelText || 'Cancel'}
          </Button>
          <Button
            type="button"
            className={`dco-button ${continueColor || 'primary'}`}
            onClick={onContinue}
          >
            {continueText || 'Continue'}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
