import React from 'react';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Emoji} from 'emoji-mart';
import moment from 'moment';

import {resolveComment} from './slice';
import Form from './form';
import Attachment from './../../helpers/attachment';

const CommentDisplay = ({
  msg,
  isAllowed,
  comment,
  getUser,
  parent,
  expanded,
  expand,
  type,
  id,
  dispatch,
  getUsers,
  replyForm,
  showForm,
  resolved,
  showEditForm,
  adData = null,
  isSharedPageComment = false,
  publicUserList = {},
  otherGrid8Users,
}) => {
  const getDisplayName = userId => {
    const {name} = getUser(msg.createdBy);
    const {lastName} = getUser(msg.createdBy);
    const publicUserName = publicUserList[userId]?.name;
    const otherGrid8UserName = otherGrid8Users[userId]?.name;

    if (name) {
      return `${name} ${lastName}`;
    }

    return publicUserName || otherGrid8UserName || '';
  };

  const replaceEmoji = text => {
    const parts = text.split(/(\:\[.*?\])/g);
    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = <Emoji emoji={{id: parts[i].match(/\:\[(.*?)\]/)[1]}} size={16} key={i} />;
    }
    return parts;
  };

  const replaceMention = arr => {
    return arr.map(part => {
      if (typeof part === 'string') {
        const parts = part.split(/(\@\[.*?\]\([0-9a-fA-F]{24}\))/g);
        for (let i = 1; i < parts.length; i += 2) {
          const matches = parts[i].match(/\@\[(.*?)\]\(([0-9a-fA-F]{24})\)/);
          parts[i] = (
            <span key={i} className="text-info">
              @{matches[1]}
            </span>
          );
        }
        return parts;
      }
      return part;
    });
  };

  return (
    <>
      <div className="comment-header">
        <span className="comment-date">
          {moment
            .utc(msg.createdAt)
            .local()
            .format('DD-MM-YYYY HH:mm')}
        </span>
        <span className="comment-author">{getDisplayName(msg.createdBy)}</span>
        {msg.updatedAt && (
          <span className="comment-edited text-muted ml-2 font-italic small font-weight-light">
            edited at{' '}
            {moment
              .utc(msg.updatedAt)
              .local()
              .format('DD-MM-YYYY HH:mm')}
          </span>
        )}
      </div>

      <>
        <div className="d-flex">
          <div className="flex-grow-1">
            {msg.deletedAt === null ? (
              <>
                <div className="comment-text">
                  <pre className="">{replaceMention(replaceEmoji(msg.text))}</pre>
                </div>
                {!!(msg.attachments || []).length && (
                  <div className="comment-attachments flex-wrap">
                    {msg.attachments.map(file => (
                      <Attachment file={file} key={file._id} paperclip />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <div className="comment-text font-italic font-weight-light pb-2 text-black-50">
                deleted
              </div>
            )}
          </div>
          <div className="d-flex align-items-center pr-2">
            {parent === null &&
              msg.resolved &&
              !!comment.asyncState[`comments/${type}/${id}`].items.find(
                c => c.parentId === msg._id
              ) && (
                <FontAwesomeIcon
                  icon={expanded[msg._id] ? 'angle-down' : 'angle-left'}
                  size="2x"
                  onClick={() => expand({...expanded, [msg._id]: !expanded[msg._id]})}
                />
              )}
          </div>
        </div>
        <div className="comment-footer">
          {!msg.resolved && !resolved && isAllowed('create') && !msg.deletedAt && (
            <span className="comment-btn comment-reply" onClick={() => showForm(msg._id)}>
              <FontAwesomeIcon icon="reply" className="mr-2" />
              reply
            </span>
          )}
          {msg.parentId === null && !msg.resolved && isAllowed('resolve', msg) && (
            <span
              className="comment-btn comment-resolve"
              onClick={() => dispatch(resolveComment({type, id, commentId: msg._id}))}
            >
              mark as resolved
            </span>
          )}
          {msg.parentId === null && msg.resolved && isAllowed('resolve', msg) && (
            <span
              className="comment-btn resolved ml-0"
              onClick={() => dispatch(resolveComment({type, id, commentId: msg._id}))}
            >
              <span className="comment-reopen text-info">reopen</span>
              <span className="comment-status">resolved</span>
            </span>
          )}
          {msg.parentId === null && msg.resolved && !isAllowed('resolve', msg) && (
            <span className="comment-btn resolved no-hover ml-0">resolved</span>
          )}
          {msg.deletedAt === null &&
            isAllowed('update', msg) &&
            !msg.resolved &&
            resolved === false &&
            moment().diff(msg.createdAt) < 3600000 && (
              <span className="comment-btn" onClick={() => showEditForm(msg._id)}>
                edit
              </span>
            )}
        </div>
        {replyForm === msg._id && isAllowed('create') && (
          <div className="p-1">
            <Form
              type={type}
              id={id}
              parentId={replyForm}
              key={replyForm}
              hide={() => showForm(null)}
              users={getUsers()}
              adData={adData}
              isSharedPageComment={isSharedPageComment}
            />
          </div>
        )}
      </>
    </>
  );
};

export default CommentDisplay;
