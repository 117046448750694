/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {ModalDialog} from 'react-bootstrap';
import Draggable from 'react-draggable';

const DraggableModal = props => {
  return (
    <Draggable handle=".modal-header">
      <ModalDialog {...props} />
    </Draggable>
  );
};

export default DraggableModal;
