import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from './../../api';
import {getUsersForMention} from './../user/slice';

export const getComments = createAsyncThunk(
  'comment/getComments',
  async ({parents, type, id, filter}, {dispatch, rejectWithValue}) => {
    try {
      const response = await api.comment.getComments(type, id, filter);
      if (parents.teamId)
        dispatch(
          getUsersForMention({key: `user/mentions/${id}`, type: 'team', id: parents.teamId})
        );
      else if (parents.brandId)
        dispatch(
          getUsersForMention({key: `user/mentions/${id}`, type: 'brand', id: parents.brandId})
        );
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const createComment = createAsyncThunk(
  'comment/createComment',
  async ({type, id, form}, {rejectWithValue}) => {
    try {
      const response = await api.comment.createComment(type, id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateComment = createAsyncThunk(
  'comment/updateComment',
  async ({commentId, form}, {rejectWithValue}) => {
    try {
      const response = await api.comment.updateComment(commentId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const resolveComment = createAsyncThunk(
  'comment/resolve',
  async ({commentId}, {rejectWithValue}) => {
    try {
      const response = await api.comment.resolve(commentId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteComment = createAsyncThunk(
  'comment/deleteComment',
  async ({commentId}, {rejectWithValue}) => {
    try {
      const response = await api.comment.deleteComment(commentId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const uploadAttachment = createAsyncThunk(
  'file/uploadAttachment',
  async ({type, id, form}, {rejectWithValue}) => {
    try {
      const formData = new FormData();
      formData.append('tmpKey', form.tmpKey);
      formData.append('attachment', form.file, form.file.filename);
      const response = await api.comment.uploadAttachment(type, id, formData);

      return response.data;
    } catch (e) {
      // console.log(e);
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteAttachment = createAsyncThunk(
  'file/deleteAttachment',
  async ({id, tmpKey}, {rejectWithValue}) => {
    try {
      const response = await api.comment.deleteAttachment(id, tmpKey);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const comment = createSlice({
  name: 'comment',
  initialState: {
    asyncState: {},
    items: [],
    attachment: {},
    fetching: false,
    deleting: {},
    error: null,
  },
  reducers: {},
  extraReducers: {
    //
    [getComments.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null, items: []};
    },
    [getComments.fulfilled]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: null, items: payload};
    },
    [getComments.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload, items: []};
    },

    [createComment.pending]: state => {
      return {...state, fetching: true, error: null};
    },
    [createComment.fulfilled]: (state, {meta, payload}) => {
      if (meta.arg.type === 'preview') {
        // @GET /previewId/adId  -  @POST /previewId
        // must add adId back to asyncState after @POST
        state.asyncState[
          `comments/${meta.arg.type}/${meta.arg.id}/${payload.secondaryId}`
        ].items.unshift(payload);
      } else {
        state.asyncState[`comments/${meta.arg.type}/${meta.arg.id}`].items.unshift(payload);
      }
      state.fetching = false;
      state.error = null;
    },
    [createComment.rejected]: (state, {error, payload}) => {
      return {
        ...state,
        error: payload || error,
        fetching: false,
      };
    },

    [updateComment.pending]: state => ({...state, fetching: true, error: null}),
    [updateComment.fulfilled]: (state, {meta, payload}) => {
      state.asyncState[`comments/${meta.arg.type}/${meta.arg.id}`].items = state.asyncState[
        `comments/${meta.arg.type}/${meta.arg.id}`
      ].items.map(i => (i._id === payload._id ? payload : i));
      state.fetching = false;
      state.error = null;
    },
    [updateComment.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),

    [resolveComment.pending]: state => ({...state, fetching: true, error: null}),
    [resolveComment.fulfilled]: (state, {meta, payload}) => {
      state.asyncState[`comments/${meta.arg.type}/${meta.arg.id}`].items = state.asyncState[
        `comments/${meta.arg.type}/${meta.arg.id}`
      ].items.map(i => (i._id === payload._id ? payload : i));
      state.fetching = false;
      state.error = null;
    },
    [resolveComment.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),

    [deleteComment.pending]: state => ({...state, fetching: true, error: null}),
    [deleteComment.fulfilled]: (state, {meta, payload}) => {
      state.asyncState[`comments/${meta.arg.type}/${meta.arg.id}`].items = state.asyncState[
        `comments/${meta.arg.type}/${meta.arg.id}`
      ].items.map(i => (i._id === payload._id ? payload : i));
      state.fetching = false;
      state.error = null;
    },
    [deleteComment.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),

    [uploadAttachment.pending]: (state, {meta}) => ({
      ...state,
      attachment: {...state.attachment, [meta.arg.tmpId]: {fetching: true, error: null}},
    }),
    [uploadAttachment.fulfilled]: (state, {meta, payload}) => {
      state.attachment[meta.arg.tmpId].fetching = false;
      state.attachment[meta.arg.tmpId].file = payload;
    },
    [uploadAttachment.rejected]: (state, {error, meta}) => {
      state.attachment[meta.arg.tmpId].error = error;
      state.attachment[meta.arg.tmpId].fetching = false;
    },

    [deleteAttachment.pending]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: {fetching: true, error: null}},
    }),
    [deleteAttachment.fulfilled]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: {fetching: false, error: null}},
    }),
    [deleteAttachment.rejected]: (state, {meta, error}) => ({
      ...state,
      deleting: {[meta.arg.id]: {fetching: false, error}},
    }),
  },
});

// export const {} = file.actions;
export default comment.reducer;
