/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {Modal} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

import api from '../../../../../../api';

const PublishModal = props => {
  const {when, setup, onClose = () => {}, parents} = props;

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const {data: adData} = await api.dco.getAds({parents: {brandId: parents.brandId}});
        const grouped = _.groupBy(
          adData.filter(
            ad =>
              ad.setup === setup._id &&
              ad.parents?.projectId !== parents?.projectId &&
              ad.version.substring(0, ad.version.lastIndexOf('.')) !==
                setup.version.substring(0, setup.version.lastIndexOf('.'))
          ),
          'parents.projectId'
        );
        console.log('grouped', grouped);
        const tmp = [];
        Object.keys(grouped).forEach(async key => {
          const currentProject = await api.project.getProject(key);
          tmp.push(currentProject.data);
        });
        setProjects(tmp);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Modal show={when} onHide={onClose} centered>
      <Modal.Header style={{justifyContent: 'end', borderBottom: '0'}}>
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center">
        {projects.length > 0 ? (
          <span
            className={`icon icon-warning ml-1 mb-4 danger-background `}
            style={{width: '64px', height: '64px'}}
          />
        ) : (
          <span
            className={`icon icon-check-outlined ml-1 mb-4 ${
              setup?.autoSchedule ? 'success-background' : 'primary-background'
            }  `}
            style={{width: '64px', height: '64px'}}
          />
        )}
        {projects.length > 0 && (
          <>
            <span className="text-center">
              Your ads have been successfuly published. There are still projects that need to be
              updated. This could cause feed issues.
            </span>
            <span className="mt-4">Projects:</span>
            {projects.map(p => (
              <NavLink to={`/project/${p._id}`}>
                <p key={p.name}>{p.name}</p>
              </NavLink>
            ))}
          </>
        )}
        {setup?.publishLink && <p>{setup?.note || ''}</p>}
        <p className="publish-modal-title">Link to Feed</p>
        {setup && setup._id && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-wrap text-break"
            href={`https://${process.env.REACT_APP_API_DOMAIN}/api/dco/feed/${setup._id}`}
          >
            {`https://${process.env.REACT_APP_API_DOMAIN}/api/dco/feed/${setup._id}`}
          </a>
        )}
        {setup?.publishLink && (
          <>
            <p className="publish-modal-title mt-4">Publish Link</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-wrap text-break"
              href={setup.publishLink}
            >
              {setup.publishLink}
            </a>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default PublishModal;
