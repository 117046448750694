/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React, {useState} from 'react';
import {Button, Toast} from 'react-bootstrap';
import Draggable from 'react-draggable';

import {stateColorCoding} from 'pages/project/DcoProject/consts';
import SharedPreview from 'pages/shared/components/SharedPreview';
import Comments from 'pages/comment/Comments';

const SharedReviewTableAd = ({data}) => {
  const [show, setShow] = useState(false);
  const {sharedAdData: ad} = data;

  const adData = {
    parents: data.parents,
    relativeId: ad.sharedAdId,
    secondaryId: ad.adId,
    relativeType: 'preview',
  };

  return (
    <div className="my-5">
      <div className="d-flex align-items-center justify-content-between py-5">
        <div className="d-flex">
          <h3>{ad.name}</h3>
          <span className={`font-weight-bold m-0 ${stateColorCoding[ad.publishedStatus]} ml-4`}>
            <span>{`\u2022`} </span>
            {ad.publishedStatus}
          </span>
        </div>
        <div>
          <Button
            onClick={() => {
              setShow(true);
            }}
            type="button"
            className="mr-4 dco-button shared-comment-btn"
          >
            Comment {ad.comments}
          </Button>
          <span className={`font-weight-bold ${stateColorCoding[ad.status]} mr-4`}>
            <span>{`\u2022`} </span>
            {ad.status}
          </span>
        </div>
      </div>
      <div className="d-flex flex-wrap" style={{gap: '3rem'}}>
        {ad.previews?.map((preview, index) => {
          if (!preview.enabled) {
            return null;
          }
          return <SharedPreview preview={preview} key={index} />;
        })}
      </div>
      {show && (
        <Draggable cancel=".shared-comment">
          <Toast
            style={{
              position: 'fixed',
              left: '20%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              minWidth: 700,
              width: 700,
              maxHeight: '50vh',
              overflowY: 'auto',
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center pt-3 pb-3 px-4"
              style={{
                borderBottom: '1px solid lightgray',
                cursor: 'grab',
                backgroundColor: '#f9f9f9',
                position: 'sticky',
                top: 0,
              }}
            >
              <h3 className="m-0">{ad.name}</h3>
              <p className="m-0 cursor-pointer p-2" onClick={() => setShow(false)}>
                x
              </p>
            </div>
            <Toast.Body>
              <Comments
                type="preview"
                parents={data.parents}
                id={`${ad.sharedAdId}/${ad.adId}`}
                adData={adData}
                isSharedPageComment
              />
            </Toast.Body>
          </Toast>
        </Draggable>
      )}
    </div>
  );
};

export default SharedReviewTableAd;
