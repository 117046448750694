import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const TooltipHelper = ({children, content, hidden, placement = 'top'}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={hidden ? <span></span> : <Tooltip className="primary">{content}</Tooltip>}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default TooltipHelper;
