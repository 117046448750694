import React, {useRef} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import Select from 'react-select/creatable';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import {registration} from './../login/slice';
import {ErrorMessages} from '../../helpers/error';

const customStyles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: state.isDisabled ? 'none' : 'flex',
  }),
  control: () => ({
    backgroundColor: '#0000B4',
    backgroundClip: 'padding-box',
    borderBottom: '1px solid #495057',
    borderRadius: '0',
    cursor: 'text',
    display: 'flex',
  }),
  valueContainer: provided => ({
    ...provided,
    paddingLeft: '0px',
    marginLeft: '0px',
    width: '100%',
    display: 'flex',
  }),
  placeholder: provided => ({
    ...provided,
    marginLeft: '0px',
    color: '#a7a7af',
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: '0px',
    color: 'white',
  }),
  dropdownIndicator: () => ({
    padding: '4px',
    color: 'white',
  }),
  clearIndicator: () => ({
    padding: '0',
    paddingRight: '8px',
    color: 'white',
  }),
};

const SignIn = ({history, location, dispatch, auth}) => {
  const initialForm = {
    password: '',
    confirmPassword: '',
    name: '',
    lastName: '',
    token: '',
  };
  const recaptchaRef = useRef();
  const queryParams = queryString.parse(location.search);
  const {token: invitationToken} = queryParams;

  const submit = async (values, formik) => {
    const token = await recaptchaRef.current.executeAsync().catch(e => {
      formik.setErrors({token: 'something went wrong'});
      formik.setSubmitting(false);
    });
    const form = {
      ...values,
      /* invitationToken: queryString.parse(location.search).i, */
      token,
      invitationToken,
    };
    const res = await dispatch(registration(form));
    if (registration.fulfilled.match(res)) {
      history.replace({pathname: '/'});
    } else {
      recaptchaRef.current.reset();
      formik.setErrors(res.payload.data);
      formik.setSubmitting(false);
    }
  };

  return (
    <div className="signIn">
      <div className="header mb-4">Create your profile</div>
      <Formik initialValues={initialForm} onSubmit={submit}>
        {({isSubmitting, values, setFieldValue}) => (
          <Form className={isSubmitting ? 'loading-blue' : ''}>
            <ErrorMessages
              error={auth.registration}
              filed="invitationToken"
              showFirstMessage={false}
              only={[422]}
            />

            <ErrorMessages error={auth.registration} ignore={[422]} />
            <div className="form-group">
              <h5 className="text-white">Name*</h5>
              <Field type="text" name="name" placeholder="Name" className="form-control" />
              <ErrorMessage
                name="name"
                render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
              />
            </div>
            <div className="form-group">
              <h5 className="text-white">Last name*</h5>
              <Field type="text" name="lastName" placeholder="Last name" className="form-control" />
              <ErrorMessage
                name="lastName"
                render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
              />
            </div>

            <div className="form-group">
              <h5 className="text-white">Password*</h5>
              <Field
                type="password"
                name="password"
                placeholder="Password"
                className="form-control"
              />
              <ErrorMessage
                name="password"
                render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
              />
            </div>

            <div className="form-group">
              <h5 className="text-white">Confirm password*</h5>
              <Field
                type="password"
                name="confirmPassword"
                placeholder="Password"
                className="form-control"
              />
              <ErrorMessage
                name="confirmPassword"
                render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
              />
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              size="invisible"
            />
            <ErrorMessage
              name="token"
              render={msg => <div className="alert alert-danger mt-1">{msg}</div>}
            />
            <div className="form-group">
              <button className="btn btn-info btn-block" type="submit">
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect(store => ({
  auth: store.auth,
}))(withRouter(SignIn));
