/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {connect} from 'react-redux';
import _ from 'lodash';

import Edit from './Edit';
import {editTabAgGridColumns} from './agHelpers';
import {TablePreview} from './components/Previews';
import {archiveSelectedAds, createAdFromSetup} from '../helpers';
import {createAd, updateAd} from '../slice';
import {defaultColDef} from '../consts';
import {ConfirmationModal} from '../../../../components';
import api from '../../../../api';
import {replaceElementInArray} from '../../../brand/Dco/helpers';
import {EditTabHeader} from './components/EditTab';
import RouteGuard from '../../../../helpers/RouteGuard';
import {editTabValidationSchema} from './components/Edit/validation';
import useValidation from '../../../../hooks/useValidation';

const EditTab = (
  {ad, file, project, dispatch, setup, parents, assets, currentAd, setCurrentAd, tab, setTab},
  ref
) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [quickSearchValue, setQuickSerchValue] = useState('');
  const [showUnpublishModal, setShowUnpublishModal] = useState();
  const [initialEditDate, setInitialEditDate] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [showAlreadyPublishedModal, setShowAlreadyPublishedModal] = useState({
    adValue: null,
    index: null,
  });
  const [showAlreadyPublishedModalBulk, setShowAlreadyPublishedModalBulk] = useState(false);
  const [showQuickSearch, setShowQuickSearch] = useState(true);
  const [adVersions, setAdVersions] = useState([]);
  const [errors, handleValidation] = useValidation();
  const [toRecover, setToRecover] = useState([]);

  const changedDiff = useMemo(() => {
    return _.difference(rowData, ad.items);
  }, [ad.items, rowData]);

  console.log(changedDiff);

  const gridRef = useRef();

  const setColumnWidth = useCallback(() => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const collapseAll = useCallback(() => {
    gridRef?.current?.api?.forEachNode(node => {
      node.setExpanded(false);
    });
  }, []);

  const deselectAll = useCallback(() => {
    gridRef?.current?.api?.deselectAll();
  }, []);

  useEffect(() => {
    if (setup && !ad.items.length) {
      setCurrentAd(createAdFromSetup(setup));
      setTab('edit');
    }
  }, [ad.items, setup, setCurrentAd, setTab]);

  useEffect(() => {
    if (tab === 'edit') {
      setRowData(ad.items);
      deselectAll();
      setSelectedRows([]);
    }

    return () => {
      setRowData([]);
    };
  }, [tab, deselectAll, ad.items]);

  // useEffect(() => {
  //   if (changedDiff.length) {
  //     setQuickSerchValue('');
  //     setShowQuickSearch(false);
  //   } else {
  //     setShowQuickSearch(true);
  //   }
  // }, [changedDiff]);

  useEffect(() => {
    if (!currentAd?._id) return;

    setInitialEditDate(currentAd?.published?.lastChange);
    (async () => {
      try {
        const diff = await api.dco.getAdDiff(currentAd._id);
        setAdVersions(diff.data.filter(item => item.diff));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [currentAd?._id]);

  useImperativeHandle(ref, () => ({
    getData: () => selectedRows,
  }));

  const handleCellClicked = useCallback(
    data => {
      setCurrentAd(data);
    },
    [setCurrentAd]
  );

  const handleSelectedChange = useCallback(
    () => setSelectedRows(gridRef?.current?.api?.getSelectedRows()),
    []
  );

  const handleEdit = adValue => {
    setCurrentAd(adValue);
    setTab('edit');
  };

  const handleTableEditSave = useCallback(
    async (adValue, index) => {
      console.log('handling save', changedDiff.length);
      if (changedDiff.length > 1) {
        console.log('did you mean, save all?', adValue, index);
        setToRecover(changedDiff.filter(d => d._id != adValue._id));
      } else console.log('just save');
      const updatedStateAd =
        adValue?.columns?.State === 'Approved' || adValue?.columns?.State === 'Rejected'
          ? {...adValue, columns: {...adValue.columns, State: 'In review'}}
          : adValue;
      try {
        const data = await dispatch(updateAd({...updatedStateAd, parents, setup: setup._id}));

        setRowData(prevData => {
          const updated = replaceElementInArray(prevData, index, data?.payload);

          return updated;
        });

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [dispatch, parents, setup._id, changedDiff]
  );

  useEffect(() => {
    console.log('to recover', toRecover);

    toRecover.forEach(recovery => {
      setRowData(prevData => {
        const foundIndex = prevData.findIndex(srch => srch._id === recovery._id);
        if (foundIndex > -1) {
          const updated = replaceElementInArray(prevData, foundIndex, recovery);

          return updated;
        }
        console.log('index not found for update');
        return prevData;
      });
    });
    setToRecover([]);
  }, [changedDiff]);

  const handleTableEditSaveVerification = useCallback(
    async (adValue, index) => {
      const isValid = await handleValidation(
        adValue.columns,
        editTabValidationSchema(setup.adColumns),
        index
      );
      if (!isValid) {
        return;
      }

      if (adValue?.published?.csv && adValue?.published?.PublishedState === 'Published') {
        setShowAlreadyPublishedModal({adValue, index});
        return;
      }

      await handleTableEditSave(adValue, index);
    },
    [handleTableEditSave]
  );

  const handleBulkEditSave = useCallback(
    async (adsToSave = changedDiff) => {
      try {
        await Promise.allSettled(
          adsToSave.map(adValue =>
            handleTableEditSave(
              adValue,
              rowData.findIndex(row => row._id === adValue._id)
            )
          )
        );
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    [changedDiff, handleTableEditSave, rowData]
  );

  const handleBulkEditSaveVerification = useCallback(() => {
    const anyPublished = changedDiff.some(
      rowAd => rowAd.published?.csv && rowAd?.published?.PublishedState === 'Published'
    );
    if (anyPublished) {
      setShowAlreadyPublishedModalBulk(true);
      return;
    }
    handleBulkEditSave();
  }, [handleBulkEditSave, changedDiff]);

  const handleTableEditReset = useCallback(
    index => {
      setRowData(prevData => replaceElementInArray(prevData, index, ad.items[index]));
    },
    [ad.items]
  );

  const columnDefs = useMemo(
    () =>
      editTabAgGridColumns(
        setup,
        setRowData,
        handleCellClicked,
        ad.items,
        handleTableEditSaveVerification,
        handleTableEditReset,
        errors
      ),
    [
      ad.items,
      setup,
      errors,
      setRowData,
      handleCellClicked,
      handleTableEditSaveVerification,
      handleTableEditReset,
    ]
  );

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const handleNewAd = () => {
    setCurrentAd(createAdFromSetup(setup));
  };

  const handleEditAdSave = (adValue, setCurrent = true) => async () => {
    const newValue = adValue ? {...adValue} : {...currentAd};

    const updatedStateAd =
      newValue?.columns?.State === 'Approved' || newValue?.columns?.State === 'Rejected'
        ? {
            ...newValue,
            columns: {
              ...newValue.columns,
              State:
                initialEditDate !== newValue?.published?.lastChange
                  ? newValue?.columns?.State
                  : 'In review',
            },
          }
        : newValue;
    let newAd = {...updatedStateAd};

    try {
      if (newValue._id) {
        newAd = (
          await dispatch(updateAd({...updatedStateAd, parents, setup: setup._id, initialEditDate}))
        ).payload;
      } else {
        newAd = (await dispatch(createAd({...updatedStateAd, parents, setup: setup._id}))).payload;
      }

      setRowData(prevData => {
        const newAdIndex = ad.items.findIndex(a => a?._id === newAd?._id);
        if (newAdIndex >= 0) {
          return replaceElementInArray(prevData, newAdIndex, newAd);
        }

        return [...prevData, newAd];
      });

      setInitialEditDate(newAd?.published?.lastChange);

      if (setCurrent) {
        setCurrentAd({
          ...newAd,
        });
      }

      if (newValue._id) {
        const diff = await api.dco.getAdDiff(newValue._id);

        setAdVersions(diff.data.filter(item => item.diff));
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleAdClose = () => {
    setCurrentAd(null);
    collapseAll();
  };

  const handleUnpublishModalContinue = async () => {
    try {
      await api.dco.unpublishAds(selectedRows.map(row => row._id));
      await dispatch(updateAd(archiveSelectedAds(selectedRows)));
    } catch (error) {
      console.error(error);
    }
    setShowUnpublishModal(false);
    setSelectedRows([]);
    deselectAll();
  };

  const handleUnpublishModalClose = () => {
    setShowUnpublishModal(false);
    setSelectedRows([]);
    deselectAll();
  };

  const handlePublishedModalContinue = async () => {
    setShowAlreadyPublishedModal({});
    try {
      await handleTableEditSave(showAlreadyPublishedModal.adValue, showAlreadyPublishedModal.index);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePublishedModalCancel = () => {
    setShowAlreadyPublishedModal({});
    const copyAdName = `${showAlreadyPublishedModal.adValue?.columns['Ad Name']}-copy`;
    handleEditAdSave(
      {
        ..._.omit(showAlreadyPublishedModal.adValue, ['_id']),
        columns: {...showAlreadyPublishedModal.adValue.columns, 'Ad Name': copyAdName},
      },
      false
    )();
    handleTableEditReset(showAlreadyPublishedModal.index);
  };
  const handlePublishedModalClose = () => {
    setShowAlreadyPublishedModal({});
  };

  const handleBulkPublishedModalContinue = async () => {
    setShowAlreadyPublishedModalBulk();

    handleBulkEditSave();
  };

  const handleBulkPublishedModalCancel = async () => {
    setShowAlreadyPublishedModalBulk();

    const [publishedAds, unpublishedAds] = _.partition(
      changedDiff,
      innerAd => innerAd?.published?.csv && innerAd?.published?.PublishedState === 'Published'
    );

    try {
      await Promise.allSettled(
        publishedAds.map(publishedAd =>
          handleEditAdSave(
            {
              ..._.omit(publishedAd, ['_id']),
              columns: {
                ...publishedAd.columns,
                'Ad Name': `${publishedAd?.columns['Ad Name']}-copy`,
              },
            },
            false
          )()
        )
      );
      await handleBulkEditSave(unpublishedAds);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkPublishedModalClose = () => {
    setShowAlreadyPublishedModalBulk();
  };

  const threeDotAfterEffects = () => {
    setSelectedRows([]);
    deselectAll();
  };

  const handleRouteGuardSave = async (adParam, callback) => {
    const success = await handleEditAdSave(adParam)();
    if (success) {
      if (callback) {
        callback();
      } else {
        handleAdClose();
      }
      return true;
    }
    return false;
  };

  const handleRouteGuardSaveBulk = async callback => {
    const success = await handleBulkEditSave();
    if (success) {
      if (callback) {
        callback();
      } else {
        handleAdClose();
      }
      return true;
    }
    return false;
  };

  const handleNextPreviousAd = action => {
    let tmp;
    switch (action) {
      case 'next':
        gridRef.current.api.forEachNodeAfterFilter(c => {
          if (tmp?._id === currentAd?._id) {
            setCurrentAd(c.data);
            tmp = null;
          } else {
            tmp = c.data;
          }
        });
        break;
      case 'previous':
        gridRef.current.api.forEachNodeAfterFilter(c => {
          if (c?.data?._id === currentAd?._id) {
            setCurrentAd(tmp);
            tmp = null;
          } else {
            tmp = c.data;
          }
        });
        break;

      default:
        handleAdClose();
    }
  };

  const getIsDirectionDisabled = id => {
    let result = {
      position: 0,
      length: gridRef?.current?.api?.getDisplayedRowCount() || 0,
    };

    if (!id)
      return {
        next: true,
        previous: true,
      };

    gridRef?.current?.api?.forEachNodeAfterFilter((c, idx) => {
      if (c?.data?._id === id) {
        result = {...result, position: idx + 1};
      }
    });

    if (result.position === 1 && result.length === 1) {
      return {
        next: true,
        previous: true,
      };
    }

    if (result.position === 1) {
      return {
        next: false,
        previous: true,
      };
    }

    if (result.position === result.length) {
      return {
        next: true,
        previous: false,
      };
    }
    return {
      next: false,
      previous: false,
    };
  };

  const handleDiscardClicked = () => {
    const callback = typeof showWarningModal?.fn === 'function' ? showWarningModal.fn : null;
    if (callback) {
      callback();
      setShowWarningModal(false);
    } else {
      handleAdClose();
    }
    return true;
  };

  const handleWarningModalContinue = () => {
    const callback = typeof showWarningModal?.fn === 'function' ? showWarningModal.fn : null;
    console.log({callback});
    setShowWarningModal(false);

    return handleRouteGuardSaveBulk(callback);
  };

  return (
    <>
      {currentAd ? (
        <Edit
          setup={setup}
          handleSaveClicked={handleEditAdSave}
          currentAd={{...currentAd}}
          adId={currentAd?.uniqueId}
          setCurrentAd={setCurrentAd}
          projectId={parents.projectId}
          assets={assets}
          handleCancelClicked={handleAdClose}
          adVersions={adVersions}
          setAdVersions={setAdVersions}
          handleRouteGuardSave={handleRouteGuardSave}
          setInitialEditDate={setInitialEditDate}
          initialEditDate={initialEditDate}
          handleNextPreviousAd={handleNextPreviousAd}
          getIsDirectionDisabled={getIsDirectionDisabled}
          parents={parents}
        />
      ) : (
        <>
          <EditTabHeader
            selectedRows={selectedRows}
            setup={setup}
            fileItems={file.items}
            parents={parents}
            assets={assets}
            project={project}
            afterEffects={threeDotAfterEffects}
            quickSearchValue={quickSearchValue}
            showQuickSearch={showQuickSearch}
            dispatch={dispatch}
            handleNewAd={handleNewAd}
            setQuickSerchValue={setQuickSerchValue}
            setRowData={setRowData}
            setShowUnpublishModal={setShowUnpublishModal}
            handleBulkEditSaveVerification={handleBulkEditSaveVerification}
            hasChangedAds={!_.isEmpty(changedDiff)}
            numOfAds={ad?.items?.length || 0}
            setShowWarningModal={setShowWarningModal}
            changed={!!changedDiff.length}
            ads={ad.items}
          />
        </>
      )}
      <div
        className="ag-theme-alpine banner"
        style={{
          display: currentAd ? 'none' : '',
        }}
      >
        <AgGridReact
          masterDetail
          ref={gridRef}
          rowData={rowData}
          rowHeight={64}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          detailCellRenderer={TablePreview}
          detailCellRendererParams={{setup, assets, handleEdit, parents}}
          detailRowAutoHeight
          quickFilterText={quickSearchValue}
          rowSelection="multiple"
          domLayout="autoHeight"
          onGridReady={onGridReady}
          suppressContextMenu
          onRowSelected={handleSelectedChange}
          suppressRowClickSelection
          enableRangeSelection
          stopEditingWhenCellsLoseFocus
        />
      </div>
      <ConfirmationModal
        show={showUnpublishModal}
        title="You have selected published ads"
        text="To archive these ads, they must first be unpublished"
        cancelText="Cancel"
        continueText="Continue"
        cancelColor="disabled"
        continueColor="danger"
        onContinue={handleUnpublishModalContinue}
        onCancel={handleUnpublishModalClose}
      />
      <ConfirmationModal
        show={showAlreadyPublishedModal.adValue}
        title="This ad is already published"
        text="Are you sure you want to change it? The last published version of the ad will still be published untill you overwrite it by publishing the changed ad."
        cancelText="Save as new ad"
        cancelColor="primary"
        continueText="Save as changed ad"
        continueColor="info"
        onCancel={handlePublishedModalCancel}
        onContinue={handlePublishedModalContinue}
        onClose={handlePublishedModalClose}
      />
      <ConfirmationModal
        show={showAlreadyPublishedModalBulk}
        title="There are published ads in the selection"
        text="Are you sure you want to change them? The last published version of the ad will still be published untill you overwrite it by publishing the changed ad."
        cancelText="Save them as new ads"
        cancelColor="primary"
        continueText="Save as changed ads"
        continueColor="info"
        onCancel={handleBulkPublishedModalCancel}
        onContinue={handleBulkPublishedModalContinue}
        onClose={handleBulkPublishedModalClose}
      />
      <RouteGuard
        when={changedDiff.length}
        manualShow={!!showWarningModal}
        title="Unsaved Changes"
        message="Do you want to save your changes?"
        cancelText="Discard"
        continueText="Save"
        onManualClose={() => setShowWarningModal(false)}
        onContinue={handleWarningModalContinue}
        onCancel={handleDiscardClicked}
      />
    </>
  );
};
export default connect(
  store => ({
    ad: store.ad,
    project: store.project,
    file: store.file,
  }),
  null,
  null,
  {forwardRef: true}
)(forwardRef(EditTab));
