/* some weird fuckup happened with the id, the useMemo one, no idea what was the previous developers plan there, afraid to not mess
 * something else up, i've created another mess for which i'm not sure how or why it works the way it does, i think the id can be completely
 * moved to current project/brand/team id and use that type as well, creative can be part of some setup so creatives parent maybe is not the best idea to use
 * when both lack, i guess it can be used? anyway, it's a mess, god help you who ever needs to figure it out */
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';
import React, {useEffect, useMemo, useState} from 'react';
import {Accordion, Button, Modal, Tab, Tabs} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import api from '../../../api';
import FileSelect from '../../../helpers/FileSelect';
import {getFiles} from '../slice';
import ExportNotificationModal from './ExportNotificationModal';
import DraggableList from 'components/DraggableList';
import {Switch} from 'components';
const maxSize = 600;

const CreativeExportModal = ({show, onClose, creative, version, parents, q2a, nameoverride}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [key] = useState(Math.random());
  const [name, setName] = useState(nameoverride || 'ExportedCreative');
  const [dynamicOptions, setDynamicOptions] = useState({
    adColumns: [],
    shotColumns: [],
  });
  const [defaultedTab, setDefaultedTab] = useState('ad');
  const [notificationModalOpened, setNotificationModalOpened] = useState(false);
  const [addingAudio, setAddingAudio] = useState(false);
  const [audioTrack, setAudioTrack] = useState(false);
  const [audioLoop, setAudioLoop] = useState(false);
  const [showGifOptions, setShowGifOptions] = useState(false);
  const [audioFadeOut, setAudioFadeOut] = useState(0);
  const [gifFps, setGifFps] = useState(15);
  const [gifFrames, setGifFrames] = useState([
    {
      start: '0.00',
      length: '1.5',
    },
  ]);
  const [files, setFiles] = useState([]);
  // console.log('q2a', q2a)

  const id = useMemo(() => {
    // console.log('pathname', location.pathname);
    // todonote looks like if it's a file and it is creative, still return id for file's project
    // currently getting 403 here
    let inFile = false;
    if (location.pathname.indexOf('file') === -1) {
      return location.pathname.split('/').at(-1);
    } else inFile = true;

    if (!inFile && creative._id) {
      return creative._id;
    }
    const regex = /\/([^/]+)\/file\//;
    const match = location.pathname.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }, [location.pathname]);
  // console.log('id', id);

  const type = useMemo(() => {
    const regex = /([^/]+)/;
    const match = location.pathname.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }, [location.pathname]);

  const fetchFiles = async () => {
    const filter = {
      ignoreTemplates: true,
      includeBrandFiles: false,
    };
    const assets = (await api.asset.getFiles(type, id, filter)).data;
    setFiles(assets);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const [dynamicSrc = '', dynamicParams = []] = useMemo(() => {
    const src = `https://${process.env.REACT_APP_API_DOMAIN}/api/tools/proxy/${creative.versions[version].dir}/${creative.versions[version].indexFile}?showDebug=true`;

    const adValues = dynamicOptions.adColumns
      .filter(option => option.name && (option.image?.url || option?.text))
      .map(
        option =>
          `${encodeURIComponent(`${option.name}`)}=${encodeURIComponent(
            option.image?.url || option.text
          ).replaceAll('%26')}`
      )
      .join('&');
    const adValuesString = adValues && `${adValues}&`;
    const shotValues = dynamicOptions.shotColumns
      .map((options, shot) => {
        const prefix = `s${shot}_`;

        // console.log({prefix});

        const opts = options
          .filter(option => option.name && (option.image?.url || option?.text !== null))
          .map(option => {
            console.log(option);
            return `${encodeURIComponent(`${prefix}${option.name}`)}=${encodeURIComponent(
              option.image?.url || option?.text?.replace(/\r\n|\r|\n/g, '<br />') || ''
            ).replaceAll('&', '%26')}`;
          })
          .join('&');
        // console.log('options', opts);
        return opts;
      })
      .join('&');
    return [`${src}&${adValuesString}${shotValues}`, `${adValuesString}${shotValues}`];
  }, [creative, version, dynamicOptions]);

  const getMaxSVariable = url => {
    // Creating a URLSearchParams object from the provided URL
    const params = new URLSearchParams(url);

    let max = 0;
    // Iterate through all keys
    params.forEach(function(value, k) {
      // Check if the key starts with 's'
      if (k.startsWith('s')) {
        // Extract the prefix
        const prefix = parseInt(k.substring(1, k.indexOf('_')), 10);
        max = prefix + 1 > max ? prefix + 1 : max;
      }
    });

    return max;
  };
  // console.log(dynamicOptions, 'dynamic');
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_S3_URL}/${creative.versions[version].dir}/manifest.js`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const text = response.ok && (await response.text());
        const manifest = text && JSON.parse(text.slice(16));
        const options = manifest && manifest?.settings?.dynamicValues;
        const maxS = q2a ? getMaxSVariable(q2a) : false;
        const formattedValues = {
          adColumns: [],
          shotColumns: maxS
            ? new Array(maxS).fill(null).map(() => [])
            : manifest.shots.map(() => []),
        };
        console.log(new URLSearchParams(q2a));
        const q2sParams = new URLSearchParams(q2a);
        const q2sParamsObj = {};
        const q2sEntries = q2sParams.entries();
        for (const [keyName, value] of q2sEntries) {
          q2sParamsObj[keyName.toLowerCase()] = value;
        }
        console.log(q2sParamsObj);
        // console.log({manifest});
        if (options) {
          options.forEach(option => {
            // console.log('option', {option});
            /* suspicious part related to chiel's changes, most likely will need to revert back because of common sense
             * which seems to be lacking when looking at example he provided and a variable called 'didnotadd' or something like that
             * anyway, revert back to using full option when he changes his mind again */
            const fullOption = manifest.layers.find(layer => layer.dynamicValue === option.id);
            // console.log('fullOption', option, fullOption)
            /* if (!fullOption) {
               console.log('no full option, layers:', manifest.layers);
               return;
             }
             console.log('full option', fullOption); */
            // if (option.name === '') console.log('full option', fullOption, option, manifest);
            const newValue = {
              ...option,
              type: fullOption?.fileType || 'text',
              text: q2sParamsObj[option.name.toLowerCase()] || '',
            };
            if (!option?.shotLevel) {
              formattedValues.adColumns.push({...newValue});
            } else {
              formattedValues.shotColumns?.forEach((shot, idx) => {
                // console.log('this', shot, idx);
                formattedValues.shotColumns[idx].push({
                  ...newValue,
                  text: q2sParamsObj[`s${idx}_${option.name.toLowerCase()}`],
                });
              });
            }
          });
        }
        if (formattedValues.adColumns.length === 0) {
          setDefaultedTab('0');
        }
        // console.log('formval', formattedValues)
        setDynamicOptions(formattedValues);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [creative.versions, version, q2a]);

  const modifyManifest = text => {
    const starting = text.slice(0, 16);
    const manifest = text && JSON.parse(text.slice(16));

    const newLayers = manifest.layers.map(layer => {
      const dynamicOption = dynamicOptions.find(option => option.guid === layer.guid);

      if (!dynamicOption) {
        return layer;
      }

      if (dynamicOption.text) {
        return {
          ...layer,
          text: dynamicOption.text,
        };
      }

      if (dynamicOption.image) {
        const lastDot = dynamicOption.image.name.lastIndexOf('.');
        const name = dynamicOption.image.name.slice(0, lastDot);
        const extension = dynamicOption.image.name.slice(lastDot + 1);

        return {
          ...layer,
          name,
          fileType: extension,
        };
      }
      return layer;
    });

    const finalManifest = {
      ...manifest,
      layers: newLayers,
    };

    return `${starting}${JSON.stringify(finalManifest)}`;
  };

  // console.log({array: [..._.flatten(dynamicOptions.shotColumns), ...dynamicOptions.adColumns]});

  const exportToHtml = async () => {
    const fileList = creative.versions[version].files;
    const directory = creative.versions[version].dir;
    const zip = new JSZip();

    const fileFetchPromises = fileList.map(file => {
      return fetch(`${process.env.REACT_APP_S3_URL}/${directory}${file}`)
        .then(response => {
          if (file === '/manifest.js') {
            return response.text();
          }
          return response.blob();
        })
        .then(async fileBlob => {
          if (file === '/manifest.js') {
            zip.file('manifest.js', modifyManifest(fileBlob));
          } else {
            zip.file(file.replace(/\//g, ''), fileBlob);
          }
        });
    });
    const imagePromises = dynamicOptions.map(option => {
      if (
        (option.fileType === 'jpg' || option.fileType === 'png' || option.fileType === 'svg') &&
        option.image
      ) {
        return fetch(`${option.image.url}?t=${Date.now()}`)
          .then(response => response.blob())
          .then(fileBlob => {
            zip.file(option.image.name.replace(/\//g, ''), fileBlob);
          });
      }
      return Promise.resolve();
    });
    Promise.all([...fileFetchPromises, ...imagePromises]).then(() => {
      zip.generateAsync({type: 'blob'}).then(content => {
        FileSaver(content, name);
      });
    });
  };

  const exportToVideo = async () => {
    setNotificationModalOpened(true);
    const isGif = false;

    try {
      const result = await api.asset.videoConverterWithQueryParams(
        creative._id,
        version,
        q2a ? q2a : dynamicParams,
        'empty',
        parents,
        name,
        {
          audioTrack,
          audioLoop,
          audioFadeOut,
        },
        isGif,
        0
      );
      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
          includeBrandFiles: false,
        };
        await dispatch(getFiles({type: type, id: id, filter}));
      }
    } catch (error) {
      console.log('video converter error', error);
    }
  };

  const exportToGif = async slideShow => {
    setNotificationModalOpened(true);
    const isGif = true;

    try {
      const result = await api.asset.videoConverterWithQueryParams(
        creative._id,
        version,
        q2a ? q2a : dynamicParams,
        'empty',
        parents,
        name,
        {
          audioTrack,
          audioLoop,
          audioFadeOut,
        },
        isGif,
        gifFps,
        slideShow ? gifFrames : undefined
      );
      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
          includeBrandFiles: false,
        };
        await dispatch(getFiles({type: type, id: id, filter}));
      }
    } catch (error) {
      console.log('video converter error', error);
    }
  };

  /** this will only work on the same domain, using localhost you will always get frameValue 0.0 because contentDocument will be null and parsed to number that will be zero
   */
  const exportScreenshot = async () => {
    try {
      const element = document.getElementById(key);
      const frameValue = Number(
        element?.contentDocument?.getElementById('debug-label')?.textContent?.slice(0, 4) || 0
      );
      // console.log('frameValue', frameValue, key); // look at comment 8 lines above
      if (!typeof frameValue === 'number' || Number.isNaN(frameValue)) {
        return;
      }
      setNotificationModalOpened(true);
      // console.log('parents', parents);
      const isGif = true;
      const result = await api.asset.videoConverterWithQueryParams(
        creative._id,
        version,
        q2a ? q2a : dynamicParams,
        frameValue.toFixed(2),
        parents,
        name,
        false,
        isGif
      );

      if (result.data.msg.success) {
        // get assets
        const filter = {
          ignoreTemplates: true,
          includeBrandFiles: false,
        };
        await dispatch(getFiles({type: type, id: id, filter}));
      }
    } catch (error) {
      console.log('screenshot error', error);
    }
  };

  const getFileNameFromUrl = url => {
    try {
      // console.log('files:', files, url.substring(url.lastIndexOf('/')), url);
      const file = files.find(
        f => f.versions.at(-1).name === url.substring(url.lastIndexOf('/') + 1)
      );
      // console.log('file', file);
      if (file) {
        // console.log('displayName', file.displayName);
        return file.displayName;
      }
      return url.substring(url.lastIndexOf('/') + 1);
    } catch (e) {
      console.log(e);
      return 'image';
    }
  };

  useEffect(() => {
    console.log('this should rerend');
  }, [gifFrames]);

  // console.log(creative.parents, 'parents');

  return (
    <Modal show={show} onHide={onClose} centered size="xl">
      <Modal.Header style={{justifyContent: 'space-between'}}>
        <Modal.Title style={{fontWeight: 'bold'}}>Dynamic Creative Export</Modal.Title>
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex flex-column w-40">
            <div style={{width: '100%', height: '50px'}} className="form-group pr-4 ">
              <span className="dco-edit-label">Name</span>
              <input
                type="text"
                value={name}
                className="form-control form-control-sm dco-edit-input"
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div
              className="iframe-holder"
              style={{
                display: 'block',
                maxWidth: `calc(1px *${
                  creative.versions[version].width
                } * ${maxSize} / max(${maxSize}, ${creative.versions[version].height + 50},${
                  creative.versions[version].width
                }))`,
                maxHeight: `calc(1px * ${creative.versions[version].height +
                  50} * ${maxSize} / max(${maxSize}, ${creative.versions[version].height + 50},${
                  creative.versions[version].width
                }))`,
              }}
            >
              <iframe
                key={key}
                title={key}
                anonymous="true"
                className=" border-0"
                src={dynamicSrc}
                id={key}
                style={{
                  width: creative.versions[version].width,
                  height: creative.versions[version].height + 50,
                  marginRight: '15px',
                  padding: 0,
                  transformOrigin: '0 0',
                  transform: `scale(calc(${maxSize} / max(${maxSize}, ${creative.versions[version]
                    .height + 50}, ${creative.versions[version].width})))`,
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column w-40">
            {/*<h3>Settings</h3>
            <div>
              <Switch
                label={'Preprocess asset video'}
                checked={true}
                handleChange={() => console.log('not implemented')}
              />
            </div>
            <hr style={{borderTop: '1px solid #DEE2E6', width: '100%'}} />*/}
            <h4>Export as</h4>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <p style={{width: 50}}>Image</p>{' '}
              <Button type="button" className="dco-button primary " onClick={exportScreenshot}>
                Render this frame
              </Button>
            </div>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <p style={{width: 50}}>Video</p>
              <Button type="button" className="dco-button primary" onClick={exportToVideo}>
                Render video
              </Button>
              <div>
                {!addingAudio ? (
                  <a
                    type="button"
                    className="simple-txt-btn"
                    style={{color: 'black', textDecoration: 'underline', marginLeft: 15}}
                    onClick={() => setAddingAudio(true)}
                  >
                    {audioTrack ? audioTrack.name : 'Add audio'}
                  </a>
                ) : (
                  <a
                    type="button"
                    className="simple-txt-btn"
                    style={{color: 'black', textDecoration: 'underline', marginLeft: 15}}
                    onClick={() => {
                      setAddingAudio(false);
                      setAudioTrack(false);
                    }}
                  >
                    {audioTrack ? `${audioTrack.name}` : 'Add audio'}
                    {audioTrack ? <span style={{color: 'red', marginLeft: 4}}>X</span> : null}
                  </a>
                )}
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <p style={{width: 50}}>Gif</p>

              <a
                type="button"
                className="simple-txt-btn"
                style={{color: 'black', textDecoration: 'underline'}}
                onClick={() => setShowGifOptions(true)}
              >
                Select frames
              </a>
            </div>
            {addingAudio ? (
              <div style={{marginTop: 15}}>
                <div style={{marginBottom: 10}}>
                  <FileSelect
                    value={audioTrack}
                    setValue={value => {
                      // console.log('audio track value', value);
                      setAudioTrack(value);
                      // setAddingAudio(false);
                    }}
                    includeBrandFiles={false}
                    handleCallback={() => {}}
                    audio
                    autoOpen
                    type={
                      location.pathname.indexOf('file') === -1 &&
                      location.pathname.indexOf('brand') !== -1
                        ? 'brand'
                        : undefined
                    }
                    filesId={id}
                  />
                </div>
                {audioTrack && (
                  <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
                    <span className="dco-edit-label">Fade Out (seconds)</span>
                    <input
                      type="number"
                      value={audioFadeOut}
                      onChange={e =>
                        parseInt(e.currentTarget.value, 10) > 0
                          ? setAudioFadeOut(e.currentTarget.value)
                          : setAudioFadeOut(0)
                      }
                      className="form-control form-control-sm"
                    />
                    <div style={{display: 'flex', alignItems: 'center', height: 50}}>
                      <span className="dco-edit-label">Loop</span>
                      <input
                        type="checkbox"
                        value={audioLoop}
                        onChange={e => setAudioLoop(!audioLoop)}
                        style={{marginLeft: 10, marginBottom: 5}}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {showGifOptions && (
              <div style={{padding: 10}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p>choose frames and duration of each frame</p>
                  <DraggableList
                    items={gifFrames.map((gf, i) => (
                      <GifFrameItem
                        start={gf.start}
                        length={gf.length}
                        index={i}
                        setFrames={setGifFrames}
                        getTimeFromPlayer={e => {
                          console.log(e, key);
                          const element = document.getElementById(key);
                          return element.contentWindow.grid8player.timelineMaster._time;
                        }}
                      />
                    ))}
                    setFrames={setGifFrames}
                  />{' '}
                  <button
                    className="simple-txt-btn"
                    style={{margin: 5, padding: 10}}
                    onClick={() => {
                      setGifFrames(old => [
                        ...old,
                        {start: old?.at(-1)?.start || '0.00', length: '1.5'},
                      ]);
                    }}
                  >
                    + add extra frame
                  </button>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <button
                      // style={{margin: 5, padding: 10}}
                      className="dco-button primary "
                      onClick={() => exportToGif(true)}
                    >
                      Make gif slide show
                    </button>
                  </div>
                </div>
                {false && (
                  <div>
                    <p>Or set a fixed fps:</p>
                    <div style={{width: 120}}>
                      <div
                        className="form-group"
                        style={{display: 'flex', flexDirection: 'column'}}
                      >
                        <span className="dco-edit-label">FPS</span>
                        <input
                          type="number"
                          value={gifFps}
                          onChange={e => setGifFps(e.currentTarget.value)}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <button
                        // style={{width: '100%', margin: 5, padding: 10}}
                        className="dco-button primary "
                        onClick={() => exportToGif(false)}
                      >
                        Make gif video
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            <h4>Select Shot</h4>
            <Tabs
              defaultActiveKey={defaultedTab}
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={e => {
                console.log(e, key);
                const element = document.getElementById(key);
                element.contentWindow.grid8player.timelineMaster.pause('shot' + e);
              }}
            >
              {!!dynamicOptions.adColumns.length && (
                <Tab eventKey="ad" title="Ad">
                  <div className="accordion-body">
                    {dynamicOptions.adColumns.map((option, index) => {
                      switch (option.type) {
                        case 'jpg':
                        case 'png':
                        case 'svg':
                          return (
                            <div
                              style={{width: '100%', height: '100px'}}
                              className="form-group pr-4 mt-4 "
                            >
                              <span className="dco-edit-label">{option?.name}</span>
                              {id && (
                                <div className="d-flex justify-content-between align-items-center">
                                  <FileSelect
                                    value={
                                      option.image || option?.text
                                        ? {name: getFileNameFromUrl(option.text), url: option.text}
                                        : false
                                    }
                                    setValue={value => {
                                      const newOptions = [...dynamicOptions.adColumns];
                                      newOptions[index].image = value;
                                      setDynamicOptions({
                                        ...dynamicOptions,
                                        adColumns: newOptions,
                                      });
                                    }}
                                    handleCallback={() => {}}
                                    type={
                                      location.pathname.indexOf('file') === -1 &&
                                      location.pathname.indexOf('brand') !== -1
                                        ? 'brand'
                                        : undefined
                                    }
                                    filesId={id}
                                  />
                                  <div
                                    role="button"
                                    onClick={() => {
                                      const newOptions = [...dynamicOptions.adColumns];
                                      newOptions[index].image = null;
                                      setDynamicOptions({
                                        ...dynamicOptions,
                                        adColumns: newOptions,
                                      });
                                    }}
                                    style={{
                                      color: 'red',
                                      fontSize: '16px',
                                    }}
                                  >
                                    x
                                  </div>
                                </div>
                              )}
                              <div
                                style={{width: '100%', height: '50px', paddingLeft: 20}}
                                className="form-group pr-4 "
                              >
                                <span className="dco-edit-label">Manual url input</span>
                                <input
                                  type="text"
                                  value={option.image?.url || ''}
                                  placeholder=""
                                  disabled={!!q2a}
                                  className="form-control form-control-sm dco-edit-input"
                                  onChange={e => {
                                    const newOptions = [...dynamicOptions.adColumns];
                                    newOptions[index].image = {url: e.target.value};
                                    setDynamicOptions({
                                      ...dynamicOptions,
                                      adColumns: newOptions,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div
                              style={{width: '100%', height: '50px'}}
                              className="form-group pr-4 "
                            >
                              <span className="dco-edit-label">{option?.name}</span>
                              <input
                                type="text"
                                value={option.text}
                                disabled={!!q2a}
                                className="form-control form-control-sm dco-edit-input"
                                onChange={e => {
                                  const newOptions = [...dynamicOptions.adColumns];
                                  newOptions[index].text = e.target.value;
                                  setDynamicOptions({
                                    ...dynamicOptions,
                                    adColumns: newOptions,
                                  });
                                }}
                              />
                            </div>
                          );
                      }
                    })}
                  </div>
                </Tab>
              )}
              {!!dynamicOptions.shotColumns.flat().length &&
                dynamicOptions.shotColumns.map((options, shot) => {
                  // console.log(shot, options, 'test');
                  return (
                    <Tab eventKey={shot} title={shot + 1}>
                      <div className="accordion-body">
                        {options.map((option, index) => {
                          switch (option.type) {
                            case 'jpg':
                            case 'png':
                            case 'svg':
                              return (
                                <div
                                  style={{width: '100%', height: '50px'}}
                                  className="form-group pr-4 mt-4 "
                                >
                                  <span className="dco-edit-label">{option?.name}</span>
                                  {id && (
                                    <div className="d-flex justify-content-between align-items-center">
                                      <FileSelect
                                        value={option.image}
                                        setValue={value => {
                                          const newOptions = [...dynamicOptions.shotColumns];
                                          // console.log({newOptions, shot, index});
                                          newOptions[shot][index].image = value;
                                          setDynamicOptions({
                                            ...dynamicOptions,
                                            shotColumns: newOptions,
                                          });
                                        }}
                                        handleCallback={() => {}}
                                        type={
                                          location.pathname.indexOf('file') === -1 &&
                                          location.pathname.indexOf('brand') !== -1
                                            ? 'brand'
                                            : undefined
                                        }
                                        filesId={id}
                                      />
                                      <div
                                        role="button"
                                        onClick={() => {
                                          const newOptions = [...dynamicOptions.shotColumns];
                                          newOptions[shot][index].image.url = null;
                                          setDynamicOptions({
                                            ...dynamicOptions,
                                            shotColumns: newOptions,
                                          });
                                        }}
                                        style={{
                                          color: 'red',
                                          fontSize: '16px',
                                        }}
                                      >
                                        x
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            default:
                              return (
                                <div
                                  style={{width: '100%', minHeight: '50px'}}
                                  className="form-group pr-4 "
                                >
                                  <span className="dco-edit-label">{option?.name}</span>
                                  <textarea
                                    value={option.text}
                                    disabled={!!q2a}
                                    className="form-control form-control-sm dco-edit-input"
                                    onChange={e => {
                                      const newOptions = [...dynamicOptions.shotColumns];
                                      newOptions[shot][index].text = e.target.value;
                                      setDynamicOptions({
                                        ...dynamicOptions,
                                        shotColumns: newOptions,
                                      });
                                    }}
                                  />
                                </div>
                              );
                          }
                        })}
                      </div>
                    </Tab>
                  );
                })}
            </Tabs>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="dco-button gray-600-background" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
      <ExportNotificationModal
        when={notificationModalOpened}
        onClose={() => {
          setNotificationModalOpened(false);
        }}
      />
    </Modal>
  );
};

export const GifFrameItem = ({start, length, index, setFrames, getTimeFromPlayer}) => {
  return (
    <div style={{display: 'flex'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: 25,
          height: 24,
          cursor: 'pointer',
          marginRight: 4,
          marginTop: 3,
          padding: 3,
        }}
      >
        {[1, 2, 3].map(l => (
          <div style={{height: 4, backgroundColor: '#333', borderRadius: 2}}></div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          marginRight: 5,
          displaye: 'flex',
          alignItems: 'center',
          border: '2px solid #ccc',
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        <button
          style={{
            color: '#007bff',
            backgroundColor: 'white',
            border: 'none',
            borderRight: '2px solid white',
            padding: 2,
            paddingLeft: 5,
            fontSize: 16,
            fontWeight: 'bolder',
            cursor: 'pointer',
            borderRadius: '5px 0 0 px',
          }}
          onClick={e => {
            setFrames(oldFrames => {
              console.log('oldFrames', oldFrames, e.target.value);
              try {
                oldFrames[index].start = getTimeFromPlayer();
              } catch (error) {
                console.log(
                  "probably error because you're on localhost, this will work in staging/prod"
                );
              }
              return [...oldFrames];
            });
          }}
        >
          o
        </button>
        <input
          type="number"
          placeholder="Timeline"
          value={start}
          style={{
            border: 'none',
            outline: 'none',
            padding: 2,
            fontSize: 16,
            flexGrow: 1,
            borderRadius: '0 5px 5px 0',
          }}
          onChange={e => {
            setFrames(oldFrames => {
              console.log('oldFrames', oldFrames, e.target.value);
              oldFrames[index].start = e.target.value;
              return [...oldFrames];
            });
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginRight: 5,
          displaye: 'flex',
          alignItems: 'center',
          border: '2px solid #ccc',
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        <input
          type="number"
          placeholder="Length"
          value={length}
          style={{
            border: 'none',
            outline: 'none',
            padding: 2,
            paddingLeft: 10,
            fontSize: 16,
            flexGrow: 1,
            borderRadius: '0 5px 5px 0',
          }}
        />
      </div>
    </div>
  );
};

export default CreativeExportModal;
