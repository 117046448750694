import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from './../../api';

export const captureLiveVideo = api.videobuilder.captureLiveVideo;

export const getFiles = createAsyncThunk(
  'file/getFiles',
  async ({type, id, filter}, {rejectWithValue}) => {
    try {
      const response = await api.asset.getFiles(type, id, filter);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const getFile = createAsyncThunk('file/getFile', async ({id, params}, {rejectWithValue}) => {
  try {
    const response = await api.asset.getFile(id, params);
    return response.data;
  } catch (e) {
    const {data, status, statusText, message} = e.response;
    return rejectWithValue({data, status, statusText, message});
  }
});

export const uploadFiles = createAsyncThunk(
  'file/uploadFiles',
  async ({tmpId, type, id, file, path, filePath}, {rejectWithValue, dispatch}) => {
    const resolvedPath = filePath ? filePath : file.path;

    try {
      const formData = new FormData();
      formData.append(
        'asset',
        file,
        path === '/'
          ? resolvedPath.replace(/^\//, '')
          : `${path.replace(/^\//, '')}/${resolvedPath.replace(/^\//, '')}`
      );
      const response = await api.asset.uploadFile(type, id, formData, percent =>
        dispatch(updateProgress({percent, tmpId}))
      );
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  'file/uploadAvatar',
  async ({type, id, file}, {rejectWithValue}) => {
    try {
      const formData = new FormData();
      formData.append('image', file, file.filename);
      const response = await api.asset.uploadAvatar(type, id, formData);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const addLink = createAsyncThunk(
  'file/addLink',
  async ({type, id, form, path}, {rejectWithValue}) => {
    try {
      const response = await api.asset.addLink(type, id, form, path);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

// export const findAvatars = createAsyncThunk(
//   'file/findAvatarsByIds',
//   async ({ type, ids }, { rejectWithValue }) => {
//     try {
//       const response = await api.asset.findAvatars(type, ids);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

export const uploadDco = createAsyncThunk(
  'file/uploadDco',
  async ({key, type, id, form}, {rejectWithValue, dispatch}) => {
    try {
      const formData = new FormData();
      formData.append('displayName', form.dcoTitle);
      formData.append('dcoTitle', form.dcoTitle);
      formData.append('manifestFile', form.manifestFile);
      formData.append('path', form.path);
      formData.append('sizes', JSON.stringify(form.sizes));
      form.files.forEach(file => formData.append('dco', file, file.path));

      const response = await api.asset.uploadDco(type, id, formData, percent =>
        dispatch(updateProgress({percent, key}))
      );
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const uploadCreative = createAsyncThunk(
  'file/uploadCreative',
  async ({key, type, id, form}, {rejectWithValue, dispatch}) => {
    try {
      const formData = new FormData();
      formData.append('displayName', form.displayName);
      formData.append('creativeTitle', form.creativeTitle);
      formData.append('height', form.height);
      formData.append('width', form.width);
      formData.append('indexFile', form.indexFile);
      formData.append('path', form.path);
      for (let i = 0; i < form?.tags?.length; i++) {
        formData.append('tags[]', form.tags[i]);
      }

      form.files?.forEach(file => {
        formData.append('creative', file, file.path);
      });

      const response = await api.asset.uploadCreative(type, id, formData, percent =>
        dispatch(updateProgress({percent, key}))
      );
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateCreative = createAsyncThunk(
  'file/updateCreative',
  async ({key, id, form}, {rejectWithValue, dispatch}) => {
    try {
      const formData = new FormData();
      formData.append('creativeTitle', form.creativeTitle);
      formData.append('height', form.height);
      formData.append('width', form.width);
      formData.append('indexFile', form.indexFile);
      for (let i = 0; i < form?.tags?.length; i++) {
        formData.append('tags[]', form.tags[i]);
      }

      form.files?.forEach(file => formData.append('creative', file, file.path));

      const response = await api.asset.updateCreative(id, formData, percent =>
        dispatch(updateProgress({percent, key}))
      );
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteAsset = createAsyncThunk(
  'file/deleteAsset',
  async ({id}, {rejectWithValue, dispatch}) => {
    try {
      const response = await api.asset.deleteAsset(id);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const getDeletedFiles = createAsyncThunk(
  'file/getDeletedFiles',
  async ({type, id, filter}, {rejectWithValue}) => {
    try {
      const response = await api.asset.getDeletedFiles(type, id, filter);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const file = createSlice({
  name: 'file',
  initialState: {
    asyncState: {},
    items: [],
    deletedItems: [],
    item: {},
    form: {},
    avatars: {
      user: {},
      brand: {},
      team: {},
      project: {},
    },
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
    updateProgress(state, action) {
      if (action.payload.tmpId && state.form[action.payload.tmpId])
        state.form[action.payload.tmpId].progress = action.payload.percent;
      if (action.payload.key && state.asyncState[action.payload.key])
        state.asyncState[action.payload.key].progress = action.payload.percent;
    },
  },
  extraReducers: {
    [getFiles.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {items: [], fetching: true, error: null};
    },
    [getFiles.fulfilled]: (state, {payload, meta}) => {
      state.items = payload;
      state.asyncState[meta.arg.key] = {fetching: false, error: null, items: payload};
    },
    [getFiles.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {items: [], fetching: false, error: payload};
    },
    [getDeletedFiles.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {deletedItems: [], fetching: true, error: null};
    },
    [getDeletedFiles.fulfilled]: (state, {payload, meta}) => {
      state.deletedItems = payload;
      state.asyncState[meta.arg.key] = {fetching: false, error: null, deletedItems: payload};
    },
    [getDeletedFiles.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {deletedItems: [], fetching: false, error: payload};
    },
    //
    [getFile.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null, item: {}};
    },
    [getFile.fulfilled]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: null, item: payload};
    },
    [getFile.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {item: {}, fetching: false, error: payload};
    },
    //
    [uploadFiles.pending]: (state, {meta}) => ({
      ...state,
      form: {...state.form, [meta.arg.tmpId]: {fetching: true, error: null}},
    }),
    [uploadFiles.fulfilled]: (state, {meta, payload}) => {
      delete state.form[meta.arg.tmpId];
      state.items = payload;
    },
    [uploadFiles.rejected]: (state, {payload, meta}) => {
      state.form[meta.arg.tmpId].error = payload;
      state.form[meta.arg.tmpId].fetching = false;
    },
    //
    [uploadAvatar.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [uploadAvatar.fulfilled]: (state, {payload, meta}) => {
      state.avatars[meta.arg.type][meta.arg.id] = payload;
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [uploadAvatar.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [addLink.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null, items: []};
    },
    [addLink.fulfilled]: (state, {payload, meta}) => {
      state.items = payload;
      state.asyncState[meta.arg.key] = {
        fetching: false,
        error: null,
        res: payload,
        items: payload,
      };
    },
    [addLink.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [updateCreative.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateCreative.fulfilled]: (state, {payload, meta}) => {
      state.items = payload;
      delete state.asyncState[meta.arg.key];
    },
    [updateCreative.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [uploadCreative.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [uploadCreative.fulfilled]: (state, {payload, meta}) => {
      state.items = payload;
      delete state.asyncState[meta.arg.key];
    },
    [uploadCreative.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [uploadDco.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [uploadDco.fulfilled]: (state, {payload, meta}) => {
      state.items = payload;
      delete state.asyncState[meta.arg.key];
    },
    [uploadDco.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    // [findAvatars.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [findAvatars.fulfilled]: (state, { meta, payload }) => {
    //   const avatars = {};
    //   payload.forEach(avatar => { avatars[avatar.relativeId] = avatar; });
    //   state.avatars[meta.arg.type] = { ...state.avatars[meta.arg.type], ...avatars };
    //   state.asyncState[meta.arg.key] = { fetching: false, error: null, result: payload };
    // },
    // [findAvatars.rejected]: (state, { meta, payload }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },
    //
    [deleteAsset.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteAsset.fulfilled]: (state, {payload, meta}) => {
      state.items = payload;
      delete state.asyncState[meta.arg.key];
    },
    [deleteAsset.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
  },
});

export const {dismissError, updateProgress} = file.actions;
export default file.reducer;
