import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import SingleInputForm from '../../helpers/singleInputForm';
import {IconError} from '../../helpers/error';
import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import Project from './project';
import {getProjects, updateProject, createProject, dismissError} from './slice';
import {Dropdown, FormControl} from 'react-bootstrap';

const ProjectListPage = ({dispatch, team, project, match, location}) => {
  const [showForm, toggleForm] = useState(false);
  const [source, setSource] = useState(null);
  const [projectType, setProjectType] = useState(false);
  const [usingSetupFilter, setUsingSetupFilter] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const [filter] = useState({
    sortType: 'desc',
    sortField: 'createdAt',
    page: parseInt(queryString.parse(location.search).page, 10)
      ? parseInt(queryString.parse(location.search).page, 10)
      : 1,
    limit: 1000,
    teamId: match.params.id,
  });

  useEffect(() => {
    dispatch(dismissError('create'));
  }, [showForm]);

  useEffect(() => {
    dispatch(getProjects({...filter, teamId: match.params.id, includeDCOSetup: true}));
  }, [filter, match.params.id, dispatch]);

  const drag = stat => {
    dispatch(dismissError(`status/${stat.draggableId}`));
    setSource(stat.source.droppableId);
  };

  const drop = async stat => {
    if (stat.destination && stat.destination.droppableId !== stat.source.droppableId)
      await dispatch(
        updateProject({
          key: `status/${stat.draggableId}`,
          id: stat.draggableId,
          form: {status: stat.destination.droppableId},
        })
      );
    setSource(null);
  };

  const create = async name => {
    return dispatch(
      createProject({
        key: 'create',
        form: {teamId: match.params.id, name, status: 'draft', type: projectType},
      })
    );
  };

  useEffect(() => {
    if (!showForm) {
      setProjectType(false);
    }
  }, [showForm]);

  console.log('proj', project);

  // if (!team.item._id) return null;
  return (
    <Access
      type="project"
      action="list"
      parents={{brandId: (team.item.parents || {}).brandId, teamId: match.params.id}}
    >
      {({isAllowed}) => (
        <DragDropContext onDragStart={drag} onDragEnd={drop}>
          <div className="d-flex w-100 " style={{marginBottom: 30}}>
            <div style={{width: '20px', height: '20px'}} className="icon icon-search mr-2" />
            <input
              type="text"
              className="form-control form-control-sm dco-search col-2"
              placeholder="Filter/Search"
              onChange={e => setSearchStr(e.target.value)}
              value={searchStr}
            />
            {project.dcoList && (
              <CustomDropdown
                items={[
                  {label: 'Clear filter'},
                  ...project.dcoList.map(i => ({...i, label: i.setupName})),
                ]}
                onSelect={item => {
                  console.log('on select', item);
                  setUsingSetupFilter(item.label === 'Clear filter' ? false : item);
                }}
                selected={usingSetupFilter}
              />
            )}
          </div>

          <div className="project-board d-flex">
            <div className="">
              <h3 className="mb-2">Draft</h3>

              <Droppable droppableId="draft" isDropDisabled={source === 'draft'} placeholder={null}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="project-board-item"
                  >
                    {isAllowed('create') && (
                      <div
                        className="project-item d-flex create-project"
                        onClick={() => toggleForm(!showForm)}
                      >
                        <div className="project-thumbnail project-body w-100 d-flex justify-content-between flex-column">
                          <div className="d-flex align-items-center d-flex justify-content-center w-100 flex-grow-1">
                            <FontAwesomeIcon icon="plus-circle" className="mr-1 text-primary" />{' '}
                            Create a project
                          </div>
                        </div>
                      </div>
                    )}
                    {isAllowed('create') && showForm && (
                      <div className="project-item d-flex align-items-center">
                        <div className="pl-2 d-flex align-items-center project-body">
                          <IconError
                            asyncState={project.asyncState.create}
                            fontSize="12"
                            className="mr-2"
                          />
                          {projectType ? (
                            <SingleInputForm
                              placeholder="Project name"
                              showError={false}
                              asyncState={project.asyncState.create}
                              onSubmit={create}
                              show={toggleForm}
                            />
                          ) : (
                            <div>
                              <p onClick={e => setProjectType('regular')}>Standard</p>
                              <p onClick={e => setProjectType('videobuilder')}>Videobuilder</p>
                              <p onClick={e => setProjectType('socialdpa')}>Social DPA</p>
                              <p onClick={e => setProjectType('dco')}>DCO</p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {project.items
                      .filter(
                        item =>
                          item.status === 'draft' &&
                          (searchStr.length
                            ? item.name.toLowerCase().includes(searchStr.toLowerCase())
                            : true) &&
                          !(project.asyncState[`status/${item._id}`] || {}).fetching
                      )
                      .filter(item => {
                        if (!usingSetupFilter) return item;
                        console.log(usingSetupFilter);
                        return usingSetupFilter.projects.indexOf(item._id) !== -1;
                      })
                      .map((item, index) => (
                        <Draggable
                          draggableId={item._id}
                          index={index}
                          key={item._id}
                          isDragDisabled={!isAllowed('updateStatus')}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Project
                                item={item}
                                name={item.name}
                                teamId={item.parents.teamId}
                                id={item._id}
                                status={item.status}
                                statusBorder={'#afafaf'}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div className="">
              <h3 className="mb-2">Active</h3>
              <Droppable droppableId="active" isDropDisabled={source === 'active'}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="project-board-item"
                  >
                    {project.items
                      .filter(
                        item =>
                          item.status === 'active' &&
                          (searchStr.length
                            ? item.name.toLowerCase().includes(searchStr.toLowerCase())
                            : true) &&
                          !(project.asyncState[`status/${item._id}`] || {}).fetching
                      )
                      .filter(item => {
                        if (!usingSetupFilter) return item;
                        console.log(usingSetupFilter);
                        return usingSetupFilter.projects.indexOf(item._id) !== -1;
                      })
                      .map((item, index) => (
                        <Draggable
                          draggableId={item._id}
                          index={index}
                          key={item._id}
                          isDragDisabled={!isAllowed('updateStatus')}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Project
                                item={item}
                                name={item.name}
                                teamId={item.parents.teamId}
                                id={item._id}
                                status={item.status}
                                statusBorder={'#081ee0'}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>

            <div className="">
              <h3 className="mb-2">Completed</h3>
              <Droppable droppableId="completed" isDropDisabled={source === 'completed'}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="project-board-item"
                  >
                    {project.items
                      .filter(
                        item =>
                          item.status === 'completed' &&
                          (searchStr.length
                            ? item.name.toLowerCase().includes(searchStr.toLowerCase())
                            : true) &&
                          !(project.asyncState[`status/${item._id}`] || {}).fetching
                      )
                      .filter(item => {
                        if (!usingSetupFilter) return item;
                        console.log(usingSetupFilter);
                        return usingSetupFilter.projects.indexOf(item._id) !== -1;
                      })
                      .map((item, index) => (
                        <Draggable
                          draggableId={item._id}
                          index={index}
                          key={item._id}
                          isDragDisabled={!isAllowed('updateStatus')}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Project
                                item={item}
                                name={item.name}
                                teamId={item.parents.teamId}
                                id={item._id}
                                status={item.status}
                                statusBorder={'#1dd1a1'}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
      )}
    </Access>
  );
};

const CustomDropdown = ({items, onSelect, selected}) => {
  console.log(items);
  const [filter, setFilter] = useState('');
  const [show, setShow] = useState(false);

  const handleToggle = nextShow => setShow(nextShow);

  const handleFilterChange = e => setFilter(e.target.value);

  const filteredItems = items
    .filter(item => item.label && item.label.toLowerCase().includes(filter.toLowerCase()))
    .slice(0, 5);

  return (
    <Dropdown show={show} onToggle={handleToggle}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        style={{
          backgroundColor: 'white',
          borderColor: 'none',
          border: 'none',
          borderBottom: '1px solid #081EE0',
          borderRadius: 0,
          color: '#AFAFAF',
          borderRight: '0.3rem',
          marginRight: 10,
          marginLeft: 25,
          paddingTop: 3,
          paddingBottom: 4,
        }}
      >
        Fiter: {selected.label || 'setup name'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <FormControl
          autoFocus
          placeholder="Type to filter..."
          onChange={handleFilterChange}
          value={filter}
        />
        {filteredItems.map((item, index) => (
          <Dropdown.Item key={index} onClick={() => onSelect(item)}>
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect(store => ({
  project: store.project,
  team: store.team,
}))(ProjectListPage);
