import {configureStore, combineReducers} from '@reduxjs/toolkit';
import auth from './pages/login/slice';
import user from './pages/user/slice';
import brand from './pages/brand/slice';
import team from './pages/team/slice';
import project from './pages/project/slice';
import file from './pages/file/slice';
import comment from './pages/comment/slice';
import color from './pages/color/slice';
import ad from './pages/project/DcoProject/slice';
import shared from './pages/project/DcoProject/components/SharedPage/slice';
import videoTemplates from './pages/brand/Assets/Templates/slice';
import setups from './services/brand/dcoSetup/redux';

const store = configureStore({
  reducer: combineReducers({
    auth,
    user,
    brand,
    team,
    project,
    file,
    comment,
    color,
    ad,
    videoTemplates,
    setups,
    shared,
  }),
  devTools: process.env.REACT_APP_ENV === 'development',
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
