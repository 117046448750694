import React, {useState, useEffect} from 'react';
import Select from 'react-select/creatable';
import {unwrapResult} from '@reduxjs/toolkit';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import Access from './../../helpers/access';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {createBrand, updateBrand, getBrand} from './slice';

const customStyles = {
  control: () => ({
    backgroundColor: '#fdfffe',
    backgroundClip: 'padding-box',
    border: '1px solid #eaeff1',
    borderRadius: '0.3125rem',
    cursor: 'text',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
};

const BrandForm = ({dispatch, brand, hide, id}) => {
  const [initialForm, setForm] = useState({});

  useEffect(() => {
    (async () => {
      const data = id ? unwrapResult(await dispatch(getBrand(id))) : {};
      setForm({
        name: data.name || '',
        dcmProfileId: data.dcmProfileId || '',
        adAccounts: data.adAccounts || [],
      });
    })();
  }, [id]);

  const submit = async (form, formik) => {
    const action = id ? updateBrand : createBrand;
    const res = await dispatch(action({id, form}));
    if (action.rejected.match(res)) {
      formik.setErrors(res.payload);
      formik.setSubmitting(false);
    } else {
      hide();
    }
  };

  return (
    <Access type="brand" action="create" overWritRole={false}>
      {({}) => (
        <Modal show onHide={hide}>
          <Modal.Header style={{justifyContent: 'space-between'}}>
            <Modal.Title>{id ? 'Edit brand' : 'Create a new brand'}</Modal.Title>
            <button
              onClick={hide}
              style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
            >
              x
            </button>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={initialForm}
              onSubmit={submit}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
            >
              {({isSubmitting, setFieldValue, values}) => (
                <Form className={isSubmitting ? 'loading' : ''}>
                  <div className="form-group">
                    <Field type="text" name="name" placeholder="Name" className="form-control" />
                    <ErrorMessage
                      name="name"
                      render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      type="text"
                      name="dcmProfileId"
                      placeholder="DCM Profile ID"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="dcmProfileId"
                      render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                    />
                  </div>
                  <div className="form-group">
                    <Select
                      isMulti
                      isClearable
                      styles={customStyles}
                      name="adAccounts"
                      placeholder="Facebook ad accounts"
                      noOptionsMessage={() => null}
                      components={{DropdownIndicator: () => null}}
                      value={(values.adAccounts || []).map(v => ({value: v, label: v})) || []}
                      onChange={values =>
                        setFieldValue('adAccounts', [...new Set((values || []).map(v => v.value))])
                      }
                    />
                    <ErrorMessage
                      name="adAccounts"
                      render={msg => <div className="alert alert-danger mt-1">{msg[0]}</div>}
                    />
                  </div>
                  <div className="form-group text-right">
                    <button className="btn btn-secondary mr-3" type="button" onClick={hide}>
                      Cancel
                    </button>
                    <button className="btn btn-primary" type="submit">
                      Save
                    </button>
                  </div>
                  {brand.error && brand.error.status && !isSubmitting && (
                    <div className="alert alert-danger margins-no mt-4 mb-4 rounded-0 p-2">
                      {brand.error.message}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      )}
    </Access>
  );
};

export default connect(store => ({
  brand: store.brand,
}))(BrandForm);
