import React from 'react';
import {Link} from 'react-router-dom';

const P404 = () => (
  <div className="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
    <img
      src={`${process.env.PUBLIC_URL}/images/notfound.png`}
      style={{height: '40vh', width: '50vw', objectFit: 'contain'}}
      alt="error"
    />
    <div className="d-flex justify-content-center align-items-center flex-column ml-3">
      <h1>Oops!</h1>
      <h2>Page not found</h2>
      <Link to="/">
        <h3 className="text-primary" style={{textDecoration: 'underline'}}>
          Back to brands
        </h3>
      </Link>
    </div>
  </div>
);

export default P404;
