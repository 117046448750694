import React from 'react';

const PresetCellRenderer = props => {
  const {value} = props;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <span>{value}</span>
      <span
        className="icon icon-arrow-left dco-filters-header-icon x10 primary-background mr-2"
        style={{transform: 'rotate(270deg)', width: '14px', height: '14px'}}
      />
    </div>
  );
};

export default PresetCellRenderer;
