import React from 'react';
import {Switch} from '../../../../../../../components';

const CellEditor = props => {
  const {value, colDef, type} = props;

  switch (type) {
    case 'boolean':
      return (
        <div className="d-flex align-items-center" style={{height: '100%'}}>
          <Switch
            checked={value}
            handleChange={t => {
              colDef.valueSetter({...props, newValue: t});
            }}
          />
        </div>
      );

    default:
      return <span>{value}</span>;
  }
};

export default CellEditor;
