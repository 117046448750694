import React from 'react';

import {Switch} from 'components';

const SharedColumns = ({columns}) => {
  const columnsArray = Object.entries(columns)
    .filter(([key]) => key !== 'Ad Name')
    .map(([key, value]) => ({
      key: key === 'State' ? 'Review State' : key,
      value,
      type: key === 'Default' ? 'boolean' : 'text',
    }));

  const renderField = col => {
    switch (col.type) {
      case 'text':
        return (
          <div className="form-group d-flex flex-column flex-1" style={{minWidth: 200}}>
            <span className="dco-edit-label">{col.key}</span>
            <input
              className="from-control form-control-sm dco-edit-input"
              placeholder={col.key}
              value={col.value}
              readOnly
            />
          </div>
        );
      case 'boolean':
        return (
          <div className="form-group d-flex flex-column">
            <span className="dco-edit-label">{col.key}</span>
            <Switch handleChange={() => {}} checked={col.value} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="d-flex gap-2 py-5 flex-wrap">{columnsArray.map(col => renderField(col))}</div>
  );
};

export default SharedColumns;
