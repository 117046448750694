import React from 'react';
import {Switch, Route} from 'react-router-dom';
import SideBar from './sideBarPublic';
import Welcome from '../pages/welcome';
import Login from '../pages/login';
// import Privacy from '../pages/privacy';
import SignUp from '../pages/signUp';
import SharedPage from 'pages/shared/SharedPage';

const PublicLayout = () => {
  return (
    <div className="d-flex container-fluid p-0 m-0">
      <Route
        render={({location}) =>
          ['/signUp', '/shared'].includes(location.pathname) ? null : (
            <div className="sidebar">
              <SideBar />
            </div>
          )
        }
      />
      <div className="content-container">
        <Welcome />
        <Switch>
          <Route path="/login">
            <div className="public-subcontent">
              <Login />
            </div>
          </Route>
          <Route path="/shared/:id">
            <div className="public-page">
              <SharedPage />
            </div>
          </Route>
          {/* <Route path="/privacy">
            <div className="public-subcontent">
              <Privacy />
            </div>
          </Route> */}
          <Route path="/signUp">
            <div className="public-subcontent pl-5 pr-5">
              <SignUp />
            </div>
          </Route>
          <Route path="*" />
        </Switch>
      </div>
    </div>
  );
};

export default PublicLayout;
