import React from 'react';
import {Formik, Form, Field} from 'formik';
import {ErrorMessages} from './error';

const SingleTextAreaForm = ({
  value,
  placeholder = '',
  className = '',
  asyncState = {},
  onSubmit,
  show,
  readOnly = false,
  showButtons = true,
}) => {
  const submit = async ({text}) => {
    const res = await onSubmit(text);
    if (!res || !res.error) show(false);
  };

  return (
    <Formik
      initialValues={{text: value}}
      onSubmit={submit}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({setFieldValue}) => (
        <Form className={asyncState.fetching ? 'loading' : 'w-100'}>
          <div className="form-group border p-1">
            <Field
              autoFocus
              as="textarea"
              name="text"
              placeholder={placeholder}
              className={`border-0 form-control m-0 p-0 rounded-0 ${className} `}
              style={{resize: 'none'}}
              readOnly={readOnly}
            />
            {!readOnly && showButtons && (
              <div className="d-flex justify-content-end">
                <div
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={() => {
                    setFieldValue('text', value);
                    show(false);
                  }}
                >
                  Cancel
                </div>
                <button type="submit" className="btn btn-sm btn-primary">
                  Save
                </button>
              </div>
            )}
          </div>
          {asyncState.error && (
            <ErrorMessages error={asyncState.error} className="mt-1 alert alert-danger" />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SingleTextAreaForm;
