import React from 'react';
import {Button} from 'react-bootstrap';
import Preview from './Preview';
import Access from '../../../../../../helpers/access';

const TablePreview = ({data, setup, assets, handleEdit, parents}) => {
  console.log('parents in tablePreview', parents);
  return (
    <div className="preview-wrapper pt-4 pb-4">
      {handleEdit && (
        <Access type="dco" action="edit" parents={parents}>
          {() => (
            <div className="d-flex justify-content-start w-100">
              <Button
                type="button"
                onClick={() => {
                  handleEdit(data);
                }}
                className="mr-4 dco-button primary px-4 py-1"
              >
                Edit Ad
              </Button>
            </div>
          )}
        </Access>
      )}
      <Preview
        setup={setup}
        shots={data.shots}
        adSizes={data.sizes}
        columnStructure={setup}
        adColumns={data.columns}
        assets={assets}
        overview
        parents={parents}
      />
    </div>
  );
};

export default TablePreview;
