export const shotStates = ['Draft', 'In Review', 'Approved', 'Fulfilled'];
export const editAdStates = ['Draft', 'In review', 'On hold'];

export const customInputStyles = {
  control: provided => {
    return {
      ...provided,
      border: 'none',
      borderRadius: '0',
      borderBottom: '1px solid #081ee0',
    };
  },
  multiValueLabel: (provided, state) => {
    const enabled = state?.data?.value?.enabled;

    return {
      ...provided,
      color: enabled ? 'black' : '#afafaf',
      textDecoration: enabled ? 'none' : 'line-through',
    };
  },

  multiValueRemove: (provided, state) => {
    const enabled = state?.data?.value?.enabled;

    return {
      ...provided,
      ':hover': {
        backgroundColor: 'unset',
      },
      '.icon': {
        backgroundColor: enabled ? 'black' : '#afafaf',
      },
      ':hover .icon': {
        backgroundColor: enabled ? '#afafaf' : 'black',
        color: 'white',
      },
    };
  },
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#ffffff',
    border: '1px solid #afafaf ',
    borderRadius: '1rem',
  }),
};
export const customInputStylesDynamic = {
  control: provided => {
    return {
      ...provided,
      border: 'none',
      borderRadius: '0',
      borderBottom: '1px solid #081ee0',
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      width: '175px',
    };
  },
  multiValueLabel: (provided, state) => {
    const enabled = state?.data?.value?.enabled;

    return {
      ...provided,
      color: enabled ? 'black' : '#afafaf',
      textDecoration: enabled ? 'none' : 'line-through',
    };
  },

  multiValueRemove: (provided, state) => {
    const enabled = state?.data?.value?.enabled;

    return {
      ...provided,
      ':hover': {
        backgroundColor: 'unset',
      },
      '.icon': {
        backgroundColor: enabled ? 'black' : '#afafaf',
      },
      ':hover .icon': {
        backgroundColor: enabled ? '#afafaf' : 'black',
        color: 'white',
      },
    };
  },
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#ffffff',
    border: '1px solid #afafaf ',
    borderRadius: '1rem',
  }),
};

export const customInputStylesSmartCrop = {
  control: provided => {
    return {
      ...provided,
      height: 40,
      minWidth: 80,
      border: 'none',
      borderRadius: '0',
      borderBottom: '1px solid #081ee0',
    };
  },
  multiValueLabel: (provided, state) => {
    const enabled = state?.data?.value?.enabled;

    return {
      ...provided,
      color: enabled ? 'black' : '#afafaf',
      textDecoration: enabled ? 'none' : 'line-through',
    };
  },
  menu: provided => ({
    ...provided,
    zIndex: `9999 !important`,
  }),

  multiValueRemove: (provided, state) => {
    const enabled = state?.data?.value?.enabled;

    return {
      ...provided,
      ':hover': {
        backgroundColor: 'unset',
      },
      '.icon': {
        backgroundColor: enabled ? 'black' : '#afafaf',
      },
      ':hover .icon': {
        backgroundColor: enabled ? '#afafaf' : 'black',
        color: 'white',
      },
    };
  },
  multiValue: provided => ({
    ...provided,
    backgroundColor: '#ffffff',
    border: '1px solid #afafaf ',
    borderRadius: '1rem',
  }),
};

export const stateColorCoding = {
  Draft: 'gray-600-text',
  'In review': 'info-text',
  Approved: 'success-text',
  Rejected: 'danger-text',
  'On hold': 'warning-text',
  Published: 'primary-text',
  Unpublished: 'gray-600-text',
  Changed: 'info-text',
};

export const defaultColDef = {
  flex: 1,
  resizable: true,
  minWidth: 200,
  sortable: true,
};

export const AD_LIMIT = 100;
