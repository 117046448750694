import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from './../../api';
import {} from './../file/slice';

export const getColorGroups = createAsyncThunk(
  'asset/color/getColorGroups',
  async ({brandId}, {rejectWithValue}) => {
    try {
      const response = await api.asset.getColorGrouos(brandId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const addColorGroup = createAsyncThunk(
  'asset/color/addColorGroup',
  async ({brandId, form}, {rejectWithValue}) => {
    try {
      const response = await api.asset.createColorGroup(brandId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateColorGroup = createAsyncThunk(
  'asset/color/updateColorGroup',
  async ({groupId, form}, {rejectWithValue}) => {
    try {
      const response = await api.asset.updateColorGroup(groupId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteColorGroup = createAsyncThunk(
  'asset/color/deleteColorGroup',
  async ({groupId}, {rejectWithValue}) => {
    try {
      const response = await api.asset.deleteColorGroup(groupId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const addColor = createAsyncThunk(
  'asset/color/addColor',
  async ({groupId, form}, {rejectWithValue}) => {
    try {
      const response = await api.asset.addColor(groupId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateColor = createAsyncThunk(
  'asset/color/updateColor',
  async ({groupId, colorId, form}, {rejectWithValue}) => {
    try {
      const response = await api.asset.updateColor(groupId, colorId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteColor = createAsyncThunk(
  'asset/color/deleteColor',
  async ({groupId, colorId}, {rejectWithValue}) => {
    try {
      const response = await api.asset.deleteColor(groupId, colorId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const brand = createSlice({
  name: 'color',
  initialState: {
    asyncState: {},
    items: {},
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
  },
  extraReducers: {
    [getColorGroups.pending]: (state, {meta}) => {
      state.items = {};
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [addColorGroup.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateColorGroup.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteColorGroup.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [addColor.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateColor.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteColor.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },

    [getColorGroups.fulfilled]: (state, {payload, meta}) => {
      payload.map(group => (state.items[group._id] = group));
      delete state.asyncState[meta.arg.key];
    },
    [addColorGroup.fulfilled]: (state, {payload, meta}) => {
      state.items[payload._id] = payload;
      delete state.asyncState[meta.arg.key];
    },
    [updateColorGroup.fulfilled]: (state, {payload, meta}) => {
      state.items[payload._id] = payload;
      delete state.asyncState[meta.arg.key];
    },
    [deleteColorGroup.fulfilled]: (state, {payload, meta}) => {
      delete state.items[meta.arg.groupId];
      delete state.asyncState[meta.arg.key];
    },
    [addColor.fulfilled]: (state, {payload, meta}) => {
      state.items[payload._id] = payload;
      delete state.asyncState[meta.arg.key];
    },
    [updateColor.fulfilled]: (state, {payload, meta}) => {
      state.items[payload._id] = payload;
      delete state.asyncState[meta.arg.key];
    },
    [deleteColor.fulfilled]: (state, {payload, meta}) => {
      state.items[payload._id] = payload;
      delete state.asyncState[meta.arg.key];
    },

    [getColorGroups.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [addColorGroup.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [updateColorGroup.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [deleteColorGroup.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [addColor.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [updateColor.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [deleteColor.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
  },
});

export const {dismissError} = brand.actions;
export default brand.reducer;
