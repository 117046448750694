/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Select, {components} from 'react-select';

import {replacePropertyInArray} from '../../../../../brand/Dco/helpers';
import {customInputStyles} from '../../../consts';

const MultiValueRemove = props => {
  const {enabled} = props.data.value;
  return (
    <components.MultiValueRemove {...props}>
      <div className={`icon ${enabled ? 'icon-view' : 'icon-invisible'}`} />
    </components.MultiValueRemove>
  );
};

const SizeSelect = ({handleChange, sizes = [], assets = []}) => {
  const handleSizeChange = newSizes => {
    const clickedSize = sizes.filter(size => !newSizes.some(s => s.value === size))[0];
    const updatedSizes = replacePropertyInArray(
      sizes,
      sizes.indexOf(clickedSize),
      !clickedSize.enabled,
      'enabled'
    );
    handleChange(updatedSizes);
  };

  return (
    <Select
      isClearable={false}
      styles={customInputStyles}
      isMulti
      menuIsOpen={false}
      components={{
        MultiValueRemove,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
      cacheOptions={false}
      value={sizes.map(s => ({
        label:
          assets?.files?.[s.assetId]?.creativeSetupTitle || s?.name || `${s.width}x${s.height}`,
        value: s,
      }))}
      placeholder="Sizes"
      onChange={handleSizeChange}
      options={sizes.map(s => ({
        label:
          assets?.files?.[s.assetId]?.creativeSetupTitle || s?.name || `${s.width}x${s.height}`,
        value: s,
      }))}
      getOptionValue={option => option.value._id}
    />
  );
};

export default SizeSelect;
