import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../api';
// import { findAvatars } from './../../pages/file/slice';

export const getProjects = createAsyncThunk('project/get', async (filter, {dispatch}) => {
  const response = await api.project.getProjects(filter);
  const projects = [...new Set(response.data.items.map(p => p._id))];
  // await Promise.all([
  //   dispatch(findAvatars({ type: 'project', ids: projects })),
  // ]);
  return response.data;
});

export const getProject = createAsyncThunk('project/getProject', async id => {
  const response = await api.project.getProject(id);
  return response.data;
});

export const createProject = createAsyncThunk(
  'project/createProject',
  async ({form}, {rejectWithValue}) => {
    try {
      const response = await api.project.createProject(form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateProject = createAsyncThunk(
  'project/updateProject',
  async ({id, form}, {rejectWithValue}) => {
    try {
      const response = await api.project.updateProject(id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

// export const updateStatus = createAsyncThunk(
//   'project/updateStatus',
//   async ({ id, form }, { rejectWithValue }) => {
//     try {
//       const response = await api.project.updateStatus(id, form);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

export const deleteProject = createAsyncThunk(
  'project/deleteProject',
  async ({id}, {rejectWithValue}) => {
    try {
      const response = await api.project.deleteProject(id);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const project = createSlice({
  name: 'project',
  initialState: {
    asyncState: {},
    item: {},
    items: [],
    total: 0,
    fetching: false,
    error: null,
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
  },
  extraReducers: {
    //
    [getProjects.pending]: state => ({...state, items: [], fetching: true, error: null}),
    [getProjects.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload.items,
      total: payload.total,
      dcoList: payload.dcoList,
      fetching: false,
      error: null,
    }),
    [getProjects.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    //
    [getProject.pending]: state => ({...state, fetching: true, error: null}),
    [getProject.fulfilled]: (state, {payload}) => {
      const updatedPayload = {
        ...payload,
        parents: {...payload.parents, projectId: payload._id},
      };
      return {
        ...state,
        item: updatedPayload,
        fetching: false,
        error: null,
      };
    },
    [getProject.rejected]: (state, {error}) => ({...state, item: null, error, fetching: false}),
    //
    [createProject.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [createProject.fulfilled]: (state, {payload, meta}) => {
      state.items.unshift(payload);
      delete state.asyncState[meta.arg.key];
    },
    [createProject.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [updateProject.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateProject.fulfilled]: (state, {payload, meta}) => {
      const updatedPayload = {
        ...payload,
        parents: {...payload.parents, projectId: payload._id},
      };
      state.item = updatedPayload;
      state.items = state.items.map(i => (i._id === payload._id ? payload : i));
      delete state.asyncState[meta.arg.key];
    },
    [updateProject.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    // [updateStatus.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [updateStatus.fulfilled]: (state, { payload, meta }) => {
    //   state.item = payload;
    //   state.items = state.items.map(i => i._id === payload._id ? payload : i);
    //   delete state.asyncState[meta.arg.key];
    // },
    // [updateStatus.rejected]: (state, { payload, meta }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },
    // //

    [deleteProject.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteProject.fulfilled]: (state, {payload, meta}) => {
      state.items = state.items.filter(i => i._id !== meta.arg.id);
      delete state.asyncState[meta.arg.key];
    },
    [deleteProject.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
  },
});

export const {dismissError} = project.actions;
export default project.reducer;
