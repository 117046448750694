import React, {useState} from 'react';
import moment from 'moment';
import style from './queueRow.module.css';
import api from '../../api';

const QueueRow = ({data, download, preview, admin}) => {
  const [disabled, setDisabled] = useState(false);
  const restartJob = async jobUid => {
    await api.videobuilder.restartJob(jobUid);
  };

  const deleteJob = async jobUid => {
    setDisabled(true);
    await api.videobuilder.deleteJob(jobUid);
  };

  const getColorFromState = state => {
    switch (state) {
      case 'finished':
        return 'green';
      case 'error':
        return 'red';
      default:
        return 'black';
    }
  };

  return (
    <tr style={disabled ? {cursor: 'not-allowed'} : null}>
      {/*  onClick={() => console.log(data)} */}
      <td />
      <td>
        <img src="/images/video.svg" width={20} height={20} alt="video icon" />
      </td>
      <td style={{backgroundColor: 'white'}}>
        {`${data.templateName}_${data.preset}_${data.composition}`}
      </td>
      <td style={{backgroundColor: 'white'}}>
        {disabled ? 'deleting...' : moment(data.createdAt).fromNow()}
      </td>
      <td
        style={{
          color: getColorFromState(data.state),
          backgroundColor: 'white',
        }}
      >
        {data.state === 'render:dorender' && (
          <div>
            <progress id={`rend_${data.uid}`} value={data.renderProgress} max="100">
              {' '}
              {data.renderProgress}%{' '}
            </progress>
            {admin ? (
              <div role="button" tabIndex={0} onClick={() => restartJob(data.uid)}>
                R
              </div>
            ) : null}
          </div>
        )}
        {data.state === 'finished' && (
          <div style={{display: 'flex'}}>
            <div
              role="button"
              tabIndex={0}
              className={`icon icon-download-blue ml-2 ${style.hvr}`}
              onClick={e => {
                e.stopPropagation();
                download(
                  `${data.templateName}_${data.preset}_${data.composition}.mp4`,
                  data.awsFilename
                );
              }}
              aria-label="Download"
            />
            <div
              role="button"
              tabIndex={0}
              className={`icon icon-eye-blue ml-2 ${style.hvr}`}
              onClick={e => {
                e.stopPropagation();
                preview(
                  data.filename || `${data.templateName}_${data.preset}_${data.composition}.mp4`,
                  data.awsFilename
                );
              }}
              aria-label="Preview"
            />
          </div>
        )}
        {data.state !== 'finished' && data.state !== 'render:dorender' && (
          <div style={{display: 'flex'}}>
            {data.state}
            {admin ? (
              <div
                role="button"
                tabIndex={1}
                onClick={() => deleteJob(data.uid)}
                style={{paddingLeft: 5}}
              >
                X
              </div>
            ) : null}
          </div>
        )}
      </td>
    </tr>
  );
};

export default QueueRow;
