import {useState} from 'react';

export default function useValidation() {
  const [errors, setErrors] = useState({});

  const handleValidation = async (data, yupSchema, prefix) => {
    const isValid = await yupSchema.isValid(data, {abortEarly: false});

    if (!isValid) {
      yupSchema.validate(data, {abortEarly: false}).catch(err => {
        const errors = err.inner.reduce((acc, error) => {
          return {
            ...acc,
            [error.path]: error.message,
          };
        }, {});
        if (prefix) {
          setErrors(old => ({
            ...old,
            [`${prefix}`]: errors,
          }));
        } else {
          setErrors(errors);
        }
      });
      return false;
    }

    setErrors({});
    return true;
  };

  return [errors, handleValidation, setErrors];
}
