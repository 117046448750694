import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {connect} from 'react-redux';
import {Tab, Tabs} from 'react-bootstrap';
import api from 'api';

import Access from '../../helpers/access';
import {getComments} from './slice';
import Form from './form';
import CommentDisplay from './CommentDisplay';

const Comments = ({
  dispatch,
  comment,
  user,
  parents,
  type,
  id,
  adData = null,
  isSharedPageComment = false,
}) => {
  const [replyForm, showForm] = useState(false);
  const [editForm, showEditForm] = useState(false);
  const [expanded, expand] = useState({});
  const [activeTab, setActiveTab] = useState('active');
  const [publicUserList, setPublicUserList] = useState({});
  const [otherGrid8Users, setOtherGrid8Users] = useState({});

  useEffect(() => {
    (async () => {
      await dispatch(getComments({key: `comments/${type}/${id}`, parents, type, id}));
    })();
  }, [dispatch, id, parents, type]);

  const getUsers = () => {
    if (user.asyncState[`user/mentions/${id}`]) {
      return user.asyncState[`user/mentions/${id}`].users
        .filter(item => item.mention)
        .map(item => ({id: item._id, display: `${item.name} ${item.lastName}`}));
    }
    return [];
  };

  const getPublicUsers = useCallback(async () => {
    try {
      // Get all user IDs from comments
      const commentUserIds = [
        ...new Set(comment.asyncState[`comments/${type}/${id}`]?.items.map(item => item.createdBy)),
      ];

      // Get all user IDs from redux state
      const grid8UserIds = user.asyncState[`user/mentions/${id}`]?.users.map(user => user._id);

      // Filter out user IDs that are not in the redux state
      const filteredPublicUserIds = commentUserIds.filter(userId => !grid8UserIds.includes(userId));

      if (Array.isArray(filteredPublicUserIds) && filteredPublicUserIds.length) {
        // Fetch public user data for the filtered user IDs
        const fetchPromises = filteredPublicUserIds.map(userId => api.user.getPublicUser(userId));
        const publicUserResponses = await Promise.all(fetchPromises);

        // Extract the IDs of users that failed to fetch
        const failedUserIds = publicUserResponses
          .filter(response => !response.data)
          .map(response => response.config.url.split('/').at(-1));

        if (Array.isArray(failedUserIds) && failedUserIds.length) {
          // Fetch user data for the failed user IDs
          const fetchUserPromises = failedUserIds.map(userId => api.user.getUserBasic(userId));
          const userResponses = await Promise.all(fetchUserPromises);

          // Create a map of otherGrid8Users using the fetched user data
          const otherGrid8UsersMap = {};
          userResponses.forEach(response => {
            otherGrid8UsersMap[response.data._id] = response.data;
          });

          setOtherGrid8Users(otherGrid8UsersMap);
        }

        // Filter out null data and create a map of public users
        const publicUserMap = {};
        publicUserResponses
          .map(response => response.data)
          .filter(el => el !== null)
          .forEach(u => {
            publicUserMap[u._id] = u;
          });

        setPublicUserList(publicUserMap);
      }
    } catch (error) {
      console.log('Error while fetching public users:', error);
    }
  }, [comment.asyncState, id, type, user.asyncState]);

  const getUser = createdBy => {
    if (user.asyncState[`user/mentions/${id}`]) {
      return (
        user.asyncState[`user/mentions/${id}`].users.find(item => createdBy === item._id) || {}
      );
    }
    return {};
  };

  const [resolvedComments, unresolvedComments] = useMemo(() => {
    const comments = (comment.asyncState[`comments/${type}/${id}`] || {}).items || [];
    const commentsToObjects = {};
    comments.forEach(cmnt => {
      commentsToObjects[cmnt._id] = cmnt;
    });
    const checkForResolvedAncestor = cmnt => {
      if (cmnt.resolved) return true;
      if (cmnt.parentId) {
        if (commentsToObjects[cmnt.parentId].resolved) return true;
        return checkForResolvedAncestor(commentsToObjects[cmnt.parentId]);
      }
      return false;
    };
    const commentsTotal = comments.length;
    const commentsResolved = comments.map(c => checkForResolvedAncestor(c)).filter(c => c).length;

    return [commentsResolved, commentsTotal - commentsResolved];
  }, [comment.asyncState, id, type]);

  const renderComments = (isAllowed, active = true, parent = null, resolved = false) => {
    const filteredComments = ((comment.asyncState[`comments/${type}/${id}`] || {}).items || [])
      .filter(({parentId}) => parentId === parent)
      .filter(({resolved: msgResolved}) =>
        parent === null ? (active ? !msgResolved : msgResolved) : true
      );
    if (filteredComments.length === 0 && parent === null) {
      return (
        <div className="comment-text">
          <pre
            className=""
            style={{
              textAlign: 'center',
              marginTop: '20px',
              fontSize: '14px',
              fontStyle: 'italic',
            }}
          >
            No comments
          </pre>
        </div>
      );
    }

    return filteredComments.map(msg => (
      <div
        key={msg._id}
        className={`comment ${msg.resolved && 'resolved'}`}
        style={{
          borderTop: !parent ? '10px solid #f5f5f5' : '1px solid #f5f5f5',
        }}
      >
        {editForm !== msg._id ? (
          <CommentDisplay
            publicUserList={publicUserList}
            msg={msg}
            isAllowed={isAllowed}
            comment={comment}
            getUser={getUser}
            parent={parent}
            expanded={expanded}
            expand={expand}
            type={type}
            id={id}
            dispatch={dispatch}
            getUsers={getUsers}
            replyForm={replyForm}
            showForm={showForm}
            resolved={resolved}
            showEditForm={showEditForm}
            adData={adData}
            isSharedPageComment={isSharedPageComment}
            otherGrid8Users={otherGrid8Users}
          />
        ) : (
          <div className="p-1" key={msg._id}>
            {isAllowed('update', msg) && (
              <Form
                type={type}
                id={id}
                commentId={editForm}
                key={editForm}
                hide={() => showEditForm(null)}
                users={getUsers()}
                value={msg.text}
                attachments={msg.attachments}
                adData={adData}
                isSharedPageComment={isSharedPageComment}
              />
            )}
          </div>
        )}
        {(!msg.resolved || expanded[msg._id] === true) && (
          <div className="comment-padding">
            {renderComments(isAllowed, false, msg._id, parent === null ? msg.resolved : resolved)}
          </div>
        )}
      </div>
    ));
  };

  const tabTitle = (title, count, key) => {
    const active = activeTab !== key;
    return (
      <div className="d-flex w-100 align-items-center justify-content-between">
        <span>{title}</span>
        <sup
          className={`${active ? 'gray-600-text' : 'primary-text'}  ml-5`}
          style={{
            borderRadius: '50%',
            display: 'inline-block',
            textAlign: 'center',
            width: '20px',
            lineHeight: '20px',
            backgroundColor: 'white',
            verticalAlign: 'middle',
            position: 'inherit',
          }}
        >
          {count}
        </sup>
      </div>
    );
  };

  useEffect(() => {
    getPublicUsers();
  }, [getPublicUsers]);

  return (
    <Access type="comment" parents={parents}>
      {({isAllowed}) => (
        <div className="comments-container">
          <Tabs activeKey={activeTab} className="comments-tabs " onSelect={t => setActiveTab(t)}>
            <Tab eventKey="active" title={tabTitle('Active', unresolvedComments, 'active')}>
              {isAllowed('create') && (
                <Form
                  type={type}
                  id={id}
                  key={replyForm}
                  users={getUsers()}
                  adData={adData}
                  isSharedPageComment={isSharedPageComment}
                />
              )}
              <div className="comments">{renderComments(isAllowed, true, null, false)}</div>
            </Tab>
            <Tab eventKey="resolved" title={tabTitle('Resolved', resolvedComments, 'resolved')}>
              {/* {isAllowed('create') && (
                <Form type={type} id={id} key={replyForm} users={getUsers()} />
              )} */}
              <div className="comments">{renderComments(isAllowed, false, null, false)}</div>
            </Tab>
          </Tabs>
        </div>
      )}
    </Access>
  );
};

export default connect(store => ({
  comment: store.comment,
  auth: store.auth,
  user: store.user,
}))(Comments);
