/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useRef, useState, useMemo, useCallback, useEffect} from 'react';

import _ from 'lodash';

import {connect} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';

import {defaultColDef} from '../consts';
import {autoFillImagesBySize} from '../helpers';
import {ShotTable} from './components/TableComponents';
import RouteGuard from '../../../../helpers/RouteGuard';
import useVersioning from '../../../../hooks/useVersioning';
import useLoaderButton from '../../../../hooks/useLoaderButton';
import {HeaderCellRenderer} from './components/TableComponents/AgCellRenderers';
import {ConfirmationModal} from '../../../../components';
import {EditHeader} from './components/Edit';
import {agHelpers, editAgGridColumns} from './agHelpers';
import {Preview} from './components/Previews';
import api from '../../../../api';

const Edit = ({
  ad,
  file,
  currentAd,
  setCurrentAd,
  setup,
  handleSaveClicked,
  projectId,
  assets,
  handleCancelClicked,
  adVersions,
  setAdVersions,
  handleRouteGuardSave,
  initialEditDate,
  setInitialEditDate,
  handleNextPreviousAd,
  getIsDirectionDisabled,
  parents,
}) => {
  const gridRef = useRef();

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showAlreadyPublishedModal, setShowAlreadyPublishedModal] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [fillAllModal, setFillAllModal] = useState({
    imgName: null,
    key: null,
    size: null,
    shotIndex: null,
    type: null,
  });

  const [versioning, handleLocalChange, handleUndo, handleRedo, reset] = useVersioning();
  const [
    loaderButtonStatus,
    setLoaderButtonCompleted,
    setLoaderButtonError,
    setLoaderButtonLoading,
    setLoaderButtonDefault,
  ] = useLoaderButton();

  const [test, setTest] = useState(0);
  const func = expandedNodes => {
    setTimeout(() => {
      expandedNodes.forEach(node => {
        const fullNode = gridRef?.current?.api?.getRowNode(node);
        if (fullNode) {
          fullNode.setExpanded(true);
        }
      });
    }, 1);
  };

  useEffect(() => {
    const expandedNodes = [];
    gridRef?.current?.api?.forEachNode(n => {
      if (n.expanded) {
        expandedNodes.push(n.id);
      }
    });

    setTest(Math.random());
    func(expandedNodes);
  }, [currentAd.sizes]);

  const {getSelectedRows, reloadTable, setColumnWidth, deselectAll} = useMemo(
    () => agHelpers(gridRef),
    [gridRef]
  );

  const currentAdChanged = useMemo(() => {
    const oldAd = ad.items.find(item => item._id === currentAd._id);

    return !_.isEqual(
      {
        ...currentAd,
        published: {},
      },
      {...oldAd, published: {}}
    );
  }, [ad.items, currentAd]);

  const masterSizeId = useMemo(() => {
    return setup.sizes.find(s => s.mastersize)?._id;
  }, [setup.sizes]);

  const handleChange = useCallback(
    stateChange => {
      if (loaderButtonStatus.completed) {
        setLoaderButtonDefault();
      }
      setCurrentAd(old => {
        return stateChange ? handleLocalChange(old, stateChange) : handleLocalChange(old);
      });
    },
    [handleLocalChange, loaderButtonStatus.completed, setCurrentAd, setLoaderButtonDefault]
  );
  const columnDefs = useMemo(
    () => editAgGridColumns(setup, projectId, masterSizeId, handleChange, setFillAllModal),
    [handleChange, masterSizeId, projectId, setup]
  );

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const handleSave = async newAd => {
    console.log('handling save click');
    setLoaderButtonLoading();
    try {
      const res = await handleSaveClicked(newAd)();
      if (res) {
        setLoaderButtonCompleted();
      } else {
        setLoaderButtonError();
      }
    } catch (error) {
      setLoaderButtonError();
    }
  };

  const handleDiscardClicked = () => {
    const callback = typeof showWarningModal?.fn === 'function' ? showWarningModal.fn : null;
    if (callback) {
      callback();
      setShowWarningModal(false);
      reset();
    } else {
      handleCancelClicked();
    }
    return true;
  };

  const handleSelectedChange = useCallback(() => setSelectedRows(getSelectedRows), [
    getSelectedRows,
  ]);

  const handleWarningModalContinue = () => {
    const callback = typeof showWarningModal?.fn === 'function' ? showWarningModal.fn : null;
    setShowWarningModal(false);
    reset();

    return handleRouteGuardSave(currentAd, callback);
  };

  const handleUnpublishModalContinue = async () => {
    setShowAlreadyPublishedModal(false);
    try {
      await handleSave();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUnpublishModalCancel = async () => {
    try {
      await api.dco.unpublishAds([currentAd._id]);

      handleUnpublishModalContinue();
    } catch (error) {
      console.error(error);
    }
  };
  const handleUnpublishModalClose = () => {
    setShowAlreadyPublishedModal(false);
  };

  const handleAutoFillClicked = () => {
    setCurrentAd(old => autoFillImagesBySize(old, file.items, fillAllModal));
    setFillAllModal({});
  };

  const handleDraftModalContinue = () => {
    handleSave({
      ...currentAd,
      columns: {...currentAd.columns, State: 'In review'},
    });
    setShowDraftModal(false);
  };

  const handleDraftModalCancel = () => {
    setShowDraftModal(false);
    handleSave();
  };

  const processCellForClipboard = useCallback(params => {
    return params.value?.name ?? params.value;
  }, []);

  function moveInArray(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  const onRowDragMove = useCallback(
    event => {
      const movingNode = event.node;
      const {overNode} = event;
      const rowNeedsToMove = movingNode !== overNode;
      if (rowNeedsToMove) {
        // the list of rows we have is data, not row nodes, so extract the data
        const movingData = movingNode.data;
        const overData = overNode.data;
        const fromIndex = currentAd.shots.indexOf(movingData);
        const toIndex = currentAd.shots.indexOf(overData);
        const newStore = currentAd.shots.slice();
        moveInArray(newStore, fromIndex, toIndex);
        setCurrentAd(old => ({
          ...old,
          shots: newStore,
        }));
        gridRef.current.api.clearFocusedCell();
        handleChange(old => {
          console.log('old chech', old);
          return {...old, shots: newStore};
        });
      }
    },
    [currentAd.shots, setCurrentAd]
  );

  console.log(currentAd.shots, 'shots');

  console.log('columnDefs', columnDefs);

  return (
    <div>
      {currentAd && (
        <>
          <EditHeader
            selectedRows={selectedRows}
            setup={setup}
            currentAd={currentAd}
            setCurrentAd={setCurrentAd}
            handleChange={handleChange}
            currentAdChanged={currentAdChanged}
            handleCancelClicked={handleCancelClicked}
            setShowWarningModal={setShowWarningModal}
            handleSave={handleSave}
            assets={assets}
            setShowDraftModal={setShowDraftModal}
            versioningArr={[versioning, handleUndo, handleRedo]}
            setSelectedRows={setSelectedRows}
            deselectAll={deselectAll}
            reloadTable={reloadTable}
            setShowAlreadyPublishedModal={setShowAlreadyPublishedModal}
            adVersions={adVersions}
            setAdVersions={setAdVersions}
            loaderButtonStatus={loaderButtonStatus}
            initialEditDate={initialEditDate}
            setInitialEditDate={setInitialEditDate}
            handleNextPreviousAd={handleNextPreviousAd}
            getIsDirectionDisabled={getIsDirectionDisabled}
            parents={parents}
          />
          <div className="ag-theme-alpine mb-4">
            <AgGridReact
              key={test}
              masterDetail
              ref={gridRef}
              rowData={currentAd.shots}
              rowHeight={64}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              detailCellRenderer={ShotTable}
              detailCellRendererParams={{
                setup,
                handleChange,
                projectId,
                sizes: currentAd.sizes,
                setFillAllModal,
                assets,
              }}
              components={{agColumnHeader: HeaderCellRenderer}}
              processCellForClipboard={processCellForClipboard}
              detailRowAutoHeight
              keepDetailRows
              refreshStrategy="rows"
              rememberGroupStateWhenNewData
              suppressRowClickSelection
              suppressRowTransform
              suppressContextMenu
              onRowSelected={handleSelectedChange}
              rowSelection="multiple"
              immutableData
              getRowId={params => params.data._id}
              domLayout="autoHeight"
              onGridReady={onGridReady}
              stopEditingWhenCellsLoseFocus
              embedFullWidthRows
              onRowDragMove={onRowDragMove}
              sortModel={[{colId: 'shotId', sort: 'asc'}]}
            />
          </div>
          <Preview
            shots={currentAd.shots}
            assets={assets}
            adSizes={currentAd.sizes}
            columnStructure={setup.adColumns}
            adColumns={currentAd.columns}
            shotStructure={setup.shotStructure}
            setup={setup}
            parents={parents}
          />
        </>
      )}
      <ConfirmationModal
        show={showAlreadyPublishedModal}
        title="This ad is already published"
        text="Are you sure you want to change it? The last published version of the ad will still be published untill you overwrite it by publishing the changed ad."
        cancelText="Unpublish and save"
        cancelColor="danger"
        continueText="Save as changed ad"
        continueColor="info"
        onContinue={handleUnpublishModalContinue}
        onCancel={handleUnpublishModalCancel}
        onClose={handleUnpublishModalClose}
      />
      <RouteGuard
        when={currentAdChanged}
        manualShow={!!showWarningModal}
        title="Unsaved Changes"
        message="Do you want to save your changes?"
        cancelText="Discard"
        continueText="Save"
        onManualClose={() => setShowWarningModal(false)}
        onContinue={handleWarningModalContinue}
        onCancel={handleDiscardClicked}
      />
      <ConfirmationModal
        show={!!fillAllModal.key}
        title="Attempt to autofill other  sizes?"
        text={`To successfully autofill other  images, they have to be in the same WxH format as ${fillAllModal.imgName} and their corresponding sizes.`}
        onCancel={() => setFillAllModal({})}
        onContinue={handleAutoFillClicked}
        continueText="Apply to all"
        cancelColor="disabled"
        cancelText="Just this size"
      />
      <ConfirmationModal
        show={showDraftModal}
        title="Current Ad is in Draft"
        text='Do you wish to move it to "In Review" state?'
        onClose={() => setShowDraftModal(false)}
        onCancel={handleDraftModalCancel}
        onContinue={handleDraftModalContinue}
        continueText="Put in review"
        continueColor="info"
        cancelColor="disabled"
        cancelText="Keep in draft"
      />
    </div>
  );
};

export default connect(store => ({
  ad: store.ad,
  file: store.file,
}))(Edit);
