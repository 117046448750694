import {useState} from 'react';

const defaultState = {loading: false, completed: false, error: false};

export default function useLoaderButton() {
  const [loaderButtonStatus, setLoaderButtonStatus] = useState(defaultState);

  const handleStatusChange = status => {
    setLoaderButtonStatus({
      ...defaultState,
      [status]: true,
    });
  };

  const setLoaderButtonCompleted = () => {
    handleStatusChange('completed');
  };
  const setLoaderButtonError = () => {
    handleStatusChange('error');
  };
  const setLoaderButtonLoading = () => {
    handleStatusChange('loading');
  };

  const setLoaderButtonDefault = () => {
    if (loaderButtonStatus === defaultState) {
      return;
    }
    setLoaderButtonStatus(defaultState);
  };
  return [
    loaderButtonStatus,
    setLoaderButtonCompleted,
    setLoaderButtonError,
    setLoaderButtonLoading,
    setLoaderButtonDefault,
  ];
}
