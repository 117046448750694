/* eslint-disable no-unused-expressions */
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Modal, Button} from 'react-bootstrap';

const RouteGuard = props => {
  const {
    when,
    manualShow,
    message,
    title,
    continueColor,
    continueText,
    cancelColor,
    cancelText,
    onContinue,
    onCancel,
    onManualClose,
  } = props;

  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block(prompt => {
        setCurrentPath(prompt.pathname);
        setShowModal(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleContinue = useCallback(async () => {
    if (onContinue) {
      const allowRouting = await onContinue();
      if (allowRouting) {
        history.block(() => {});
        !manualShow && history.push(currentPath);
      }
    }
  }, [currentPath, history, manualShow, onContinue]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const allowRouting = await Promise.resolve(onCancel());
      if (allowRouting) {
        history.block(() => {});
        !manualShow && history.push(currentPath);
      }
    }
    setShowModal(false);
  }, [currentPath, history, manualShow, onCancel]);

  return (
    <Modal
      show={showModal || manualShow}
      onHide={() => {
        setShowModal(false);
      }}
      centered
    >
      <Modal.Header style={{justifyContent: 'space-between'}}>
        <Modal.Title>{title}</Modal.Title>
        <button
          onClick={() => {
            setShowModal(false);
            manualShow && onManualClose && onManualClose();
          }}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className={`dco-button ${continueColor || 'primary'}`}
          onClick={handleContinue}
        >
          {continueText || 'Continue'}
        </Button>
        <Button
          type="button"
          className={`dco-button ${cancelColor || 'danger'}`}
          onClick={handleCancel}
        >
          {cancelText || 'Cancel'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RouteGuard;
