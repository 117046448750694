/* eslint-disable react/function-component-definition */
import React, {useEffect, useState} from 'react';
import Loadable from 'react-loadable';
import {connect} from 'react-redux';
import {Dropdown} from 'react-bootstrap';
import {NavLink, Switch, Route} from 'react-router-dom';

import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import {getBrand, getBrands} from './slice';
import {updateUser} from '../user/slice';
import BrandTeamsList from './BrandTeamsList';
import InvitationForm from '../../components/InvitationForm';

const Assets = Loadable({loader: () => import('./Assets/assets'), loading: () => null});
const BrandBook = Loadable({loader: () => import('./book'), loading: () => null});
const BrandInnovation = Loadable({loader: () => import('./innovation'), loading: () => null});
const Setting = Loadable({loader: () => import('./setting'), loading: () => null});
const Users = Loadable({loader: () => import('../../helpers/users'), loading: () => null});

function BrandDetailsPage({dispatch, match, brand, user, auth}) {
  const [showInvitationForm, show] = useState(false);
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    (async () => {
      // get brands
      try {
        await dispatch(getBrand(match.params.id));
        const {payload} = await dispatch(getBrands());
        setBrandList(payload.items);
      } catch (error) {
        console.log('brand details: fetch failed: ', error);
      }
    })();
  }, [dispatch, match.params.id]);

  useEffect(() => {
    // save brand id to user object - last visited brand id
    (async () => {
      try {
        dispatch(
          updateUser({
            id: auth.user._id,
            form: {
              misc: {...auth.user.misc, lastBrandId: match.params.id},
            },
          })
        );
        return;
      } catch (error) {
        console.log('update lastBrandId error', error);
      }
    })();
    // avoid infinite loop with redux dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Access type="brand" action="get" parents={{brandId: match.params.id}}>
      {({isAllowed, role}) => (
        <>
          <div className="d-flex justify-content-between align-items-center mb-5">
            <div className="d-flex w-100 align-items-center">
              <Avatar type="brand" id={match.params.id} className="avatar-head mr-4" />
              <h1 className="m-0 p-0 detail-title">{(brand.item || {}).name}</h1>
              {Array.isArray(brand.items) && brand.items.length > 1 ? (
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" className="ml-5">
                    Switch Brand
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Array.isArray(brandList) &&
                      brandList.length &&
                      brandList.map(b => (
                        <Dropdown.Item href={`/brand/view/${b._id}/teams`}>{b.name}</Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}
              <span className="template-text-gray ml-4">{role ?? auth?.user?.role}</span>
            </div>
            <Users
              users={user.accesses}
              show={show}
              allowAdd={isAllowed('invite')}
              shownRoles={['brandManager']}
            />
          </div>
          <div className="d-flex">
            <div>
              {/*  <NavLink to="/brand" className="nav-link mb-3 text-dark d-flex align-items-center pl-0">
              <h5 className="text-dark d-flex align-items-center">
                <span className="icon icon-arrow-left x10 mr-2" /> Brands
              </h5>
            </NavLink> */}
              <nav className="nav flex-column pr-2 submenu">
                <NavLink to={`/brand/view/${match.params.id}/teams`} exact className="nav-link">
                  Teams
                </NavLink>
                <NavLink to={`/brand/view/${match.params.id}`} exact className="nav-link">
                  Brand library
                </NavLink>
                <NavLink to={`/brand/view/${match.params.id}/settings`} exact className="nav-link">
                  Settings
                </NavLink>
                {/* <NavLink to={`/brand/view/${match.params.id}/book`} className="nav-link">Brand book</NavLink>
            <NavLink to={`/brand/view/${match.params.id}/innovation`} className="nav-link">Innovation program</NavLink> */}
              </nav>
            </div>
            <div className="w-100">
              <div>
                <Switch>
                  <Route
                    path="/brand/:action(view)/:id/teams"
                    render={() => <BrandTeamsList brandId={match.params.id} />}
                  />
                  <Route exact path="/brand/:action(view)/:id/settings" component={Setting} />
                  <Route exact path="/brand/:action(view)/:id/book" component={BrandBook} />
                  <Route path="/brand/:action(view)/:id/(file)?/:fileId?" component={Assets} />
                  <Route
                    exact
                    path="/brand/:action(view)/:id/(file)?/innovation"
                    component={BrandInnovation}
                  />
                </Switch>
              </div>
            </div>
            {showInvitationForm && (
              <InvitationForm
                hide={() => show(false)}
                id={match.params.id}
                parents={{brandId: match.params.id}}
                type="brand"
              />
            )}
          </div>
        </>
      )}
    </Access>
  );
}

export default connect(store => ({
  brand: store.brand,
  user: store.user,
  auth: store.auth,
}))(BrandDetailsPage);
