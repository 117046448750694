/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {useState} from 'react';
import {copyToClipboard} from 'helpers/copyToClipboard';

const maxSize = 800;

const SharedPreview = ({preview}) => {
  const [iframeKey, setIframeKey] = useState(Date.now());

  return (
    <div className="pb-4">
      <span className="dco-select">
        {preview.creativeSetupTitle}{' '}
        {`${maxSize / Math.max(maxSize, preview.height, preview.width) !== 1 ? '(scaled)' : ''}`}
      </span>
      <div
        className="iframe-holder"
        style={{
          display: 'block',
          maxWidth: `calc(1px *${preview.width} * ${maxSize} / max(${maxSize}, ${preview.height},${preview.width}))`,
          maxHeight: `calc(1px * ${preview.height + 50} * ${maxSize} / max(${maxSize}, ${
            preview.height
          },${preview.width}))`,
        }}
      >
        <iframe
          key={iframeKey}
          title={preview.title}
          src={preview.remoteUrl}
          width={preview.width}
          height={preview.height}
          frameBorder="0"
          loading="lazy"
          style={{
            transformOrigin: '0 0',
            transform: `scale(calc(${maxSize} / max(${maxSize}, ${preview.height}, ${preview.width})))`,
          }}
        />
      </div>

      <div className="d-flex justify-content-end mt-1">
        <div
          className="icon icon-share mr-2"
          role="button"
          onClick={() => {
            copyToClipboard(preview.remoteUrl);
          }}
        />

        <div role="button" className="icon icon-reload" onClick={() => setIframeKey(Date.now())} />
      </div>
    </div>
  );
};

export default SharedPreview;
