/* eslint-disable react/jsx-props-no-spreading */
import React, {useMemo} from 'react';
import _ from 'lodash';
import {Button} from 'react-bootstrap';

import UserCellRenderer from './UserCellRenderer';
import StateRenderer from './StateRenderer';
import PresetCellRenderer from './PresetCellRenderer';

const AdCellRenderer = props => {
  const {
    value,
    data,
    handleClick,
    colDef,
    type,
    initialData,
    onSave,
    onCancel,
    node,
    errors,
  } = props;

  const isChanged = useMemo(() => {
    if (type !== 'actions') {
      return false;
    }
    const oldAd = initialData.find(item => item._id === data._id);

    return !_.isEqual(
      {
        ...data,
        published: {},
      },
      {...oldAd, published: {}}
    );
  }, [data, initialData, type]);

  switch (true) {
    case colDef.field === 'Ad Name':
      return (
        <span
          tabIndex={0}
          className={`dco-table-title-cell ${handleClick && 'hoverable'}`}
          role="button"
          onClick={() => handleClick(data)}
        >
          {value}
        </span>
      );
    case colDef.field === 'State':
      return <StateRenderer {...props} />;
    case colDef.field === 'Published State':
      return <StateRenderer {...props} />;

    case colDef.field === 'Last Edited By': {
      return <UserCellRenderer {...props} />;
    }

    case type === 'preset':
      return <PresetCellRenderer {...props} />;
    case type === 'dynamic':
      return <PresetCellRenderer {...props} />;

    case type === 'boolean':
      return (
        <span className={` ${value ? 'primary-text' : 'gray-600-text'}`}>
          <span>{`\u25CF`} </span>
        </span>
      );
    case type === 'actions':
      return (
        <div className="d-flex align-items-center h-100">
          {isChanged && (
            <>
              <Button
                className={`border-0 ${!isChanged && 'gray-600-background'} mr-2`}
                disabled={!isChanged}
                onClick={() => onSave(data, node?.id)}
              >
                Save
              </Button>
              <button
                type="button"
                aria-label="discard changes"
                onClick={() => onCancel(node?.id)}
                disabled={!isChanged}
                className={`icon icon-circle-with-cross ${isChanged && 'primary-background'} `}
                style={{width: '20px', height: '20px', border: 0}}
              />
            </>
          )}
        </div>
      );

    default:
      return (
        <span className={` ${errors?.[node?.id]?.[colDef.field] && 'alert-danger'}`}>{value}</span>
      );
  }
};

export default AdCellRenderer;
