/* eslint-disable no-unused-expressions */
import api from 'api';
import React, {useState} from 'react';
import {Button, Modal} from 'react-bootstrap';

const ChangedSetupModal = props => {
  const [remaps, setRemaps] = useState({});
  const [showChangeLog, setShowChangeLog] = useState(false);
  /** testing jsdoc style of floating help */
  const [cachedAssets, setCachedAssets] = useState({});
  const {preflight, when, onClose = () => {}, onSave = () => {}} = props;
  const {changes} = props;
  const major = preflight?.previousVersion?.split('.')[0] ?? 0;
  const minor = preflight?.previousVersion?.split('.')[1] ?? 0;
  const patch = preflight?.previousVersion?.split('.')[2] ?? 0;

  console.log('preflight', preflight);
  const nonSame = preflight
    ? Object.keys(preflight?.changes?.columns)
        .map(key => {
          const keyValue = {
            key,
            value: preflight.changes.columns[key].split('|')[1],
            type: 'column',
          };
          return preflight.changes.columns[key] === 'same' ? false : keyValue;
        })
        .filter(v => v)
        .concat(
          ...(Object.keys(preflight?.changes?.shotStructure)
            .map(key => {
              const keyValue = {
                key,
                value: preflight.changes.shotStructure[key].split('|')[1],
                type: 'shot',
              };
              return preflight.changes.shotStructure[key] === 'same' ? false : keyValue;
            })
            .filter(v => v) || [])
        )
    : [];
  const tobeDropped = preflight ? Object.values(preflight?.changes?.extras) : [];
  const tobeDroppedInShots = preflight ? Object.values(preflight?.changes?.extrasShots) : [];

  const setOverride = (key, value) => {
    console.log('set override', key, value);
    setRemaps(old => {
      const map = {...old};
      map[key] = value;
      return map;
    });
  };
  console.log(nonSame);

  const getCachedAsset = async assetId => {
    if (!cachedAssets[assetId]) {
      const cached = (await api.asset.getFile(assetId)).data;
      setCachedAssets(old => {
        console.log('cachedAsset', cached);
        return {...old, [assetId]: cached};
      });
    }
    return cachedAssets[assetId];
  };

  return (
    <Modal show={when} onHide={onClose} centered>
      <Modal.Header style={{justifyContent: 'end', border: 0}}>
        <button
          onClick={onClose}
          type="button"
          style={{backgroundColor: 'inherit', border: 'none', marginRight: 10}}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column align-items-center text-center">
        <span
          className="icon icon-warning ml-1 mb-4 warning-background"
          style={{width: '64px', height: '64px'}}
        />
        <p className="publish-modal-title">Setup changed</p>
        <p>
          Click UPDATE to update all ads to the new setup version (
          <span style={{color: 'red'}}>{preflight.previousVersion}</span>
          {' -> '}
          <span style={{color: 'green'}}>{props.version}</span>),
          <br />
          To also update the feed, review and publish all ads.
        </p>
        {nonSame.length ? (
          <div>
            <p>Changes:</p>
            {nonSame.map(change =>
              change.type === 'column' ? (
                <p>
                  column <span style={{color: 'red'}}>{change.key}</span> will use default value:{' '}
                  {change.value}{' '}
                  {tobeDropped.length ? (
                    <span>
                      or you can remap it to a field which is about to be dropped:{' '}
                      <select onChange={e => setOverride(change.key, e.currentTarget.value)}>
                        <option value={change.value}>default</option>
                        {tobeDropped.map(drop => (
                          <option value={drop}>{drop}</option>
                        ))}
                      </select>
                    </span>
                  ) : null}
                </p>
              ) : (
                <p>
                  shot variable <span style={{color: 'red'}}>{change.key}</span> will use default
                  value: {change.value}{' '}
                  {tobeDroppedInShots.length ? (
                    <span>
                      or you can remap it to a field which is about to be dropped:{' '}
                      <select onChange={e => setOverride(change.key, e.currentTarget.value)}>
                        <option value={change.value}>default</option>
                        {tobeDroppedInShots.map(drop => (
                          <option value={drop}>{drop}</option>
                        ))}
                      </select>
                    </span>
                  ) : null}
                </p>
              )
            )}
          </div>
        ) : null}
        <div>
          {tobeDropped.concat(tobeDroppedInShots).length ? (
            <div>
              <p>to be dropped: {tobeDropped.concat(tobeDroppedInShots).toString()}</p>
              <br />
            </div>
          ) : null}
          {preflight?.changes?.extraShotSizes?.length ? (
            <div>
              <p>
                New sizes are available in this setup. <strong>Hide</strong> or{' '}
                <strong>show</strong> them in all
                <br />
                existing ads. Fill with content from <strong>Master</strong> size or from a size{' '}
                <br />
                that is about to be <strong>dropped</strong>
              </p>
              {preflight?.changes?.extraShotSizes?.map(size => (
                <p>
                  <span style={{color: '#00FFCC'}}>
                    {cachedAssets[size.assetId]?.creativeSetupTitle ||
                      getCachedAsset(size.assetId)?.creativeSetupTitle}{' '}
                    {size.width}x{size.height}
                  </span>
                  {remaps?.[size._id] ? (
                    <span style={{marginLeft: 20}}>value is overriden</span>
                  ) : (
                    <div style={{display: 'inline'}}>
                      <select
                        style={{marginLeft: 20}}
                        onChange={() => {
                          const ovrr = {...(remaps.sizeOverrides || {})};
                          ovrr[size._id] =
                            ovrr[size._id] !== undefined ? !ovrr[size._id] : !size.enabled;
                          console.log(ovrr, size.enabled);
                          setOverride('sizeOverrides', ovrr);
                        }}
                      >
                        <option value="enabled" enabled={size.enabled}>
                          Show
                        </option>
                        <option value="disabled">Hide</option>
                      </select>
                      <select
                        style={{marginLeft: 20}}
                        onChange={ch => {
                          console.log(ch);
                          const replaces = {...(remaps.shotReplace || {})};
                          replaces[size._id] = ch.target.value;
                          // {
                          //   deletedSizeId: ch.target.value,
                          //   newSizeId: size._id,
                          // }
                          setOverride('shotReplace', replaces);
                        }}
                      >
                        <option value="none">Master</option>
                        {preflight.changes.deletedSizes.map(delSize => {
                          return (
                            <option value={delSize._id}>
                              {cachedAssets[delSize.assetId]?.creativeSetupTitle ||
                                getCachedAsset(delSize.assetId)?.creativeSetupTitle}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                </p>
              ))}
            </div>
          ) : null}
        </div>
        {showChangeLog ? (
          <div>
            <p className="downloadtxtbtn" onClick={() => setShowChangeLog(!showChangeLog)}>
              Change log:
            </p>
            {changes &&
              Object.keys(changes)
                .filter(versionKey => {
                  const mmp = versionKey.split('-');
                  return (
                    parseInt(mmp[0], 10) >= major * 1 &&
                    parseInt(mmp[1], 10) >= minor * 1 &&
                    parseInt(mmp[2], 10) >= patch * 1
                  );
                })
                .map((versionKey, k, a) => {
                  return (
                    <div
                      style={{
                        backgroundColor: k % 2 === 0 ? 'white' : 'lightgrey', //'#FCBD0E' : '#0DDC79',
                        borderRadius:
                          k === 0 ? '0 0 6px 6px' : k === a.length - 1 ? '6px 6px 0 0' : null,
                      }}
                    >
                      {Object.keys(changes[versionKey]).map(chlogKey => {
                        return chlogKey ? (
                          <span>
                            {versionKey} - {chlogKey} -{' '}
                            {JSON.stringify(changes[versionKey][chlogKey])}
                          </span>
                        ) : null;
                      })}
                    </div>
                  );
                })
                .reverse()}
          </div>
        ) : (
          <p className="downloadtxtbtn" onClick={() => setShowChangeLog(!showChangeLog)}>
            Show full change log
          </p>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button type="button" className="dco-button disabled" onClick={onClose}>
          Cancel
        </Button>
        <Button type="button" className="dco-button primary" onClick={() => onSave(remaps)}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangedSetupModal;
