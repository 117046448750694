/* eslint-disable no-unused-expressions */
import React from 'react';
import {Button, Modal} from 'react-bootstrap';

const ExportNotificationModal = props => {
  const {when, onClose = () => {}} = props;

  return (
    <Modal show={when} onHide={onClose} size="sm" centered>
      <Modal.Body className="d-flex flex-column align-items-start text-start">
        <p>Exporting in progress</p>
        <p>It can take a few minutes until its ready</p>
        <br />

        <p>JPG &#xBB; Instant</p>
        <p>Video &#xBB; 2-5 minutes</p>
        <br />

        <p>You can always refresh the page to find your creatives in the export folder</p>

        <br />
        <Button type="button" className="dco-button primary " onClick={onClose}>
          Ok
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export default ExportNotificationModal;
