import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from './../../api';
// import { findAvatars } from './../../pages/file/slice';
import {getAccesses} from './../../pages/user/slice';

export const getBrands = createAsyncThunk(
  'brand/get',
  async (filter, {dispatch, rejectWithValue}) => {
    try {
      const response = await api.brand.getBrands(filter);
      const brands = response.data.items.map(i => i._id);
      // dispatch(findAvatars({ key: 'brand/avatars', type: 'brand', ids: brands }));
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

// export const findBrands = createAsyncThunk(
//   'brand/findByIds',
//   async (ids) => {
//     const response = await api.brand.findByIds(ids);
//     return response.data;
//   }
// );

export const getBrand = createAsyncThunk('brand/getBrand', async (id, {dispatch}) => {
  const response = await api.brand.getBrand(id);
  // dispatch(findAvatars({ key: `brand/${id}`, type: 'brand', ids: [id] }));
  dispatch(getAccesses({key: `brand/${id}`, type: 'brand', id}));
  return response.data;
});

export const createBrand = createAsyncThunk(
  'brand/createBrand',
  async ({form}, {rejectWithValue}) => {
    try {
      const response = await api.brand.createBrand(form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateBrand = createAsyncThunk(
  'brand/updateBrand',
  async ({id, form}, {rejectWithValue}) => {
    try {
      const response = await api.brand.updateBrand(id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

// export const updateBrandNote = createAsyncThunk(
//   'brand/updateNote',
//   async ({ id, form }, { rejectWithValue }) => {
//     try {
//       const response = await api.brand.updateNote(id, form);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

// export const updateBrandName = createAsyncThunk(
//   'brand/updateName',
//   async ({ id, form }, { rejectWithValue }) => {
//     try {
//       const response = await api.brand.updateName(id, form);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

// export const updateBrandDCM = createAsyncThunk(
//   'brand/updateDCM',
//   async ({ id, form }, { rejectWithValue }) => {
//     try {
//       const response = await api.brand.updateDCM(id, form);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

// export const updateBrandAdAccounts = createAsyncThunk(
//   'brand/updateAdAccounts',
//   async ({ id, form }, { rejectWithValue }) => {
//     try {
//       const response = await api.brand.updateAdAccounts(id, form);
//       return response.data;
//     } catch (e) {
//       const { data, status, statusText, message } = e.response;
//       return rejectWithValue({ data, status, statusText, message });
//     }
//   }
// );

export const deleteBrand = createAsyncThunk(
  'brand/deleteBrand',
  async ({id, filter}, {dispatch}) => {
    const response = await api.brand.deleteBrand(id);
    await dispatch(getBrands(filter));
    return response.data;
  }
);

export const addColorGroup = createAsyncThunk(
  'brand/color/addColorGroup',
  async ({brandId, form}, {rejectWithValue}) => {
    try {
      const response = await api.brand.addColorGroup(brandId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateColorGroup = createAsyncThunk(
  'brand/color/updateColorGroup',
  async ({brandId, groupId, form}, {rejectWithValue}) => {
    try {
      const response = await api.brand.updateColorGroup(brandId, groupId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteColorGroup = createAsyncThunk(
  'brand/color/deleteColorGroup',
  async ({brandId, groupId}, {rejectWithValue}) => {
    try {
      const response = await api.brand.deleteColorGroup(brandId, groupId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const addColor = createAsyncThunk(
  'brand/color/addColor',
  async ({brandId, groupId, form}, {rejectWithValue}) => {
    try {
      const response = await api.brand.addColor(brandId, groupId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateColor = createAsyncThunk(
  'brand/color/updateColor',
  async ({brandId, groupId, colorId, form}, {rejectWithValue}) => {
    try {
      const response = await api.brand.updateColor(brandId, groupId, colorId, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteColor = createAsyncThunk(
  'brand/color/deleteColor',
  async ({brandId, groupId, colorId}, {rejectWithValue}) => {
    try {
      const response = await api.brand.deleteColor(brandId, groupId, colorId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const brand = createSlice({
  name: 'brand',
  initialState: {
    asyncState: {},
    item: {},
    items: [],
    search: {},
    total: 0,
    fetching: false,
    deleting: {},
    error: null,
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
  },
  extraReducers: {
    //
    [getBrands.pending]: state => ({...state, fetching: true, error: null}),
    [getBrands.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload.items,
      total: payload.total,
      fetching: false,
      error: null,
    }),
    [getBrands.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    //
    // [findBrands.pending]: state => ({ ...state, fetching: true, error: null }),
    // [findBrands.fulfilled]: (state, { payload }) => ({ ...state, search: { ...state.search, ...payload }, fetching: false, error: null }),
    // [findBrands.rejected]: (state, { error }) => ({ ...state, fetching: false, error }),
    //
    [getBrand.pending]: state => ({...state, item: {}, fetching: true, error: null}),
    [getBrand.fulfilled]: (state, {payload}) => ({
      ...state,
      item: payload,
      fetching: false,
      error: null,
    }),
    [getBrand.rejected]: (state, {error}) => ({...state, item: {}, error, fetching: false}),
    //
    [createBrand.pending]: state => ({...state, fetching: true, error: null}),
    [createBrand.fulfilled]: (state, {payload}) => {
      state.items.unshift(payload);
      state.fetching = false;
      state.error = null;
    },
    [createBrand.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
    //
    [updateBrand.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateBrand.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      state.items = state.items.map(i => (i._id === payload._id ? payload : i));
      delete state.asyncState[meta.arg.key];
    },
    [updateBrand.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [deleteBrand.pending]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: true},
      error: null,
    }),
    [deleteBrand.fulfilled]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: false},
      error: null,
    }),
    [deleteBrand.rejected]: (state, {meta, error}) => ({
      ...state,
      error,
      deleting: {[meta.arg.id]: false},
    }),

    // [updateBrandNote.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [updateBrandNote.fulfilled]: (state, { payload, meta }) => {  state.item = payload; delete state.asyncState[meta.arg.key]; },
    // [updateBrandNote.rejected]: (state, { payload, meta }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },

    // [updateBrandName.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [updateBrandName.fulfilled]: (state, { payload, meta }) => {  state.item = payload; delete state.asyncState[meta.arg.key]; },
    // [updateBrandName.rejected]: (state, { payload, meta }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },

    // [updateBrandDCM.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [updateBrandDCM.fulfilled]: (state, { payload, meta }) => {  state.item = payload; delete state.asyncState[meta.arg.key]; },
    // [updateBrandDCM.rejected]: (state, { payload, meta }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },

    // [updateBrandAdAccounts.pending]: (state, { meta }) => { state.asyncState[meta.arg.key] = { fetching: true, error: null }; },
    // [updateBrandAdAccounts.fulfilled]: (state, { payload, meta }) => {  state.item = payload; delete state.asyncState[meta.arg.key]; },
    // [updateBrandAdAccounts.rejected]: (state, { payload, meta }) => { state.asyncState[meta.arg.key] = { fetching: false, error: payload }; },

    [addColorGroup.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateColorGroup.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteColorGroup.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [addColor.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateColor.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteColor.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },

    [addColorGroup.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [updateColorGroup.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [deleteColorGroup.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [addColor.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [updateColor.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },
    [deleteColor.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      delete state.asyncState[meta.arg.key];
    },

    [addColorGroup.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [updateColorGroup.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [deleteColorGroup.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [addColor.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [updateColor.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [deleteColor.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
  },
});

export const {dismissError} = brand.actions;
export default brand.reducer;
