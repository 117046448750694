export const zipTypes = ['zip', '7z', 'gz'];

export const initialHtmlForm = {
  creativeTitle: '',
  displayName: '',
  fileOptions: [],
  files: [],
  height: '',
  indexFile: '',
  path: '/',
  width: '',
  tags: [],
};
