import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {AgGridReact} from 'ag-grid-react';

import {TablePreview} from './components/Previews';
import {defaultColDef} from '../consts';
import {updateAd} from '../slice';
import {changeValueInSelectedAds, findElementById} from '../helpers';
import {agHelpers, reviewAgGridColumns} from './agHelpers';
import Access from '../../../../helpers/access';

const Review = ({ad, dispatch, setup, assets, setCurrentAd, setTab, parents}, ref) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const gridRef = useRef();

  const {deselectAll, setColumnWidth, getSelectedRows} = useMemo(() => agHelpers(gridRef), [
    gridRef,
  ]);

  const adsInReview = useMemo(
    () => ad.items.filter(innerAd => innerAd?.columns?.State === 'In review'),
    [ad.items]
  );

  const columnDefs = useMemo(() => reviewAgGridColumns(setup), [setup]);

  useImperativeHandle(ref, () => ({
    getData: () => selectedRows,
  }));

  const handleSelectedChange = useCallback(() => setSelectedRows(getSelectedRows()), [
    getSelectedRows,
  ]);

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const handleAction = useCallback(
    async state => {
      try {
        const data = await dispatch(
          updateAd(changeValueInSelectedAds(selectedRows, 'State', state))
        );

        setCurrentAd(old => {
          const currentAd = findElementById(data.payload, old?._id);
          if (currentAd) {
            return {
              ...old,
              columns: {...old.columns, State: currentAd.columns.State},
            };
          }
          return old;
        });
      } catch (error) {
        console.error(error);
      }
      deselectAll();
      setSelectedRows([]);
    },
    [dispatch, deselectAll, selectedRows, setCurrentAd]
  );

  const handleEdit = adValue => {
    setCurrentAd({...adValue});
    setTab('edit');
  };

  return (
    <div>
      <span className="dco-review-gradient" />
      <p style={{fontSize: '1.25rem', fontWeight: 'bold'}}>
        Please check if all these new or changed ads are okay
      </p>
      <p style={{fontSize: '1rem', fontWeight: 'semibold'}}>Reject ads to edit them again</p>

      <Access type="dco" action="edit" parents={parents}>
        {() => (
          <div className="d-flex mb-4 align-items-center">
            <Button
              disabled={!selectedRows.length}
              type="button"
              className="dco-button success"
              onClick={() => handleAction('Approved')}
            >
              Approve ({selectedRows.length}) ads
            </Button>
            <Button
              disabled={!selectedRows.length}
              type="button"
              className="dco-button danger ml-3"
              onClick={() => handleAction('Rejected')}
            >
              Reject ({selectedRows.length}) ads
            </Button>
          </div>
        )}
      </Access>

      <div className="ag-theme-alpine banner">
        <AgGridReact
          masterDetail
          ref={gridRef}
          rowData={adsInReview || []}
          rowHeight={64}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onRowSelected={handleSelectedChange}
          detailCellRenderer={TablePreview}
          detailCellRendererParams={{setup, assets, handleEdit, parents}}
          suppressRowClickSelection
          suppressRowTransform
          suppressContextMenu
          detailRowAutoHeight
          rowSelection="multiple"
          domLayout="autoHeight"
          onGridReady={onGridReady}
        />
      </div>
    </div>
  );
};

export default connect(
  store => ({
    ad: store.ad,
  }),
  null,
  null,
  {forwardRef: true}
)(forwardRef(Review));
