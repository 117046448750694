import React, {useMemo} from 'react';
import TooltipHelper from '../../../../../../helpers/tooltip';
import {AD_LIMIT} from '../../../consts';

const calculatePercentage = (x, y) => (x / y) * 100;

const UsageLabel = ({numOfAds}) => {
  const percentage = calculatePercentage(numOfAds, AD_LIMIT);
  const warningLevel = useMemo(() => {
    if (percentage > 85) {
      return 'danger-text';
    }
    if (percentage > 60) {
      return 'warning-text';
    }
    return 'gray-600-text';
  }, [percentage]);

  return (
    <TooltipHelper
      content={`A project supports a maximum of ${AD_LIMIT} ads. Create a new DCO project to add more ads to the setup`}
    >
      <p
        style={{
          textWrap: 'nowrap',
          margin: 0,
        }}
        className={warningLevel}
      >
        {Math.round((percentage + Number.EPSILON) * 100) / 100}% used
      </p>
    </TooltipHelper>
  );
};

export default UsageLabel;
