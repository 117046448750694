import React from 'react';
import Select from 'react-select';

const SharedPagination = ({
  setResultsPerPage,
  currentPage,
  setCurrentPage,
  resultsPerPage,
  totalPages,
  maxLimit,
}) => {
  const customStyles = {
    control: styles => ({...styles, width: '75px'}),
  };

  const handleResultsPerPageChange = e => {
    setResultsPerPage(Number(e.value));
    setCurrentPage(1);
  };

  const handlePrevPageClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="d-flex justify-content-between my-4">
      <div style={{width: 250}}>
        <button
          className="btn btn-secondary"
          type="button"
          disabled={currentPage === 1}
          onClick={handlePrevPageClick}
        >
          Prev
        </button>
        <span className="mx-4">
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="btn btn-primary"
          type="button"
          disabled={currentPage === totalPages}
          onClick={handleNextPageClick}
        >
          Next
        </button>
      </div>
      <div className="d-flex align-items-center">
        <span className="m-0 mr-2">Results per page:</span>
        <Select
          styles={customStyles}
          defaultValue={[{value: resultsPerPage, label: resultsPerPage}]}
          options={[
            {value: '1', label: '1'},
            {value: '5', label: '5'},
            {value: '10', label: '10'},
            {value: '20', label: '20'},
            {value: '100', label: '100'},
            {value: maxLimit, label: 'All'},
          ]}
          onChange={handleResultsPerPageChange}
          isClearable={false}
        />
      </div>
    </div>
  );
};

export default React.memo(SharedPagination);
