import React, {useState, useCallback, useEffect} from 'react';
import {Dropdown} from 'react-bootstrap';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import Avatar from '../../helpers/avatar';
import {IconError} from '../../helpers/error';
import SingleInputForm from '../../helpers/singleInputForm';
import Access from '../../helpers/access';
import CustomToggle from '../../helpers/customToggle';
import {updateProject, deleteProject, dismissError} from './slice';
import {uploadAvatar} from '../file/slice';
import {ConfirmationModal} from '../../components';

const Project = ({
  dispatch,
  name,
  progress,
  teamId,
  id,
  status,
  project,
  file,
  item,
  type,
  statusBorder,
}) => {
  const [showForm, toggleForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDrop = useCallback(
    acceptedFiles =>
      dispatch(
        uploadAvatar({key: `project/avatar/${id}`, type: 'project', id, file: acceptedFiles[0]})
      ),
    []
  );
  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });
  const rename = async name =>
    await dispatch(updateProject({key: `rename/${id}`, id, form: {name}}));

  const destroy = async name => await dispatch(deleteProject({key: `delete/${id}`, id}));

  useEffect(() => {
    dispatch(dismissError(`delete/${id}`));
    dispatch(dismissError(`rename/${id}`));
  }, [showForm]);

  const handleDeleteClicked = () => {
    if (item.type === 'dco') {
      setShowDeleteModal(true);
      return;
    }

    destroy();
  };

  const handleModalContinueClicked = () => {
    setShowDeleteModal(false);
    destroy();
  };

  return (
    <Access type="project" parents={item.parents}>
      {({isAllowed}) => (
        <div
          className={`project-item d-flex ${status}`}
          style={{borderRight: statusBorder ? `5px solid ${statusBorder}` : ''}}
        >
          <div className="project-avatar-container">
            <Avatar type="project" id={id} className="project-avatar" />
            <IconError
              asyncState={file.asyncState[`project/avatar/${id}`]}
              fontSize="12"
              className="project-avatar-preloader"
            />
          </div>
          <div className="project-body w-100 d-flex justify-content-between align-items-center">
            <IconError
              asyncState={project.asyncState[`rename/${id}`]}
              fontSize="12"
              className="ml-1"
            />
            <IconError
              asyncState={project.asyncState[`delete/${id}`]}
              fontSize="12"
              className="ml-1"
            />
            <IconError
              asyncState={project.asyncState[`status/${id}`]}
              fontSize="12"
              className="ml-1"
            />
            <div className="flex-grow-1">
              {isAllowed('update') && showForm && (
                <SingleInputForm
                  value={name}
                  showError={false}
                  placeholder="Project name"
                  asyncState={project.asyncState[`rename/${id}`]}
                  onSubmit={rename}
                  show={toggleForm}
                />
              )}
              {!showForm && (
                <Link to={`/project/${id}`}>
                  <div>
                    <div className="text-primary ml-3">{name}</div>
                    <div className="ml-3" style={{color: 'lightgrey', fontSize: 'smaller'}}>
                      {item.type == 'regular' ? 'standard' : item.type}
                    </div>
                  </div>
                </Link>
              )}
            </div>
            {isAllowed('update') && (
              <div {...getRootProps()} className="d-none">
                <input {...getInputProps()} accept="image/*" multiple={false} />
              </div>
            )}
            {isAllowed('update') && (
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => toggleForm(true)} disabled={showForm}>
                    Rename
                  </Dropdown.Item>
                  <Dropdown.Item onClick={open} disabled={showForm}>
                    Change image
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleDeleteClicked} disabled={showForm}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <div className="project-progressbar">
              <div className="progress rounded-0">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{width: '30%'}}
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={{width: '70%'}}
                  aria-valuenow="70"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            show={showDeleteModal}
            title="Delete project"
            text="Deleting a project will unpublish all associated ads. Are you sure you want to delete this project?"
            onClose={() => setShowDeleteModal(false)}
            onCancel={() => setShowDeleteModal(false)}
            onContinue={handleModalContinueClicked}
            continueText="Delete"
            continueColor="danger"
            cancelColor="disabled"
            cancelText="Cancel"
          />
        </div>
      )}
    </Access>
  );
};

export default connect(store => ({
  project: store.project,
  file: store.file,
}))(Project);
