/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {Overlay, Tooltip} from 'react-bootstrap';

import {copyToClipboard} from '../../../../../../helpers/copyToClipboard';
import {replaceElementInArray} from '../../../../../brand/Dco/helpers';
import {buildPreviewData, findElementById} from '../../../helpers';
import CreativeExport from 'pages/file/CreativeExportModal/CreativeExport';

const maxH = 800;
const maxW = 1000;

const Preview = ({
  adColumns,
  shots = [],
  adSizes = [],
  assets,
  setup,
  filterSizes = [],
  overview = false,
  parents,
}) => {
  const [iframes, setIframes] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showTooltip, setShowTooltip] = useState([]);

  const refInfo = useRef([]);

  useEffect(() => {
    if (!shots.length) {
      return;
    }
    const {sizes: setupSizes, adColumns: columnStructure, shotStructure} = setup;

    const columnsUsedInPreview = Object.entries(columnStructure)
      .filter(([, v]) => {
        if (v.includeInManifest) {
          return true;
        }
        return false;
      })
      .map(([k]) => k);

    const reduced = setupSizes.map(size => {
      const file = assets?.files?.[size.assetId];
      // findElementById searches, first param array, second id, returns that item in the array
      const currentSize = shots.map(s => findElementById(s.shotSizes, size._id)).filter(s => s);

      if (_.isEmpty(currentSize) || !file) {
        // console.log('faulty size id', size._id, shots);
        // console.log(currentSize, file, 'god damn you karlo!');
        return [];
      }

      return buildPreviewData(
        currentSize,
        file,
        shotStructure,
        size._id,
        columnsUsedInPreview,
        adColumns
      );
    });

    setIframes(reduced.map((s, index) => index * 100));
    setShowTooltip(reduced.map(() => false));
    setSizes(reduced);
  }, [assets, shots, setup, adColumns]);
  // lastChangedUser:26, dcoHeader, dcoProject

  // console.log(setup);
  // console.log(adSizes);

  return (
    <div className="preview-wrapper">
      {sizes.map((size, indexMain) => {
        // console.log('adSizes:', adSizes, 'size id to find:', size);
        const currentSize = adSizes.find(s => s._id === size.sizeId);
        const filterSize = filterSizes.find(s => s._id === size.sizeId);
        const currentCreative = setup?.sizes?.find
          ? assets.files?.[setup?.sizes?.find(s => s._id === size.sizeId)?.assetId]
          : null;
        // console.log('currentCreative', currentCreative)
        // console.log(size, currentCreative, size.asset.substring(0, size.asset.indexOf('/')))
        // console.log("current creative", size, assets.files, indexMain, Object.keys(assets.files).indexOf(size.sizeId), setup.sizes.find(s=>s._id===size.sizeId).assetId);
        if (!currentSize) {
          // console.log('currentSize is null');
          return null;
        }
        if (!currentSize.enabled) {
          // console.log('currentSize is disabled');
          return null;
        }
        if (overview && filterSize && !filterSize.enabled) {
          // console.log('something is false here:', overview && filterSize && !filterSize.enabled);
          return null;
        }

        let maxSize = Math.max(
          currentSize.height > maxH ? maxH : currentSize.height,
          currentSize.width > maxW ? maxW : currentSize.width
        );
        if (maxSize > maxH && currentSize.height > currentSize.width) maxSize = maxH;

        return (
          <div className="d-flex flex-column" key={size.sizeId}>
            <span className="dco-select">
              {assets?.files?.[currentSize.assetId]?.creativeSetupTitle ||
                `${currentSize.width}x${currentSize.height}`}{' '}
              {`${
                maxSize / Math.max(maxSize, currentSize.height, currentSize.width) !== 1
                  ? '(scaled)'
                  : ''
              }`}
            </span>

            <div
              className="iframe-holder"
              style={{
                display: 'block',
                maxWidth: `calc(1px *${currentSize.width} * ${maxSize} / max(${maxSize}, ${currentSize.height},${currentSize.width}))`,
                maxHeight: `calc(1px * ${currentSize.height + 50} * ${maxSize} / max(${maxSize}, ${
                  currentSize.height
                },${currentSize.width}))`,
              }}
            >
              <iframe
                title={size.size}
                key={iframes[indexMain]}
                src={`${process.env.REACT_APP_S3_URL}/${size.asset}?${size.src}`}
                width={currentSize.width}
                height={currentSize.height}
                id={`${size.size}`}
                style={{
                  transformOrigin: '0 0',
                  transform: `scale(calc(${maxSize} / max(${maxSize}, ${currentSize.height}, ${currentSize.width})))`,
                }}
                frameBorder="0"
                loading="lazy"
              />
            </div>
            <div className="d-flex justify-content-end mt-1">
              {currentCreative && (
                <CreativeExport
                  alternative
                  creative={currentCreative}
                  version={currentCreative.versions.length - 1}
                  parents={parents}
                  q2a={size.src}
                  nameoverride={
                    (assets?.files?.[currentSize.assetId]?.creativeSetupTitle ||
                      `${currentSize.width}x${currentSize.height}`) +
                    '_' +
                    adColumns['Ad Name']
                  }
                />
              )}
              {/* <div className="icon icon-comment mr-2" role="button" /> */}
              <div
                style={{marginLeft: 5}}
                className="icon icon-share mr-2"
                role="button"
                ref={ref => (refInfo.current[indexMain] = ref)}
                onClick={() => {
                  copyToClipboard(`${process.env.REACT_APP_S3_URL}/${size.asset}?${size.src}`);
                  setShowTooltip(old => replaceElementInArray(old, indexMain, true));

                  setTimeout(() => {
                    setShowTooltip(old => replaceElementInArray(old, indexMain, false));
                  }, 3000);
                }}
              />
              <Overlay
                target={refInfo.current[indexMain]}
                show={showTooltip[indexMain]}
                placement="bottom"
              >
                {overlayProps => (
                  <Tooltip id="overlay-example" {...overlayProps}>
                    <div className="d-flex flex-column">
                      <span className="px-2">Copied successfully</span>
                    </div>
                  </Tooltip>
                )}
              </Overlay>
              <div
                role="button"
                onClick={() =>
                  setIframes(old => replaceElementInArray(old, indexMain, old[indexMain] + 1))
                }
                className="icon icon-reload "
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Preview;
