import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import {replaceUpdatedItemsById} from '../../helpers';
import api from '../../../../../api';

const getTypeString = data => {
  const {settings, ads} = data;
  if (!settings || !settings.type) {
    return 'None';
  }

  switch (settings.type) {
    case 'all':
      return `All (${ads.length} ads)`;
    case 'selected':
      return `Selected (${ads.length} ads)`;
    case 'filtered':
      return `${Object.keys(settings?.filters || {}).length} Filters (${ads.length} ads)`;
    default:
      return 'None';
  }
};

const transformToSharedPage = data => ({
  ...data,
  viewed: 0,
  active: true,
  dateUpdated: data.lastUpdate,
  filters: getTypeString(data),
  link: `https://${process.env.REACT_APP_API_DOMAIN}/shared/${data.publicId}`,
});

export const getSharedPages = createAsyncThunk(
  'shared/getSharedPages',
  async (id, {rejectWithValue}) => {
    try {
      const response = await api.dco.getSharedPages(id);

      return response.data
        .map(transformToSharedPage)
        .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      console.error(e);
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const createSharedPage = createAsyncThunk(
  'shared/createSharedPage',
  async (form, {rejectWithValue}) => {
    try {
      const response = await api.dco.createSharedPage(form);

      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateSharedPage = createAsyncThunk(
  'shared/updateSharedPage',
  async (form, {rejectWithValue}) => {
    try {
      await api.dco.updateSharedPage(form);
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);
export const deleteSharedPage = createAsyncThunk(
  'shared/updateSharedPage',
  async (id, {rejectWithValue}) => {
    try {
      await api.dco.deleteSharedPage(id);
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const shared = createSlice({
  name: 'shared',
  initialState: {
    asyncState: {},
    items: [],
    fetching: false,
    deleting: {},
    error: null,
  },
  reducers: {},
  extraReducers: {
    //
    [getSharedPages.pending]: state => ({...state, fetching: true, error: null}),
    [getSharedPages.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload,
      fetching: false,
      error: null,
    }),
    [getSharedPages.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    [createSharedPage.pending]: state => ({...state, fetching: true, error: null}),
    [createSharedPage.fulfilled]: (state, {payload}) => {
      state.items = replaceUpdatedItemsById(state.items, payload);
      state.fetching = false;
      state.error = null;
    },
    [createSharedPage.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
  },
});

// export const {} = file.actions;
export default shared.reducer;
