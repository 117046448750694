import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from './../../api';
// import { findAvatars } from './../../pages/file/slice';

export const login = createAsyncThunk('auth/login', async (form, {rejectWithValue, dispatch}) => {
  try {
    const response = await api.auth.login(form);
    localStorage.setItem('access-token', response.headers['access-token']);
    // dispatch(findAvatars({ key: `user/${response.data._id}`, type: 'user', ids: [response.data._id] }));
    return response.data;
  } catch (e) {
    return rejectWithValue(e.response.data);
  }
});

export const registration = createAsyncThunk(
  'auth/registration',
  async (form, {rejectWithValue}) => {
    try {
      const response = await api.auth.registration(form);
      localStorage.setItem('access-token', response.headers['access-token']);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const getUser = createAsyncThunk('auth/getUser', async (_, {dispatch}) => {
  const response = await api.auth.getUser();
  // dispatch(findAvatars({ key: `user/${response.data._id}`, type: 'user', ids: [response.data._id] }));
  return response.data;
});

export const auth = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    fetched: false,
    fetching: false,
    error: null,
    registration: null,
  },
  reducers: {
    logout: state => {
      state.user = null;
      state.fetched = true;
      localStorage.removeItem('access-token');
    },
    updateUserData: (state, {payload}) => {
      // TODO BE should return correct payload (access field is missing)
      return {...state, user: {...state.user, ...payload}};
    },
  },
  extraReducers: {
    //
    [login.pending]: state => ({...state, fetching: true, error: null}),
    [login.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload,
      fetching: false,
      fetched: true,
      error: null,
    }),
    [login.rejected]: (state, action) => ({
      ...state,
      user: null,
      fetching: false,
      fetched: true,
      error: action.payload,
    }),
    //
    [registration.pending]: state => ({...state, fetching: true, registration: null}),
    [registration.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload,
      fetching: false,
      fetched: true,
      registration: null,
    }),
    [registration.rejected]: (state, action) => ({
      ...state,
      user: null,
      fetching: false,
      fetched: true,
      registration: action.payload,
    }),
    //
    [getUser.pending]: state => ({...state, fetched: false, fetching: true, error: null}),
    [getUser.fulfilled]: (state, action) => ({
      ...state,
      user: action.payload,
      fetched: true,
      error: null,
    }),
    [getUser.rejected]: (state, action) => ({
      ...state,
      user: null,
      error: action.error,
      fetched: true,
    }),
  },
});

export const {logout, updateUserData} = auth.actions;
export default auth.reducer;
