const transformToVersionOptions = num => {
  if (!num || typeof num !== 'number') {
    return [];
  }
  if (num === 1) {
    return [{value: 0, label: '1 (latest)'}];
  }

  const arrayNum = Array.from(Array(num).keys());

  return arrayNum.map((n, i) => {
    return {value: n, label: `${n + 1} ${i + 1 === num ? '(latest)' : ''}`};
  });
};

export default transformToVersionOptions;
