import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Th = ({children, name, className, filter: {sortField, sortType}, onSelect}) => {
  return (
    <th
      className={`sorting ${className}`}
      onClick={() => onSelect({sortField: name, sortType: sortType === 'asc' ? 'desc' : 'asc'})}
    >
      <span className="mr-2">{children}</span>
      {sortType === 'asc' && name === sortField ? (
        <FontAwesomeIcon icon="caret-up" />
      ) : (
        <FontAwesomeIcon icon="caret-down" />
      )}
    </th>
  );
};

export default Th;
