import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from '../../../../api';

export const getVideoTemplates = createAsyncThunk(
  'asset/videoTemplates/getVideoTemplates',
  async (brandId, {rejectWithValue}) => {
    try {
      const response = await api.videobuilder.listTemplates(brandId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const uploadVideoTemplate = createAsyncThunk(
  'asset/videoTemplates/uploadVideoTemplate',
  async ({formData, onUploadProgress}, {rejectWithValue}) => {
    try {
      const response = await api.videobuilder.uploadTemplate(formData, onUploadProgress);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const videoTemplates = createSlice({
  name: 'videoTemplates',
  initialState: {
    asyncState: {},
    items: {},
    fetching: false,
    deleting: {},
    error: null,
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
  },
  extraReducers: {
    [getVideoTemplates.pending]: (state, {meta}) => {
      state.items = {};
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [getVideoTemplates.fulfilled]: (state, {payload}) => {
      return {
        ...state,
        items: payload,
        fetching: false,
        error: null,
      };
    },
    [getVideoTemplates.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    [uploadVideoTemplate.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [uploadVideoTemplate.fulfilled]: (state, {payload}) => {
      return {...state, items: payload};
    },
    [uploadVideoTemplate.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
  },
});

export const flipTemplateBulkState = createAsyncThunk(
  'asset/videoTemplates/switchTemplateBulkState',
  async (templateId, {rejectWithValue}) => {
    try {
      const response = await api.videobuilder.bulkModeFlip(templateId);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const {dismissError} = videoTemplates.actions;
export default videoTemplates.reducer;
