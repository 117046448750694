import React, {useEffect, useMemo, useState} from 'react';
import {Button, Dropdown} from 'react-bootstrap';

const FeedsStatusBar = ({
  status,
  updateFeed,
  feed,
  feedId,
  publishFeed,
  deleteFeed,
  getFeeds,
  disabled = false,
}) => {
  const [manifest, setManifest] = useState(false);

  const getStateColor = state => {
    switch (state) {
      case 'updating':
        return '#FFBE00';
      case 'error':
        return '#FF6B6B';
      case 'active':
        return '#1DD1A1';
      case 'stopped':
      // return '#FFA700';
      case 'paused':
        return '#AFAFAF';
      default:
        return 'gray';
    }
  };

  const isPublishable = useMemo(() => {
    let numOfValues = 0;
    if (!manifest?.settings?.version) {
      // old manifest
      numOfValues = manifest.layers?.filter(l => l.isDynamic === true).length;
    } else if (manifest?.settings?.dynamicValues) {
      numOfValues = manifest.settings?.dynamicValues.length;
    }
    console.log('numOfValues', numOfValues);
    return !!feed && !!feed.creativeMap && Object.keys(feed.creativeMap).length === numOfValues;
  }, [feed, manifest.layers]);

  const handleStatusUpdate = state => async e => {
    e.stopPropagation();
    await updateFeed({
      _id: feedId,
      state,
    });
    // getFeeds();
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const handleDescription = () => {
    switch (status) {
      case 'active':
        return 'This feed will be updated shortly';
      case 'initial':
        return 'Please refresh the page';
      case 'updating':
        return 'Workers in progress…';
      case 'stopped':
        return 'Output feed is not available';
      case 'paused':
        return 'Output feed is still available';
      default:
        return '';
    }
  };

  const handleActions = () => {
    switch (status) {
      case 'active':
        return (
          <>
            <button
              type="button"
              onClick={handleStatusUpdate('paused')}
              className="btn btn-secondary"
            >
              Pause
            </button>
            <button
              type="button"
              onClick={handleStatusUpdate('stopped')}
              className="btn btn-secondary ml-2"
            >
              Stop
            </button>
          </>
        );
      case 'updating':
        return <div />;
      default:
        return (
          <div className="feeds-table-setup-actions d-flex">
            <Button
              onClick={() => {
                publishFeed(feed);
              }}
              disabled={!isPublishable || disabled}
              type="button"
              className={`btn ${disabled ? 'btn-secondary' : 'btn-primary'}`}
            >
              Publish
            </Button>
            {status !== 'stopped' && (
              <button
                type="button"
                onClick={handleStatusUpdate('stopped')}
                className="btn btn-secondary ml-2"
              >
                Stop
              </button>
            )}
            {!disabled && (
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="bg-transparent border-0 text-dark"
                />
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      deleteFeed(feed._id);
                    }}
                    href="#/action-1"
                    className="text-danger"
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        );
    }
  };

  useEffect(() => {
    if (feed.template) {
      fetch(
        `${process.env.REACT_APP_S3_URL}/${
          feed.template.versions[feed.template.versions.length - 1].dir
        }/manifest.js`
      )
        .then(r => r.text())
        .then(r => {
          try {
            const j = JSON.parse(r.substr(r.indexOf('{')));
            setManifest(j);
          } catch (e) {
            console.error(e);
          }
        })
        .catch(console.error);
    }
  }, [feed.template]);

  return (
    <div
      className="d-flex align-items-center justify-content-between pl-4"
      style={{
        background: getStateColor(status),
        height: 50,
        marginTop: -3,
        color: 'white',
        fontWeight: 900,
      }}
    >
      <div className="d-flex align-items-center">
        <p className="m-0 pr-5" style={{letterSpacing: 2}}>
          {status.toUpperCase()}
        </p>
        <h6 className="m-0 p-0 mt-1">{handleDescription()}</h6>
      </div>
      {!disabled && <div className="d-flex align-items-center pr-4">{handleActions()}</div>}
    </div>
  );
};

export default FeedsStatusBar;
