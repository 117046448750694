/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {useRef, useState, useMemo, useCallback, useEffect} from 'react';
import {AgGridReact} from 'ag-grid-react';
import {connect} from 'react-redux';
import {agHelpers, shareTableAgGridColumns} from '../../Tabs/agHelpers';
import {defaultColDef} from '../../consts';
import {ShareTableThreeDot} from '../../Tabs/components/TableComponents';
import {deleteSharedPage, getSharedPages, updateSharedPage} from './slice';
import ShareEditModal from './ShareEditModal';
import {shareFilterData} from './helpers';

const ShareTable = ({shared, ad, dispatch, parents}) => {
  const gridRef = useRef();
  const {items} = shared;
  const {items: adItems} = ad;

  const [selectedRows, setSelectedRows] = useState([]);
  const [activeSharedPage, setActiveSharedPage] = useState(null);

  const {getSelectedRows, setColumnWidth} = useMemo(() => agHelpers(gridRef), [gridRef]);

  const onEditClicked = data => {
    setActiveSharedPage(data);
  };

  const columnDefs = useMemo(() => shareTableAgGridColumns(onEditClicked), []);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(getSharedPages({parents}));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dispatch, parents]);

  const onGridReady = useCallback(() => {
    setColumnWidth();
  }, [setColumnWidth]);

  const handleSelectedChange = useCallback(() => setSelectedRows(getSelectedRows), [
    getSelectedRows,
  ]);

  const handleThreeDotEvents = async event => {
    try {
      switch (event) {
        case 'update':
          await Promise.allSettled(
            selectedRows.map(row => {
              if (!row.settings) {
                return new Promise();
              }
              switch (row.settings.type) {
                case 'all':
                  return dispatch(
                    updateSharedPage({
                      _id: row._id,
                      parents: row.parents,
                      setup: row.setup,
                      ads: adItems.map(item => item._id),
                      title: row.title,
                    })
                  );
                case 'selected': {
                  return dispatch(
                    updateSharedPage({
                      _id: row._id,
                      parents: row.parents,
                      setup: row.setup,
                      ads: row.adIds,
                      title: row.title,
                    })
                  );
                }
                case 'filtered': {
                  const newFilteredAds = row.settings?.filters
                    ? shareFilterData(adItems, row.settings.filters).map(i => i._id)
                    : row.ads;
                  return dispatch(
                    updateSharedPage({
                      _id: row._id,
                      parents: row.parents,
                      setup: row.setup,
                      ads: newFilteredAds,
                      title: row.title,
                    })
                  );
                }

                default:
                  return new Promise();
              }
            })
          );

          break;
        case 'delete':
          await Promise.allSettled(selectedRows.map(row => dispatch(deleteSharedPage(row._id))));
          break;

        default:
          break;
      }

      await dispatch(getSharedPages({parents}));
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditModalSave = async justSave => {
    let sharedAds = activeSharedPage.ads;
    switch (activeSharedPage.settings.type) {
      case 'all':
        sharedAds = adItems.map(item => item._id);
        break;
      case 'selected':
        sharedAds = activeSharedPage.adIds;
        break;
      case 'filtered':
        sharedAds = activeSharedPage.settings?.filters
          ? shareFilterData(adItems, activeSharedPage.settings.filters).map(i => i._id)
          : activeSharedPage.ads;
        break;
      default:
        break;
    }
    try {
      await dispatch(updateSharedPage({...activeSharedPage, ads: sharedAds}));
      await dispatch(getSharedPages({parents}));
    } catch (error) {
      console.error(error);
    }
    if (!justSave) {
      setActiveSharedPage(null);
    }
  };

  return (
    <>
      <div
        className="d-flex flex-column"
        style={{
          flex: 1,
          minWidth: '700px',
          overflow: 'visible',
          height: '35vh',
        }}
      >
        <div className="d-flex justify-content-start align-items-center mb-4">
          <h3>Shared links</h3>
          <ShareTableThreeDot selectedRows={selectedRows} onEventClicked={handleThreeDotEvents} />
        </div>
        <div className="ag-theme-alpine banner share-table">
          <AgGridReact
            ref={gridRef}
            rowData={items}
            rowHeight={64}
            columnDefs={columnDefs}
            defaultColDef={{...defaultColDef, minWidth: 150, menuTabs: []}}
            onRowSelected={handleSelectedChange}
            suppressRowClickSelection
            suppressRowTransform
            suppressContextMenu
            rowSelection="multiple"
            domLayout="autoHeight"
            onGridReady={onGridReady}
          />
        </div>
      </div>

      <ShareEditModal
        show={!!activeSharedPage}
        onClose={() => setActiveSharedPage(null)}
        shareState={activeSharedPage}
        setShareState={setActiveSharedPage}
        onSave={handleEditModalSave}
      />
    </>
  );
};

export default connect(store => ({
  shared: store.shared,
  ad: store.ad,
}))(ShareTable);
