import React, {useEffect, useRef, useState} from 'react';
import useWebSocket, {ReadyState} from 'react-use-websocket';
import {Badge, Toast, ToastContainer} from 'react-bootstrap';
import {useHistory, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';

const NotificationMenu = ({userId}) => {
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [show, setShow] = useState(false);
  const [unread, setUnread] = useState(0);
  const [lastRead, setLastRead] = useState(Date.now());
  const newRef = useRef(null);
  const {sendMessage, lastMessage, readyState} = useWebSocket(
    `wss://${
      process.env.REACT_APP_API_DOMAIN
    }/api/ws/server/?id=${userId}&token=${`Bearer ${localStorage.getItem('access-token')}`}`,
    {
      heartbeat: {
        message: 'heartbeat',
        returnMessage: 'livinlavidaloca',
        timeout: 60000,
        interval: 30000,
      },
      reconnectInterval: 1000,
      share: true,
      shouldReconnect: () => true,
    }
  );

  const convertType = type => {
    switch (type) {
      case 'renderFinished':
        return 'Render ready';
      default:
        return type;
    }
  };

  const constructLinkFromNotification = notification => {
    let link = `/${notification.data.relativeType}/${notification.data.relativeId}`;
    if (notification.data.relativeType === 'brand') {
      link = `/${notification.data.relativeType}/view/${notification.data.relativeId}`;
    }
    return notification?.data?.file?.id ? `${link}/file/${notification.data.file.id}` : link;
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = e => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    // console.log('(ws.NotificationMenu)', lastMessage?.data);
    if (lastMessage?.data) {
      try {
        const jsonMsg = JSON.parse(lastMessage?.data);
        // console.log(jsonMsg);
        if (jsonMsg.type === 'initial') {
          const notifs = [];
          jsonMsg.data.forEach(notification => {
            notifs.push({
              ...notification.data,
              type: convertType(notification.data.type),
              time: notification.createdAt,
              _id: notification._id,
            });
          });
          // console.log('notifications should be:', notifs);
          setNotifications(notifs);
        } else
          setNotifications([
            {
              ...jsonMsg.data,
              time: Date.now(),
              type: convertType(jsonMsg.data.type),
              _id: jsonMsg._id,
            },
            ...notifications,
          ]);
      } catch (error) {
        if (error.message.indexOf('unexpected character at line') === -1) console.error(error);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (show) {
      setLastRead(Date.now());
    }
  }, [show]);

  useEffect(() => {
    // console.log('(ws) readyState:', readyState);
  }, [readyState]);

  useEffect(() => {
    let unreadCount = 0;
    notifications.forEach(notification => {
      if (notification.time > lastRead) {
        unreadCount += 1;
      }
    });
    setUnread(unreadCount);
  }, [notifications, show]);

  return (
    <div ref={newRef}>
      <div onClick={() => setShow(!show)} style={{position: 'relative', padding: 6}}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.513"
          height="20.125"
          viewBox="0 0 16.513 20.125"
        >
          <path
            id="Icon_material-notifications-none"
            data-name="Icon material-notifications-none"
            d="M14.256,23.875a2.07,2.07,0,0,0,2.064-2.064H12.192A2.07,2.07,0,0,0,14.256,23.875Zm6.192-6.192v-5.16C20.449,9.354,18.766,6.7,15.8,6V5.3a1.548,1.548,0,1,0-3.1,0V6c-2.952.7-4.644,3.344-4.644,6.523v5.16L6,19.747v1.032H22.513V19.747Zm-2.064,1.032H10.128V12.522c0-2.559,1.558-4.644,4.128-4.644s4.128,2.085,4.128,4.644Z"
            transform="translate(-6 -3.75)"
            fill="#e7e7e7"
          />
        </svg>
        {!!unread && (
          <Badge
            bg="danger"
            className="rounded-circle"
            style={{position: 'absolute', top: 0, right: -5}}
          >
            {unread}
          </Badge>
        )}
      </div>
      {show && (
        <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', top: 0, right: 0, width: 300, zIndex: 100}}>
            <ToastContainer
              className="position-static"
              style={{
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: 4,
                marging: '7px 0px 10px -60px',
                boxShadow: '0px 7px 7px 2px rgba(0,0,0,0.18)',
                maxHeight: '70vh',
                overflow: 'scroll',
              }}
            >
              {show && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'end',
                    color: 'grey',
                    padding: 5,
                  }}
                  onClick={() => {
                    notifications.length
                      ? sendMessage(
                          JSON.stringify({
                            action: 'clearAll',
                          })
                        )
                      : null;
                    setShow(false);
                    setNotifications([]);
                  }}
                >
                  {notifications.length ? 'clear all' : 'no notifications'}
                </div>
              )}
              {notifications.length > 0 &&
                notifications.map(
                  notification =>
                    notification?.type &&
                    notification?.message && (
                      <Toast
                        onClose={e => {
                          e.stopPropagation();
                        }}
                        show={show}
                        onClick={() => {
                          // history.push(
                          //   `/${notification.data.relativeType}/${notification.data.relativeId}`
                          // );
                          // setShow(false);
                        }}
                        style={{
                          borderBottom: 'none',
                          marginBottom: 0,
                          border: 'none',
                          boxShadow: 'none',
                        }}
                      >
                        <Toast.Header
                          closeButton={false}
                          style={{borderBottom: 'none', color: 'grey'}}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              alignItems: 'end',
                              borderBottom: '1px solid',
                              borderColor: 'lightgrey',
                            }}
                          ></div>
                        </Toast.Header>
                        <Toast.Body style={{padading: '0 0.75rem 0.75rem 0.75rem'}}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              alignItems: 'end',
                              color: 'grey',
                            }}
                          >
                            <div style={{}}>
                              <span>
                                {notification.time && moment(notification.time).fromNow()}
                              </span>
                              <span
                                onClick={e => {
                                  console.log('delete', notification._id);
                                  sendMessage(
                                    JSON.stringify({action: 'deleteOne', _id: notification._id})
                                  );
                                  setNotifications(
                                    notifications.filter(n => n._id !== notification._id)
                                  );
                                  e.stopPropagation();
                                }}
                                style={{marginLeft: 4}}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    id="circle-with-cross"
                                    d="M10.56,2.56a8,8,0,1,0,8,8,8,8,0,0,0-8-8Zm4.561,10.915-1.646,1.646L10.56,12.206,7.645,15.121,6,13.474,8.914,10.56,6,7.645,7.645,6l2.914,2.914L13.475,6l1.646,1.646-2.916,2.915,2.915,2.915Z"
                                    transform="translate(-2.56 -2.56)"
                                    fill="#AFAFAF"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                          <strong>{notification.type}</strong>
                          {notification?.data?.file ? (
                            <p>
                              File{' '}
                              <Link
                                style={{textDecoration: 'underline'}}
                                onClick={() => {
                                  console.log('goto:', constructLinkFromNotification(notification));
                                  history.push(constructLinkFromNotification(notification));
                                }}
                              >
                                {notification?.data?.file?.displayName}
                              </Link>{' '}
                              is ready!
                            </p>
                          ) : (
                            <p>{notification.message}</p>
                          )}

                          {/* {<Link
                          style={{textDecoration: 'underline', marginLeft: 15}}
                          href={`/${notification.data.relativeType}/${notification.data.relativeId}`}
                        >
                          Download{' '}
                          <span
                            className="icon icon-download-blue"
                            style={{marginLeft: 5, display: 'inline-block'}}
                          />
                        </Link>} */}
                        </Toast.Body>
                      </Toast>
                    )
                )}
            </ToastContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;
