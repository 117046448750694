/* eslint-disable react/function-component-definition */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import Pagination from '../../helpers/pagination';
import Avatar from '../../helpers/avatar';
import Access from '../../helpers/access';
import {getBrands} from './slice';
import Form from './form';

function BrandsPage({history, dispatch, brand, location, match}) {
  const [filter, setFilter] = useState({
    q: queryString.parse(location.search).q || '',
    sortType: queryString.parse(location.search).sortType || 'desc',
    sortField: queryString.parse(location.search).sortField || 'createdAt',
    page: parseInt(queryString.parse(location.search).page, 10)
      ? parseInt(queryString.parse(location.search).page, 10)
      : 1,
    limit: localStorage.getItem('brandLimit') || queryString.parse(location.search).limit || 20,
  });

  useEffect(() => {
    (async () => dispatch(getBrands(filter)))();
  }, [dispatch, filter]);

  const applyFilter = params => {
    history.replace({
      ...location,
      search: queryString.stringify({...queryString.parse(location.search), ...params}),
    });
    setFilter({...filter, ...params});
  };

  // if (match.params.id && match.params.action === 'view')
  //   return <Route path="/brand/:action(view)/:id" component={Layout} />

  // If new user, return this msg
  if (
    (!Array.isArray(brand.items) && !location.search) ||
    (!brand.items.length && !location.search)
  ) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{minHeight: '70vh'}}
      >
        <h3>You are not invited to any team.</h3>
        <h3>Please ask your contact to add you to their team.</h3>
      </div>
    );
  }

  return (
    <Access type="brand" action="list" overWritRole={false}>
      {({isAllowed}) => (
        <div>
          {match.params.action === 'create' && isAllowed('create') && (
            <Form hide={() => history.push(`/${location.search}`)} id={match.params.id} />
          )}
          <div className="row mb-2">
            <div className="col-2 d-flex align-items-baseline">
              <h3 className="mb-0">Brands</h3>
            </div>
            <div className="col-10 d-flex align-items-baseline justify-content-end">
              {isAllowed('create') && (
                <Link to={`/create/${location.search}`} className="icon-btn">
                  <div className="icon icon-plus-blue ml-2" />
                  <span className="ml-1 small text-primary text">Create new brand</span>
                </Link>
              )}
            </div>
          </div>

          <div className="mb-2">
            <input
              type="text"
              className="form-control form-control-sm col-2 border-0 pl-0"
              placeholder="search"
              onChange={e => applyFilter({q: e.target.value})}
              value={filter.q}
            />
          </div>

          <div className={`mb-2 ${brand.fetching ? 'loading tiles' : 'tiles'}`}>
            {location.search && !brand.items.length ? (
              <div className="d-flex align-items-center py-2">
                <h4>No result</h4>
              </div>
            ) : (
              brand.items.map(item => (
                <Link to={`/brand/view/${item._id}/teams`} key={item._id} className="tile">
                  <Avatar type="brand" id={item._id} className="avatar" disableClick />
                  <div className="title">{item.name}</div>
                </Link>
              ))
            )}
          </div>

          <div>
            <Pagination
              applyFilter={applyFilter}
              total={brand.total}
              filter={filter}
              localName="brandLimit"
            />
          </div>
        </div>
      )}
    </Access>
  );
}

export default connect(store => ({
  brand: store.brand,
}))(BrandsPage);
