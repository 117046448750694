/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useEffect} from 'react';
import {Button} from 'react-bootstrap';

import {stateColorCoding} from 'pages/project/DcoProject/consts';
import SharedPreview from 'pages/shared/components/SharedPreview';
import SharedColumns from 'pages/shared/components/SharedColumns';

const PrivateShareAd = ({ad, adId, setAdId, setAdName}) => {
  useEffect(() => {
    return () => {
      setAdId(null);
    };
  }, [setAdId]);
  console.log({ad});

  return (
    <div className="my-5">
      <div className="d-flex align-items-center justify-content-between py-5">
        <div className="d-flex">
          <h3>{ad.name}</h3>
          <span className={`font-weight-bold m-0 ${stateColorCoding[ad.publishedStatus]} ml-4`}>
            <span>{`\u2022`} </span>
            {ad.publishedStatus}
          </span>
        </div>
        <div>
          <Button
            onClick={() => {
              if (adId) {
                setAdId(null);
                return;
              }
              setAdId(ad.adId);
              setAdName(ad.name);
            }}
            type="button"
            className="mr-4 dco-button shared-comment-btn"
          >
            Comment {ad.comments}
          </Button>
        </div>
      </div>
      <SharedColumns columns={ad.columns} />
      <div className="d-flex flex-wrap" style={{gap: '3rem'}}>
        {ad.previews?.map((preview, index) => {
          if (!preview.enabled) {
            return null;
          }
          return <SharedPreview preview={preview} key={index} />;
        })}
      </div>
    </div>
  );
};

export default PrivateShareAd;
