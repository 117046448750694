import {useState} from 'react';

const useVersioning = () => {
  const [versioning, setVersioning] = useState({
    redo: [],
    undo: [],
  });

  const handleLocalChange = (currentValue, onChange) => {
    setVersioning(old => ({
      redo: [],
      undo: [currentValue, ...old.undo],
    }));
    return onChange ? onChange(currentValue) : currentValue;
  };

  const handleUndo = (currentValue, onUndo) => {
    setVersioning(old => ({
      ...old,
      redo: [currentValue, ...old.redo],
      undo: [...old.undo.slice(1)],
    }));
    return onUndo ? onUndo(versioning.undo[0]) : versioning.undo[0];
  };

  const handleRedo = (currentValue, onRedo) => {
    setVersioning(old => ({
      ...old,
      redo: [...old.redo.slice(1)],
      undo: [currentValue, ...old.undo],
    }));
    return onRedo ? onRedo(versioning.redo[0]) : versioning.redo[0];
  };
  const reset = () => {
    setVersioning({
      redo: [],
      undo: [],
    });
  };
  return [versioning, handleLocalChange, handleUndo, handleRedo, reset];
};

export default useVersioning;
