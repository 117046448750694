/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-loop-func */
/* eslint-disable no-inner-declarations */
/* eslint-disable camelcase */
/* eslint-disable one-let */
/* eslint-disable prefer-template */
/* eslint-disable no-multi-assign */
import React, {forwardRef, useEffect, useLayoutEffect, useReducer, useRef} from 'react';
import pica from 'pica';
import Select from 'react-select';
import {Button, Spinner} from 'react-bootstrap';
import Joyride from 'react-joyride';

import {SizeSelect} from '../../pages/project/DcoProject/Tabs/components/Inputs';
import './imageCrop.css';
import {customInputStyles, customInputStylesSmartCrop} from '../../pages/project/DcoProject/consts';

const steps = [
  {
    target: '.joyride-preset',
    placement: 'right-end',
    content:
      'Make sure you select the right preset for your project, choose from the list or build custom ones on the brand pages. The presets define the size of the exported images.',
  },
  {
    target: '.joyride-size-select',
    content:
      'Select the sizes you want to include/exclude. Images with the same size, name, retina setting will be saved as new versions. DCO ads will not automatically pick up the most recent version.',
  },
  {
    target: '.joyride-generate',
    content:
      'After you are happy with your cropped images, click here to generate them! After generating they will be stored in the “Smartcropper Exports” folder under the Assets tab..',
  },
  {
    target: '.joyride-cropper',
    content:
      'This is the image cropper, you can set the focus point and crop out unwanted elements. This will be quickly applied to all the sizes.',
  },
  {
    target: '.joyride-title',
    content: 'You can change the name of the exported images to your preference.',
  },

  {
    target: '.joyride-format',
    content:
      'Select the format of the exported images. JPG is better for quick loading, use PNG if you need transparency in your image or quality is more important than performance.',
  },
  {
    target: '.joyride-quality',
    content: 'Lower quality images (0-100) can result in better loading times.',
  },
  {
    target: '.joyride-retina',
    content:
      'Some ads require big assets, too big to preview on smaller screens. With the multiplier you can edit with normal size, but still export big images the ad expects.',
  },
  {
    target: '.joyride-preview',
    content: 'Quick Preview of the export quality. It also shows the kb’s after generating.',
  },
  {
    target: '.joyride-overlay',
    content:
      'Presets can have an overlay image attached to each size. This will mark the safe areas of the ad, so you can better position your image.',
  },
  {
    target: '.joyride-previews',
    content: 'Edit each size independently so they will fit perfectly.',
  },
];

const Cropper = React.memo(
  ({myUrl, onload}) => {
    return (
      <div id="myHolder" className="joyride-cropper">
        <img id="myVideo" src={`${myUrl}`} alt="" onLoad={onload} />
        <div id="masker">
          <div id="bg" />
          <div id="res" />
        </div>
        <div className="resizable">
          <div className="resizers">
            <div className="resizer top-left" />
            <div className="resizer top-right" />
            <div className="resizer bottom-left" />
            <div className="resizer bottom-right" />
          </div>
        </div>
        <div id="fPoint" className="fCenter">
          <i className="fa fa-cog fa-spin " aria-hidden="true" />
          <span
            className="fa icon icon-target primary-background"
            style={{
              height: 24,
              width: 24,
            }}
          />
        </div>
      </div>
    );
  },
  () => true
);

function reducer(state, action) {
  console.log('ch ch changeeez', state, action);
  switch (action.type) {
    case 'SIZE_CHANGE': {
      return {...state, sizes: action.value};
    }
    case 'PRESET_CHANGE': {
      return {
        ...state,
        sizes: action.value.sizes,
        preset: action.value,
        scale: state.scale || 1,
        quality: action.value.quality || 70,
        format: action.value.format || 'JPG',
      };
    }
    case 'QUALITY_CHANGE': {
      return {...state, quality: action.value};
    }
    case 'IMAGE_NAME_CHANGE': {
      return {...state, imageName: action.value};
    }
    case 'FORMAT_CHANGE': {
      return {...state, format: action.value};
    }
    case 'SCALE_CHANGE': {
      return {...state, scale: action.value};
    }
    case 'RETINA_CHANGE': {
      return {...state, retina: action.value};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
const SmartCrop = ({initialState, image: myUrl, preset, presets, onPresetChange}, ref) => {
  const [state, stateDispatch] = useReducer(reducer, {initialState}, () => initialState);
  const [runJoyride, setRunJoyride] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const imgRef = useRef(new Image());

  // console.log(myUrl, state.sizes, 'myUrl');
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
  const my = elm => document.getElementById(elm);
  const lerp = (b, c, a) => b * (1 - a) + c * a;
  // const imageVar = new Image();
  // imageVar.src = `${myUrl}?t=${Date.now()}`;

  function doScale(value) {
    css().style.setProperty('--myScale', value);
    myFunction(true);
  }

  function rogier(png) {
    localStorage.type = png.value;
    my('preFix').className = png.parentElement.parentElement.className = png.value;
  }

  let duration = 0;
  let trim = 6.5;
  let fps = 25;
  const scrub = () => my('scrub');
  const ghost = () => my('ghost');
  const css = () => document.querySelector('.rootContainer');
  const res = () => my('res').style;
  const fPoint = () => my('fPoint');
  const myIcon = () => document.querySelector('#fPoint>i');
  const masker = () => my('masker');
  const myCrop = () => document.querySelector('.resizable');
  const myVid = () => my('myVideo');
  const previews = () => my('previews');
  const uploader = () => my('file-upload-form');
  const isSafari = () =>
    navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
  const firstRun = React.useRef(true);
  const foc = React.useRef(null);

  const img = document.createElement('img');

  const myJson = React.useRef({x: 0, y: 0, c: true, left: 0, top: 0, right: 0, bottom: 0});
  const temp = React.useRef({width: 0, height: 0, e: true, s: 1});
  useEffect(() => {
    imgRef.current.crossOrigin = 'anonymous';
    imgRef.current.src = `${myUrl}?t=${Date.now()}`;
  }, [myUrl]);

  function bBox(elmnt = myCrop()) {
    const {y, x, top, bottom, left, right, width, height} = elmnt.getBoundingClientRect();

    const wrapper = document.getElementById('image-crop');
    const root = document.getElementById('rootContainer');
    const pageY = wrapper.scrollTop;
    const pageX = wrapper.scrollLeft;
    // console.log('originalRect', rect, elmnt.getBoundingClientRect());
    const newRect = {
      width,
      height,
      x: x + pageX,
      y: y + pageY,
      left: left + pageX,
      top: top + pageY,
      right: right + pageX,
      bottom: bottom + pageY,
    };

    console.log('rectgs', elmnt.getBoundingClientRect(), newRect, pageX, pageY);
    return newRect;
  }
  function pEnter(e) {
    if (e.buttons < 1) {
      foc.current = e.target;
    }
  }
  function pLeave(e) {
    if (e.buttons < 1) {
      foc.current = null;
    }
  }

  function getRes(str) {
    const res = str.replace(/[^0-9]/g, ' ');
    const w = parseInt(res);
    const h = parseInt(res.replace(w, ''));
    return {w, h};
  }

  function saveList() {
    // localStorage.unsaved = my('mySpan').classList.contains('unsaved');
    // localStorage.title = my('mySpan').innerHTML;
    // localStorage.creatives = JSON.stringify(myList());
  }

  function myList() {
    return Array.from(my('myList').getElementsByTagName('input')).map(li => {
      let ob = getRes(li.id);
      ob.c = li.checked;
      return ob;
    });
  }

  function Additem(w, h, c = true, sizeId, overlayUrl, displayName) {
    console.log('Additem', w, h, c, sizeId, overlayUrl, displayName);
    let input = {value: 0};
    let single = false;
    if (h == null) {
      single = true;
      ({w, h} = getRes(my('myName').value));
      my('mySpan').className = 'unsaved';
    }

    const id = w + 'x' + h;
    if (w < 50 || h < 50 || isNaN(w) || isNaN(h) || w > 2560 || h > 2560) {
      const nan = id.toLowerCase().includes('nan') ? '\n(NaN is short for "Not-a-Number")' : '';
      alert(id + ' is not a valid resolution.' + nan);
      return;
    }

    // saveList();
    let preview = document.createElement('div');
    const idFromSize = `id=${sizeId}`;

    preview.id = 'preview';
    preview.style.minWidth = preview.style.width = 'calc(' + w + 'px * var(--myScale))';
    preview.style.height = 'calc(' + h + 'px* var(--myScale))';
    preview.className = 'joyride-thumb"';
    // console.log('preview', preview, preview.style);
    previews().appendChild(preview);
    preview.innerHTML =
      '<div id="thumb" z=1 x=0 y=0><img id="myImg" src="' +
      `${myUrl}` +
      '" w=' +
      w +
      ' h=' +
      h +
      ' style="position: absolute; transform-origin: 0 0;"/></div><p class="header" >' +
      (displayName || `${w}x${h}`) +
      '</p><img url = ' +
      overlayUrl +
      ' src="' +
      overlayUrl +
      '" width="' +
      w +
      '"height="' +
      h +
      '" class="overlayImage hidden" alt="" id="' +
      sizeId +
      '" /><p id=p' +
      id +
      ' class="footer">' +
      w +
      'x' +
      h +
      '</p><i class="fa fa-undo icon icon-restart-plain primary-background " aria-hidden="true""></i><i class="fa fa-search-plus icon icon-zoom primary-background  "></i><input type="number" id="zoom" name="zoom" value=1  min=1 max=100 step="0.01"></input></div>';
    // <i id="iHateYou" class="fa fa-repeat icon icon-flip primary-background"></i>
    setTimeout(function() {
      // console.log('setAttribute', preview.firstChild);
      preview.firstChild.setAttribute('x', 0);
      preview.firstChild.setAttribute('y', 0);
      // console.log('setAttribute2', preview.firstChild);
    }, 30);
    preview.addEventListener('contextmenu', handleContextMenu);
    function handleContextMenu(event) {
      console.log('event', event);
      event.preventDefault(); // Prevent the default context menu
      event.stopPropagation();
      foc.current = event.target;
    }
    preview.addEventListener('pointerenter', pEnter);
    function pEnter(e) {
      console.log('penter', e);
      if (e.buttons < 1) {
        foc.current = e.target;
      }
    }
    preview.addEventListener('pointerleave', pLeave);
    function pLeave(e) {
      console.log('pleave', e);
      if (e.buttons < 1) {
        foc.current = null;
      }
    }
    dragElement(preview.firstChild);
    // console.log(preview.children, 'firstChild');
    preview.children[4]?.addEventListener('pointerdown', e => e.preventDefault());
    preview.children[4]?.addEventListener('click', e => doReset(e.target));
    preview.children[5]?.addEventListener('pointerdown', e =>
      doZoom(e, e.target.parentElement.firstChild)
    );
    preview.children[6]?.addEventListener('blur', e => zoomIn(e.target));
    preview.children[6]?.addEventListener('change', e => zoomIn(e.target));
    preview.children[7]?.addEventListener('click', e => {
      preview.firstChild.setAttribute(
        'mirror',
        preview.firstChild.getAttribute('mirror') ? '' : 'true'
      );
      doPreview(preview.firstChild.firstChild);
    });
    // preview.lastChild.addEventListener('focus', e => {
    //   e.target.value = '';
    //   preview.classList.add('aktief');
    //   previews().style.pointerEvents = 'none';
    // });

    if (single) {
      doPreview(preview.firstChild.firstChild);
    }
    if (!c) {
      preview.style.display = 'none';
    }
  }

  function SortData() {
    const getSize = s => s.offsetHeight + s.offsetWidth / 10;
    const subjects = Array.from(previews().querySelectorAll('#preview'));
    subjects.sort((a, b) => Math.sign(getSize(a) - getSize(b)));
    subjects.forEach(sub => {
      previews().appendChild(sub);
      // const q = "[id='" + sub.querySelector('.footer').innerHTML + "']";
      // my('myList').appendChild(my('myList').querySelector(q).parentElement);
    });
    // saveList();
    optimize();
  }

  function optimize() {
    let drags = previews().querySelectorAll('.break');
    drags.forEach(drag => drag.remove());
    drags = previews().querySelectorAll('#preview');

    let j = 0,
      thisY = null,
      tmpArray = [],
      myFlex = [],
      myRow = [],
      myLeft = [],
      myRight = [];

    for (j; j < drags.length; j++) {
      //drags[j].setAttribute("num", j)
      if (thisY != drags[j].offsetTop) {
        thisY = drags[j].offsetTop;
        myLeft.push(drags[j].offsetLeft);
        if (myRow.length) {
          myFlex.push(myRow);
          myRight.push(drags[j - 1].offsetLeft + drags[j - 1].offsetWidth);
        }
        myRow = [drags[j]];
      } else {
        myRow.push(drags[j]);
      }

      if (j == drags.length - 1) {
        myFlex.push(myRow);
        myRight.push(drags[j].offsetLeft + drags[j].offsetWidth);
      }
    }
    function getWidth(ar) {
      return ar[ar.length - 1].offsetLeft + ar[ar.length - 1].offsetWidth - ar[0].offsetLeft;
    }

    if (myFlex.length < 2) return;
    const gap = parseFloat(getComputedStyle(previews()).getPropertyValue('gap'));
    for (let k = myFlex.length - 2; k > -1; k--) {
      myRow = myFlex[k];
      if (myRow.length < 2) continue;
      for (let l = 0; l < myRow.length; l++) {
        if (l > 0) {
          thisY = myRow
            .slice(0, l)
            .map(a => a.offsetHeight)
            .reduce((b, c) => Math.max(b, c));
          const remain = myRow.slice(l).map(a => a.offsetHeight);
          const smallest = remain.reduce((b, c) => Math.min(b, c));
          const biggest = remain.reduce((b, c) => Math.max(b, c));
          if (smallest - 50 > thisY) {
            const minY = myFlex[k + 1].map(a => a.offsetHeight).reduce((b, c) => Math.min(b, c));
            if (
              biggest < minY + 45 &&
              getWidth(myRow.slice(l)) + getWidth(myFlex[k + 1]) + gap < previews().offsetWidth
            ) {
              myFlex[k + 1].unshift(...myFlex[k].splice(l));
              let breakDiv = document.createElement('div');
              breakDiv.className = 'break';
              previews().insertBefore(breakDiv, myFlex[k + 1][0]);
              break;
            }
          }
        }
      }
    }
    // console.log(myFlex);
  }

  function slist(target = previews()) {
    let items,
      drags,
      current,
      myVar = 0;
    if (!target) {
      target = my('dropdown-menu');
      myVar = 1;
    }
    if (target == previews()) myVar = -1;

    function doArray() {
      current = null;
      if (target == previews()) {
        items = previews().querySelectorAll('#preview');
      } else {
        items = target.getElementsByTagName('li');
      }
      drags = myVar == 1 ? Array.from(items).slice(2, items.length - 3) : Array.from(items);
    }
    doArray();
    for (let i of drags) {
      i.draggable = true;

      i.ondragstart = ev => {
        if (myVar < 0) {
          previews().className = 'drag';
          i.classList.add('dragging');
          const myImg = i.firstChild.firstChild;
          if (isSafari()) {
            const myCss = getComputedStyle(myImg);
            const s = myCss.transform.replace('matrix(', '').split(',')[0] * 1;
            const sx = Math.round(parseFloat(myCss.left) / s);
            const sy = Math.round(parseFloat(myCss.top) / s);
            const w = myImg.getAttribute('w') * 1;
            const h = yImg.getAttribute('h') * 1;
            let canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(myVid(), -sx, -sy, w / s, h / s, 0, 0, w, h);
            document.body.append(canvas);
            canvas.style = 'position:absolute;left:-100%;top:-100%;';
            ev.dataTransfer.setDragImage(canvas, 20, 20);
            setTimeout(function() {
              canvas.remove();
              canvas.width = canvas.height = 0;
              canvass = null;
            }, 100);
          } else {
            const r = window.devicePixelRatio;
            ev.dataTransfer.setDragImage(
              i.firstChild,
              20 - myImg.offsetLeft * r,
              20 - myImg.offsetTop * r
            );
          }
        }

        current = i;
        if (myVar < 0) {
          previews().addEventListener('dragover', event => {
            event.preventDefault();
          });
        }
      };

      i.ondragenter = ev => {
        if (i != current && drags.indexOf(current) > -1) {
          i.classList.add('drop');
        }
      };

      i.ondragleave = ev => {
        i.classList.remove('drop');
      };

      i.ondragend = () => {
        for (let it of drags) {
          it.classList.remove('drop');
          it.classList.remove('dragging');
        }
        if (myVar < 0) previews().className = '';
        if (myVar < 0) {
          previews().removeEventListener('drop', event => {});
          previews().removeEventListener('ondragover', event => {
            event.preventDefault();
          });
        }
      };

      if (myVar > 0) {
        i.onclick = ev => {
          doPreset(i.firstChild.innerHTML);
        };
      }

      i.ondragover = evt => {
        if (drags.indexOf(current) > -1) evt.preventDefault();
      };
      i.ondrop = dropped;
      function dropped(evt) {
        if (i == current) current == null;
        if (current == null) return;
        if (evt.target == previews()) {
          let j = 0,
            maxY = 0,
            thisY = null,
            tmpArray = [],
            bottoms = [];
          for (j; j < drags.length; j++) {
            const nxt = thisY != drags[j].offsetTop;
            const lst = j == drags.length - 1;
            if (nxt || lst) {
              tmpArray.forEach(drag => bottoms.push(maxY));
              if (lst) {
                if (nxt) {
                  bottoms.push(drags[j].offsetTop + drags[j].offsetHeight);
                } else {
                  bottoms.push(maxY);
                }
              }
              tmpArray = [drags[j]];
              thisY = drags[j].offsetTop;
              maxY = thisY + drags[j].offsetHeight;
            } else {
              tmpArray.push(drags[j]);
              maxY = Math.max(maxY, drags[j].offsetTop + drags[j].offsetHeight);
            }
          }

          for (j; j > 0; j--) {
            let test = drags[j - 1];
            if (current != test && test.offsetTop < evt.offsetY && test.offsetLeft < evt.offsetX)
              break;
            if (current != test && bottoms[j - 1] < evt.offsetY) break;
          }
          i = drags[Math.min(j, drags.length - 1)];
        }

        evt.preventDefault();

        let currentpos = 0,
          droppedpos = 0;
        for (let it = 0; it < items.length; it++) {
          if (current == items[it]) {
            currentpos = it;
          }
          if (i == items[it]) {
            droppedpos = it;
          }
        }
        if (currentpos < droppedpos) {
          i.parentNode.insertBefore(current, i.nextSibling);
        } else i.parentNode.insertBefore(current, i);

        doArray();
        if (myVar > 0) {
          const presets = JSON.parse(localStorage.presets || '{}');
          const sorted = new Map();
          drags.forEach(drag => {
            key = drag.lastChild.innerHTML;
            sorted.set(String(key), presets[key]);
          });
          localStorage.presets = JSON.stringify(Object.fromEntries(sorted));
        }
        if (myVar == 0) {
          for (let it = 0; it < items.length; it++) {
            previews().appendChild(my('p' + items[it].lastChild.innerHTML).parentElement);
          }
          saveList();
        }
        // if (myVar < 0) {
        //   for (let it = 0; it < items.length; it++) {
        //     const q = "[id='" + items[it].querySelector('.footer').innerHTML + "']";
        //     my('myList').appendChild(my('myList').querySelector(q).parentElement);
        //   }
        //   saveList();
        // }
      }
      if (myVar < 0 && previews().ondrop == null)
        previews().ondrop = function(event) {
          setTimeout(dropped(event), 0);
        };
    }
  }

  function doubleTap(e) {
    let lastTap = 0,
      timeout;
    return function detectDoubleTap(event) {
      const curTime = new Date().getTime();
      const tapLen = curTime - lastTap;
      if (tapLen < 500 && tapLen > 0) {
        let dblclickEvt = document.createEvent('MouseEvents');
        dblclickEvt.initEvent('dblclick');
        event.target.dispatchEvent(dblclickEvt);
        event.preventDefault();
      } else {
        timeout = setTimeout(() => {
          clearTimeout(timeout);
        }, 500);
      }
      lastTap = curTime;
    };
  }

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    document.body.addEventListener('touchend', doubleTap());
  }

  document.body.addEventListener('dblclick', function(e) {
    if (e.target == document.body && e.offsetY < bBox(myVid()).bottom && !e.targey) doTrack();
    if (e.target == previews()) SortData();
  });

  function moveFp(e) {
    const wrapper = document.getElementById('image-crop');
    if ((wrapper.scrollTop || wrapper.scrollLeft) > 0) {
      wrapper.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }
    console.log('movefp');
    fPoint().style.left = e.pageX + 'px';
    fPoint().style.top = e.pageY + 'px';
    let vid = bBox(myVid());
    const zoom = myJson.current.width / vid.width;
    myJson.current.x = Math.round(
      (parseFloat(getComputedStyle(fPoint(), null).getPropertyValue('left')) - vid.left) * zoom
    );
    myJson.current.y = Math.round(
      (parseFloat(getComputedStyle(fPoint(), null).getPropertyValue('top')) - vid.top) * zoom
    );
    myJson.current.c = false;

    fPoint().className = 'fMove';
    const myRect = document.querySelector('.rect');
    if (e.target) {
      if (myRect) myRect.style.display = 'none';
      myIcon().className = 'fa fa-crosshairs';
    } else {
      myIcon().className = 'fa fa-smile-o';
    }
    foc.current = 'fPoint';
    doPreview();
  }

  function fReset() {
    //
    if (myJson.current.c) {
      myIcon().className = 'fa fa-cog fa-spin';
      doTrack();
      return;
    }
    const myRect = document.querySelector('.rect');
    if (myRect) myRect.style.display = 'none';
    myIcon().className = 'fa fa-crosshairs';
    myJson.current.c = true;
    fPoint().className = 'fCenter';
    const rect = bBox();
    fPoint().style.left = (rect.left + rect.right) / 2 + 'px';
    fPoint().style.top = (rect.top + rect.bottom) / 2 + 'px';
    myJson.current.x = (myJson.current.left + myJson.current.width - myJson.current.right) / 2;
    myJson.current.y = (myJson.current.top + myJson.current.height - myJson.current.bottom) / 2;
    foc.current = 'fPoint';
    doPreview();
  }

  function mReset(e) {
    myJson.current.right = myJson.current.left = temp.current.width = temp.current.height = 0;
    myJson.current.top = e && 'top' in e ? e.top : 0;
    myJson.current.bottom = e && 'bottom' in e ? e.bottom : 0;
    myFunction(true);
    upDate();
    res().display = 'none';
  }

  function dragElement(elmnt) {
    let newX = 0,
      newY = 0,
      oldX = 0,
      oldY = 0,
      myLimit = null;

    elmnt.onpointerdown = dragMouseDown;
    // console.log(elmnt, 'drag');

    function dragMouseDown(e) {
      if (e.target.id != 'thumb') {
        if (e.target.id == 'fPoint') {
          foc.current = 'fPoint';
        } else {
          if (e.target.className != 'resizers') return;
          foc.current = 'crop';
        }
        myLimit = bBox(myVid());
        e = e || window.event;
      } else {
        foc.current = e.target.parentElement;
        foc.current.classList.add('aktief');
        document.body.classList.add('move');
      }
      e.preventDefault();
      oldX = typeof e.detail === 'object' && e.detail['pageX'] ? e.detail.pageX : e.pageX;
      oldY = e.pageY;
      document.onpointerup = closeDragElement;
      document.onpointerleave = closeDragElement;
      document.onpointermove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      let prev =
        foc.current == null || (foc.current && foc.current.id && foc.current.id == 'preview');
      const myX = prev ? e.pageX : clamp(e.pageX, myLimit.left, myLimit.right);
      const myY = prev ? e.pageY : clamp(e.pageY, myLimit.top, myLimit.bottom);
      newX = oldX - myX;
      newY = oldY - myY;
      oldX = myX;
      oldY = myY;
      if (foc.current == 'fPoint') {
        const wrapper = document.getElementById('image-crop');
        if ((wrapper.scrollTop || wrapper.scrollLeft) > 0) {
          wrapper.scrollTo(0, 0);
          window.scrollTo(0, 0);
        }
        myJson.current.c = false;
        fPoint().className = 'fMove';
        myIcon().className = 'fa fa-crosshairs';
        const myRect = document.querySelector('.rect');
        if (myRect) myRect.style.display = 'none';
        // set the myCrop()'s new position:
        const vid = bBox(myVid());
        const x = clamp(
          parseFloat(getComputedStyle(elmnt, null).getPropertyValue('left')) - newX,
          vid.left,
          vid.right
        );
        const y = clamp(
          parseFloat(getComputedStyle(elmnt, null).getPropertyValue('top')) - newY,
          vid.top,
          vid.bottom
        );

        elmnt.style.left = x + 'px';
        elmnt.style.top = y + 'px';
        const zoom = myJson.current.width / vid.width;
        myJson.current.x = Math.round((x - vid.left) * zoom);
        myJson.current.y = Math.round((y - vid.top) * zoom);

        doPreview();
      } else {
        moveIt(-newX, -newY);
      }
    }
  }

  function closeDragElement(e) {
    const path = e.path || (e.composedPath && e.composedPath());
    if (foc.current && foc.current.id && foc.current.id == 'preview') {
      foc.current.classList.remove('aktief');

      if (path.indexOf(foc.current) < 0) foc.current = null;
      document.body.classList.remove('move');
      document.body.classList.remove('zoom');
    }
    if (path.length > 1 && path[1].id && path[1].id == 'preview') foc.current = path[1];
    document.onpointerup = null;
    document.onpointermove = null;
  }

  function makeResizableDiv(skip) {
    // console.log('makeResizableDiv');
    const vid = bBox(myVid());
    //myVid().style.maxWidth= vid.width *  (window.innerHeight)/vid.height-previews().offsetHeight -window.innerHeight/3.5+'px';
    masker().style.left = vid.left + 'px';
    masker().style.width = vid.width + 'px';
    masker().style.top = vid.top + 'px';
    masker().style.height = vid.height + 'px';
    const zoom = myJson.current.width / vid.width || 1;

    if (temp.current.width == 0)
      temp.current.width = myJson.current.width - myJson.current.left - myJson.current.right;
    if (temp.current.height == 0)
      temp.current.height = myJson.current.height - myJson.current.top - myJson.current.bottom;

    myCrop().style.left = vid.left + myJson.current.left / zoom + 'px';
    myCrop().style.width = temp.current.width / zoom + 'px';
    myCrop().style.top = vid.top + myJson.current.top / zoom + 'px';
    myCrop().style.height = temp.current.height / zoom + 'px';

    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;
    let hitX = 0;
    let hitY = 0;
    const rect = bBox();

    if (myJson.current.c) {
      fPoint().style.left = (rect.left + rect.right) / 2 + 'px';
      fPoint().style.top = (rect.top + rect.bottom) / 2 + 'px';
      myJson.current.x = (myJson.current.left + myJson.current.width - myJson.current.right) / 2;
      myJson.current.y = (myJson.current.top + myJson.current.height - myJson.current.bottom) / 2;
    } else {
      fPoint().style.left = vid.left + myJson.current.x / zoom + 'px';
      fPoint().style.top = vid.top + myJson.current.y / zoom + 'px';
    }
    // console.log('myLeft', Math.max(rect.left - vid.left, 0));

    css().style.setProperty('--myLeft', Math.max(rect.left - vid.left, 0) + 'px');
    css().style.setProperty('--myTop', Math.max(rect.top - vid.top, 0) + 'px');
    css().style.setProperty('--myRight', Math.max(vid.right - rect.right, 0) + 'px');
    css().style.setProperty('--myBottom', Math.max(vid.bottom - rect.bottom, 0) + 'px');
    css().style.setProperty('--myZoom', zoom);
    css().style.setProperty('--myHeight', myVid().offsetHeight + 'px');
    document
      .querySelectorAll('.resizable .resizer')
      .forEach(element => element.replaceWith(element.cloneNode(true)));

    const resizers = document.querySelectorAll('.resizable .resizer');

    for (let i = 0; i < resizers.length; i++) {
      const currentResizer = resizers[i];

      currentResizer.addEventListener('pointerdown', mDown);
      function mDown(e) {
        e.preventDefault();
        const rect = bBox();
        original_width = rect.width;
        original_height = rect.height;
        original_x = rect.left;
        original_y = rect.top;
        hitX = e.offsetX - 7;
        hitY = e.offsetY - 7;
        original_mouse_x = e.pageX - hitX;
        original_mouse_y = e.pageY - hitY;

        window.removeEventListener('pointermove', resize, true);
        window.addEventListener('pointermove', resize, true);
        window.removeEventListener('pointerup', stopResize, true);
        window.addEventListener('pointerup', stopResize, true);
        document.body.removeEventListener('pointerleave', leaving, true);
        document.body.addEventListener('pointerleave', leaving, true);
      }

      function leaving(e) {
        if (e.target == '[object HTMLBodyElement]') stopResize();
      }

      function resize(e) {
        const mousX = clamp(e.pageX - hitX, vid.left, vid.right);
        const mousY = clamp(e.pageY - hitY, vid.top, vid.bottom);

        if (currentResizer.classList.contains('bottom-right')) {
          const width = original_width + (mousX - original_mouse_x);
          const height = original_height + (mousY - original_mouse_y);
          if (width > 20) {
            myCrop().style.width = Math.min(width, vid.right - vid.left) + 'px';
          }
          if (height > 20) {
            myCrop().style.height = Math.min(height, vid.bottom - vid.top) + 'px';
          }
        } else if (currentResizer.classList.contains('bottom-left')) {
          const height = original_height + (mousY - original_mouse_y);
          const width = original_width - (mousX - original_mouse_x);
          if (height > 20) {
            myCrop().style.height = Math.min(height, vid.bottom - vid.top) + 'px';
          }
          if (width > 20) {
            myCrop().style.width = Math.min(width, vid.right - vid.left) + 'px';
            myCrop().style.left =
              clamp(original_x + (mousX - original_mouse_x), vid.left, vid.right) + 'px';
          }
        } else if (currentResizer.classList.contains('top-right')) {
          const width = original_width + (mousX - original_mouse_x);
          const height = original_height - (mousY - original_mouse_y);
          if (width > 20) {
            myCrop().style.width = Math.min(width, vid.right - vid.left) + 'px';
          }
          if (height > 20) {
            myCrop().style.height = Math.min(height, vid.bottom - vid.top) + 'px';
            myCrop().style.top =
              clamp(original_y + (mousY - original_mouse_y), vid.top, vid.bottom) + 'px';
          }
        } else if (currentResizer.classList.contains('top-left')) {
          const width = original_width - (mousX - original_mouse_x);
          const height = original_height - (mousY - original_mouse_y);
          if (width > 20) {
            myCrop().style.width = Math.min(width, vid.right - vid.left) + 'px';
            myCrop().style.left =
              clamp(original_x + (mousX - original_mouse_x), vid.left, vid.right) + 'px';
          }
          if (height > 20) {
            myCrop().style.height = Math.min(height, vid.bottom - vid.top) + 'px';
            myCrop().style.top =
              clamp(original_y + (mousY - original_mouse_y), vid.top, vid.bottom) + 'px';
          }
        }
        upDate(vid);
      }

      function stopResize() {
        setTimeout(function() {
          foc.current = currentResizer.classList.value;
        }, 10);
        document.body.removeEventListener('pointerleave', leaving, true);
        window.removeEventListener('pointermove', resize, true);
        window.removeEventListener('pointerup', stopResize, true);
      }
    }
  }

  function upDate(vid) {
    const wrapper = document.getElementById('image-crop');
    if ((wrapper.scrollTop || wrapper.scrollLeft) > 0) {
      wrapper.scrollTo(0, 0);
      window.scrollTo(0, 0);
    }

    if (vid == null) vid = bBox(myVid());
    const rect = bBox();

    myCrop().style.cursor = rect.width < vid.width || rect.height < vid.height ? 'move' : 'default';
    const myLeft = rect.left - vid.left,
      myRight = vid.right - rect.right,
      myTop = rect.top - vid.top,
      myBottom = vid.bottom - rect.bottom;
    console.log('upDate', myLeft, myRight, myTop, myBottom, vid.top);
    const biggest = [myLeft, myRight, myTop, myBottom].sort((a, b) => a - b).slice(-1);
    res().display = biggest > 20 ? 'block' : 'none';
    let lB = '';
    res().transform = '';
    res().left = res().right = res().top = res().bottom = 'auto';
    if (myTop == biggest) {
      res().top = '5px';
      res().left = '45%';
    }
    if (myBottom == biggest) {
      res().bottom = '5px';
      res().left = '45%';
    }
    if (myLeft == biggest) {
      res().top = '48%';
      if (myLeft < 30) {
        res().transform = 'rotate(90deg)';
        res().left = '-15px';
      } else {
        lB = '<br>';
        res().left = '5px';
      }
    }
    if (myRight == biggest) {
      res().top = '48%';
      if (myRight < 30) {
        res().transform = 'rotate(-90deg)';
        res().right = '-15px';
      } else {
        lB = '<br>';
        res().right = '5px';
      }
    }
    const zoom = myJson.current.width / myVid().offsetWidth; // .getBoundingClientRect().width;
    my('res').innerHTML =
      Math.round(rect.width * zoom) + lB + 'x' + lB + Math.round(rect.height * zoom);
    css().style.setProperty('--myLeft', Math.max(myLeft, 0) + 'px');
    css().style.setProperty('--myTop', Math.max(myTop, 0) + 'px');
    css().style.setProperty('--myRight', Math.max(myRight, 0) + 'px');
    css().style.setProperty('--myBottom', Math.max(myBottom, 0) + 'px');
    css().style.setProperty('--myZoom', zoom);
    // console.log('myLeft update', Math.max(myLeft, 0) + 'px', css());
    myJson.current.left = Math.round(Math.max(myLeft, 0) * zoom);
    myJson.current.top = Math.round(Math.max(myTop, 0) * zoom);
    temp.current.width = Math.round(rect.width * zoom);
    temp.current.height = Math.round(rect.height * zoom);
    myJson.current.right = Math.round(Math.max(myRight, 0) * zoom);
    myJson.current.bottom = Math.round(Math.max(myBottom, 0) * zoom);

    if (myJson.current.c) {
      myJson.current.x = (myJson.current.left + myJson.current.width - myJson.current.right) / 2;
      myJson.current.y = (myJson.current.top + myJson.current.height - myJson.current.bottom) / 2;
      fPoint().style.left = (rect.left + rect.right) / 2 + 'px';
      fPoint().style.top = (rect.top + rect.bottom) / 2 + 'px';
    }

    doPreview();
  }

  window.onresize = function() {
    myFunction(true);
  };

  function myFunction(skip) {
    // console.log('myFunction', skip);
    // my('preset').style.visibility = my('mySide').offsetWidth > 149 ? 'visible' : 'hidden';
    makeResizableDiv(skip);
    doPreview();
    const vpw = document.body.clientWidth;
    document
      .querySelectorAll('#preview')
      .forEach(elem => (elem.style.left = Math.max(0, (elem.offsetWidth - vpw) / 2) + 'px'));
    optimize();
  }

  function keyDown(event) {
    let path = event.path || (event.composedPath && event.composedPath());

    if (event.keyCode == 13) {
      if (path[0].name == 'zoom') {
        zoomIn(path[0]);
        path[0].blur();
      }
      if (path[0].id && path[0].id == 'myName') {
        Additem();
      }
    }

    if (path.includes(my('mySide'))) return;

    const p = foc.current && foc.current.id && foc.current.id == 'preview' ? 2 : 1;

    switch (event.code) {
      case 'ArrowUp':
        moveIt(0, -1 * p);
        break;
      case 'ArrowDown':
        moveIt(0, +1 * p);
        break;
      case 'ArrowLeft':
        moveIt(-1 * p, 0);
        break;
      case 'ArrowRight':
        moveIt(1 * p, 0);
        break;
    }

    const holder = path.indexOf(my('myHolder')) < 0 ? false : true;
    const isPreview = foc.current && foc.current.id && foc.current.id == 'preview';
    if (isPreview && Math.abs(event.deltaY || 0)) {
      if (
        parseFloat(
          getComputedStyle(foc.current.firstChild)
            .getPropertyValue('outline-color')
            .split(',')
            .pop()
        ) > 0.8
      ) {
        return;
      } else {
        event.preventDefault();
      }
    }
    if (event.key == '-' || event.deltaY > 0) {
      if (isPreview) {
        foc.current.firstChild.attributes.z.value = foc.current.lastChild.value = Math.max(
          parseFloat(foc.current.lastChild.value * 1 - 0.01).toFixed(2),
          1
        );
        doPreview(foc.current.firstChild.firstChild);
      } else if (holder) {
        event.preventDefault();
        const rect = bBox();
        if (rect.width >= 22) {
          myCrop().style.left = rect.left + 1 + 'px';
          myCrop().style.width = rect.width - 2 + 'px';
        }
        if (rect.height >= 22) {
          myCrop().style.top = rect.top + 1 + 'px';
          myCrop().style.height = rect.height - 2 + 'px';
        }
        upDate(bBox(myVid()));
      }
    }
    if (event.key == '+' || event.key == '=' || event.deltaY < 0) {
      if (isPreview) {
        foc.current.firstChild.attributes.z.value = foc.current.lastChild.value = parseFloat(
          foc.current.lastChild.value * 1 + 0.01
        ).toFixed(2);
        doPreview(foc.current.firstChild.firstChild);
      } else if (holder) {
        event.preventDefault();
        const rect = bBox();
        const vid = bBox(myVid());
        if (
          rect.left - 1 >= vid.left &&
          rect.right + 1 <= vid.right &&
          rect.top - 1 >= vid.top &&
          rect.bottom + 1 <= vid.bottom
        ) {
          myCrop().style.left = rect.left - 1 + 'px';
          myCrop().style.top = rect.top - 1 + 'px';
          myCrop().style.width = rect.width + 2 + 'px';
          myCrop().style.height = rect.height + 2 + 'px';
          upDate(vid);
        }
      }
    }
  }

  function moveIt(X, Y) {
    if (foc.current == 'fPoint') {
      myJson.current.c = false;
      fPoint().className = 'fMove';
      const vid = bBox(myVid());
      const x = clamp(
        parseFloat(getComputedStyle(fPoint(), null).getPropertyValue('left')) + X,
        vid.left,
        vid.right
      );
      const y = clamp(
        parseFloat(getComputedStyle(fPoint(), null).getPropertyValue('top')) + Y,
        vid.top,
        vid.bottom
      );
      fPoint().style.left = x + 'px';
      fPoint().style.top = y + 'px';
      const zoom = myJson.current.width / vid.width;
      myJson.current.x = Math.round((x - vid.left) * zoom);
      myJson.current.y = Math.round((y - vid.top) * zoom);
      doPreview();
    }

    if (foc.current == 'crop') {
      const rect = bBox();
      const vid = bBox(myVid());

      if (rect.left + X >= vid.left && rect.right + X <= vid.right) {
        myCrop().style.left = rect.left + X + 'px';
      }
      if (rect.top + Y >= vid.top && rect.bottom + Y <= vid.bottom) {
        myCrop().style.top = rect.top + Y + 'px';
      }
      upDate(vid);
    }

    if (foc.current && foc.current.id && foc.current.id == 'preview') {
      const p = foc.current.firstChild;
      p.attributes.x.value = p.attributes.x.value * 1 + X;
      p.attributes.y.value = p.attributes.y.value * 1 + Y;
      doPreview(p.firstChild);
      return;
    }

    if (foc.current && foc.current.includes('resizer')) {
      const rect = bBox();
      const vid = bBox(myVid());

      if (
        foc.current.includes('top') &&
        Y != 0 &&
        rect.height - Y > 20 &&
        rect.top + Y >= vid.top
      ) {
        myCrop().style.height = rect.height - Y + 'px';
        myCrop().style.top = rect.top + Y + 'px';
      }
      if (
        foc.current.includes('bottom') &&
        Y != 0 &&
        rect.height + Y > 20 &&
        rect.bottom + Y <= vid.bottom
      ) {
        myCrop().style.height = rect.height + Y + 'px';
      }
      if (
        foc.current.includes('left') &&
        X != 0 &&
        rect.width - X > 20 &&
        rect.left + X >= vid.left
      ) {
        myCrop().style.width = rect.width - X + 'px';
        myCrop().style.left = rect.left + X + 'px';
      }
      if (
        foc.current.includes('right') &&
        X != 0 &&
        rect.width + X > 20 &&
        rect.right + X <= vid.right
      ) {
        myCrop().style.width = rect.width + X + 'px';
      }
      upDate(vid);
    }
  }

  function finish() {
    fPoint().className = 'fCenter';
    if (firstRun.current) {
      const creatives = state.sizes;
      // console.log(creatives, 'creatives');
      creatives.forEach(creative =>
        Additem(
          creative.width,
          creative.height,
          true,
          creative._id,
          creative.coverImage,
          creative.name
        )
      );
      // slist(my('myList'));
      slist(previews());
      // buildList(JSON.parse(localStorage.presets || '{}'));
      firstRun.current = false;
    } else {
      document.querySelectorAll('#myImg').forEach(myImg => (myImg.src = myUrl));
    }
    previews().style.visibility = 'hidden';
    setTimeout(function() {
      previews().style.visibility = 'visible';
    }, 40);
    setTimeout(function() {
      doPreview();
      myFunction(true);
    }, 50);
  }

  function doTrack() {
    myIcon().className = 'fa fa-cog fa-spin';
    // console.log('tracking....');

    // let tracker = new tracking.ObjectTracker(['face', 'eye']);

    // tracker.once('track', function(event) {
    //   console.log('tracking:done');
    //   let faces = event.data.filter(o => o.d >= 25000 && o.total > 1);
    //   let eyes =
    //     faces.length == 1
    //       ? event.data.filter(
    //           o =>
    //             o.total > 1 &&
    //             o.d <= 25000 &&
    //             o.x > faces[0].x &&
    //             o.x + o.width < faces[0].x + faces[0].width &&
    //             o.y > faces[0].y &&
    //             o.y + o.height < faces[0].y + faces[0].height
    //         )
    //       : [];

    //   if (faces.length != 1) {

    //     return;
    //   } else if (eyes.length == 2) {
    //     (faces[0].x = Math.min(eyes[0].x, eyes[1].x)),
    //       (faces[0].y = Math.min(eyes[0].y, eyes[1].y)),
    //       (faces[0].width =
    //         Math.max(eyes[0].x + eyes[0].width, eyes[1].x + eyes[1].width) - faces[0].x);
    //     faces[0].height =
    //       Math.max(eyes[0].y + eyes[0].height, eyes[1].y + eyes[1].height) - faces[0].y;
    //   }
    //   let rect = document.querySelector('.rect');
    //   if (rect == null) {
    //     rect = document.createElement('div');
    //     masker().appendChild(rect);
    //     rect.classList.add('rect');
    //   }
    //   rect.style.display = 'block';
    //   rect.style.width = (100 * faces[0].width) / temp.current.canvas.width + '%';
    //   rect.style.height = (100 * faces[0].height) / temp.current.canvas.height + '%';
    //   rect.style.left = (100 * faces[0].x) / temp.current.canvas.width + '%';
    //   rect.style.top = (100 * faces[0].y) / temp.current.canvas.height + '%';
    //   const vid = bBox(myVid());
    //   const zoom = temp.current.canvas.width / vid.width;
    //   const e = {};
    //   e.pageX = vid.left + (faces[0].x + faces[0].width / 2) / zoom;
    //   e.pageY = vid.top + (faces[0].y + faces[0].height / 2) / zoom;
    //   moveFp(e);
    //   temp.current.canvas.width = temp.current.canvas.height = 0;
    //   temp.current.canvas = null;
    // });
    const myRect = document.querySelector('.rect');
    if (myRect) {
      myRect.style.display = 'block';
      const rectB = bBox(myRect);
      const e = {};
      e.pageX = rectB.left + rectB.width / 2;
      e.pageY = rectB.top + rectB.height / 2;
      moveFp(e);
    }
    myIcon().className = 'fa fa-crosshairs';
    myFunction(true);
    finish();
  }

  function zoomIn(e) {
    // console.log(e, 'zoomIn');
    e.parentElement.classList.remove('aktief');
    const p = e.parentElement.firstChild;
    if (isNaN(e.value) || e.value == '') {
      e.value = p.attributes.z.value;
    } else {
      p.attributes.z.value = Math.max(e.value, 1);
    }
    doPreview(p.firstChild);
    previews().style.pointerEvents = 'auto';
  }

  function doReset(e) {
    const p = e.parentElement.firstChild;
    p.attributes.z.value = 1;
    p.attributes.x.value = 0;
    p.attributes.y.value = 0;
    e.parentElement.lastChild.value = 1;
    doPreview(p.firstChild);
  }

  function doPreview(myPreview, extra) {
    const myArray = myPreview ? [myPreview] : document.querySelectorAll('#myImg');
    myArray.forEach(myImg => {
      const myDiv = myImg.parentElement,
        z = myDiv.attributes.z.value * 1;
      // console.log('previewing....', myImg, myDiv.attributes);
      if (isNaN(myDiv.getAttribute('x'))) myDiv.setAttribute('x', 0);
      if (isNaN(myDiv.getAttribute('y'))) myDiv.setAttribute('y', 0);
      // console.log('previewing after', myDiv.getAttribute('x'), myDiv.getAttribute('y'));
      if (!extra) {
        while (myDiv.childElementCount > 1) {
          myDiv.removeChild(myDiv.lastChild);
        }
      }

      // console.log('before calc', myDiv.offsetWidth, temp.current, myJson.current);
      const fW = myDiv.offsetWidth,
        fH = myDiv.offsetHeight,
        H = temp.current.width * z,
        V = temp.current.height * z;
      // console.log('fW: ' + fW);
      const s = Math.max(fW / H, fH / V) * z;
      // console.log('s: ' + s);
      //const S = temp.current.s * s * z;
      const S = Math.max(temp.current.s * s * z, fW / temp.current.width, fH / temp.current.height);
      // console.log('S: ' + S);
      const x = myJson.current.left * z + H / 2;
      // console.log('x: ' + x);
      const y = myJson.current.top * z + V / 2;
      // console.log('y: ' + y);
      const X = Math.max(H - fW / s, 0) / 2;
      // console.log('X: ' + X);
      const Y = Math.max(V - fH / s, 0) / 2;
      // console.log('Y: ' + Y);
      const h = fW / 2 - (clamp(myJson.current.x * z, x - X, x + X) - myJson.current.x) * s;
      // console.log('h: ' + h);
      const v = fH / 2 - (clamp(myJson.current.y * z, y - Y, y + Y) - myJson.current.y) * s;
      // console.log('v: ' + v);
      const L = h - myJson.current.x * s;
      // console.log('L: ' + L);
      const T = v - myJson.current.y * s;
      // console.log('T: ' + T);
      const xv = clamp(
        myDiv.attributes.x.value * 1,
        1 - (S * (temp.current.width + myJson.current.left) - fW + L),
        -L - S * myJson.current.left
      );
      // console.log('xv: ' + xv);
      const yv = clamp(
        myDiv.attributes.y.value * 1,
        1 - (S * (temp.current.height + myJson.current.top) - fH + T),
        -T - S * myJson.current.top
      );
      // console.log('yv: ' + yv);
      // console.log('doPreview test', xv, yv, myDiv.attributes);

      myDiv.attributes.x.value = xv;
      myDiv.attributes.y.value = yv;
      myImg.style.left = L + xv + 'px';
      myImg.style.top = T + yv + 'px';
      myImg.style.transform =
        // (myDiv.attributes?.mirror ? 'translate(' + S * 100 + '%, 0)' : '') +
        // 'scale(' + S * (myDiv.attributes?.mirror ? -1 : 1) + ',' + S + ')';
        'scale(' + S + ')';
      console.log('scale(' + S + ',' + S * (myDiv.attributes?.mirror ? -1 : 1) + ')');
    });
  }

  function doZoom(e, p) {
    // console.log('doZoom', e, p);
    p.parentElement.classList.add('aktief');
    document.body.classList.add('zoom');
    const oldY = e.pageY;
    const oldX = e.pageX;
    document.onpointerup = closeDragElement;
    document.onpointerleave = closeDragElement;
    document.onpointermove = Zoom;
    let z = p.attributes.z.value * 1;
    function Zoom(e) {
      p.parentElement.lastChild.value = p.attributes.z.value = Math.max(
        z + (oldY - e.pageY) / 50 + (oldX - e.pageX) / 200,
        1
      ).toFixed(2);
      doPreview(p.firstChild);
    }
  }

  async function generate(sketch = false) {
    const filtered = [].filter.call(document.querySelectorAll('#myImg'), function(el) {
      return (
        el.parentElement.parentElement.style.display !== 'none ' &&
        !el.parentElement.parentElement.classList.contains('toggle')
      );
    });
    // console.log('filtered', filtered);

    const files = await Promise.all(
      filtered.map(myImg =>
        img2canvas(myImg, sketch).then(myBlob => {
          console.log(myBlob, 'myblob');
          return myBlob;

          // zip.file(myBlob.name, new File([myBlob.blob], myBlob.name, {type: myBlob.mime}), {
          //   binary: true,
          // });
        })
      )
    );

    // // console.log(files, 'blobfiles');
    // if (sketch) {
    //   doScale(state.scale);
    // }

    return files.filter(x => x);
  }

  async function img2canvas(myImg, preview = false) {
    const myDiv = myImg.parentElement;
    const title =
      myDiv?.parentElement?.children?.[1]?.innerHTML ||
      `${myImg.getAttribute('w')}x${myImg.getAttribute('h')}`;
    const dragged = myDiv.classList.contains('dragging');
    if (
      myDiv.parentElement.style.display == 'none' ||
      myDiv.parentElement.classList.contains('hidden')
    )
      return;
    const render = document.createElement('div');
    render.className = 'fa fa-cog fa-spin render';

    const ret = state.scale;
    console.log('myImg', myImg, myImg.clientHeight, 'w', myImg.clientWidth);
    css().style.setProperty('--myScale', 1);
    doPreview(myImg);
    myDiv.appendChild(render);
    const myCss = getComputedStyle(myImg);
    let s = myCss.transform.replace('matrix(', '').split(',')[3];
    const s2 = myCss.transform.replace('matrix(', '').split(',')[0];
    //console.log(s, 's');
    const w = myImg.getAttribute('w') * 1;
    const h = myImg.getAttribute('h') * 1;
    // const sx = Math.round(s2 < 0 ? w - parseFloat(myCss.left) : parseFloat(myCss.left) / s);
    const sx = Math.round(parseFloat(myCss.left) / s);
    const sy = Math.round(parseFloat(myCss.top) / s);
    const sw = Math.ceil(w / s);
    const sh = Math.ceil(h / s);
    console.log('s:', s, 'sx:', sx, 'sy:', sy, 'w:', w, 'h:', h, 'sw:', sw, 'sh:', sh);
    console.log('myCss top/left', myCss.top, myCss.left);
    // console.log('img2canvas', sx, sy, sw, sh, w, h, s, ret, pica);
    let canvas = document.createElement('canvas');
    canvas.width = sw;
    canvas.height = sh;
    console.log('canvas width', canvas.width);
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(0,0,0,0)';
    if (state.format !== 'PNG') ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // if (s2 < 0) {
    //   console.log('sx', sx, 'canvas.width translate', canvas.width);
    //   // sx *= -1;
    //   ctx.translate(canvas.width, 0);
    //   ctx.scale(-1, 1);
    //   console.log(ctx);
    // }

    ctx.drawImage(imgRef.current, sx, sy);
    let disxt;
    let offScreenCanvas = document.createElement('canvas');
    offScreenCanvas.width = w * ret;
    offScreenCanvas.height = h * ret;
    let offScreenCanvasRetina = document.createElement('canvas');
    offScreenCanvasRetina.width = w * 2;
    offScreenCanvasRetina.height = h * 2;
    console.log(myCss.transform, 'myCss.transform');
    // console.log(w, h, canvas, offScreenCanvas, s, sw, sh, myCss, 'test preview');
    const q = state.quality / 100;
    const typ = state.format.toLowerCase();
    const mime = 'image/' + (state.format == 'PNG' ? 'png' : 'jpeg');
    css().style.setProperty('--myScale', 1);
    doPreview(myImg, true);
    console.log(canvas, offScreenCanvas);

    return new Promise(function(myResolve, myReject) {
      pica()
        .resize(canvas, offScreenCanvas, {
          unsharpAmount: 200,
          unsharpRadius: 0.5,
          unsharpThreshold: 1,
          transferable: true,
        })
        .then(result => pica().toBlob(result, mime, q))
        .then(blob => {
          if (state.retina && preview) {
            pica()
              .resize(canvas, offScreenCanvasRetina, {
                unsharpAmount: 200,
                unsharpRadius: 0.5,
                unsharpThreshold: 1,
                transferable: true,
              })
              .then(result => pica().toBlob(result, mime, q))
              .then(secondBlob => {
                let file = new File([secondBlob], w + 'x' + h + typ, {type: mime});
                render.className = 'filesize';
                console.log(file.size, 'file.size');
                render.innerHTML = (file.size / 1000).toFixed(1) + 'KB';
              });
          }
          // offScreenCanvas.height = offScreenCanvas.width = canvas.width = canvas.height = 0;
          // offScreenCanvas = canvas = null;

          if (preview) {
            let file = new File([blob], w + 'x' + h + typ, {type: mime});
            if (!state.retina) {
              render.className = 'filesize';
              render.innerHTML = (file.size / 1000).toFixed(1) + 'KB';
            }
            const pic = new Image();
            myDiv.appendChild(pic);
            pic.className = 'pic';
            pic.src = URL.createObjectURL(file);
            console.log(preview, 'inner preview');
            myResolve('');
          } else {
            render.remove();
            myResolve({
              blob: blob,
              type: mime,
              name: `${title}_${state.imageName}${ret > 1 ? `x${ret}` : ''}.${typ}`,
            });
          }
        });
    });
  }

  function unhide() {
    my('myContainer').classList.remove('hidden');
    my('overlayGroup').classList.remove('hidden');
    // my('title').classList.remove('hidden');
    // uploader().classList.add('hidden');
  }

  function svgUrlToPng(svgUrl, callback) {
    const svgImage = document.createElement('img');
    document.body.appendChild(svgImage);
    svgImage.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width = svgImage.clientWidth;
      canvas.height = svgImage.clientHeight;
      const canvasCtx = canvas.getContext('2d');
      canvasCtx.drawImage(svgImage, 0, 0);
      const imgData = canvas.toDataURL('image/png');
      callback(imgData);
      document.body.removeChild(svgImage);
      //canvas.width = canvas.height = 0;
      //canvas = null;
    };
    svgImage.src = svgUrl;
  }

  function svgToPng(svg, callback) {
    const url = URL.createObjectURL(
      new Blob([svg], {
        type: 'image/svg+xml',
      })
    );
    svgUrlToPng(url, imgData => {
      callback(imgData);
      URL.revokeObjectURL(url);
    });
  }
  const onload = () => {
    myCrop().addEventListener('dblclick', moveFp);
    fPoint().addEventListener('dblclick', fReset);
    masker().addEventListener('dblclick', mReset);
    dragElement(fPoint());
    dragElement(myCrop());

    myCrop().onclick = masker().onclick = function() {
      foc.current = 'crop';
    };

    document.addEventListener('keyup', event => {
      if (event.code === 'Escape') {
        if (foc.current == 'fPoint') fReset();
        if (foc.current == 'crop') mReset();
        if (foc.current && foc.current.id && foc.current.id == 'preview') {
          doReset(foc.current.firstChild);
        }
      }
    });

    document.addEventListener('wheel', keyDown, {passive: false});
    document.addEventListener('keydown', keyDown);

    unhide();
    myJson.current.width = myVid().naturalWidth;
    myJson.current.height = myVid().naturalHeight;
    temp.current.canvas = document.createElement('canvas');
    const zoom = Math.min(512 / myJson.current.width, 512 / myJson.current.height, 1);
    const w = (temp.current.canvas.width = myJson.current.width * zoom);
    const h = (temp.current.canvas.height = myJson.current.height * zoom);
    const ctx = temp.current.canvas.getContext('2d');
    ctx.drawImage(myVid(), 0, 0, w, h);
    if (!firstRun.current) {
      fReset();
      mReset();
      //doPreview();
    }
    try {
      doTrack();
      setRunJoyride(true);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useLayoutEffect(() => {
    Array.from(previews().querySelectorAll('#preview')).forEach(p => dragElement(p.firstChild));
  }, [previews, dragElement]);

  React.useImperativeHandle(ref, () => ({
    generate,
    getState: () => state,
  }));

  function toggleOverlay() {
    previews()
      .querySelectorAll('.overlayImage')
      .forEach(o => {
        console.log('overlayTest', o, o.src);
        o.classList.toggle('hidden');
      });
  }

  function toggleVisiblePreviews(sizes) {
    sizes.forEach(s => {
      const pr = document.getElementById(s._id);
      if (s.enabled) {
        pr.parentElement.classList.remove('hidden');
      } else {
        pr.parentElement.classList.add('hidden');
      }
      // .forEach(o => o.classList.toggle('hidden'));
      // console.log(pr.parentElement, s, 'preview');
    });
  }

  console.log(foc);
  return (
    <div className="rootContainer" id="rootContainer">
      {isLoading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden" />
          </Spinner>
        </div>
      )}
      <div
        id="myContainer"
        className="hidden my-other-step"
        // onContextMenu={e => {
        //   console.log('event', 2);
        //   e.preventDefault();
        //   e.stopPropagation();
        // }}
        // onMouseDown={event => {
        //   console.log(foc, 'event3');
        //   if (event.button === 2) {
        //     event.preventDefault(); // Prevent right mouse button click
        //     event.stopPropagation();
        //   }
        // }}
      >
        <Cropper myUrl={myUrl} onload={onload} />
        <div className="ml-4" style={{width: '35%'}}>
          <div style={{width: '80%', height: '50px'}} className="form-group mr-4 joyride-title ">
            <span className="dco-edit-label">Title</span>
            <input
              type="text"
              name="title"
              value={state.imageName}
              className="form-control  dco-edit-input"
              onChange={e => {
                stateDispatch({type: 'IMAGE_NAME_CHANGE', value: e.target.value});
              }}
            />
          </div>
          <div style={{width: '80%', height: '50px'}} className="form-group mr-4 joyride-preset ">
            <span className="dco-edit-label">Select Preset</span>
            <Select
              isClearable={false}
              styles={customInputStyles}
              isSearchable={false}
              value={{label: state.preset.name, value: state.preset}}
              cacheOptions={false}
              // menuPortalTarget={document.body}
              placeholder="Status"
              onChange={selectValue => {
                stateDispatch({type: 'PRESET_CHANGE', value: selectValue.value});
                onPresetChange(selectValue.value);
              }}
              options={presets.map(s => ({label: s.name, value: s}))}
            />
          </div>
          <div
            style={{width: '80% ', minHeight: '50px'}}
            className="form-group mr-4 joyride-size-select "
          >
            <SizeSelect
              sizes={state.sizes.sort((a, b) => a.width - b.width)}
              handleChange={newSizes => {
                stateDispatch({type: 'SIZE_CHANGE', value: newSizes});
                toggleVisiblePreviews(newSizes);
              }}
            />
          </div>
          <br />

          <div style={{width: '100%'}} className=" d-flex flex-wrap ">
            <div style={{width: '40%'}} className="form-group mr-4 joyride-format ">
              <span className="dco-edit-label">Format</span>
              <Select
                isClearable={false}
                styles={customInputStylesSmartCrop}
                isSearchable={false}
                value={{label: state.format, value: state.format}}
                cacheOptions={false}
                // menuPortalTarget={document.body}
                placeholder="Status"
                onChange={selectValue => {
                  stateDispatch({type: 'FORMAT_CHANGE', value: selectValue.value});
                }}
                options={['JPG', 'PNG'].map(s => ({label: s, value: s}))}
              />
            </div>
            {state.format !== 'PNG' && (
              <div style={{width: '40%'}} className="form-group mr-4 joyride-quality ">
                <span className="dco-edit-label">
                  Quality
                  {/* {renderOverlay()} */}
                </span>
                <input
                  type="number"
                  name="quality"
                  style={{
                    height: '40px',
                  }}
                  value={state.quality}
                  min="1"
                  step="1"
                  max="100"
                  className="form-control form-control-sm dco-edit-input"
                  onChange={e => {
                    stateDispatch({type: 'QUALITY_CHANGE', value: Number(e.target.value)});
                  }}
                />
              </div>
            )}
            {/* {         <div style={{width: '40%'}} className="form-group mr-4 ">
              <span className="dco-edit-label">
                Retina (2X)
              </span>
              <div className="mt-3">
                <Switch
                  checked={state.retina}
                  handleChange={checked => {
                    stateDispatch({type: 'RETINA_CHANGE', value: checked});
                  }}
                />
              </div>
            </div>} */}

            <div style={{width: '40%'}} className="form-group mr-4 joyride-retina ">
              <span className="dco-edit-label text-nowrap">Retina multiplier</span>
              <Select
                isClearable={false}
                styles={customInputStylesSmartCrop}
                isSearchable={false}
                value={{label: `${state.scale}x`, value: state.scale}}
                cacheOptions={false}
                // menuPortalTarget={document.body}
                placeholder="Retina multiplier"
                onChange={selectValue => {
                  // doScale(selectValue.value);
                  stateDispatch({type: 'SCALE_CHANGE', value: selectValue.value});
                }}
                options={['0.5', '1', '1.5', '2', '3', '4'].map(s => ({
                  label: `${s}x`,
                  value: s,
                }))}
              />
            </div>

            <div className="form-group mr-4 d-flex align-items-end ">
              <div
                className="icon icon-view bg-primary joyride-preview"
                onClick={() => {
                  generate(true);
                }}
                role="button"
                tabIndex={0}
                style={{
                  height: 30,
                  width: 30,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div id="overlayGroup" className="hidden d-flex align-items-end justify-content-end">
        <div className="form-group mr-4">
          <Button
            className="loader-button overlayButton joyride-overlay"
            onClick={() => toggleOverlay()}
            disabled={!state.sizes.some(s => s.coverImage)}
          >
            Show Overlay
          </Button>
        </div>
      </div>

      <div
        id="previews"
        className="joyride-previews"
        onContextMenu={e => {
          console.log('event4', e);

          e.preventDefault();

          // e.stopPropagation();
        }}
        onMouseDown={event => {
          console.log('event5');
          if (event.button === 2) {
            event.preventDefault(); // Prevent right mouse button click
            event.stopPropagation();
            foc.current = null;
          }
        }}
      />

      <Joyride
        steps={steps}
        run={runJoyride}
        continuous
        showSkipButton
        locale={{
          last: 'End Tour',
        }}
        styles={{
          options: {
            arrowColor: '#081ee0',
            backgroundColor: '#f5f5f5',
            primaryColor: '#081ee0',
            textColor: '#000',
            zIndex: 1051,
          },
        }}
      />
    </div>
  );
};

export default forwardRef(SmartCrop);
