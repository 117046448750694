/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const CustomToggle = React.forwardRef(({children, onClick, disabled}, ref) => {
  return (
    <div
      ref={ref}
      className={`d-flex align-items-center custom-toggle ${disabled ? 'disabled' : ''}`}
      onClick={e => {
        e.preventDefault();
        if (disabled) {
          return;
        }
        onClick(e);
      }}
    >
      {!!children && <div className="mr-4">{children}</div>}
      <FontAwesomeIcon icon="ellipsis-v" fixedWidth />
    </div>
  );
});

export default CustomToggle;
