import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import api from './../../api';
import {updateUserData} from './../login/slice';
// import { findAvatars } from './../../pages/file/slice';

export const getUsers = createAsyncThunk(
  'user/get',
  async (filter, {dispatch, rejectWithValue}) => {
    try {
      const response = await api.user.getUsers(filter);
      const users = [...new Set(response.data.items.map(p => p._id))];
      // dispatch(findAvatars({ key: `users/avatars`, type: 'user', ids: users }));
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const getUsersBasic = createAsyncThunk(
  'user/get',
  async (filter, {dispatch, rejectWithValue}) => {
    try {
      const response = await api.user.getUsersBasic(filter);
      const users = [...new Set(response.data.items.map(p => p._id))];
      // dispatch(findAvatars({ key: `users/avatars`, type: 'user', ids: users }));
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const getUsersForMention = createAsyncThunk(
  'user/getUsersForMention',
  async ({type, id}, {rejectWithValue}) => {
    try {
      const response = await api.user.getUsersForMention(type, id);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const findUsers = createAsyncThunk('user/findByIds', async ids => {
  const response = await api.user.findByIds(ids);
  return response.data;
});

export const getUser = createAsyncThunk('user/getUser', async (id, {dispatch, rejectWithValue}) => {
  try {
    const response = await api.user.getUser(id);
    // dispatch(findAvatars({  key: `user/${id}`, type: 'user', ids: [id] }));
    return response.data;
  } catch (e) {
    const {data, status, statusText, message} = e.response;
    return rejectWithValue({data, status, statusText, message});
  }
});

export const createUser = createAsyncThunk('user/createUser', async ({form}, {rejectWithValue}) => {
  try {
    const response = await api.user.createUser(form);
    return response.data;
  } catch (e) {
    const {data, status, statusText, message} = e.response;
    return rejectWithValue({data, status, statusText, message});
  }
});

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({id, form}, {dispatch, rejectWithValue, getState}) => {
    try {
      const {auth} = getState();
      const response = await api.user.updateUser(id, form);
      if (id === auth.user._id) dispatch(updateUserData(response.data));
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateUserPass = createAsyncThunk(
  'user/updateUserPass',
  async ({id, form}, {rejectWithValue}) => {
    try {
      const response = await api.user.updateUserPass(id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteUser = createAsyncThunk('user/deleteUser', async ({id, filter}, {dispatch}) => {
  const response = await api.user.deleteUser(id);
  await dispatch(getUsers(filter));
  return response.data;
});

export const grandAccess = createAsyncThunk(
  'user/grandAccess',
  async ({type, id, form}, {rejectWithValue}) => {
    try {
      const response = await api.user.grandAccess(type, id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const getAccesses = createAsyncThunk(
  'user/getAccesses',
  async ({type, id}, {rejectWithValue}) => {
    try {
      const response = await api.user.getAccesses(type, id);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const updateAccess = createAsyncThunk(
  'user/updateAccess',
  async ({id, form}, {rejectWithValue}) => {
    try {
      const response = await api.user.updateAccess(id, form);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const deleteAccess = createAsyncThunk(
  'user/deleteAccess',
  async ({id}, {rejectWithValue}) => {
    try {
      const response = await api.user.deleteAccess(id);
      return response.data;
    } catch (e) {
      const {data, status, statusText, message} = e.response;
      return rejectWithValue({data, status, statusText, message});
    }
  }
);

export const user = createSlice({
  name: 'user',
  initialState: {
    item: {},
    items: [],
    search: {},
    brandUsers: {},
    total: 0,
    fetching: false,
    deleting: {},
    error: null,
    asyncState: {},
    accesses: [],
  },
  reducers: {
    dismissError(state, action) {
      delete state.asyncState[action.payload];
    },
  },
  extraReducers: {
    //
    [getUsers.pending]: state => ({...state, fetching: true, error: null}),
    [getUsers.fulfilled]: (state, {payload}) => ({
      ...state,
      items: payload.items,
      total: payload.total,
      fetching: false,
      error: null,
    }),
    [getUsers.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    //
    [getUsersForMention.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null, users: []};
    },
    [getUsersForMention.fulfilled]: (state, {meta, payload}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: null, users: payload};
    },
    [getUsersForMention.rejected]: (state, {meta, payload}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload, users: []};
    },
    //
    [findUsers.pending]: state => ({...state, fetching: true, error: null}),
    [findUsers.fulfilled]: (state, {payload}) => ({
      ...state,
      search: {...state.search, ...payload},
      fetching: false,
      error: null,
    }),
    [findUsers.rejected]: (state, {error}) => ({...state, fetching: false, error}),
    //
    [getUser.pending]: state => ({...state, fetching: true, error: null}),
    [getUser.fulfilled]: (state, {payload}) => ({
      ...state,
      item: payload,
      fetching: false,
      error: null,
    }),
    [getUser.rejected]: (state, {error}) => ({...state, item: {}, error, fetching: false}),
    //
    [createUser.pending]: state => ({...state, fetching: true, error: null}),
    [createUser.fulfilled]: (state, {payload}) => {
      state.items.unshift(payload);
      state.fetching = false;
      state.error = null;
    },
    [createUser.rejected]: (state, {error, payload}) => ({
      ...state,
      error: payload || error,
      fetching: false,
    }),
    //
    [updateUser.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateUser.fulfilled]: (state, {payload, meta}) => {
      state.item = payload;
      state.items = state.items.map(i => (i._id === payload._id ? payload : i));
      delete state.asyncState[meta.arg.key];
    },
    [updateUser.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [updateUserPass.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateUserPass.fulfilled]: (state, {payload, meta}) => {
      state.items = state.items.map(i => (i._id === payload._id ? payload : i));
      delete state.asyncState[meta.arg.key];
    },
    [updateUserPass.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
    //
    [deleteUser.pending]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: true},
      error: null,
    }),
    [deleteUser.fulfilled]: (state, {meta}) => ({
      ...state,
      deleting: {[meta.arg.id]: false},
      error: null,
    }),
    [deleteUser.rejected]: (state, {meta, error}) => ({
      ...state,
      error,
      deleting: {[meta.arg.id]: false},
    }),

    [getAccesses.pending]: (state, {meta}) => {
      state.accesses = [];
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [getAccesses.fulfilled]: (state, {payload, meta}) => {
      state.accesses = payload;
      delete state.asyncState[meta.arg.key];
    },
    [getAccesses.rejected]: (state, {payload, meta}) => {
      state.accesses = [];
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },

    [grandAccess.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [grandAccess.fulfilled]: (state, {payload, meta}) => {
      const index = state.accesses.findIndex(item => item.user._id === payload.user._id);
      if (index !== -1) {
        state.accesses[index] = payload;
      } else {
        state.accesses.push(payload);
      }
      delete state.asyncState[meta.arg.key];
    },
    [grandAccess.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },

    [updateAccess.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [updateAccess.fulfilled]: (state, {payload, meta}) => {
      state.accesses = state.accesses.map(item => {
        return item._id === meta.arg.id ? payload : item;
      });
      delete state.asyncState[meta.arg.key];
    },
    [updateAccess.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },

    [deleteAccess.pending]: (state, {meta}) => {
      state.asyncState[meta.arg.key] = {fetching: true, error: null};
    },
    [deleteAccess.fulfilled]: (state, {meta}) => {
      state.accesses = state.accesses.filter(item => item._id !== meta.arg.id);
      delete state.asyncState[meta.arg.key];
    },
    [deleteAccess.rejected]: (state, {payload, meta}) => {
      state.asyncState[meta.arg.key] = {fetching: false, error: payload};
    },
  },
});

export const {dismissError} = user.actions;
export default user.reducer;
