import React, {useMemo} from 'react';
import Select from 'react-select';
import FilterInput from './FilterInput';

const Filter = props => {
  const {filterId, setup, options, filters, setFilters, projectId, assets} = props;

  const {currentFilterField, currentFilterValue, currentFilterLabel} = useMemo(
    () => ({
      currentFilterField: filters[filterId]?.field,
      currentFilterValue: filters[filterId]?.value,
      currentFilterLabel: filters[filterId]?.label,
    }),
    [filters, filterId]
  );

  const filterFieldOptions = setup.adColumns[currentFilterField] ||
    setup.shotStructure[currentFilterField] || {
      type: currentFilterField,
      options: setup[currentFilterField],
    };

  const customInputStyles = {
    control: provided => {
      return {
        ...provided,
        border: 'none',
        borderRadius: '0',
      };
    },
  };

  const handleSelectChange = ({field, label}) => {
    setFilters(old => ({
      ...old,
      [filterId]: {
        field,
        value: field === 'sizes' ? setup.sizes : null,
        label,
      },
    }));
  };

  const handleFilterChange = value => {
    setFilters(old => ({
      ...old,
      [filterId]: {...old[filterId], value},
    }));
  };

  const handleRemoveClicked = () => {
    setFilters(old => {
      const {[filterId]: removed, ...undeleted} = old;

      return undeleted;
    });
  };

  return (
    <div className="dco-filter">
      <button
        type="button"
        onClick={handleRemoveClicked}
        className="border-0 gray-600-text bg-transparent align-self-end"
      >
        x
      </button>
      <div className="pb-4 pr-4 pl-4">
        {/* <span className="dco-edit-label ">Filter on</span> */}

        <Select
          isClearable={false}
          styles={customInputStyles}
          isSearchable={false}
          value={{label: currentFilterLabel, field: currentFilterField}}
          cacheOptions={false}
          menuPortalTarget={document.body}
          placeholder="Select filter "
          className="mb-3"
          options={options}
          onChange={handleSelectChange}
        />
        {currentFilterField && (
          <FilterInput
            value={currentFilterValue}
            field={filterFieldOptions}
            projectId={projectId}
            handleChange={handleFilterChange}
            assets={assets}
          />
        )}
      </div>
    </div>
  );
};

export default Filter;
