import React from 'react';
import {Formik, Form, Field} from 'formik';
import {ErrorMessages} from './error';

const SingleInputForm = ({
  value = '',
  placeholder = '',
  asyncState = {},
  onSubmit,
  show,
  dismiss = () => null,
  hideOnBlur = null,
  showError = true,
  type,
  mandatory = false,
  className = '',
}) => {
  const submit = async ({text}) => {
    const res = await onSubmit(text);
    if (!res || !res.error) {
      dismiss();
      return show(false);
    }
  };
  //onBlur={() => hideOnBlur && asyncState.fetching === false && asyncState.error === null ? show(false) : null }
  return (
    <Formik
      initialValues={{text: value}}
      onSubmit={submit}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {({}) => (
        <Form
          className={
            asyncState.fetching ? 'loading w-100 single-input-from' : 'w-100 single-input-from'
          }
        >
          <div className="form-group">
            <div className="d-flex align-items-center d-flex single-input-form">
              <Field
                type={type || 'text'}
                name="text"
                autoFocus
                placeholder={placeholder}
                className={`border-0 form-control m-0 p-0 rounded-0 ${className}`}
              />
              {!mandatory && (
                <div
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={() => {
                    dismiss();
                    show(false);
                  }}
                >
                  Cancel
                </div>
              )}
              <button type="submit" className="btn btn-sm btn-primary">
                Save
              </button>
            </div>
            {asyncState.error && showError && (
              <ErrorMessages error={asyncState.error} className="mt-1 alert alert-danger" />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SingleInputForm;
