/* eslint-disable no-nested-ternary */
import React from 'react';
import {Button} from 'react-bootstrap';
import {defaultLoaderButtonState} from './consts';

const LoaderButton = props => {
  const {
    className = '',
    loaderButtonStatus = {defaultLoaderButtonState},
    disabled = false,
    defaultText = 'Save',
    defaultColor = 'primary',
    loadingText = 'Saving',
    completedText = 'Saved',
    errorText = 'Error, please try again',
    onClick = () => {},
    classes = '',
  } = props;

  const text = () => {
    switch (true) {
      case loaderButtonStatus.loading:
        return loadingText;
      case loaderButtonStatus.completed:
        return completedText;
      case loaderButtonStatus.error:
        return errorText;
      default:
        return defaultText;
    }
  };

  return (
    <Button
      disabled={disabled || loaderButtonStatus.loading}
      type="button"
      onClick={onClick}
      className={`loader-button ${defaultColor} ${loaderButtonStatus.loading &&
        'active'} ${loaderButtonStatus.completed && 'completed'} ${loaderButtonStatus.error &&
        'error'} ${className} ${classes}`}
    >
      <div className={`loader-button-text ${loaderButtonStatus.loading && 'active'}`}>{text()}</div>
      <div className="loader-button-loader" />
      {loaderButtonStatus.completed && (
        <span className="icon icon-check ml-1" style={{backgroundColor: 'white'}} />
      )}
    </Button>
  );
};

export default LoaderButton;
