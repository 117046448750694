export const initialPrivateSharedData = {
  ads: [],
  brand: '',
  dcoSetupName: '',
  filters: [],
  limit: '',
  maxPage: null,
  page: '0',
  project: '',
  sharedBy: '',
  team: '',
  title: '',
};

export const defaultColDef = {
  flex: 1,
  resizable: true,
  minWidth: 200,
};
