import React from 'react';
import {findElementById} from '../../../../helpers';

const MasterSizeCell = ({value, sizes, data}) => {
  const {mastersize = false} = findElementById(sizes, data._id) || {};

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <span>{value}</span>
      {mastersize && <span className="icon icon-favorites-fill" />}
    </div>
  );
};

export default MasterSizeCell;
