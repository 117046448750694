import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';

import Access from 'helpers/access';
import Avatar from 'helpers/avatar';
import {getTeams} from 'pages/team/slice';
import CreateTeamForm from './CreateTeamForm';

const BrandTeamsList = ({brandId, teams, dispatch}) => {
  const [teamList, setTeamList] = useState(teams);

  useEffect(() => {
    (async () => {
      try {
        dispatch(getTeams({brandId}));
      } catch (error) {
        console.log('brand details: fetch failed: ', error);
      }
    })();
  }, [brandId, dispatch]);

  useEffect(() => {
    setTeamList(teams);
  }, [teams]);

  return (
    <Access type="team" parents={{brandId}}>
      {({isAllowed}) => (
        <>
          <div className="d-flex align-items-center">
            <h2 className="pt-2 pb-2 mb-0 mr-auto">Select a team</h2>
            {isAllowed('create') && (
              <CreateTeamForm
                id={brandId}
                callbackFn={newTeam => setTeamList(old => [...old, newTeam])}
              />
            )}
          </div>
          {!teamList.length && <h3>No teams available.</h3>}
          <div className="brand-teams-list">
            {teamList.map(t => (
              <NavLink key={t._id} to={`/team/view/${t._id}`} exact className="nav-link p-0">
                <Avatar type="team" id={t._id} className="thumbnail" />
                <p className="title mt-2 ">{t.name}</p>
              </NavLink>
            ))}
          </div>
        </>
      )}
    </Access>
  );
};

export default connect(store => ({
  teams: store.team.items || [],
}))(BrandTeamsList);
