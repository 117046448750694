import {connect} from 'react-redux';
import P404 from '../pages/404';

const Access = ({auth, parents = {}, children, type, action, item, overWritRole = true}) => {
  try {
    if (!auth.user || !type) return false;

    const initialRole = auth.user.role;
    const overwriteRole = () => {
      let tmpRole = null;
      if (auth.user.access.brand[parents.brandId]) {
        const brandRole = auth.user.access.brand[parents.brandId].role;
        if (brandRole === 'member') {
          tmpRole = brandRole;
        } else {
          return auth.user.access.brand[parents.brandId].role;
        }
      }

      if (auth.user.access.team[parents.teamId]) {
        const teamRole = auth.user.access.team[parents.teamId].role;
        if (teamRole === 'member') {
          tmpRole = teamRole;
        } else {
          return auth.user.access.team[parents.teamId].role;
        }
      }
      if (auth.user.access.project[parents.projectId]) {
        return auth.user.access.project[parents.projectId].role;
      }

      const allowedBrandsByTeam =
        Object.keys(auth.user.access.team).map(val => auth.user.access.team[val].brandId) || [];

      const allowedBrandsByProject =
        Object.keys(auth.user.access.project).map(val => auth.user.access.project[val].brandId) ||
        [];

      const allowedBrands = [...allowedBrandsByTeam, ...allowedBrandsByProject];
      if (parents.brandId && !parents.teamId && allowedBrands.includes(parents.brandId)) {
        return `member`;
      }
      return tmpRole;
    };

    let role = initialRole === 'admin' || !overWritRole ? initialRole : overwriteRole();

    //process.env.REACT_APP_ENV === 'development' && console.log('role:', role);
    if (!role) return false;

    if (action) {
      if (permissions(auth.user)?.[type]?.[action]?.[role]?.())
        return children({
          initialRole,
          role,
          isAllowed: (action, info) => permissions(auth.user, info)?.[type]?.[action]?.[role]?.(),
        });
      return false;
    }
    return children({
      initialRole,
      role,
      isAllowed: (action, info) => permissions(auth.user, info)?.[type]?.[action]?.[role]?.(),
    });
  } catch (e) {
    process.env.REACT_APP_ENV === 'development' && console.log(e);
    process.env.REACT_APP_ENV === 'development' && console.groupEnd();
    throw e;
  }
};

const permissions = (user, info) => {
  return {
    brand: {
      list: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      create: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,

        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
      get: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
      update: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
      uploadAvatar: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
      invite: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
    },
    color: {
      manageColors: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
    },
    videoTemplates: {
      manageVideoTemplates: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
    },
    file: {
      upload: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      delete: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      bin: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
    },
    comment: {
      create: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      update: {
        admin: () => user._id === info.createdBy,
        member: () => user._id === info.createdBy,
        teamManager: () => user._id === info.createdBy,
        support: () => user._id === info.createdBy,
        brandManager: () => user._id === info.createdBy,
      },
      resolve: {
        admin: () => true,
        member: () => user._id === info.createdBy,
        teamManager: () => true,
        support: () => true,
        brandManager: () => true,
      },
    },
    team: {
      list: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      get: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
      create: {
        admin: () => true,
        member: () => false,
        teamManager: () => Object.keys(user.access.brand).length > 0,
        support: () => true,
        projectManager: () => false,
        brandManager: () => Object.keys(user.access.brand).length > 0,
      },
      update: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
      delete: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
      invite: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
    },
    project: {
      list: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      get: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      create: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      update: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      delete: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      updateStatus: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      invite: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
    },
    videobuilder: {
      get: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
    },
    // user: {
    //   list: {
    //     'admin': () => true,
    //     'member': () => true,
    //     'teamManager': () => true,
    //
    //     'support': () => true,
    //
    //   },
    //   create: {
    //     'admin': () => true,
    //     'member': () => false,
    //     'teamManager': () => false,
    //
    //     'support': () => false,
    //
    //   },
    // },
    profile: {
      get: {
        admin: () => true,
        member: () => true,
        teamManager: () => true,
        support: () => true,
        projectManager: () => false,
        brandManager: () => true,
      },
      uploadAvatar: {
        admin: () => true,
        member: () => user._id === info._id,
        teamManager: () => user._id === info._id,
        support: () => user._id === info._id,
        brandManager: () => user._id === info._id,
      },
      update: {
        admin: () => true,
        member: () => user._id === info._id,
        teamManager: () => user._id === info._id,
        support: () => user._id === info._id,
        brandManager: () => user._id === info._id,
      },
      updatePassWithoutConfirm: {
        admin: () => user._id !== info._id,
        member: () => false,
        teamManager: () => false,
        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
      updateUserStatus: {
        admin: () => user._id !== info._id,
        member: () => false,
        teamManager: () => false,
        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
      updateUserRole: {
        admin: () => user._id !== info._id,
        member: () => false,
        teamManager: () => false,
        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
      updateSpecialization: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => false,
        projectManager: () => false,
        brandManager: () => false,
      },
    },
    dco: {
      creativeSetup: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      edit: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        brandManager: () => true,
        projectManager: () => true,
      },
      create: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        projectManager: () => true,
        brandManager: () => true,
      },
      delete: {
        admin: () => true,
        member: () => false,
        teamManager: () => true,
        support: () => true,
        brandManager: () => true,
        projectManager: () => true,
      },
      accessLog: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        brandManager: () => false,
        projectManager: () => false,
      },
      changeLog: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        brandManager: () => false,
        projectManager: () => false,
      },
      duplicateTo: {
        admin: () => true,
        member: () => false,
        teamManager: () => false,
        support: () => true,
        brandManager: () => false,
        projectManager: () => false,
      },
    },
  };
};

export default connect(store => ({
  auth: store.auth,
}))(Access);
