/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export const validateEmail = email => {
  if (!email) {
    return false;
  }
  // Regular expression pattern to match email addresses
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const getFilteredAds = (ads, filters) => {
  if (!ads) {
    return [];
  }
  const copy = _.cloneDeep(ads);
  return copy
    .map(ad => {
      // Filter by name
      if (filters.name && !ad.name.toLowerCase().includes(filters.name.toLowerCase())) {
        return null;
      }

      // Filter by status
      if (filters.status && ad.status.toLowerCase() !== filters.status.toLowerCase()) {
        return null;
      }

      // Filter by public status
      if (
        filters.publishedStatus &&
        ad.publishedStatus.toLowerCase() !== filters.publishedStatus.toLowerCase()
      ) {
        return null;
      }

      // Filter by sizes
      if (filters.sizes.length > 0) {
        const filteredPreviews = ad.previews.filter(adSize => {
          return !filters.sizes.some(filter => filter === adSize.creativeSetupTitle);
        });

        if (filteredPreviews.length === 0) {
          return null;
        }
        ad.previews = filteredPreviews;
      }

      // Filter by comments
      if (filters.hasComments && ad.comments === 0) {
        return null;
      }

      return ad;
    })
    .filter(ad => ad !== null);
};

export const getCommentTree = comments => {
  if (!comments) {
    return;
  }
  const copy = _.cloneDeep(comments);
  const commentMap = {};
  const nestedComments = [];

  // First, create a mapping of all comments by ID
  copy.forEach(comment => {
    commentMap[comment.commentId] = comment;
    comment.replys = [];
  });

  // Then, iterate over all comments and nest them by parentId
  copy.forEach(comment => {
    if (comment.parentId) {
      const parentComment = commentMap[comment.parentId];
      if (parentComment) {
        parentComment.replys.push(comment);
      }
    } else {
      nestedComments.push(comment);
    }
  });

  return nestedComments;
};

export const getUniqueDimensions = arr => {
  if (!arr) {
    return [];
  }
  // create new set with ad previews array
  const uniqueDimensions = arr.reduce((acc, cur) => {
    cur.previews.forEach(preview => {
      const {creativeSetupTitle} = preview;
      acc.add(creativeSetupTitle);
    });
    return acc;
  }, new Set());

  return Array.from(uniqueDimensions);
};

export const filterPreviews = data => {
  /* const newData = _.cloneDeep(data);
  const newAds = newData.ads.map(ad => {
    // Ad
    const newPreviewArr = ad.previews.filter(iframe => {
      // Preview
      const index = newData.filters.find(
        filter => filter.assetId === iframe.assetId && filter.enabled === false
      );
      if (index) {
        return;
      }
      return iframe;
    });

    ad.previews = newPreviewArr;
    return ad;
  });

  newData.ads = newAds;

  return newData; */

  const newData = _.cloneDeep(data);
  const disabledFilters = new Set(
    newData.filters.filter(filter => !filter.enabled).map(filter => filter.assetId)
  );

  const newAds = newData.ads
    .map(ad => {
      if (_.isEmpty(ad)) {
        return null;
      }
      const newPreviews = ad.previews.filter(preview => !disabledFilters.has(preview.assetId));
      return {...ad, previews: newPreviews};
    })
    .filter(ad => ad !== null);

  return {...newData, ads: newAds};
};
