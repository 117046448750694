import React from 'react';

const NoResult = () => (
  <div className="d-flex justify-content-center align-items-center">
    <img src={`${process.env.PUBLIC_URL}/images/noresult.png`} width="100" />
    <div className="d-flex justify-content-center align-items-center flex-column ml-3">
      <h1>No results found!</h1>
    </div>
  </div>
);

export default NoResult;
